export default function IkonkaNaczepa(props){
    return(
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg" style={props.style}
 width="444.000000pt" height="149.000000pt" viewBox="0 0 444.000000 149.000000"
 preserveAspectRatio="xMidYMid meet">
 {props.title ? <title>{props.title}</title> : ""}
 {props.wypelnienie ?
 <defs>
 <linearGradient id="wypelnienieFillNaczepa" x1="0" x2="0" y2="1">
     <stop offset={`${props.wypelnienie}%`} stopColor="#910a24" />
     <stop offset="0%" stopColor="#b1b1b1" />
 </linearGradient>
</defs>
 : ""}
<g transform="translate(0.000000,149.000000) scale(0.100000,-0.100000)"
fill="#b1b1b1" stroke="none">
<path d="M5 1478 c-3 -7 -4 -245 -3 -528 l3 -515 575 -5 c533 -5 578 -6 603
-23 38 -25 47 -54 47 -152 0 -83 0 -84 33 -109 l34 -26 581 0 582 0 0 63 c1
89 36 151 109 189 100 52 212 32 283 -51 19 -22 38 -41 43 -41 4 0 26 21 48
46 23 26 56 53 74 60 45 19 147 18 186 -2 33 -17 97 -80 97 -95 0 -20 28 -7
49 22 13 17 43 44 69 60 38 24 56 29 109 29 134 0 223 -89 223 -222 l0 -63
212 6 c191 5 223 9 308 33 52 15 105 34 117 42 55 36 53 10 51 675 l-3 614
-2213 3 c-1765 2 -2214 0 -2217 -10z"
fill={props.wypelnienie ? `url(#wypelnienieFillNaczepa)` : "#b1b1b1"}
/>
<path d="M2605 348 c-137 -76 -139 -260 -3 -329 92 -47 215 -3 254 90 35 85
-5 194 -88 237 -32 17 -135 18 -163 2z m145 -95 c20 -18 33 -41 37 -65 5 -33
2 -41 -30 -73 -28 -28 -43 -35 -75 -35 -33 0 -45 6 -71 35 -42 49 -41 87 3
131 44 44 92 46 136 7z"/>
<path d="M2637 222 c-38 -42 -8 -102 50 -102 55 0 85 67 45 103 -25 22 -75 21
-95 -1z"/>
<path d="M3025 348 c-137 -76 -139 -260 -3 -329 92 -47 215 -3 254 90 35 85
-5 194 -88 237 -32 17 -135 18 -163 2z m145 -95 c20 -18 33 -41 37 -65 5 -33
2 -41 -30 -73 -28 -28 -43 -35 -75 -35 -33 0 -45 6 -71 35 -42 49 -41 87 3
131 44 44 92 46 136 7z"/>
<path d="M3057 222 c-38 -42 -8 -102 50 -102 55 0 85 67 45 103 -25 22 -75 21
-95 -1z"/>
<path d="M3445 348 c-137 -76 -139 -260 -3 -329 92 -47 215 -3 254 90 35 85
-5 194 -88 237 -32 17 -135 18 -163 2z m145 -95 c20 -18 33 -41 37 -65 5 -33
2 -41 -30 -73 -28 -28 -43 -35 -75 -35 -33 0 -45 6 -71 35 -42 49 -41 87 3
131 44 44 92 46 136 7z"/>
<path d="M3477 222 c-38 -42 -8 -102 50 -102 55 0 85 67 45 103 -25 22 -75 21
-95 -1z"/>
</g>
</svg>

    );
};