const g_countries_json = [
    {
      "CountryId": 14,
      "Name": "Shqipëria",
      "CountryCode": "AL",
      "X": 39000.0,
      "Y": 55000.0,
      "LocalizationToken": "albania",
      "LocalizedNames": {
        "bg_bg": "Албания",
        "ca_es": "Albània",
        "cs_cz": "Albánie",
        "da_dk": "Albanien",
        "de_de": "Albanien",
        "el_gr": "Αλβανία",
        "en_gb": "Albania",
        "en_us": "Albania",
        "es_es": "Albania",
        "es_la": "Albania",
        "et_ee": "Albaania",
        "eu_es": "Albania",
        "fi_fi": "Albania",
        "fr_ca": "Albanie",
        "fr_fr": "Albanie",
        "gl_es": "Albania",
        "hr_hr": "Albanija",
        "hu_hu": "Albánia",
        "it_it": "Albania",
        "ja_jp": "アルバニア",
        "ka_ge": "ალბანეთი",
        "ko_kr": "알바니아",
        "lt_lt": "Albanija",
        "lv_lv": "Albānija",
        "mk_mk": "Албанија",
        "nl_nl": "Albanië",
        "no_no": "Albania",
        "pl_pl": "Albania",
        "pl_si": "Albanijŏ",
        "pt_br": "Albânia",
        "pt_pt": "Albânia",
        "ro_ro": "Albania",
        "ru_ru": "Албания",
        "sk_sk": "Albánsko",
        "sl_sl": "Albanija",
        "sr_sp": "Albanija",
        "sr_sr": "Албанија",
        "sv_se": "Albanien",
        "tr_tr": "Arnavutluk",
        "uk_uk": "Албанія",
        "vi_vn": "Albani",
        "zh_cn": "阿尔巴尼亚",
        "zh_tw": "阿爾巴尼亞"
      }
    },
    {
      "CountryId": 1,
      "Name": "Österreich",
      "CountryCode": "A",
      "X": 14940.0,
      "Y": 18780.0,
      "LocalizationToken": "austria",
      "LocalizedNames": {
        "bg_bg": "Австрия",
        "ca_es": "Àustria",
        "cs_cz": "Rakousko",
        "da_dk": "Østrig",
        "de_de": "Österreich",
        "el_gr": "Αυστρία",
        "en_gb": "Austria",
        "en_us": "Austria",
        "es_es": "Austria",
        "es_la": "Austria",
        "et_ee": "Austria",
        "eu_es": "Austria",
        "fi_fi": "Itävalta",
        "fr_ca": "Autriche",
        "fr_fr": "Autriche",
        "gl_es": "Austria",
        "hr_hr": "Austrija",
        "hu_hu": "Ausztria",
        "it_it": "Austria",
        "ja_jp": "オーストリア",
        "ka_ge": "ავსტრია",
        "ko_kr": "오스트리아",
        "lt_lt": "Austrija",
        "lv_lv": "Austrija",
        "mk_mk": "Австрија",
        "nl_nl": "Oostenrijk",
        "no_no": "Østerrike",
        "pl_pl": "Austria",
        "pl_si": "Austryjŏ",
        "pt_br": "Áustria",
        "pt_pt": "Áustria",
        "ro_ro": "Austria",
        "ru_ru": "Австрия",
        "sk_sk": "Rakúsko",
        "sl_sl": "Avstrija",
        "sr_sp": "Austrija",
        "sr_sr": "Аустрија",
        "sv_se": "Österrike",
        "tr_tr": "Avusturya",
        "uk_uk": "Австрія",
        "vi_vn": "Áo",
        "zh_cn": "奥地利",
        "zh_tw": "奧地利"
      }
    },
    {
      "CountryId": 2,
      "Name": "België",
      "CountryCode": "B",
      "X": -21133.0,
      "Y": -1133.0,
      "LocalizationToken": "belgium",
      "LocalizedNames": {
        "bg_bg": "Белгия",
        "ca_es": "Bèlgica",
        "cs_cz": "Belgie",
        "da_dk": "Belgien",
        "de_de": "Belgien",
        "el_gr": "Βέλγιο",
        "en_gb": "Belgium",
        "en_us": "Belgium",
        "es_es": "Bélgica",
        "es_la": "Bélgica",
        "et_ee": "Belgia",
        "eu_es": "Belgika",
        "fi_fi": "Belgia",
        "fr_ca": "Belgique",
        "fr_fr": "Belgique",
        "gl_es": "Bélxica",
        "hr_hr": "Belgija",
        "hu_hu": "Belgium",
        "it_it": "Belgio",
        "ja_jp": "ベルギー",
        "ka_ge": "ბელგია",
        "ko_kr": "벨기에",
        "lt_lt": "Belgija",
        "lv_lv": "Beļģija",
        "mk_mk": "Белгија",
        "nl_nl": "België",
        "no_no": "Belgia",
        "pl_pl": "Belgia",
        "pl_si": "Belgijŏ",
        "pt_br": "Bélgica",
        "pt_pt": "Bélgica",
        "ro_ro": "Belgia",
        "ru_ru": "Бельгия",
        "sk_sk": "Belgicko",
        "sl_sl": "Belgija",
        "sr_sp": "Belgija",
        "sr_sr": "Белгија",
        "sv_se": "Belgien",
        "tr_tr": "Belçika",
        "uk_uk": "Бельгія",
        "vi_vn": "Nước Bỉ",
        "zh_cn": "比利时",
        "zh_tw": "比利時"
      }
    },
    {
      "CountryId": 17,
      "Name": "Bosna i Hercegovina",
      "CountryCode": "BIH",
      "X": 29000.0,
      "Y": 36000.0,
      "LocalizationToken": "bosnia_and_herzegovina",
      "LocalizedNames": {
        "bg_bg": "Bosnia and Herzegovina",
        "ca_es": "Bòsnia i Hercegovina",
        "cs_cz": "Bosna a Hercegovina",
        "da_dk": "Bosnien-Hercegovina",
        "de_de": "Bosnien und Herzegowina",
        "el_gr": "Βοσνία και Ερζεγοβίνη",
        "en_gb": "Bosnia and Herzegovina",
        "en_us": "Bosnia and Herzegovina",
        "es_es": "Bosnia Herzegovina",
        "es_la": "Bosnia Herzegovina",
        "et_ee": "Bosnia and Herzegovina",
        "eu_es": "Bosnia eta Herzegovina",
        "fi_fi": "Bosnia and Herzegovina",
        "fr_ca": "Bosnie-Herzégovine",
        "fr_fr": "Bosnie-Herzégovine",
        "gl_es": "Bosnia and Herzegovina",
        "hr_hr": "Bosna i Hercegovina",
        "hu_hu": "Bosznia-Hercegovina",
        "it_it": "Bosnia Erzegovina",
        "ja_jp": "ボスニア・ヘルツェゴビナ",
        "ka_ge": "ბოსნია და ჰერცეგოვინა",
        "ko_kr": "보스니아 헤르체고비나",
        "lt_lt": "Bosnija ir Hercegovina",
        "lv_lv": "Bosnia and Herzegovina",
        "mk_mk": "Босна и Херцеговина",
        "nl_nl": "Bosnië en Herzegovina",
        "no_no": "Bosnia-Herzegovina",
        "pl_pl": "Bośnia i Hercegowina",
        "pl_si": "Bośnia i Hercegowina",
        "pt_br": "Bósnia e Herzegovina",
        "pt_pt": "Bósnia Herzegovina",
        "ro_ro": "Bosnia şi Herţegovina",
        "ru_ru": "Босния и Герцеговина",
        "sk_sk": "Bosna a Hercegovina",
        "sl_sl": "Bosna in Hercegovina",
        "sr_sp": "Bosna i Hercegovina",
        "sr_sr": "Босна и Херцеговина",
        "sv_se": "Bosnia and Herzegovina",
        "tr_tr": "Bosna Hersek",
        "uk_uk": "Боснія і Герцеговина",
        "vi_vn": "Bosnia and Herzegovina",
        "zh_cn": "波斯尼亚和黑塞哥维那",
        "zh_tw": "波士尼亞與赫塞哥維納"
      }
    },
    {
      "CountryId": 18,
      "Name": "България",
      "CountryCode": "BG",
      "X": 57500.0,
      "Y": 44000.0,
      "LocalizationToken": "bulgaria",
      "LocalizedNames": {
        "bg_bg": "България",
        "ca_es": "Bulgària",
        "cs_cz": "Bulharsko",
        "da_dk": "Bulgarien",
        "de_de": "Bulgarien",
        "el_gr": "Βουλγαρία",
        "en_gb": "Bulgaria",
        "en_us": "Bulgaria",
        "es_es": "Bulgaria",
        "es_la": "Bulgaria",
        "et_ee": "Bulgaaria",
        "eu_es": "Bulgaria",
        "fi_fi": "Bulgaria",
        "fr_ca": "Bulgarie",
        "fr_fr": "Bulgarie",
        "gl_es": "Bulgaria",
        "hr_hr": "Bugarska",
        "hu_hu": "Bulgária",
        "it_it": "Bulgaria",
        "ja_jp": "ブルガリア",
        "ka_ge": "ბულგარეთი",
        "ko_kr": "불가리아",
        "lt_lt": "Bulgarija",
        "lv_lv": "Bulgārija",
        "mk_mk": "Бугарија",
        "nl_nl": "Bulgarije",
        "no_no": "Bulgaria",
        "pl_pl": "Bułgaria",
        "pl_si": "Bułgaryjŏ",
        "pt_br": "Bulgária",
        "pt_pt": "Bulgária",
        "ro_ro": "Bulgaria",
        "ru_ru": "Болгария",
        "sk_sk": "Bulharsko",
        "sl_sl": "Bulgarija",
        "sr_sp": "Bugarska",
        "sr_sr": "Бугарска",
        "sv_se": "Bulgarien",
        "tr_tr": "Bulgaristan",
        "uk_uk": "Болгарія",
        "vi_vn": "Bulgaria",
        "zh_cn": "保加利亚",
        "zh_tw": "保加利亞"
      }
    },
    {
      "CountryId": 19,
      "Name": "Hrvatska",
      "CountryCode": "HR",
      "X": 23000.0,
      "Y": 29000.0,
      "LocalizationToken": "croatia",
      "LocalizedNames": {
        "bg_bg": "Хърватия",
        "ca_es": "Croàcia",
        "cs_cz": "Chorvatsko",
        "da_dk": "Kroatien",
        "de_de": "Kroatien",
        "el_gr": "Κροατία",
        "en_gb": "Croatia",
        "en_us": "Croatia",
        "es_es": "Croacia",
        "es_la": "Croacia",
        "et_ee": "Horvaatia",
        "eu_es": "Kroazia",
        "fi_fi": "Kroatia",
        "fr_ca": "Croatie",
        "fr_fr": "Croatie",
        "gl_es": "Croacia",
        "hr_hr": "Hrvatska",
        "hu_hu": "Horvátország",
        "it_it": "Croazia",
        "ja_jp": "クロアチア",
        "ka_ge": "ხორვატია",
        "ko_kr": "크로아티아",
        "lt_lt": "Kroatija",
        "lv_lv": "Horvātija",
        "mk_mk": "Хрватска",
        "nl_nl": "Kroatië",
        "no_no": "Kroatia",
        "pl_pl": "Chorwacja",
        "pl_si": "Chorwacyjŏ",
        "pt_br": "Croácia",
        "pt_pt": "Croácia",
        "ro_ro": "Croaţia",
        "ru_ru": "Хорватия",
        "sk_sk": "Chorvátsko",
        "sl_sl": "Hrvaška",
        "sr_sp": "Hrvatska",
        "sr_sr": "Хрватска",
        "sv_se": "Kroatien",
        "tr_tr": "Hırvatistan",
        "uk_uk": "Хорватія",
        "vi_vn": "Croatia",
        "zh_cn": "克罗地亚",
        "zh_tw": "克羅地亞"
      }
    },
    {
      "CountryId": 3,
      "Name": "Česká Republika",
      "CountryCode": "CZ",
      "X": 18500.0,
      "Y": 6000.0,
      "LocalizationToken": "czech",
      "LocalizedNames": {
        "bg_bg": "Република Чехия",
        "ca_es": "República Txeca",
        "cs_cz": "Česká republika",
        "da_dk": "Tjekkiet",
        "de_de": "Tschechische Republik",
        "el_gr": "Τσεχία",
        "en_gb": "Czech Republic",
        "en_us": "Czech Republic",
        "es_es": "República Checa",
        "es_la": "República Checa",
        "et_ee": "Tšehhi",
        "eu_es": "Txekiar Errepublika",
        "fi_fi": "Tšekki",
        "fr_ca": "République Tchèque",
        "fr_fr": "République Tchèque",
        "gl_es": "República Checa",
        "hr_hr": "Češka",
        "hu_hu": "Csehország",
        "it_it": "Repubblica Ceca",
        "ja_jp": "チェコ",
        "ka_ge": "ჩეხეთი",
        "ko_kr": "체코 공화국",
        "lt_lt": "Čekijos Respublika",
        "lv_lv": "Čehija",
        "mk_mk": "Чешка Република",
        "nl_nl": "Tsjechië",
        "no_no": "Tsjekkia",
        "pl_pl": "Czechy",
        "pl_si": "Czeskŏ Republika",
        "pt_br": "República Tcheca",
        "pt_pt": "República Checa",
        "ro_ro": "Cehia",
        "ru_ru": "Чешская Республика",
        "sk_sk": "Česká republika",
        "sl_sl": "Češka",
        "sr_sp": "Češka Republika",
        "sr_sr": "Чешка Република",
        "sv_se": "Tjeckien",
        "tr_tr": "Çek Cumhuriyeti",
        "uk_uk": "Чехія",
        "vi_vn": "Cộng Hòa Séc",
        "zh_cn": "捷克共和国",
        "zh_tw": "捷克共和國"
      }
    },
    {
      "CountryId": 21,
      "Name": "Danmark",
      "CountryCode": "DK",
      "X": -2100.0,
      "Y": -31000.0,
      "LocalizationToken": "denmark",
      "LocalizedNames": {
        "bg_bg": "Дания",
        "ca_es": "Dinamarca",
        "cs_cz": "Dánsko",
        "da_dk": "Danmark",
        "de_de": "Dänemark",
        "el_gr": "Δανία",
        "en_gb": "Denmark",
        "en_us": "Denmark",
        "es_es": "Dinamarca",
        "es_la": "Dinamarca",
        "et_ee": "Taani",
        "eu_es": "Danimarka",
        "fi_fi": "Tanska",
        "fr_ca": "Danemark",
        "fr_fr": "Danemark",
        "gl_es": "Dinamarca",
        "hr_hr": "Danska",
        "hu_hu": "Dánia",
        "it_it": "Danimarca",
        "ja_jp": "デンマーク",
        "ka_ge": "დანია",
        "ko_kr": "덴마크",
        "lt_lt": "Danija",
        "lv_lv": "Denmark",
        "mk_mk": "Данска",
        "nl_nl": "Denemarken",
        "no_no": "Danmark",
        "pl_pl": "Dania",
        "pl_si": "Danijŏ",
        "pt_br": "Dinamarca",
        "pt_pt": "Dinamarca",
        "ro_ro": "Danemarca",
        "ru_ru": "Дания",
        "sk_sk": "Dánsko ",
        "sl_sl": "Danska",
        "sr_sp": "Danska",
        "sr_sr": "Данска",
        "sv_se": "Danmark",
        "tr_tr": "Danimarka",
        "uk_uk": "Данія",
        "vi_vn": "Đan Mạch",
        "zh_cn": "丹麦",
        "zh_tw": "丹麥"
      }
    },
    {
      "CountryId": 22,
      "Name": "Eesti",
      "CountryCode": "EST",
      "X": 45000.0,
      "Y": -47500.0,
      "LocalizationToken": "estonia",
      "LocalizedNames": {
        "bg_bg": "Естония",
        "ca_es": "Estònia",
        "cs_cz": "Estonsko",
        "da_dk": "Estland",
        "de_de": "Estland",
        "el_gr": "Εσθονία",
        "en_gb": "Estonia",
        "en_us": "Estonia",
        "es_es": "Estonia",
        "es_la": "Estonia",
        "et_ee": "Eesti",
        "eu_es": "Estonia",
        "fi_fi": "Viro",
        "fr_ca": "Estonie",
        "fr_fr": "Estonie",
        "gl_es": "Estonia",
        "hr_hr": "Estonija",
        "hu_hu": "Észtország",
        "it_it": "Estonia",
        "ja_jp": "エストニア",
        "ka_ge": "ესტონეთი",
        "ko_kr": "에스토니아",
        "lt_lt": "Estija",
        "lv_lv": "Igaunija",
        "mk_mk": "Естонија",
        "nl_nl": "Estland",
        "no_no": "Estland",
        "pl_pl": "Estonia",
        "pl_si": "Estōnijŏ",
        "pt_br": "Estônia",
        "pt_pt": "Estónia",
        "ro_ro": "Estonia",
        "ru_ru": "Эстония",
        "sk_sk": "Estónsko",
        "sl_sl": "Estonija",
        "sr_sp": "Estonija",
        "sr_sr": "Естонија",
        "sv_se": "Estland",
        "tr_tr": "Estonya",
        "uk_uk": "Естонія",
        "vi_vn": "Estonia",
        "zh_cn": "爱沙尼亚",
        "zh_tw": "愛沙尼亞"
      }
    },
    {
      "CountryId": 23,
      "Name": "Suomi",
      "CountryCode": "FI",
      "X": 42000.0,
      "Y": -65000.0,
      "LocalizationToken": "finland",
      "LocalizedNames": {
        "bg_bg": "Финландия",
        "ca_es": "Finlàndia",
        "cs_cz": "Finsko",
        "da_dk": "Finland",
        "de_de": "Finnland",
        "el_gr": "Φινλανδία",
        "en_gb": "Finland",
        "en_us": "Finland",
        "es_es": "Finlandia",
        "es_la": "Finlandia",
        "et_ee": "Soome",
        "eu_es": "Finlandia",
        "fi_fi": "Suomi",
        "fr_ca": "Finlande",
        "fr_fr": "Finlande",
        "gl_es": "Finlandia",
        "hr_hr": "Finska",
        "hu_hu": "Finnország",
        "it_it": "Finlandia",
        "ja_jp": "フィンランド",
        "ka_ge": "ფინეთი",
        "ko_kr": "핀란드",
        "lt_lt": "Suomija",
        "lv_lv": "Somija",
        "mk_mk": "Финска",
        "nl_nl": "Finland",
        "no_no": "Finland",
        "pl_pl": "Finlandia",
        "pl_si": "Finlandyjŏ",
        "pt_br": "Finlândia",
        "pt_pt": "Finlândia",
        "ro_ro": "Finlanda",
        "ru_ru": "Финляндия",
        "sk_sk": "Fínsko",
        "sl_sl": "Finska",
        "sr_sp": "Finska",
        "sr_sr": "Финска",
        "sv_se": "Finland",
        "tr_tr": "Finlandiya",
        "uk_uk": "Фінляндія",
        "vi_vn": "Phần Lan",
        "zh_cn": "芬兰",
        "zh_tw": "芬蘭"
      }
    },
    {
      "CountryId": 4,
      "Name": "France",
      "CountryCode": "F",
      "X": -32380.0,
      "Y": 20210.0,
      "LocalizationToken": "france",
      "LocalizedNames": {
        "bg_bg": "Франция",
        "ca_es": "França",
        "cs_cz": "Francie",
        "da_dk": "Frankrig",
        "de_de": "Frankreich",
        "el_gr": "Γαλλία",
        "en_gb": "France",
        "en_us": "France",
        "es_es": "Francia",
        "es_la": "Francia",
        "et_ee": "Prantsusmaa",
        "eu_es": "Frantzia",
        "fi_fi": "Ranska",
        "fr_ca": "France",
        "fr_fr": "France",
        "gl_es": "Francia",
        "hr_hr": "Francuska",
        "hu_hu": "Franciaország",
        "it_it": "Francia",
        "ja_jp": "フランス",
        "ka_ge": "საფრანგეთი:",
        "ko_kr": "프랑스",
        "lt_lt": "Prancūzija",
        "lv_lv": "Francija",
        "mk_mk": "Франција",
        "nl_nl": "Frankrijk",
        "no_no": "Frankrike",
        "pl_pl": "Francja",
        "pl_si": "Francyjŏ",
        "pt_br": "França",
        "pt_pt": "França",
        "ro_ro": "Franţa",
        "ru_ru": "Франция",
        "sk_sk": "Francúzsko",
        "sl_sl": "Francija",
        "sr_sp": "Francuska",
        "sr_sr": "Француска",
        "sv_se": "Frankrike",
        "tr_tr": "Fransa",
        "uk_uk": "Франція",
        "vi_vn": "Pháp",
        "zh_cn": "法国",
        "zh_tw": "法國"
      }
    },
    {
      "CountryId": 5,
      "Name": "Deutschland",
      "CountryCode": "D",
      "X": -2770.0,
      "Y": -370.0,
      "LocalizationToken": "germany",
      "LocalizedNames": {
        "bg_bg": "Германия",
        "ca_es": "Alemanya",
        "cs_cz": "Německo",
        "da_dk": "Tyskland",
        "de_de": "Deutschland",
        "el_gr": "Γερμανία",
        "en_gb": "Germany",
        "en_us": "Germany",
        "es_es": "Alemania",
        "es_la": "Alemania",
        "et_ee": "Saksamaa",
        "eu_es": "Alemania",
        "fi_fi": "Saksa",
        "fr_ca": "Allemagne",
        "fr_fr": "Allemagne",
        "gl_es": "Alemaña",
        "hr_hr": "Njemačka",
        "hu_hu": "Németország",
        "it_it": "Germania",
        "ja_jp": "ドイツ",
        "ka_ge": "გერმანია",
        "ko_kr": "독일",
        "lt_lt": "Vokietija",
        "lv_lv": "Vācija",
        "mk_mk": "Германија",
        "nl_nl": "Duitsland",
        "no_no": "Tyskland",
        "pl_pl": "Niemcy",
        "pl_si": "Niymcy",
        "pt_br": "Alemanha",
        "pt_pt": "Alemanha",
        "ro_ro": "Germania",
        "ru_ru": "Германия",
        "sk_sk": "Nemecko",
        "sl_sl": "Nemčija",
        "sr_sp": "Nemačka",
        "sr_sr": "Немачка",
        "sv_se": "Tyskland",
        "tr_tr": "Almanya",
        "uk_uk": "Німеччина",
        "vi_vn": "Đức",
        "zh_cn": "德国",
        "zh_tw": "德國"
      }
    },
    {
      "CountryId": 13,
      "Name": "Magyarország",
      "CountryCode": "H",
      "X": 32055.0,
      "Y": 21000.0,
      "LocalizationToken": "hungary",
      "LocalizedNames": {
        "bg_bg": "Унгария",
        "ca_es": "Hongria",
        "cs_cz": "Maďarsko",
        "da_dk": "Ungarn",
        "de_de": "Ungarn",
        "el_gr": "Ουγγαρία",
        "en_gb": "Hungary",
        "en_us": "Hungary",
        "es_es": "Hungría",
        "es_la": "Hungría",
        "et_ee": "Ungari",
        "eu_es": "Hungaria",
        "fi_fi": "Unkari",
        "fr_ca": "Hongrie",
        "fr_fr": "Hongrie",
        "gl_es": "Hungría",
        "hr_hr": "Madžarska",
        "hu_hu": "Magyarország",
        "it_it": "Ungheria",
        "ja_jp": "ハンガリー",
        "ka_ge": "უნგრეთი",
        "ko_kr": "헝가리",
        "lt_lt": "Vengrija",
        "lv_lv": "Ungārija",
        "mk_mk": "Унгарија",
        "nl_nl": "Hongarije",
        "no_no": "Ungarn",
        "pl_pl": "Węgry",
        "pl_si": "Madziary",
        "pt_br": "Hungria",
        "pt_pt": "Hungria",
        "ro_ro": "Ungaria",
        "ru_ru": "Венгрия",
        "sk_sk": "Maďarsko",
        "sl_sl": "Madžarska",
        "sr_sp": "Mađarska",
        "sr_sr": "Мађарска",
        "sv_se": "Ungern",
        "tr_tr": "Macaristan",
        "uk_uk": "Угорщина",
        "vi_vn": "Hungari",
        "zh_cn": "匈牙利",
        "zh_tw": "匈牙利"
      }
    },
    {
      "CountryId": 6,
      "Name": "Italia",
      "CountryCode": "I",
      "X": -4210.0,
      "Y": 31600.0,
      "LocalizationToken": "italy",
      "LocalizedNames": {
        "bg_bg": "Италия",
        "ca_es": "Itàlia",
        "cs_cz": "Itálie",
        "da_dk": "Italien",
        "de_de": "Italien",
        "el_gr": "Ιταλία",
        "en_gb": "Italy",
        "en_us": "Italy",
        "es_es": "Italia",
        "es_la": "Italia",
        "et_ee": "Itaalia",
        "eu_es": "Italia",
        "fi_fi": "Italia",
        "fr_ca": "Italie",
        "fr_fr": "Italie",
        "gl_es": "Italia",
        "hr_hr": "Italija",
        "hu_hu": "Olaszország",
        "it_it": "Italia",
        "ja_jp": "イタリア",
        "ka_ge": "იტალია",
        "ko_kr": "이탈리아",
        "lt_lt": "Italija",
        "lv_lv": "Itālija",
        "mk_mk": "Италија",
        "nl_nl": "Italië",
        "no_no": "Italia",
        "pl_pl": "Włochy",
        "pl_si": "Italijŏ",
        "pt_br": "Itália",
        "pt_pt": "Itália",
        "ro_ro": "Italia",
        "ru_ru": "Италия",
        "sk_sk": "Taliansko",
        "sl_sl": "Italija",
        "sr_sp": "Italija",
        "sr_sr": "Италија",
        "sv_se": "Italien",
        "tr_tr": "İtalya",
        "uk_uk": "Італія",
        "vi_vn": "Nước Ý",
        "zh_cn": "意大利",
        "zh_tw": "義大利"
      }
    },
    {
      "CountryId": 204,
      "Name": "Kosovo",
      "CountryCode": "RKS",
      "X": 42000.0,
      "Y": 44000.0,
      "LocalizationToken": "kosovo",
      "LocalizedNames": {
        "bg_bg": "Косово",
        "ca_es": "Kosovo",
        "cs_cz": "Kosovo",
        "da_dk": "Kosovo",
        "de_de": "Kosovo",
        "el_gr": "Κόσοβο",
        "en_gb": "Kosovo",
        "en_us": "Kosovo",
        "es_es": "Kosovo",
        "es_la": "Kosovo",
        "et_ee": "Kosovo",
        "eu_es": "Kosobo",
        "fi_fi": "Kosovo",
        "fr_ca": "Kosovo",
        "fr_fr": "Kosovo",
        "gl_es": "Kosovo",
        "hr_hr": "Kosovo",
        "hu_hu": "Koszovó",
        "it_it": "Kosovo",
        "ja_jp": "コソボ",
        "ka_ge": "კოსოვო",
        "ko_kr": "코소보",
        "lt_lt": "Kosovas",
        "lv_lv": "Kosova",
        "mk_mk": "Косово",
        "nl_nl": "Kosovo",
        "no_no": "Kosovo",
        "pl_pl": "Kosowo",
        "pl_si": "Kosowo",
        "pt_br": "Kosovo",
        "pt_pt": "Kosovo",
        "ro_ro": "Kosovo",
        "ru_ru": "Косово",
        "sk_sk": "Kosovo",
        "sl_sl": "Kosovo",
        "sr_sp": "Kosovo i Metohija",
        "sr_sr": "Косово и Метохија",
        "sv_se": "Kosovo",
        "tr_tr": "Kosova",
        "uk_uk": "Косово",
        "vi_vn": "Kosovo",
        "zh_cn": "科索沃",
        "zh_tw": "科索沃"
      }
    },
    {
      "CountryId": 28,
      "Name": "Latvija",
      "CountryCode": "LV",
      "X": 49500.0,
      "Y": -39000.0,
      "LocalizationToken": "latvia",
      "LocalizedNames": {
        "bg_bg": "Латвия",
        "ca_es": "Letònia",
        "cs_cz": "Lotyšsko",
        "da_dk": "Letland",
        "de_de": "Lettland",
        "el_gr": "Λετονία",
        "en_gb": "Latvia",
        "en_us": "Latvia",
        "es_es": "Letonia",
        "es_la": "Letonia",
        "et_ee": "Läti",
        "eu_es": "Letonia",
        "fi_fi": "Latvia",
        "fr_ca": "Lettonie",
        "fr_fr": "Lettonie",
        "gl_es": "Letonia",
        "hr_hr": "Latvija",
        "hu_hu": "Lettország",
        "it_it": "Lettonia",
        "ja_jp": "ラトビア",
        "ka_ge": "ლატვია",
        "ko_kr": "라트비아",
        "lt_lt": "Latvija",
        "lv_lv": "Latvija",
        "mk_mk": "Латвија",
        "nl_nl": "Letland",
        "no_no": "Latvia",
        "pl_pl": "Łotwa",
        "pl_si": "Łotwa",
        "pt_br": "Letônia",
        "pt_pt": "Letónia",
        "ro_ro": "Letonia",
        "ru_ru": "Латвия",
        "sk_sk": "Lotyšsko",
        "sl_sl": "Latvija",
        "sr_sp": "Latvija",
        "sr_sr": "Латвија",
        "sv_se": "Lettland",
        "tr_tr": "Letonya",
        "uk_uk": "Латвія",
        "vi_vn": "Latvia",
        "zh_cn": "拉脱维亚",
        "zh_tw": "拉脫維亞"
      }
    },
    {
      "CountryId": 30,
      "Name": "Lietuva",
      "CountryCode": "LT",
      "X": 42000.0,
      "Y": -28000.0,
      "LocalizationToken": "lithuania",
      "LocalizedNames": {
        "bg_bg": "Литва",
        "ca_es": "Lituània",
        "cs_cz": "Litva",
        "da_dk": "Litauen",
        "de_de": "Litauen",
        "el_gr": "Λιθουανία",
        "en_gb": "Lithuania",
        "en_us": "Lithuania",
        "es_es": "Lituania",
        "es_la": "Lituania",
        "et_ee": "Leedu",
        "eu_es": "Lituania",
        "fi_fi": "Liettua",
        "fr_ca": "Lituanie",
        "fr_fr": "Lituanie",
        "gl_es": "Lituania",
        "hr_hr": "Litva",
        "hu_hu": "Litvánia",
        "it_it": "Lituania",
        "ja_jp": "リトアニア",
        "ka_ge": "ლიეტუვა",
        "ko_kr": "리투아니아",
        "lt_lt": "Lietuva",
        "lv_lv": "Lietuva",
        "mk_mk": "Литванија",
        "nl_nl": "Litouwen",
        "no_no": "Litauen",
        "pl_pl": "Litwa",
        "pl_si": "Litwa",
        "pt_br": "Lituânia",
        "pt_pt": "Lituânia",
        "ro_ro": "Lituania",
        "ru_ru": "Литва",
        "sk_sk": "Litva",
        "sl_sl": "Litva",
        "sr_sp": "Litvanija",
        "sr_sr": "Литванија",
        "sv_se": "Litauen",
        "tr_tr": "Litvanya",
        "uk_uk": "Литва",
        "vi_vn": "Lithuania",
        "zh_cn": "立陶宛",
        "zh_tw": "立陶宛"
      }
    },
    {
      "CountryId": 12,
      "Name": "Lëtzebuerg",
      "CountryCode": "L",
      "X": -17090.0,
      "Y": 2880.0,
      "LocalizationToken": "luxembourg",
      "LocalizedNames": {
        "bg_bg": "Люксембург",
        "ca_es": "Luxemburg",
        "cs_cz": "Lucembursko",
        "da_dk": "Luxembourg",
        "de_de": "Luxemburg",
        "el_gr": "Λουξεμβούργο",
        "en_gb": "Luxembourg",
        "en_us": "Luxembourg",
        "es_es": "Luxemburgo",
        "es_la": "Luxemburgo",
        "et_ee": "Luksemburg",
        "eu_es": "Luxenburgo",
        "fi_fi": "Luxemburg",
        "fr_ca": "Luxembourg",
        "fr_fr": "Luxembourg",
        "gl_es": "Luxemburgo",
        "hr_hr": "Luksemburg",
        "hu_hu": "Luxemburg",
        "it_it": "Lussemburgo",
        "ja_jp": "ルクセンブルク",
        "ka_ge": "ლუქსემბურგი",
        "ko_kr": "룩셈부르크",
        "lt_lt": "Liuksemburgas",
        "lv_lv": "Luksemburga",
        "mk_mk": "Луксембург",
        "nl_nl": "Luxemburg",
        "no_no": "Luxembourg",
        "pl_pl": "Luksemburg",
        "pl_si": "Luksymburg",
        "pt_br": "Luxemburgo",
        "pt_pt": "Luxemburgo",
        "ro_ro": "Luxemburg",
        "ru_ru": "Люксембург",
        "sk_sk": "Luxembursko",
        "sl_sl": "Luksemburg",
        "sr_sp": "Luksemburg",
        "sr_sr": "Луксембург",
        "sv_se": "Luxemburg",
        "tr_tr": "Lüksemburg",
        "uk_uk": "Люксембург",
        "vi_vn": "Lúc Xăm Bua",
        "zh_cn": "卢森堡",
        "zh_tw": "盧森堡"
      }
    },
    {
      "CountryId": 203,
      "Name": "Северна Македонија",
      "CountryCode": "NMK",
      "X": 45500.0,
      "Y": 49000.0,
      "LocalizationToken": "north_macedonia",
      "LocalizedNames": {
        "bg_bg": "North Macedonia",
        "ca_es": "Macedònia del Nord",
        "cs_cz": "Severní Makedonie",
        "da_dk": "Nordmakedonien",
        "de_de": "Nordmazedonien",
        "el_gr": "Βόρεια Μακεδονία",
        "en_gb": "North Macedonia",
        "en_us": "North Macedonia",
        "es_es": "Macedonia del Norte",
        "es_la": "Macedonia del Norte",
        "et_ee": "North Macedonia",
        "eu_es": "Ipar Mazedonia",
        "fi_fi": "North Macedonia",
        "fr_ca": "Macédoine du Nord",
        "fr_fr": "Macédoine du Nord",
        "gl_es": "North Macedonia",
        "hr_hr": "Sjeverna Makedonija",
        "hu_hu": "Észak-Macedónia",
        "it_it": "Nord Macedonia",
        "ja_jp": "北マケドニア共和国",
        "ka_ge": "ჩრდილოეთი მაკედონია",
        "ko_kr": "북마케도니아",
        "lt_lt": "Šiaurės Makedonija",
        "lv_lv": "North Macedonia",
        "mk_mk": "Северна Македонија",
        "nl_nl": "Noord-Macedonië",
        "no_no": "Nord-Makedonia",
        "pl_pl": "Macedonia Północna",
        "pl_si": "Macedonia Północna",
        "pt_br": "Macedônia do Norte",
        "pt_pt": "Macedónia do Norte",
        "ro_ro": "Macedonia de Nord",
        "ru_ru": "Северная Македония",
        "sk_sk": "Severné Macedónsko",
        "sl_sl": "Severna Makedonija",
        "sr_sp": "Severna Makedonija",
        "sr_sr": "Северна Македонија",
        "sv_se": "North Macedonia",
        "tr_tr": "Kuzey Makedonya",
        "uk_uk": "Північна Македонія",
        "vi_vn": "North Macedonia",
        "zh_cn": "北马其顿",
        "zh_tw": "北馬其頓"
      }
    },
    {
      "CountryId": 34,
      "Name": "Црна Гора",
      "CountryCode": "MNE",
      "X": 35000.0,
      "Y": 44000.0,
      "LocalizationToken": "montenegro",
      "LocalizedNames": {
        "bg_bg": "Черна гора",
        "ca_es": "Montenegro",
        "cs_cz": "Černá Hora",
        "da_dk": "Montenegro",
        "de_de": "Montenegro",
        "el_gr": "Μαυροβούνιο",
        "en_gb": "Montenegro",
        "en_us": "Montenegro",
        "es_es": "Montenegro",
        "es_la": "Montenegro",
        "et_ee": "Montenegro",
        "eu_es": "Montenegro",
        "fi_fi": "Montenegro",
        "fr_ca": "Monténégro",
        "fr_fr": "Monténégro",
        "gl_es": "Montenegro",
        "hr_hr": "Crna Gora",
        "hu_hu": "Montenegró",
        "it_it": "Montenegro",
        "ja_jp": "モンテネグロ",
        "ka_ge": "მონტენეგრო",
        "ko_kr": "몬테네그로",
        "lt_lt": "Juodkalnija",
        "lv_lv": "Montenegro",
        "mk_mk": "Црна Гора",
        "nl_nl": "Montenegro",
        "no_no": "Montenegro",
        "pl_pl": "Czarnogóra",
        "pl_si": "Czŏrnŏ Gōra",
        "pt_br": "Montenegro",
        "pt_pt": "Montenegro",
        "ro_ro": "Muntenegru",
        "ru_ru": "Черногория",
        "sk_sk": "Čierna Hora",
        "sl_sl": "Črna gora",
        "sr_sp": "Crna Gora",
        "sr_sr": "Црна Гора",
        "sv_se": "Montenegro",
        "tr_tr": "Karadağ",
        "uk_uk": "Чорногорія",
        "vi_vn": "Montenegro",
        "zh_cn": "黑山",
        "zh_tw": "黑山(蒙特內哥羅)"
      }
    },
    {
      "CountryId": 7,
      "Name": "Nederland",
      "CountryCode": "NL",
      "X": -15575.0,
      "Y": -9660.0,
      "LocalizationToken": "netherlands",
      "LocalizedNames": {
        "bg_bg": "Холандия",
        "ca_es": "Països Baixos",
        "cs_cz": "Nizozemsko",
        "da_dk": "Holland",
        "de_de": "Niederlande",
        "el_gr": "Ολλανδία",
        "en_gb": "Netherlands",
        "en_us": "Netherlands",
        "es_es": "Países Bajos",
        "es_la": "Países Bajos",
        "et_ee": "Holland",
        "eu_es": "Herbehereak",
        "fi_fi": "Alankomaat",
        "fr_ca": "Pays-Bas",
        "fr_fr": "Pays-Bas",
        "gl_es": "Países Baixos",
        "hr_hr": "Nizozemska",
        "hu_hu": "Hollandia",
        "it_it": "Paesi Bassi",
        "ja_jp": "オランダ",
        "ka_ge": "ნიდერლანდი",
        "ko_kr": "네덜란드",
        "lt_lt": "Olandija",
        "lv_lv": "Nīderlande",
        "mk_mk": "Холандија",
        "nl_nl": "Nederland",
        "no_no": "Nederland",
        "pl_pl": "Holandia",
        "pl_si": "Niderlandy",
        "pt_br": "Holanda",
        "pt_pt": "Holanda",
        "ro_ro": "Olanda",
        "ru_ru": "Нидерланды",
        "sk_sk": "Holandsko",
        "sl_sl": "Nizozemska",
        "sr_sp": "Holandija",
        "sr_sr": "Холандија",
        "sv_se": "Nederländerna",
        "tr_tr": "Hollanda",
        "uk_uk": "Нідерланди",
        "vi_vn": "Hà Lan",
        "zh_cn": "荷兰",
        "zh_tw": "荷蘭"
      }
    },
    {
      "CountryId": 35,
      "Name": "Norge",
      "CountryCode": "N",
      "X": -2800.0,
      "Y": -55000.0,
      "LocalizationToken": "norway",
      "LocalizedNames": {
        "bg_bg": "Норвегия",
        "ca_es": "Noruega",
        "cs_cz": "Norsko",
        "da_dk": "Norge",
        "de_de": "Norwegen",
        "el_gr": "Νορβηγία",
        "en_gb": "Norway",
        "en_us": "Norway",
        "es_es": "Noruega",
        "es_la": "Noruega",
        "et_ee": "Norra",
        "eu_es": "Norvegia",
        "fi_fi": "Norja",
        "fr_ca": "Norvège",
        "fr_fr": "Norvège",
        "gl_es": "Noruega",
        "hr_hr": "Norveška",
        "hu_hu": "Norvégia",
        "it_it": "Norvegia",
        "ja_jp": "ノルウェー",
        "ka_ge": "ნორვეგია",
        "ko_kr": "노르웨이",
        "lt_lt": "Norvegija",
        "lv_lv": "Norway",
        "mk_mk": "Норвешка",
        "nl_nl": "Noorwegen",
        "no_no": "Norge",
        "pl_pl": "Norwegia",
        "pl_si": "Norwegijŏ",
        "pt_br": "Noruega",
        "pt_pt": "Noruega",
        "ro_ro": "Norvegia",
        "ru_ru": "Норвегия",
        "sk_sk": "Nórsko ",
        "sl_sl": "Norveška",
        "sr_sp": "Norveška",
        "sr_sr": "Норвешка",
        "sv_se": "Norge",
        "tr_tr": "Norveç",
        "uk_uk": "Норвегія",
        "vi_vn": "Na Uy",
        "zh_cn": "挪威",
        "zh_tw": "挪威"
      }
    },
    {
      "CountryId": 8,
      "Name": "Polska",
      "CountryCode": "PL",
      "X": 32130.0,
      "Y": -8330.0,
      "LocalizationToken": "poland",
      "LocalizedNames": {
        "bg_bg": "Полша",
        "ca_es": "Polònia",
        "cs_cz": "Polsko",
        "da_dk": "Polen",
        "de_de": "Polen",
        "el_gr": "Πολωνία",
        "en_gb": "Poland",
        "en_us": "Poland",
        "es_es": "Polonia",
        "es_la": "Polonia",
        "et_ee": "Poola",
        "eu_es": "Polonia",
        "fi_fi": "Puola",
        "fr_ca": "Pologne",
        "fr_fr": "Pologne",
        "gl_es": "Polonia",
        "hr_hr": "Poljska",
        "hu_hu": "Lengyelország",
        "it_it": "Polonia",
        "ja_jp": "ポーランド",
        "ka_ge": "პოლონეთი",
        "ko_kr": "폴란드",
        "lt_lt": "Lenkija",
        "lv_lv": "Polija",
        "mk_mk": "Полска",
        "nl_nl": "Polen",
        "no_no": "Polen",
        "pl_pl": "Polska",
        "pl_si": "Polska",
        "pt_br": "Polônia",
        "pt_pt": "Polónia",
        "ro_ro": "Polonia",
        "ru_ru": "Польша",
        "sk_sk": "Poľsko",
        "sl_sl": "Poljska",
        "sr_sp": "Poljska",
        "sr_sr": "Пољска",
        "sv_se": "Polen",
        "tr_tr": "Polonya",
        "uk_uk": "Польща",
        "vi_vn": "Ba Lan",
        "zh_cn": "波兰",
        "zh_tw": "波蘭"
      }
    },
    {
      "CountryId": 36,
      "Name": "Portugal",
      "CountryCode": "P",
      "X": -87500.0,
      "Y": 47000.0,
      "LocalizationToken": "portugal",
      "LocalizedNames": {
        "bg_bg": "Португалия",
        "ca_es": "Portugal",
        "cs_cz": "Portugalsko",
        "da_dk": "Portugal",
        "de_de": "Portugal",
        "el_gr": "Πορτογαλία",
        "en_gb": "Portugal",
        "en_us": "Portugal",
        "es_es": "Portugal",
        "es_la": "Portugal",
        "et_ee": "Portugal",
        "eu_es": "Portugal",
        "fi_fi": "Portugali",
        "fr_ca": "Portugal",
        "fr_fr": "Portugal",
        "gl_es": "Portugal",
        "hr_hr": "Portugal",
        "hu_hu": "Portugália",
        "it_it": "Portogallo",
        "ja_jp": "ポルトガル",
        "ka_ge": "პორტუგალია",
        "ko_kr": "포르투갈",
        "lt_lt": "Portugalija",
        "lv_lv": "Portugāle",
        "mk_mk": "Португалија",
        "nl_nl": "Portugal",
        "no_no": "Portugal",
        "pl_pl": "Portugalia",
        "pl_si": "Portugalijŏ",
        "pt_br": "Portugal",
        "pt_pt": "Portugal",
        "ro_ro": "Portugalia",
        "ru_ru": "Португалия",
        "sk_sk": "Portugalsko",
        "sl_sl": "Portugalska",
        "sr_sp": "Portugal",
        "sr_sr": "Португал",
        "sv_se": "Portugal",
        "tr_tr": "Portekiz",
        "uk_uk": "Португалія",
        "vi_vn": "Bồ Đào Nha",
        "zh_cn": "葡萄牙",
        "zh_tw": "葡萄牙"
      }
    },
    {
      "CountryId": 37,
      "Name": "România",
      "CountryCode": "RO",
      "X": 57500.0,
      "Y": 26000.0,
      "LocalizationToken": "romania",
      "LocalizedNames": {
        "bg_bg": "Румъния",
        "ca_es": "Romania",
        "cs_cz": "Rumunsko",
        "da_dk": "Rumænien",
        "de_de": "Rumänien",
        "el_gr": "Ρουμανία",
        "en_gb": "Romania",
        "en_us": "Romania",
        "es_es": "Rumanía",
        "es_la": "Rumanía",
        "et_ee": "Rumeenia",
        "eu_es": "Errumania",
        "fi_fi": "Romania",
        "fr_ca": "Roumanie",
        "fr_fr": "Roumanie",
        "gl_es": "Romanía",
        "hr_hr": "Rumunjska",
        "hu_hu": "Románia",
        "it_it": "Romania",
        "ja_jp": "ルーマニア",
        "ka_ge": "რუმინეთი",
        "ko_kr": "루마니아",
        "lt_lt": "Rumunija",
        "lv_lv": "Rumānija",
        "mk_mk": "Романија",
        "nl_nl": "Roemenië",
        "no_no": "Romania",
        "pl_pl": "Rumunia",
        "pl_si": "Rumynijŏ",
        "pt_br": "Romênia",
        "pt_pt": "Roménia",
        "ro_ro": "România",
        "ru_ru": "Румыния",
        "sk_sk": "Rumunsko",
        "sl_sl": "Romunija",
        "sr_sp": "Rumunija",
        "sr_sr": "Румунија",
        "sv_se": "Rumänien",
        "tr_tr": "Romanya",
        "uk_uk": "Румунія",
        "vi_vn": "Romania",
        "zh_cn": "罗马尼亚",
        "zh_tw": "羅馬尼亞"
      }
    },
    {
      "CountryId": 7777,
      "Name": "Россия",
      "CountryCode": "RU",
      "X": 36500.0,
      "Y": -24000.0,
      "LocalizedNames": {
        "bg_bg": "Русия",
        "ca_es": "Rússia",
        "cs_cz": "Rusko",
        "da_dk": "Rusland",
        "de_de": "Russland",
        "el_gr": "Ρωσία",
        "en_gb": "Russia",
        "en_us": "Russia",
        "es_es": "Rusia",
        "es_la": "Rusia",
        "et_ee": "Venemaa",
        "eu_es": "Errusia",
        "fi_fi": "Venäjä",
        "fr_fr": "Russie",
        "gl_es": "Rusia",
        "hr_hr": "Rusija",
        "hu_hu": "Oroszország",
        "it_it": "Russia",
        "ja_jp": "ロシア",
        "ka_ge": "რუსეთი",
        "ko_kr": "러시아",
        "lt_lt": "Rusija",
        "lv_lv": "Krievija",
        "mk_mk": "Русија",
        "nl_nl": "Rusland",
        "no_no": "Russland",
        "pl_pl": "Rosja",
        "pl_si": "Rusyjŏ",
        "pt_br": "Rússia",
        "pt_pt": "Rússia",
        "ro_ro": "Rusia",
        "ru_ru": "Россия",
        "sk_sk": "Rusko",
        "sl_sl": "Rusija",
        "sr_sp": "Rusija",
        "sr_sr": "Русија",
        "sv_se": "Ryssland",
        "tr_tr": "Rusya",
        "uk_uk": "Росія",
        "vi_vn": "Nga",
        "zh_cn": "俄罗斯",
        "zh_tw": "俄羅斯"
      }
    },
    {
      "CountryId": 38,
      "Name": "Россия",
      "CountryCode": "RU",
      "X": 59500.0,
      "Y": -51500.0,
      "LocalizationToken": "russia",
      "LocalizedNames": {
        "bg_bg": "Русия",
        "ca_es": "Rússia",
        "cs_cz": "Rusko",
        "da_dk": "Rusland",
        "de_de": "Russland",
        "el_gr": "Ρωσία",
        "en_gb": "Russia",
        "en_us": "Russia",
        "es_es": "Rusia",
        "es_la": "Rusia",
        "et_ee": "Venemaa",
        "eu_es": "Errusia",
        "fi_fi": "Venäjä",
        "fr_ca": "Russie",
        "fr_fr": "Russie",
        "gl_es": "Rusia",
        "hr_hr": "Rusija",
        "hu_hu": "Oroszország",
        "it_it": "Russia",
        "ja_jp": "ロシア",
        "ka_ge": "რუსეთი",
        "ko_kr": "러시아",
        "lt_lt": "Rusija",
        "lv_lv": "Krievija",
        "mk_mk": "Русија",
        "nl_nl": "Rusland",
        "no_no": "Russland",
        "pl_pl": "Rosja",
        "pl_si": "Rusyjŏ",
        "pt_br": "Rússia",
        "pt_pt": "Rússia",
        "ro_ro": "Rusia",
        "ru_ru": "Россия",
        "sk_sk": "Rusko",
        "sl_sl": "Rusija",
        "sr_sp": "Rusija",
        "sr_sr": "Русија",
        "sv_se": "Ryssland",
        "tr_tr": "Rusya",
        "uk_uk": "Росія",
        "vi_vn": "Nga",
        "zh_cn": "俄罗斯",
        "zh_tw": "俄羅斯"
      }
    },
    {
      "CountryId": 40,
      "Name": "Србија",
      "CountryCode": "SRB",
      "X": 41000.0,
      "Y": 36000.0,
      "LocalizationToken": "serbia",
      "LocalizedNames": {
        "bg_bg": "Сърбия",
        "ca_es": "Sèrbia",
        "cs_cz": "Srbsko",
        "da_dk": "Serbien",
        "de_de": "Serbien",
        "el_gr": "Σερβία",
        "en_gb": "Serbia",
        "en_us": "Serbia",
        "es_es": "Serbia",
        "es_la": "Serbia",
        "et_ee": "Serbia",
        "eu_es": "Serbia",
        "fi_fi": "Serbia",
        "fr_ca": "Serbie",
        "fr_fr": "Serbie",
        "gl_es": "Serbia",
        "hr_hr": "Srbija",
        "hu_hu": "Szerbia",
        "it_it": "Serbia",
        "ja_jp": "セルビア",
        "ka_ge": "სერბეთი",
        "ko_kr": "세르비아",
        "lt_lt": "Serbija",
        "lv_lv": "Serbia",
        "mk_mk": "Србија",
        "nl_nl": "Servië",
        "no_no": "Serbia",
        "pl_pl": "Serbia",
        "pl_si": "Serbijŏ",
        "pt_br": "Sérvia",
        "pt_pt": "Sérvia",
        "ro_ro": "Serbia",
        "ru_ru": "Сербия",
        "sk_sk": "Srbsko",
        "sl_sl": "Srbija",
        "sr_sp": "Srbija",
        "sr_sr": "Србија",
        "sv_se": "Serbien",
        "tr_tr": "Sırbistan",
        "uk_uk": "Сербія",
        "vi_vn": "Serbia",
        "zh_cn": "塞尔维亚",
        "zh_tw": "塞爾維亞"
      }
    },
    {
      "CountryId": 11,
      "Name": "Slovensko",
      "CountryCode": "SK",
      "X": 32055.0,
      "Y": 12500.0,
      "LocalizationToken": "slovakia",
      "LocalizedNames": {
        "bg_bg": "Словакия",
        "ca_es": "Eslovàquia",
        "cs_cz": "Slovensko",
        "da_dk": "Slovakiet",
        "de_de": "Slowakei",
        "el_gr": "Σλοβακία",
        "en_gb": "Slovakia",
        "en_us": "Slovakia",
        "es_es": "Eslovaquia",
        "es_la": "Eslovaquia",
        "et_ee": "Slovakkia",
        "eu_es": "Eslovakia",
        "fi_fi": "Slovakia",
        "fr_ca": "Slovaquie",
        "fr_fr": "Slovaquie",
        "gl_es": "Eslovaquia",
        "hr_hr": "Slovačka",
        "hu_hu": "Szlovákia",
        "it_it": "Slovacchia",
        "ja_jp": "スロバキア",
        "ka_ge": "სლოვაკეთი",
        "ko_kr": "슬로바키아",
        "lt_lt": "Slovakija",
        "lv_lv": "Slovākija",
        "mk_mk": "Словачка",
        "nl_nl": "Slowakije",
        "no_no": "Slovakia",
        "pl_pl": "Słowacja",
        "pl_si": "Słowacyjŏ",
        "pt_br": "Eslováquia",
        "pt_pt": "Eslováquia",
        "ro_ro": "Slovacia",
        "ru_ru": "Словакия",
        "sk_sk": "Slovensko",
        "sl_sl": "Slovaška",
        "sr_sp": "Slovačka",
        "sr_sr": "Словачка",
        "sv_se": "Slovakien",
        "tr_tr": "Slovakya",
        "uk_uk": "Словаччина",
        "vi_vn": "Slovakia",
        "zh_cn": "斯洛伐克",
        "zh_tw": "斯洛伐克"
      }
    },
    {
      "CountryId": 41,
      "Name": "Slovenija",
      "CountryCode": "SLO",
      "X": 14940.0,
      "Y": 26000.0,
      "LocalizationToken": "slovenia",
      "LocalizedNames": {
        "bg_bg": "Словения",
        "ca_es": "Eslovènia",
        "cs_cz": "Slovinsko",
        "da_dk": "Slovenien",
        "de_de": "Slowenien",
        "el_gr": "Σλοβενία",
        "en_gb": "Slovenia",
        "en_us": "Slovenia",
        "es_es": "Eslovenia",
        "es_la": "Eslovenia",
        "et_ee": "Sloveenia",
        "eu_es": "Eslovenia",
        "fi_fi": "Slovénie",
        "fr_ca": "Slovénie",
        "fr_fr": "Slovénie",
        "gl_es": "Eslovenia",
        "hr_hr": "Slovenija",
        "hu_hu": "Szlovénia",
        "it_it": "Slovenia",
        "ja_jp": "スロベニア",
        "ka_ge": "სლოვენია",
        "ko_kr": "슬로베니아",
        "lt_lt": "Slovėnija",
        "lv_lv": "Slovēnija",
        "mk_mk": "Словенија",
        "nl_nl": "Slovenië",
        "no_no": "Slovenia",
        "pl_pl": "Słowenia",
        "pl_si": "Słowynijŏ",
        "pt_br": "Eslovênia",
        "pt_pt": "Eslovénia",
        "ro_ro": "Slovenia",
        "ru_ru": "Словения",
        "sk_sk": "Slovinsko ",
        "sl_sl": "Slovenja",
        "sr_sp": "Slovenija",
        "sr_sr": "Словенија",
        "sv_se": "Slovenien",
        "tr_tr": "Slovenya",
        "uk_uk": "Словенія",
        "vi_vn": "Slovenia",
        "zh_cn": "斯洛文尼亚",
        "zh_tw": "斯洛文尼亞"
      }
    },
    {
      "CountryId": 42,
      "Name": "España",
      "CountryCode": "E",
      "X": -67500.0,
      "Y": 52500.0,
      "LocalizationToken": "spain",
      "LocalizedNames": {
        "bg_bg": "Испания",
        "ca_es": "Espanya",
        "cs_cz": "Španělsko",
        "da_dk": "Spanien",
        "de_de": "Spanien",
        "el_gr": "Ισπανία",
        "en_gb": "Spain",
        "en_us": "Spain",
        "es_es": "España",
        "es_la": "España",
        "et_ee": "Hispaania",
        "eu_es": "Espainia",
        "fi_fi": "Espanja",
        "fr_ca": "Espagne",
        "fr_fr": "Espagne",
        "gl_es": "España",
        "hr_hr": "Španjolska",
        "hu_hu": "Spanyolország",
        "it_it": "Spagna",
        "ja_jp": "スペイン",
        "ka_ge": "ესპანეთი",
        "ko_kr": "스페인",
        "lt_lt": "Ispanija",
        "lv_lv": "Spānija",
        "mk_mk": "Шпанија",
        "nl_nl": "Spanje",
        "no_no": "Spania",
        "pl_pl": "Hiszpania",
        "pl_si": "Szpanijŏ",
        "pt_br": "Espanha",
        "pt_pt": "Espanha",
        "ro_ro": "Spania",
        "ru_ru": "Испания",
        "sk_sk": "Španielsko",
        "sl_sl": "Španija",
        "sr_sp": "Španija",
        "sr_sr": "Шпанија",
        "sv_se": "Spanien",
        "tr_tr": "İspanya",
        "uk_uk": "Іспанія",
        "vi_vn": "Tây Ban Nha",
        "zh_cn": "西班牙",
        "zh_tw": "西班牙"
      }
    },
    {
      "CountryId": 43,
      "Name": "Sverige",
      "CountryCode": "S",
      "X": 14500.0,
      "Y": -45000.0,
      "LocalizationToken": "sweden",
      "LocalizedNames": {
        "bg_bg": "Швеция",
        "ca_es": "Suècia",
        "cs_cz": "Švédsko",
        "da_dk": "Sverige",
        "de_de": "Schweden",
        "el_gr": "Σουηδία",
        "en_gb": "Sweden",
        "en_us": "Sweden",
        "es_es": "Suecia",
        "es_la": "Suecia",
        "et_ee": "Rootsi",
        "eu_es": "Suedia",
        "fi_fi": "Ruotsi",
        "fr_ca": "Suède",
        "fr_fr": "Suède",
        "gl_es": "Suecia",
        "hr_hr": "Švedska",
        "hu_hu": "Svédország",
        "it_it": "Svezia",
        "ja_jp": "スウェーデン",
        "ka_ge": "შვედეთი",
        "ko_kr": "스웨덴",
        "lt_lt": "Švedija",
        "lv_lv": "Zviedrija",
        "mk_mk": "Шведска",
        "nl_nl": "Zweden",
        "no_no": "Sverige",
        "pl_pl": "Szwecja",
        "pl_si": "Szwecyjŏ",
        "pt_br": "Suécia",
        "pt_pt": "Suécia",
        "ro_ro": "Suedia",
        "ru_ru": "Швеция",
        "sk_sk": "Švédsko ",
        "sl_sl": "Švedska",
        "sr_sp": "Švedska",
        "sr_sr": "Шведска",
        "sv_se": "Sverige",
        "tr_tr": "İsveç",
        "uk_uk": "Швеція",
        "vi_vn": "Thụy Điển",
        "zh_cn": "瑞典",
        "zh_tw": "瑞典"
      }
    },
    {
      "CountryId": 9,
      "Name": "Schweiz",
      "CountryCode": "CH",
      "X": -9369.0,
      "Y": 21470.0,
      "LocalizationToken": "switzerland",
      "LocalizedNames": {
        "bg_bg": "Швейцария",
        "ca_es": "Suïssa",
        "cs_cz": "Švýcarsko",
        "da_dk": "Schweiz",
        "de_de": "Schweiz",
        "el_gr": "Ελβετία",
        "en_gb": "Switzerland",
        "en_us": "Switzerland",
        "es_es": "Suiza",
        "es_la": "Suiza",
        "et_ee": "Šveits",
        "eu_es": "Suitza",
        "fi_fi": "Sveitsi",
        "fr_ca": "Suisse",
        "fr_fr": "Suisse",
        "gl_es": "Suíza",
        "hr_hr": "Švicarska",
        "hu_hu": "Svájc",
        "it_it": "Svizzera",
        "ja_jp": "スイス",
        "ka_ge": "შვეიცარია",
        "ko_kr": "스위스",
        "lt_lt": "Šveicarija",
        "lv_lv": "Šveice",
        "mk_mk": "Швајцарија",
        "nl_nl": "Zwitserland",
        "no_no": "Sveits",
        "pl_pl": "Szwajcaria",
        "pl_si": "Szwajcaryjŏ",
        "pt_br": "Suíça",
        "pt_pt": "Suiça",
        "ro_ro": "Elveţia",
        "ru_ru": "Швейцария",
        "sk_sk": "Švajčiarsko",
        "sl_sl": "Švica",
        "sr_sp": "Švajcarska",
        "sr_sr": "Швајцарска",
        "sv_se": "Schweiz",
        "tr_tr": "İsviçre",
        "uk_uk": "Швейцарія",
        "vi_vn": "Thụy Sỹ",
        "zh_cn": "瑞士",
        "zh_tw": "瑞士"
      }
    },
    {
      "CountryId": 44,
      "Name": "Türkiye",
      "CountryCode": "TR",
      "X": 69500.0,
      "Y": 47000.0,
      "LocalizationToken": "turkey",
      "LocalizedNames": {
        "bg_bg": "Турция",
        "ca_es": "Turquia",
        "cs_cz": "Turecko",
        "da_dk": "Tyrkiet",
        "de_de": "Türkei",
        "el_gr": "Τουρκία",
        "en_gb": "Türkiye",
        "en_us": "Türkiye",
        "es_es": "Turquía",
        "es_la": "Turquía",
        "et_ee": "Türgi",
        "eu_es": "Türkiye",
        "fi_fi": "Turkki",
        "fr_ca": "Turquie",
        "fr_fr": "Turquie",
        "gl_es": "Türkiye",
        "hr_hr": "Turska",
        "hu_hu": "Törökország",
        "it_it": "Türkiye",
        "ja_jp": "トルコ",
        "ka_ge": "თურქეთი",
        "ko_kr": "튀르키예",
        "lt_lt": "Turkija",
        "lv_lv": "Türkiye",
        "mk_mk": "Турција",
        "nl_nl": "Turkije",
        "no_no": "Tyrkia",
        "pl_pl": "Turcja",
        "pl_si": "Turcyjŏ",
        "pt_br": "Turquia",
        "pt_pt": "Turquia",
        "ro_ro": "Turcia",
        "ru_ru": "Турция",
        "sk_sk": "Turecko",
        "sl_sl": "Turčija",
        "sr_sp": "Turska",
        "sr_sr": "Турска",
        "sv_se": "Turkiet",
        "tr_tr": "Türkiye",
        "uk_uk": "Туреччина",
        "vi_vn": "Türkiye",
        "zh_cn": "土耳其",
        "zh_tw": "土耳其"
      }
    },
    {
      "CountryId": 10,
      "Name": "United Kingdom",
      "CountryCode": "GB",
      "X": -42290.0,
      "Y": -14150.0,
      "LocalizationToken": "uk",
      "LocalizedNames": {
        "bg_bg": "Великобритания",
        "ca_es": "Regne Unit",
        "cs_cz": "Spojené království",
        "da_dk": "Storbritannien",
        "de_de": "Vereinigtes Königreich",
        "el_gr": "Ηνωμένο Βασίλειο",
        "en_gb": "United Kingdom",
        "en_us": "United Kingdom",
        "es_es": "Reino Unido",
        "es_la": "Reino Unido",
        "et_ee": "Suurbritannia",
        "eu_es": "Erresuma Batua",
        "fi_fi": "Iso-Britannia",
        "fr_ca": "Royaume-Uni",
        "fr_fr": "Royaume-Uni",
        "gl_es": "Reino Unido",
        "hr_hr": "Ujedinjeno Kraljevstvo",
        "hu_hu": "Egyesült Királyság",
        "it_it": "Gran Bretagna",
        "ja_jp": "イギリス",
        "ka_ge": "გაერთიანებული სამეფო",
        "ko_kr": "영국",
        "lt_lt": "Jungtinė Karalystė",
        "lv_lv": "Lielbritānija",
        "mk_mk": "Велика Британија",
        "nl_nl": "Verenigd Koninkrijk",
        "no_no": "Storbritannia",
        "pl_pl": "Wielka Brytania",
        "pl_si": "Zjednoczōne Krōlestwo",
        "pt_br": "Reino Unido",
        "pt_pt": "Reino Unido",
        "ro_ro": "Marea Britanie",
        "ru_ru": "Великобритания",
        "sk_sk": "Spojené kráľovstvo",
        "sl_sl": "Združeno Kraljestvo",
        "sr_sp": "Velika Britanija",
        "sr_sr": "Велика Британија",
        "sv_se": "Storbritannien",
        "tr_tr": "Birleşik Krallık",
        "uk_uk": "Великобританія",
        "vi_vn": "Vương Quốc Anh",
        "zh_cn": "英国",
        "zh_tw": "英國"
      }
    }
  ];
  export default g_countries_json;