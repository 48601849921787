export default function IkonkaTruck(props) {
    return(
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg" style={props.style} title={props.title}
            width="208.000000pt" height="149.000000pt" viewBox="0 0 208.000000 149.000000"
            preserveAspectRatio="xMidYMid meet">
            {props.title ? <title>{props.title}</title> : ""}
            {props.wypelnienie ?
            <defs>
            <linearGradient id="wypelnienieFillTruck" x1="0" x2="0" y2="1">
                <stop offset={`${props.wypelnienie}%`} stopColor="#910a24" />
                <stop offset="0%" stopColor="#b1b1b1" />
            </linearGradient>
            </defs>
            : ""}
            <g transform="translate(0.000000,149.000000) scale(0.100000,-0.100000)"
            fill="#b1b1b1" stroke="none">
            <path d="M720 1470 c-58 -11 -136 -31 -175 -44 l-70 -25 196 0 c170 -1 198 -3
            203 -17 3 -9 6 -234 6 -500 0 -266 4 -484 8 -484 5 0 34 5 65 11 93 19 88 -17
            85 566 l-3 508 -105 2 c-67 1 -143 -5 -210 -17z"/>
            <path d="M338 1370 c-108 -14 -146 -30 -189 -78 -22 -25 -42 -57 -46 -71 -3
            -14 -16 -58 -29 -98 -13 -39 -31 -116 -40 -170 -8 -54 -19 -106 -25 -115 -5
            -10 -9 -85 -9 -167 0 -123 3 -150 15 -155 19 -7 19 -35 0 -42 -22 -9 -22 -164
            0 -164 8 0 15 -6 15 -14 0 -8 -7 -16 -15 -20 -11 -4 -15 -22 -15 -75 0 -68 0
            -69 31 -80 43 -15 192 -14 207 1 7 7 12 30 12 53 0 61 43 140 99 181 43 31 52
            34 122 34 70 0 81 -3 125 -33 64 -43 96 -104 96 -186 l0 -61 444 0 443 0 -6
            50 c-8 69 30 146 96 195 47 34 52 35 133 35 78 0 88 -2 125 -29 74 -53 110
            -131 97 -210 -5 -32 -3 -41 8 -41 37 0 48 33 48 138 0 94 -1 102 -19 102 -11
            0 -23 5 -26 10 -3 6 -13 10 -21 10 -8 0 -32 11 -52 25 -36 24 -41 25 -224 25
            -129 0 -188 -3 -188 -11 0 -6 9 -13 20 -16 68 -18 -18 -23 -348 -21 l-367 3
            -5 500 -5 500 -225 1 c-124 1 -251 -2 -282 -6z m120 -202 c8 -8 12 -53 12
            -135 0 -121 0 -123 -29 -155 l-29 -33 -131 -3 c-72 -2 -131 0 -131 5 0 13 13
            27 35 39 17 10 20 23 23 106 2 78 0 97 -14 109 -26 22 -50 -18 -63 -108 -8
            -53 -18 -84 -31 -98 -20 -19 -20 -19 -20 13 0 30 26 140 51 219 6 18 18 37 27
            43 25 14 285 13 300 -2z m-318 -284 c0 -16 -23 -44 -35 -44 -19 0 -19 9 2 32
            16 17 33 24 33 12z"
            fill={props.wypelnienie ? `url(#wypelnienieFillTruck)` : "#b1b1b1"}
            />
            <path d="M395 348 c-137 -76 -139 -260 -3 -329 92 -47 215 -3 254 90 35 85 -5
            194 -88 237 -32 17 -135 18 -163 2z m145 -95 c20 -18 33 -41 37 -65 5 -33 2
            -41 -30 -73 -28 -28 -43 -35 -75 -35 -33 0 -45 6 -71 35 -42 49 -41 87 3 131
            44 44 92 46 136 7z"/>
            <path d="M427 222 c-38 -42 -8 -102 50 -102 55 0 85 67 45 103 -25 22 -75 21
            -95 -1z"/>
            <path d="M1725 348 c-137 -76 -139 -260 -3 -329 92 -47 215 -3 254 90 35 85
            -5 194 -88 237 -32 17 -135 18 -163 2z m145 -95 c20 -18 33 -41 37 -65 5 -33
            2 -41 -30 -73 -28 -28 -43 -35 -75 -35 -33 0 -45 6 -71 35 -42 49 -41 87 3
            131 44 44 92 46 136 7z"/>
            <path d="M1757 222 c-38 -42 -8 -102 50 -102 55 0 85 67 45 103 -25 22 -75 21
            -95 -1z"/>
            </g>
        </svg>
    );
};