var g_cities_json = [
  {
    "Name": "Nürnberg",
    "Country": "germany",
    "X": 853.4857,
    "Y": 5416.1333,
    "InGameId": "nurnberg",
    "LocalizationToken": "nurnberg",
    "CountryId": 5,
    "LocalizedNames": {
      "bg_bg": "Нюрнберг",
      "ca_es": "Nuremberg",
      "cs_cz": "Norimberk",
      "da_dk": "Nürnberg",
      "de_de": "Nürnberg",
      "el_gr": "Νυρεμβέργη",
      "en_gb": "Nuremberg",
      "en_us": "Nuremberg",
      "es_es": "Núremberg",
      "es_la": "Núremberg",
      "et_ee": "Nürnberg",
      "eu_es": "Nurenberg",
      "fi_fi": "Nürnberg",
      "fr_ca": "Nuremberg",
      "fr_fr": "Nuremberg",
      "gl_es": "Núremberg",
      "hr_hr": "Nürnberg",
      "hu_hu": "Nürnberg",
      "it_it": "Norimberga",
      "ja_jp": "ニュルンベルク",
      "ka_ge": "ნიურნბერგი",
      "ko_kr": "뉘른베르크",
      "lt_lt": "Niurnbergas",
      "lv_lv": "Nirnberga",
      "mk_mk": "Нирнберг",
      "nl_nl": "Neurenberg",
      "no_no": "Nürnberg",
      "pl_pl": "Norymberga",
      "pl_si": "Norymberga",
      "pt_br": "Nurembergue",
      "pt_pt": "Nuremberga",
      "ro_ro": "Nürnberg",
      "ru_ru": "Нюрнберг",
      "sk_sk": "Norimberg",
      "sl_sl": "Nürnberg",
      "sr_sp": "Nirnberg",
      "sr_sr": "Нирнберг",
      "sv_se": "Nürnberg",
      "tr_tr": "Nürnberg",
      "uk_uk": "Нюрнберг",
      "vi_vn": "Nuremberg",
      "zh_cn": "纽伦堡",
      "zh_tw": "紐倫堡"
    }
  },
  {
    "Name": "München",
    "Country": "germany",
    "X": 2859.25,
    "Y": 13848.9023,
    "InGameId": "munchen",
    "LocalizationToken": "munchen",
    "CountryId": 5,
    "LocalizedNames": {
      "bg_bg": "Мюнхен",
      "ca_es": "Munic",
      "cs_cz": "Mnichov",
      "da_dk": "München",
      "de_de": "München",
      "el_gr": "Μόναχο",
      "en_gb": "Munich",
      "en_us": "Munich",
      "es_es": "Múnich",
      "es_la": "Múnich",
      "et_ee": "München",
      "eu_es": "Munich",
      "fi_fi": "München",
      "fr_ca": "Munich",
      "fr_fr": "Munich",
      "gl_es": "Múnic",
      "hr_hr": "München",
      "hu_hu": "München",
      "it_it": "Monaco di Baviera",
      "ja_jp": "ミュンヘン",
      "ka_ge": "მიუნხენი",
      "ko_kr": "뮌헨",
      "lt_lt": "Miunchenas",
      "lv_lv": "Minhene",
      "mk_mk": "Минхен",
      "nl_nl": "München",
      "no_no": "München",
      "pl_pl": "Monachium",
      "pl_si": "Monachium",
      "pt_br": "Munique",
      "pt_pt": "Munique",
      "ro_ro": "München",
      "ru_ru": "Мюнхен",
      "sk_sk": "Mníchov",
      "sl_sl": "München",
      "sr_sp": "Minhen",
      "sr_sr": "Минхен",
      "sv_se": "München",
      "tr_tr": "Münih",
      "uk_uk": "Мюнхен",
      "vi_vn": "Munich",
      "zh_cn": "慕尼黑",
      "zh_tw": "慕尼黑"
    }
  },
  {
    "Name": "Innsbruck",
    "Country": "austria",
    "X": 1710.82813,
    "Y": 19182.43,
    "InGameId": "innsbruck",
    "LocalizationToken": "innsbruck",
    "CountryId": 1,
    "LocalizedNames": {
      "bg_bg": "Инсбрук",
      "ca_es": "Innsbruck",
      "cs_cz": "Innsbruck",
      "da_dk": "Innsbruck",
      "de_de": "Innsbruck",
      "el_gr": "Ίνσμπρουκ",
      "en_gb": "Innsbruck",
      "en_us": "Innsbruck",
      "es_es": "Innsbruck",
      "es_la": "Innsbruck",
      "et_ee": "Innsbruck",
      "eu_es": "Innsbruck",
      "fi_fi": "Innsbruck",
      "fr_ca": "Innsbruck",
      "fr_fr": "Innsbruck",
      "gl_es": "Innsbruck",
      "hr_hr": "Innsbruck",
      "hu_hu": "Innsbruck",
      "it_it": "Innsbruck",
      "ja_jp": "インスブルック",
      "ka_ge": "ინსბრუკი",
      "ko_kr": "인스브루크",
      "lt_lt": "Insbrukas",
      "lv_lv": "Insbruka",
      "mk_mk": "Инсбрук",
      "nl_nl": "Innsbruck",
      "no_no": "Innsbruck",
      "pl_pl": "Innsbruck",
      "pl_si": "Innsbruck",
      "pt_br": "Innsbruck",
      "pt_pt": "Innsbruck",
      "ro_ro": "Innsbruck",
      "ru_ru": "Инсбрук",
      "sk_sk": "Innsbruck",
      "sl_sl": "Innsbruck",
      "sr_sp": "Inzburk",
      "sr_sr": "Инзбрук",
      "sv_se": "Innsbruck",
      "tr_tr": "Innsbruck",
      "uk_uk": "Інсбрук",
      "vi_vn": "Innsbruck",
      "zh_cn": "因斯布鲁克",
      "zh_tw": "茵斯布魯克"
    }
  },
  {
    "Name": "Verona",
    "Country": "italy",
    "X": -461.015625,
    "Y": 28983.2,
    "InGameId": "verona",
    "LocalizationToken": "verona",
    "CountryId": 6,
    "LocalizedNames": {
      "bg_bg": "Верона",
      "ca_es": "Verona",
      "cs_cz": "Verona",
      "da_dk": "Verona",
      "de_de": "Verona",
      "el_gr": "Βερόνα",
      "en_gb": "Verona",
      "en_us": "Verona",
      "es_es": "Verona",
      "es_la": "Verona",
      "et_ee": "Verona",
      "eu_es": "Verona",
      "fi_fi": "Verona",
      "fr_ca": "Vérone",
      "fr_fr": "Vérone",
      "gl_es": "Verona",
      "hr_hr": "Verona",
      "hu_hu": "Verona",
      "it_it": "Verona",
      "ja_jp": "ヴェローナ",
      "ka_ge": "ვერონა",
      "ko_kr": "베로나",
      "lt_lt": "Verona",
      "lv_lv": "Verona",
      "mk_mk": "Верона",
      "nl_nl": "Verona",
      "no_no": "Verona",
      "pl_pl": "Werona",
      "pl_si": "Werona",
      "pt_br": "Verona",
      "pt_pt": "Verona",
      "ro_ro": "Verona",
      "ru_ru": "Верона",
      "sk_sk": "Verona",
      "sl_sl": "Verona",
      "sr_sp": "Verona",
      "sr_sr": "Верона",
      "sv_se": "Verona",
      "tr_tr": "Verona",
      "uk_uk": "Верона",
      "vi_vn": "Verona",
      "zh_cn": "维罗纳",
      "zh_tw": "維羅納"
    }
  },
  {
    "Name": "Erfurt",
    "Country": "germany",
    "X": 1740.54565,
    "Y": -3217.0354,
    "InGameId": "erfurt",
    "LocalizationToken": "erfurt",
    "CountryId": 5,
    "LocalizedNames": {
      "bg_bg": "Ерфурт",
      "ca_es": "Erfurt",
      "cs_cz": "Erfurt",
      "da_dk": "Erfurt",
      "de_de": "Erfurt",
      "el_gr": "Έρφουρτ",
      "en_gb": "Erfurt",
      "en_us": "Erfurt",
      "es_es": "Erfurt",
      "es_la": "Erfurt",
      "et_ee": "Erfurt",
      "eu_es": "Erfurt",
      "fi_fi": "Erfurt",
      "fr_ca": "Erfurt",
      "fr_fr": "Erfurt",
      "gl_es": "Erfurt",
      "hr_hr": "Erfurt",
      "hu_hu": "Erfurt",
      "it_it": "Erfurt",
      "ja_jp": "エアフルト",
      "ka_ge": "ერფურტი",
      "ko_kr": "에어푸르트",
      "lt_lt": "Erfurtas",
      "lv_lv": "Erfurte",
      "mk_mk": "Ерфурт",
      "nl_nl": "Erfurt",
      "no_no": "Erfurt",
      "pl_pl": "Erfurt",
      "pl_si": "Erfurt",
      "pt_br": "Erfurt",
      "pt_pt": "Erfurt",
      "ro_ro": "Erfurt",
      "ru_ru": "Эрфурт",
      "sk_sk": "Erfurt",
      "sl_sl": "Erfurt",
      "sr_sp": "Erfurt",
      "sr_sr": "Ерфурт",
      "sv_se": "Erfurt",
      "tr_tr": "Erfurt",
      "uk_uk": "Ерфурт",
      "vi_vn": "Erfurt",
      "zh_cn": "爱尔福特",
      "zh_tw": "艾爾福特"
    }
  },
  {
    "Name": "Winsen",
    "Country": "germany",
    "X": 863.636963,
    "Y": -15443.0088,
    "InGameId": "winsen",
    "LocalizationToken": "winsen",
    "CountryId": 5,
    "LocalizedNames": {
      "bg_bg": "Winsen",
      "ca_es": "Winsen",
      "cs_cz": "Winsen",
      "da_dk": "Winsen",
      "de_de": "Winsen",
      "el_gr": "Βίνσεν",
      "en_gb": "Winsen",
      "en_us": "Winsen",
      "es_es": "Winsen",
      "es_la": "Winsen",
      "et_ee": "Winsen",
      "eu_es": "Winsen",
      "fi_fi": "Winsen",
      "fr_ca": "Winsen",
      "fr_fr": "Winsen",
      "gl_es": "Winsen",
      "hr_hr": "Winsen",
      "hu_hu": "Winsen",
      "it_it": "Winsen",
      "ja_jp": "ヴィンゼン",
      "ka_ge": "ვინზენი",
      "ko_kr": "빈젠",
      "lt_lt": "Vinsenas",
      "lv_lv": "Winsen",
      "mk_mk": "Винзен",
      "nl_nl": "Winsen",
      "no_no": "Winsen",
      "pl_pl": "Winsen",
      "pl_si": "Winsen",
      "pt_br": "Winsen",
      "pt_pt": "Winsen",
      "ro_ro": "Winsen",
      "ru_ru": "Винзен",
      "sk_sk": "Winsen",
      "sl_sl": "Winsen",
      "sr_sp": "Winsen",
      "sr_sr": "Винзен",
      "sv_se": "Winsen",
      "tr_tr": "Winsen",
      "uk_uk": "Вінзен",
      "vi_vn": "Winsen",
      "zh_cn": "温森",
      "zh_tw": "溫森"
    }
  },
  {
    "Name": "Travemünde",
    "Country": "germany",
    "X": 2159.001,
    "Y": -20008.4551,
    "InGameId": "travemunde",
    "LocalizationToken": "travemunde",
    "CountryId": 5,
    "LocalizedNames": {
      "bg_bg": "Травемюнде",
      "ca_es": "Travemünde",
      "cs_cz": "Travemünde",
      "da_dk": "Travemünde",
      "de_de": "Travemünde",
      "el_gr": "Τραβεμούντε",
      "en_gb": "Travemünde",
      "en_us": "Travemünde",
      "es_es": "Travemünde",
      "es_la": "Travemünde",
      "et_ee": "Travemünde",
      "eu_es": "Travemünde",
      "fi_fi": "Travemünde",
      "fr_ca": "Travemünde",
      "fr_fr": "Travemünde",
      "gl_es": "Travemünde",
      "hr_hr": "Travemünde",
      "hu_hu": "Travemünde",
      "it_it": "Travemünde",
      "ja_jp": "トラーヴェミュンデ",
      "ka_ge": "ტრავემიუნდე",
      "ko_kr": "트라베뮌데",
      "lt_lt": "Travemünde",
      "lv_lv": "Traveminde",
      "mk_mk": "Травеминде",
      "nl_nl": "Travemünde",
      "no_no": "Travemünde",
      "pl_pl": "Travemünde",
      "pl_si": "Travemünde",
      "pt_br": "Travemünde",
      "pt_pt": "Travemünde",
      "ro_ro": "Travemünde",
      "ru_ru": "Травемюнде",
      "sk_sk": "Travemünde",
      "sl_sl": "Travemünde",
      "sr_sp": "Traveminde",
      "sr_sr": "Травеминде",
      "sv_se": "Travemünde",
      "tr_tr": "Travemünde",
      "uk_uk": "Травемюнде",
      "vi_vn": "Travemünde",
      "zh_cn": "特拉沃明德",
      "zh_tw": "特拉沃明德"
    }
  },
  {
    "Name": "Kiel",
    "Country": "germany",
    "X": 278.463684,
    "Y": -22703.6953,
    "InGameId": "kiel",
    "LocalizationToken": "kiel",
    "CountryId": 5,
    "LocalizedNames": {
      "bg_bg": "Кил",
      "ca_es": "Kiel",
      "cs_cz": "Kiel",
      "da_dk": "Kiel",
      "de_de": "Kiel",
      "el_gr": "Κίελο",
      "en_gb": "Kiel",
      "en_us": "Kiel",
      "es_es": "Kiel",
      "es_la": "Kiel",
      "et_ee": "Kiel",
      "eu_es": "Kiel",
      "fi_fi": "Kiel",
      "fr_ca": "Kiel",
      "fr_fr": "Kiel",
      "gl_es": "Kiel",
      "hr_hr": "Kiel",
      "hu_hu": "Kiel",
      "it_it": "Kiel",
      "ja_jp": "キール",
      "ka_ge": "კიელი",
      "ko_kr": "킬",
      "lt_lt": "Kylis",
      "lv_lv": "Kīle",
      "mk_mk": "Кил",
      "nl_nl": "Kiel",
      "no_no": "Kiel",
      "pl_pl": "Kilonia",
      "pl_si": "Kilonia",
      "pt_br": "Kiel",
      "pt_pt": "Kiel",
      "ro_ro": "Kiel",
      "ru_ru": "Киль",
      "sk_sk": "Kiel",
      "sl_sl": "Kiel",
      "sr_sp": "Kiel",
      "sr_sr": "Киел",
      "sv_se": "Kiel",
      "tr_tr": "Kiel",
      "uk_uk": "Кіль",
      "vi_vn": "Kiel",
      "zh_cn": "基尔",
      "zh_tw": "基爾"
    }
  },
  {
    "Name": "Venezia",
    "Country": "italy",
    "X": 4689.758,
    "Y": 29658.8047,
    "InGameId": "venezia",
    "LocalizationToken": "venezia",
    "CountryId": 6,
    "LocalizedNames": {
      "bg_bg": "Венеция",
      "ca_es": "Venècia",
      "cs_cz": "Benátky",
      "da_dk": "Venedig",
      "de_de": "Venedig",
      "el_gr": "Βενετία",
      "en_gb": "Venice",
      "en_us": "Venice",
      "es_es": "Venecia",
      "es_la": "Venecia",
      "et_ee": "Veneetsia",
      "eu_es": "Venezia",
      "fi_fi": "Venetsia",
      "fr_ca": "Venise",
      "fr_fr": "Venise",
      "gl_es": "Venecia",
      "hr_hr": "Venecija",
      "hu_hu": "Velence",
      "it_it": "Venezia",
      "ja_jp": "ヴェネツィア",
      "ka_ge": "ვენეცია",
      "ko_kr": "베네치아",
      "lt_lt": "Venicija",
      "lv_lv": "Venēcija",
      "mk_mk": "Венеција",
      "nl_nl": "Venetië",
      "no_no": "Venezia",
      "pl_pl": "Wenecja",
      "pl_si": "Wenecja",
      "pt_br": "Veneza",
      "pt_pt": "Veneza",
      "ro_ro": "Veneţia",
      "ru_ru": "Венеция",
      "sk_sk": "Benátky",
      "sl_sl": "Benetke",
      "sr_sp": "Venecija",
      "sr_sr": "Венеција",
      "sv_se": "Venedig",
      "tr_tr": "Venedik",
      "uk_uk": "Венеція",
      "vi_vn": "Venice",
      "zh_cn": "威尼斯",
      "zh_tw": "威尼斯"
    }
  },
  {
    "Name": "Leipzig",
    "Country": "germany",
    "X": 6649.0,
    "Y": -3503.957,
    "InGameId": "leipzig",
    "LocalizationToken": "leipzig",
    "CountryId": 5,
    "LocalizedNames": {
      "bg_bg": "Лайпциг",
      "ca_es": "Leipzig",
      "cs_cz": "Lipsko",
      "da_dk": "Leipzig",
      "de_de": "Leipzig",
      "el_gr": "Λειψία",
      "en_gb": "Leipzig",
      "en_us": "Leipzig",
      "es_es": "Leipzig",
      "es_la": "Leipzig",
      "et_ee": "Leipzig",
      "eu_es": "Leipzig",
      "fi_fi": "Leipzig",
      "fr_ca": "Leipzig",
      "fr_fr": "Leipzig",
      "gl_es": "Leipzig",
      "hr_hr": "Leipzig",
      "hu_hu": "Lipcse",
      "it_it": "Lipsia",
      "ja_jp": "ライプツィヒ",
      "ka_ge": "ლაიფციგი",
      "ko_kr": "라이프치히",
      "lt_lt": "Leipcigas",
      "lv_lv": "Leipciga",
      "mk_mk": "Лајпциг",
      "nl_nl": "Leipzig",
      "no_no": "Leipzig",
      "pl_pl": "Lipsk",
      "pl_si": "Lipsk",
      "pt_br": "Leipzig",
      "pt_pt": "Leipzig",
      "ro_ro": "Leipzig",
      "ru_ru": "Лейпциг",
      "sk_sk": "Lipsko",
      "sl_sl": "Leipzig",
      "sr_sp": "Lajpcig",
      "sr_sr": "Лајпциг",
      "sv_se": "Leipzig",
      "tr_tr": "Leipzig",
      "uk_uk": "Лейпциг",
      "vi_vn": "Leipzig",
      "zh_cn": "莱比锡",
      "zh_tw": "萊比錫"
    }
  },
  {
    "Name": "Magdeburg",
    "Country": "germany",
    "X": 4026.879,
    "Y": -8242.57,
    "InGameId": "magdeburg",
    "LocalizationToken": "magdeburg",
    "CountryId": 5,
    "LocalizedNames": {
      "bg_bg": "Магдебург",
      "ca_es": "Magdeburg",
      "cs_cz": "Magdeburg",
      "da_dk": "Magdeburg",
      "de_de": "Magdeburg",
      "el_gr": "Μαγδεβούργο",
      "en_gb": "Magdeburg",
      "en_us": "Magdeburg",
      "es_es": "Magdeburgo",
      "es_la": "Magdeburgo",
      "et_ee": "Magdeburg",
      "eu_es": "Magdeburg",
      "fi_fi": "Magdeburg",
      "fr_ca": "Magdebourg",
      "fr_fr": "Magdebourg",
      "gl_es": "Magdeburgo",
      "hr_hr": "Magdeburg",
      "hu_hu": "Magdeburg",
      "it_it": "Magdeburgo",
      "ja_jp": "マクデブルク",
      "ka_ge": "მაგდებურგი",
      "ko_kr": "마그데부르크",
      "lt_lt": "Magdeburgas",
      "lv_lv": "Magdeburga",
      "mk_mk": "Магдебург",
      "nl_nl": "Maagdenburg",
      "no_no": "Magdeburg",
      "pl_pl": "Magdeburg",
      "pl_si": "Magdeburg",
      "pt_br": "Magdeburgo",
      "pt_pt": "Magdeburgo",
      "ro_ro": "Magdeburg",
      "ru_ru": "Магдебург",
      "sk_sk": "Magdeburg",
      "sl_sl": "Magdeburg",
      "sr_sp": "Magdeburg",
      "sr_sr": "Магдебург",
      "sv_se": "Magdeburg",
      "tr_tr": "Magdeburg",
      "uk_uk": "Магдебург",
      "vi_vn": "Magdeburg",
      "zh_cn": "马格德堡",
      "zh_tw": "馬德堡"
    }
  },
  {
    "Name": "Rostock",
    "Country": "germany",
    "X": 5624.9,
    "Y": -19578.6484,
    "InGameId": "rostock",
    "LocalizationToken": "rostock",
    "CountryId": 5,
    "LocalizedNames": {
      "bg_bg": "Росток",
      "ca_es": "Rostock",
      "cs_cz": "Rostock",
      "da_dk": "Rostock",
      "de_de": "Rostock",
      "el_gr": "Ρόστοκ",
      "en_gb": "Rostock",
      "en_us": "Rostock",
      "es_es": "Rostock",
      "es_la": "Rostock",
      "et_ee": "Rostock",
      "eu_es": "Rostock",
      "fi_fi": "Rostock",
      "fr_ca": "Rostock",
      "fr_fr": "Rostock",
      "gl_es": "Rostock",
      "hr_hr": "Rostock",
      "hu_hu": "Rostock",
      "it_it": "Rostock",
      "ja_jp": "ロストック",
      "ka_ge": "როსტოკი",
      "ko_kr": "로스토크",
      "lt_lt": "Rostokas",
      "lv_lv": "Rostoka",
      "mk_mk": "Росток",
      "nl_nl": "Rostock",
      "no_no": "Rostock",
      "pl_pl": "Rostock",
      "pl_si": "Rostock",
      "pt_br": "Rostock",
      "pt_pt": "Rostock",
      "ro_ro": "Rostock",
      "ru_ru": "Росток",
      "sk_sk": "Rostock",
      "sl_sl": "Rostock",
      "sr_sp": "Rostok",
      "sr_sr": "Росток",
      "sv_se": "Rostock",
      "tr_tr": "Rostock",
      "uk_uk": "Росток",
      "vi_vn": "Rostock",
      "zh_cn": "罗斯托克",
      "zh_tw": "羅斯托克"
    }
  },
  {
    "Name": "Gedser",
    "Country": "denmark",
    "X": 5970.98047,
    "Y": -22420.4531,
    "InGameId": "gedser",
    "LocalizationToken": "gedser",
    "CountryId": 21,
    "LocalizedNames": {
      "bg_bg": "Гедсер",
      "ca_es": "Gedser",
      "cs_cz": "Gedser",
      "da_dk": "Gedser",
      "de_de": "Gedser",
      "el_gr": "Γκέντσερ",
      "en_gb": "Gedser",
      "en_us": "Gedser",
      "es_es": "Gedser",
      "es_la": "Gedser",
      "et_ee": "Gedser",
      "eu_es": "Gedser",
      "fi_fi": "Gedser",
      "fr_ca": "Gedser",
      "fr_fr": "Gedser",
      "gl_es": "Gedser",
      "hr_hr": "Gedser",
      "hu_hu": "Gedser",
      "it_it": "Gedser",
      "ja_jp": "ゲッサー",
      "ka_ge": "გედსერი",
      "ko_kr": "게세르",
      "lt_lt": "Gedseris",
      "lv_lv": "Gedsera",
      "mk_mk": "Гедсер",
      "nl_nl": "Gedser",
      "no_no": "Gedser",
      "pl_pl": "Gedser",
      "pl_si": "Gedser",
      "pt_br": "Gedser",
      "pt_pt": "Gedser",
      "ro_ro": "Gedser",
      "ru_ru": "Гедсер",
      "sk_sk": "Gedser",
      "sl_sl": "Gedser",
      "sr_sp": "Gedser",
      "sr_sr": "Гедсер",
      "sv_se": "Gedser",
      "tr_tr": "Gedser",
      "uk_uk": "Гедсер",
      "vi_vn": "Gedser",
      "zh_cn": "盖瑟",
      "zh_tw": "蓋瑟"
    }
  },
  {
    "Name": "Salzburg",
    "Country": "austria",
    "X": 8519.496,
    "Y": 15274.625,
    "InGameId": "salzburg",
    "LocalizationToken": "salzburg",
    "CountryId": 1,
    "LocalizedNames": {
      "bg_bg": "Залцбург",
      "ca_es": "Salzburg",
      "cs_cz": "Salzburg",
      "da_dk": "Salzburg",
      "de_de": "Salzburg",
      "el_gr": "Σάλτσμπουργκ",
      "en_gb": "Salzburg",
      "en_us": "Salzburg",
      "es_es": "Salzburgo",
      "es_la": "Salzburgo",
      "et_ee": "Salzburg",
      "eu_es": "Salzburg",
      "fi_fi": "Salzburg",
      "fr_ca": "Salzbourg",
      "fr_fr": "Salzbourg",
      "gl_es": "Salzburgo",
      "hr_hr": "Salzburg",
      "hu_hu": "Salzburg",
      "it_it": "Salisburgo",
      "ja_jp": "ザルツブルク",
      "ka_ge": "ზალცბურგი",
      "ko_kr": "잘츠부르크",
      "lt_lt": "Salcburgas",
      "lv_lv": "Zalcburga",
      "mk_mk": "Салзбург",
      "nl_nl": "Salzburg",
      "no_no": "Salzburg",
      "pl_pl": "Salzburg",
      "pl_si": "Salzburg",
      "pt_br": "Salzburgo",
      "pt_pt": "Salzburgo",
      "ro_ro": "Salzburg",
      "ru_ru": "Зальцбург",
      "sk_sk": "Salzburg",
      "sl_sl": "Salzburg",
      "sr_sp": "Salcburg",
      "sr_sr": "Салцбург",
      "sv_se": "Salzburg",
      "tr_tr": "Salzburg",
      "uk_uk": "Зальцбург",
      "vi_vn": "Salzburg",
      "zh_cn": "萨尔茨堡",
      "zh_tw": "薩爾斯堡"
    }
  },
  {
    "Name": "Trieste",
    "Country": "italy",
    "X": 10154.0605,
    "Y": 27184.23,
    "InGameId": "trieste",
    "LocalizationToken": "trieste",
    "CountryId": 6,
    "LocalizedNames": {
      "bg_bg": "Trieste",
      "ca_es": "Trieste",
      "cs_cz": "Terst",
      "da_dk": "Trieste",
      "de_de": "Triest",
      "el_gr": "Τεργέστη",
      "en_gb": "Trieste",
      "en_us": "Trieste",
      "es_es": "Trieste",
      "es_la": "Trieste",
      "et_ee": "Trieste",
      "eu_es": "Trieste",
      "fi_fi": "Trieste",
      "fr_ca": "Trieste",
      "fr_fr": "Trieste",
      "gl_es": "Trieste",
      "hr_hr": "Trst",
      "hu_hu": "Trieszt",
      "it_it": "Trieste",
      "ja_jp": "トリエステ",
      "ka_ge": "ტრიესტი",
      "ko_kr": "트리에스테",
      "lt_lt": "Triestas",
      "lv_lv": "Trieste",
      "mk_mk": "Трст",
      "nl_nl": "Triëst",
      "no_no": "Trieste",
      "pl_pl": "Triest",
      "pl_si": "Triest",
      "pt_br": "Trieste",
      "pt_pt": "Trieste",
      "ro_ro": "Trieste",
      "ru_ru": "Триест",
      "sk_sk": "Terst",
      "sl_sl": "Trst",
      "sr_sp": "Trst",
      "sr_sr": "Трст",
      "sv_se": "Trieste",
      "tr_tr": "Trieste",
      "uk_uk": "Трієст",
      "vi_vn": "Trieste",
      "zh_cn": "的里雅斯特",
      "zh_tw": "的里雅斯特"
    }
  },
  {
    "Name": "Koper",
    "Country": "slovenia",
    "X": 10733.7813,
    "Y": 28959.6328,
    "InGameId": "koper",
    "LocalizationToken": "koper",
    "CountryId": 41,
    "LocalizedNames": {
      "bg_bg": "Koper",
      "ca_es": "Koper",
      "cs_cz": "Koper",
      "da_dk": "Koper",
      "de_de": "Koper",
      "el_gr": "Κόπερ",
      "en_gb": "Koper",
      "en_us": "Koper",
      "es_es": "Koper",
      "es_la": "Koper",
      "et_ee": "Koper",
      "eu_es": "Koper",
      "fi_fi": "Koper",
      "fr_ca": "Koper",
      "fr_fr": "Koper",
      "gl_es": "Koper",
      "hr_hr": "Kopar",
      "hu_hu": "Koper",
      "it_it": "Koper",
      "ja_jp": "コペル",
      "ka_ge": "კოპერი",
      "ko_kr": "코페르",
      "lt_lt": "Koperis",
      "lv_lv": "Koper",
      "mk_mk": "Копер",
      "nl_nl": "Koper",
      "no_no": "Koper",
      "pl_pl": "Koper",
      "pl_si": "Koper",
      "pt_br": "Koper",
      "pt_pt": "Koper",
      "ro_ro": "Koper",
      "ru_ru": "Копер",
      "sk_sk": "Koper",
      "sl_sl": "Koper",
      "sr_sp": "Kopar",
      "sr_sr": "Копар",
      "sv_se": "Koper",
      "tr_tr": "Koper",
      "uk_uk": "Копер",
      "vi_vn": "Koper",
      "zh_cn": "科佩尔",
      "zh_tw": "科佩爾"
    }
  },
  {
    "Name": "Berlin",
    "Country": "germany",
    "X": 9682.941,
    "Y": -10721.3594,
    "InGameId": "berlin",
    "LocalizationToken": "berlin",
    "CountryId": 5,
    "LocalizedNames": {
      "bg_bg": "Берлин",
      "ca_es": "Berlín",
      "cs_cz": "Berlín",
      "da_dk": "Berlin",
      "de_de": "Berlin",
      "el_gr": "Βερολίνο",
      "en_gb": "Berlin",
      "en_us": "Berlin",
      "es_es": "Berlín",
      "es_la": "Berlín",
      "et_ee": "Berliin",
      "eu_es": "Berlin",
      "fi_fi": "Berliini",
      "fr_ca": "Berlin",
      "fr_fr": "Berlin",
      "gl_es": "Berlín",
      "hr_hr": "Berlin",
      "hu_hu": "Berlin",
      "it_it": "Berlino",
      "ja_jp": "ベルリン",
      "ka_ge": "ბერლინი",
      "ko_kr": "베를린",
      "lt_lt": "Berlynas",
      "lv_lv": "Berlīne",
      "mk_mk": "Берлин",
      "nl_nl": "Berlijn",
      "no_no": "Berlin",
      "pl_pl": "Berlin",
      "pl_si": "Berlin",
      "pt_br": "Berlim",
      "pt_pt": "Berlim",
      "ro_ro": "Berlin",
      "ru_ru": "Берлин",
      "sk_sk": "Berlín",
      "sl_sl": "Berlin",
      "sr_sp": "Berlin",
      "sr_sr": "Берлин",
      "sv_se": "Berlin",
      "tr_tr": "Berlin",
      "uk_uk": "Берлін",
      "vi_vn": "Berlin",
      "zh_cn": "柏林",
      "zh_tw": "柏林"
    }
  },
  {
    "Name": "Praha",
    "Country": "czech",
    "X": 13699.582,
    "Y": 3458.84766,
    "InGameId": "prague",
    "LocalizationToken": "prague",
    "CountryId": 3,
    "LocalizedNames": {
      "bg_bg": "Прага",
      "ca_es": "Praga",
      "cs_cz": "Praha",
      "da_dk": "Prag",
      "de_de": "Prag",
      "el_gr": "Πράγα",
      "en_gb": "Prague",
      "en_us": "Prague",
      "es_es": "Praga",
      "es_la": "Praga",
      "et_ee": "Praha",
      "eu_es": "Praga",
      "fi_fi": "Praha",
      "fr_ca": "Prague",
      "fr_fr": "Prague",
      "gl_es": "Praga",
      "hr_hr": "Prag",
      "hu_hu": "Prága",
      "it_it": "Praga",
      "ja_jp": "プラハ",
      "ka_ge": "პრაღა",
      "ko_kr": "프라하",
      "lt_lt": "Praha",
      "lv_lv": "Prāga",
      "mk_mk": "Прага",
      "nl_nl": "Praag",
      "no_no": "Praha",
      "pl_pl": "Praga",
      "pl_si": "Praga",
      "pt_br": "Praga",
      "pt_pt": "Praga",
      "ro_ro": "Praga",
      "ru_ru": "Прага",
      "sk_sk": "Praha",
      "sl_sl": "Praga",
      "sr_sp": "Prag",
      "sr_sr": "Праг",
      "sv_se": "Prag",
      "tr_tr": "Prag",
      "uk_uk": "Прага",
      "vi_vn": "Prague",
      "zh_cn": "布拉格",
      "zh_tw": "布拉格"
    }
  },
  {
    "Name": "Linz",
    "Country": "austria",
    "X": 12886.2656,
    "Y": 13343.457,
    "InGameId": "linz",
    "LocalizationToken": "linz",
    "CountryId": 1,
    "LocalizedNames": {
      "bg_bg": "Линц",
      "ca_es": "Linz",
      "cs_cz": "Linec",
      "da_dk": "Linz",
      "de_de": "Linz",
      "el_gr": "Λιντς",
      "en_gb": "Linz",
      "en_us": "Linz",
      "es_es": "Linz",
      "es_la": "Linz",
      "et_ee": "Linz",
      "eu_es": "Linz",
      "fi_fi": "Linz",
      "fr_ca": "Linz",
      "fr_fr": "Linz",
      "gl_es": "Linz",
      "hr_hr": "Linz",
      "hu_hu": "Linz",
      "it_it": "Linz",
      "ja_jp": "リンツ",
      "ka_ge": "ლინცი",
      "ko_kr": "린츠",
      "lt_lt": "Lincas",
      "lv_lv": "Linca",
      "mk_mk": "Линц",
      "nl_nl": "Linz",
      "no_no": "Linz",
      "pl_pl": "Linz",
      "pl_si": "Linz",
      "pt_br": "Linz",
      "pt_pt": "Linz",
      "ro_ro": "Linz",
      "ru_ru": "Линц",
      "sk_sk": "Linec",
      "sl_sl": "Linz",
      "sr_sp": "Linc",
      "sr_sr": "Линц",
      "sv_se": "Linz",
      "tr_tr": "Linçe",
      "uk_uk": "Лінц",
      "vi_vn": "Linz",
      "zh_cn": "林茨",
      "zh_tw": "林茲"
    }
  },
  {
    "Name": "Klagenfurt am Wörthersee",
    "Country": "austria",
    "X": 13296.98,
    "Y": 22755.7656,
    "InGameId": "klagenfurt",
    "LocalizationToken": "klagenfurt_am_worthersee",
    "CountryId": 1,
    "LocalizedNames": {
      "bg_bg": "Клагенфурт",
      "ca_es": "Klagenfurt",
      "cs_cz": "Klagenfurt",
      "da_dk": "Klagenfurt",
      "de_de": "Klagenfurt am Wörthersee",
      "el_gr": "Κλάγκενφουρτ",
      "en_gb": "Klagenfurt am Wörthersee",
      "en_us": "Klagenfurt am Wörthersee",
      "es_es": "Klagenfurt am Wörthersee",
      "es_la": "Klagenfurt am Wörthersee",
      "et_ee": "Klagenfurt am Wörthersee",
      "eu_es": "Klagenfurt am Wörthersee",
      "fi_fi": "Klagenfurt am Wörthersee",
      "fr_ca": "Klagenfurt am Wörthersee",
      "fr_fr": "Klagenfurt am Wörthersee",
      "gl_es": "Klagenfurt am Wörthersee",
      "hr_hr": "Klagenfurt",
      "hu_hu": "Klagenfurt am Wörthersee",
      "it_it": "Klagenfurt am Wörthersee",
      "ja_jp": "クラーゲンフルト・アム・ヴェルターゼー",
      "ka_ge": "კლაგენფურტი",
      "ko_kr": "클라겐푸르트 암 뵈르테제",
      "lt_lt": "Klagenfurtas",
      "lv_lv": "Klāgenfurte",
      "mk_mk": "Клагенфурт на Врбско езеро",
      "nl_nl": "Klagenfurt am Wörthersee",
      "no_no": "Klagenfurt am Wörthersee",
      "pl_pl": "Klagenfurt am Wörthersee",
      "pl_si": "Klagenfurt am Wörthersee",
      "pt_br": "Klagenfurt am Wörthersee",
      "pt_pt": "Klagenfurt am Wörthersee",
      "ro_ro": "Klagenfurt ",
      "ru_ru": "Клагенфурт-ам-Вёртерзе",
      "sk_sk": "Klagenfurt am Wörthersee",
      "sl_sl": "Celovec ob Vrbskem jezeru",
      "sr_sp": "Klagenfurt",
      "sr_sr": "Клагенфурт",
      "sv_se": "Klagenfurt am Wörthersee",
      "tr_tr": "Klagenfurt",
      "uk_uk": "Клагенфурт-ам-Вертерзеє",
      "vi_vn": "Klagenfurt am Wörthersee",
      "zh_cn": "克拉根福",
      "zh_tw": "克拉根福"
    }
  },
  {
    "Name": "Ljubljana",
    "Country": "slovenia",
    "X": 14797.9727,
    "Y": 25545.0859,
    "InGameId": "ljubljana",
    "LocalizationToken": "ljubljana",
    "CountryId": 41,
    "LocalizedNames": {
      "bg_bg": "Ljubljana",
      "ca_es": "Ljubljana",
      "cs_cz": "Lublaň",
      "da_dk": "Ljubljana",
      "de_de": "Laibach",
      "el_gr": "Λιουμπλιάνα",
      "en_gb": "Ljubljana",
      "en_us": "Ljubljana",
      "es_es": "Liubliana",
      "es_la": "Liubliana",
      "et_ee": "Ljubljana",
      "eu_es": "Ljubljana",
      "fi_fi": "Ljubljana",
      "fr_ca": "Ljubljana",
      "fr_fr": "Ljubljana",
      "gl_es": "Ljubljana",
      "hr_hr": "Ljubljana",
      "hu_hu": "Ljubljana",
      "it_it": "Ljubljana",
      "ja_jp": "リュブリャナ",
      "ka_ge": "ლუბლიანა",
      "ko_kr": "류블랴나",
      "lt_lt": "Liublijana",
      "lv_lv": "Ljubljana",
      "mk_mk": "Љубљана",
      "nl_nl": "Ljubljana",
      "no_no": "Ljubljana",
      "pl_pl": "Lublana",
      "pl_si": "Lublana",
      "pt_br": "Liubliana",
      "pt_pt": "Liubliana",
      "ro_ro": "Ljubljana",
      "ru_ru": "Любляна",
      "sk_sk": "Ľubľana",
      "sl_sl": "Ljubljana",
      "sr_sp": "Ljubljana",
      "sr_sr": "Љубљана",
      "sv_se": "Ljubljana",
      "tr_tr": "Lübliyana",
      "uk_uk": "Любляна",
      "vi_vn": "Ljubljana",
      "zh_cn": "卢布尔雅那",
      "zh_tw": "盧比安納"
    }
  },
  {
    "Name": "Rijeka",
    "Country": "croatia",
    "X": 13866.1445,
    "Y": 29916.1367,
    "InGameId": "rijeka",
    "LocalizationToken": "rijeka",
    "CountryId": 19,
    "LocalizedNames": {
      "bg_bg": "Rijeka",
      "ca_es": "Rijeka",
      "cs_cz": "Rijeka",
      "da_dk": "Rijeka",
      "de_de": "Rijeka",
      "el_gr": "Ριέκα",
      "en_gb": "Rijeka",
      "en_us": "Rijeka",
      "es_es": "Rijeka",
      "es_la": "Rijeka",
      "et_ee": "Rijeka",
      "eu_es": "Rijeka",
      "fi_fi": "Rijeka",
      "fr_ca": "Rijeka",
      "fr_fr": "Rijeka",
      "gl_es": "Rijeka",
      "hr_hr": "Rijeka",
      "hu_hu": "Fiume",
      "it_it": "Rijeka",
      "ja_jp": "リエカ",
      "ka_ge": "რიეკა",
      "ko_kr": "리예카",
      "lt_lt": "Rijeka",
      "lv_lv": "Rijeka",
      "mk_mk": "Риека",
      "nl_nl": "Rijeka",
      "no_no": "Rijeka",
      "pl_pl": "Rijeka",
      "pl_si": "Rijeka",
      "pt_br": "Rijeka",
      "pt_pt": "Rijeka",
      "ro_ro": "Rijeka",
      "ru_ru": "Риека",
      "sk_sk": "Rijeka",
      "sl_sl": "Reka",
      "sr_sp": "Rijeka",
      "sr_sr": "Ријека",
      "sv_se": "Rijeka",
      "tr_tr": "Rijeka",
      "uk_uk": "Рієка",
      "vi_vn": "Rijeka",
      "zh_cn": "里耶卡",
      "zh_tw": "里耶卡"
    }
  },
  {
    "Name": "Dresden",
    "Country": "germany",
    "X": 11926.2813,
    "Y": -2164.55469,
    "InGameId": "dresden",
    "LocalizationToken": "dresden",
    "CountryId": 5,
    "LocalizedNames": {
      "bg_bg": "Дрезден",
      "ca_es": "Dresden",
      "cs_cz": "Drážďany",
      "da_dk": "Dresden",
      "de_de": "Dresden",
      "el_gr": "Δρέσδη",
      "en_gb": "Dresden",
      "en_us": "Dresden",
      "es_es": "Dresde",
      "es_la": "Dresde",
      "et_ee": "Dresden",
      "eu_es": "Dresden",
      "fi_fi": "Dresden",
      "fr_ca": "Dresde",
      "fr_fr": "Dresde",
      "gl_es": "Dresden",
      "hr_hr": "Dresden",
      "hu_hu": "Drezda",
      "it_it": "Dresda",
      "ja_jp": "ドレスデン",
      "ka_ge": "დრეზდენი",
      "ko_kr": "드레스덴",
      "lt_lt": "Dresdenas",
      "lv_lv": "Drēzdene",
      "mk_mk": "Дрезден",
      "nl_nl": "Dresden",
      "no_no": "Dresden",
      "pl_pl": "Drezno",
      "pl_si": "Drezno",
      "pt_br": "Dresden",
      "pt_pt": "Dresden",
      "ro_ro": "Dresda",
      "ru_ru": "Дрезден",
      "sk_sk": "Drážďany",
      "sl_sl": "Dresden",
      "sr_sp": "Drezden",
      "sr_sr": "Дрезден",
      "sv_se": "Dresden",
      "tr_tr": "Dresden",
      "uk_uk": "Дрезден",
      "vi_vn": "Dresden",
      "zh_cn": "德累斯顿",
      "zh_tw": "德勒斯登"
    }
  },
  {
    "Name": "Szczecin",
    "Country": "poland",
    "X": 14502.5352,
    "Y": -15569.8906,
    "InGameId": "szczecin",
    "LocalizationToken": "szczecin",
    "CountryId": 8,
    "LocalizedNames": {
      "bg_bg": "Шчечин",
      "ca_es": "Szczecin",
      "cs_cz": "Štětín",
      "da_dk": "Szczecin",
      "de_de": "Stettin",
      "el_gr": "Στσέτσιν",
      "en_gb": "Szczecin",
      "en_us": "Szczecin",
      "es_es": "Szczecin",
      "es_la": "Szczecin",
      "et_ee": "Szczecin",
      "eu_es": "Szczecin",
      "fi_fi": "Szczecin",
      "fr_ca": "Szczecin",
      "fr_fr": "Szczecin",
      "gl_es": "Szczecin",
      "hr_hr": "Szczecin",
      "hu_hu": "Szczecin",
      "it_it": "Szczecin",
      "ja_jp": "シュチェチン",
      "ka_ge": "შჩეცინი",
      "ko_kr": "슈체친",
      "lt_lt": "Ščecinas",
      "lv_lv": "Ščecina",
      "mk_mk": "Шчеќин",
      "nl_nl": "Szczecin",
      "no_no": "Szczecin",
      "pl_pl": "Szczecin",
      "pl_si": "Sztetin",
      "pt_br": "Estetino",
      "pt_pt": "Szczecin",
      "ro_ro": "Szczecin",
      "ru_ru": "Щецин",
      "sk_sk": "Štetín",
      "sl_sl": "Szczecin",
      "sr_sp": "Ščećin",
      "sr_sr": "Шчећин",
      "sv_se": "Szczecin",
      "tr_tr": "Ştetin",
      "uk_uk": "Щецин",
      "vi_vn": "Szczecin",
      "zh_cn": "什切青",
      "zh_tw": "斯塞新"
    }
  },
  {
    "Name": "Graz",
    "Country": "austria",
    "X": 18210.99,
    "Y": 19941.7,
    "InGameId": "graz",
    "LocalizationToken": "graz",
    "CountryId": 1,
    "LocalizedNames": {
      "bg_bg": "Грац",
      "ca_es": "Graz",
      "cs_cz": "Št. Hradec",
      "da_dk": "Graz",
      "de_de": "Graz",
      "el_gr": "Γκρατς",
      "en_gb": "Graz",
      "en_us": "Graz",
      "es_es": "Graz",
      "es_la": "Graz",
      "et_ee": "Graz",
      "eu_es": "Graz",
      "fi_fi": "Graz",
      "fr_ca": "Graz",
      "fr_fr": "Graz",
      "gl_es": "Graz",
      "hr_hr": "Graz",
      "hu_hu": "Graz",
      "it_it": "Graz",
      "ja_jp": "グラーツ",
      "ka_ge": "გრაცი",
      "ko_kr": "그라츠",
      "lt_lt": "Gracas",
      "lv_lv": "Grāca",
      "mk_mk": "Грац",
      "nl_nl": "Graz",
      "no_no": "Graz",
      "pl_pl": "Graz",
      "pl_si": "Graz",
      "pt_br": "Graz",
      "pt_pt": "Graz",
      "ro_ro": "Graz",
      "ru_ru": "Грац",
      "sk_sk": "Graz",
      "sl_sl": "Gradec",
      "sr_sp": "Grac",
      "sr_sr": "Грац",
      "sv_se": "Graz",
      "tr_tr": "Graz",
      "uk_uk": "Грац",
      "vi_vn": "Graz",
      "zh_cn": "格拉茨",
      "zh_tw": "格拉茲"
    }
  },
  {
    "Name": "Maribor",
    "Country": "slovenia",
    "X": 18074.4648,
    "Y": 22717.3359,
    "InGameId": "maribor",
    "LocalizationToken": "maribor",
    "CountryId": 41,
    "LocalizedNames": {
      "bg_bg": "Maribor",
      "ca_es": "Maribor",
      "cs_cz": "Maribor",
      "da_dk": "Maribor",
      "de_de": "Marburg",
      "el_gr": "Μάριμπορ",
      "en_gb": "Maribor",
      "en_us": "Maribor",
      "es_es": "Maribor",
      "es_la": "Maribor",
      "et_ee": "Maribor",
      "eu_es": "Maribor",
      "fi_fi": "Maribor",
      "fr_ca": "Maribor",
      "fr_fr": "Maribor",
      "gl_es": "Maribor",
      "hr_hr": "Maribor",
      "hu_hu": "Maribor",
      "it_it": "Maribor",
      "ja_jp": "マリボル",
      "ka_ge": "მარიბორი",
      "ko_kr": "마리보르",
      "lt_lt": "Mariboras",
      "lv_lv": "Maribor",
      "mk_mk": "Марибор",
      "nl_nl": "Maribor",
      "no_no": "Maribor",
      "pl_pl": "Maribor",
      "pl_si": "Maribor",
      "pt_br": "Maribor",
      "pt_pt": "Maribor",
      "ro_ro": "Maribor",
      "ru_ru": "Марибор",
      "sk_sk": "Maribor",
      "sl_sl": "Maribor",
      "sr_sp": "Maribor",
      "sr_sr": "Марибор",
      "sv_se": "Maribor",
      "tr_tr": "Maribor",
      "uk_uk": "Марибор",
      "vi_vn": "Maribor",
      "zh_cn": "马里博尔",
      "zh_tw": "馬里波爾"
    }
  },
  {
    "Name": "Brno",
    "Country": "czech",
    "X": 21873.7148,
    "Y": 8794.824,
    "InGameId": "brno",
    "LocalizationToken": "brno",
    "CountryId": 3,
    "LocalizedNames": {
      "bg_bg": "Бърно",
      "ca_es": "Brno",
      "cs_cz": "Brno",
      "da_dk": "Brno",
      "de_de": "Brünn",
      "el_gr": "Μπρνο",
      "en_gb": "Brno",
      "en_us": "Brno",
      "es_es": "Brno",
      "es_la": "Brno",
      "et_ee": "Brno",
      "eu_es": "Brno",
      "fi_fi": "Brno",
      "fr_ca": "Brno",
      "fr_fr": "Brno",
      "gl_es": "Brno",
      "hr_hr": "Brno",
      "hu_hu": "Brünn",
      "it_it": "Brno",
      "ja_jp": "ブルノ",
      "ka_ge": "ბრნო",
      "ko_kr": "브르노",
      "lt_lt": "Brno",
      "lv_lv": "Brno",
      "mk_mk": "Брно",
      "nl_nl": "Brno",
      "no_no": "Brno",
      "pl_pl": "Brno",
      "pl_si": "Brno",
      "pt_br": "Bruno",
      "pt_pt": "Brno",
      "ro_ro": "Brno",
      "ru_ru": "Брно",
      "sk_sk": "Brno",
      "sl_sl": "Brno",
      "sr_sp": "Brno",
      "sr_sr": "Брно",
      "sv_se": "Brno",
      "tr_tr": "Brno",
      "uk_uk": "Брно",
      "vi_vn": "Brno",
      "zh_cn": "布尔诺",
      "zh_tw": "布爾諾"
    }
  },
  {
    "Name": "Wien",
    "Country": "austria",
    "X": 19939.6328,
    "Y": 13465.9063,
    "InGameId": "wien",
    "LocalizationToken": "wien",
    "CountryId": 1,
    "LocalizedNames": {
      "bg_bg": "Виена",
      "ca_es": "Viena",
      "cs_cz": "Vídeň",
      "da_dk": "Wien",
      "de_de": "Wien",
      "el_gr": "Βιέννη",
      "en_gb": "Vienna",
      "en_us": "Vienna",
      "es_es": "Viena",
      "es_la": "Viena",
      "et_ee": "Viin",
      "eu_es": "Viena",
      "fi_fi": "Wien",
      "fr_ca": "Vienne",
      "fr_fr": "Vienne",
      "gl_es": "Viena",
      "hr_hr": "Beč",
      "hu_hu": "Bécs",
      "it_it": "Vienna",
      "ja_jp": "ウィーン",
      "ka_ge": "ვენა",
      "ko_kr": "빈",
      "lt_lt": "Viena",
      "lv_lv": "Vīne",
      "mk_mk": "Виена",
      "nl_nl": "Wenen",
      "no_no": "Wien",
      "pl_pl": "Wiedeń",
      "pl_si": "Wiedziyń",
      "pt_br": "Viena",
      "pt_pt": "Viena",
      "ro_ro": "Viena",
      "ru_ru": "Вена",
      "sk_sk": "Viedeň",
      "sl_sl": "Dunaj",
      "sr_sp": "Beč",
      "sr_sr": "Беч",
      "sv_se": "Wien",
      "tr_tr": "Viyana",
      "uk_uk": "Відень",
      "vi_vn": "Vienna",
      "zh_cn": "维也纳",
      "zh_tw": "維也納"
    }
  },
  {
    "Name": "Wrocław",
    "Country": "poland",
    "X": 22802.7734,
    "Y": -3021.10547,
    "InGameId": "wroclaw",
    "LocalizationToken": "wroclaw",
    "CountryId": 8,
    "LocalizedNames": {
      "bg_bg": "Вроцлав",
      "ca_es": "Breslau",
      "cs_cz": "Vratislav",
      "da_dk": "Wrocław",
      "de_de": "Breslau",
      "el_gr": "Βρότσλαβ",
      "en_gb": "Wrocław",
      "en_us": "Wrocław",
      "es_es": "Breslavia",
      "es_la": "Breslavia",
      "et_ee": "Wrocław",
      "eu_es": "Wrocław",
      "fi_fi": "Wrocław",
      "fr_ca": "Wrocław",
      "fr_fr": "Wrocław",
      "gl_es": "Wrocław",
      "hr_hr": "Wrocław",
      "hu_hu": "Boroszló",
      "it_it": "Breslavia",
      "ja_jp": "ヴロツワフ",
      "ka_ge": "ვროცლავი",
      "ko_kr": "브로츠와프",
      "lt_lt": "Vroclavas",
      "lv_lv": "Vroclava",
      "mk_mk": "Вроцлав",
      "nl_nl": "Wrocław",
      "no_no": "Wrocław",
      "pl_pl": "Wrocław",
      "pl_si": "Wrocław",
      "pt_br": "Breslávia",
      "pt_pt": "Wrocław",
      "ro_ro": "Wrocław",
      "ru_ru": "Вроцлав",
      "sk_sk": "Vroclav",
      "sl_sl": "Vroclav",
      "sr_sp": "Vrocłav",
      "sr_sr": "Вроцлав",
      "sv_se": "Wrocław",
      "tr_tr": "Breslava",
      "uk_uk": "Вроцлав",
      "vi_vn": "Wrocław",
      "zh_cn": "弗罗茨瓦夫",
      "zh_tw": "弗次瓦夫"
    }
  },
  {
    "Name": "Poznań",
    "Country": "poland",
    "X": 22313.2773,
    "Y": -9682.324,
    "InGameId": "poznan",
    "LocalizationToken": "poznan",
    "CountryId": 8,
    "LocalizedNames": {
      "bg_bg": "Познан",
      "ca_es": "Poznań",
      "cs_cz": "Poznaň",
      "da_dk": "Poznań",
      "de_de": "Posen",
      "el_gr": "Πόζναν",
      "en_gb": "Poznań",
      "en_us": "Poznań",
      "es_es": "Poznan",
      "es_la": "Poznan",
      "et_ee": "Poznań",
      "eu_es": "Poznań",
      "fi_fi": "Poznań",
      "fr_ca": "Poznań",
      "fr_fr": "Poznań",
      "gl_es": "Poznań",
      "hr_hr": "Poznanj",
      "hu_hu": "Poznań",
      "it_it": "Poznań",
      "ja_jp": "ポズナン",
      "ka_ge": "პოზნანი",
      "ko_kr": "포즈난",
      "lt_lt": "Poznanė",
      "lv_lv": "Poznaņa",
      "mk_mk": "Познањ",
      "nl_nl": "Poznań",
      "no_no": "Poznań",
      "pl_pl": "Poznań",
      "pl_si": "Poznań",
      "pt_br": "Poznań",
      "pt_pt": "Poznań",
      "ro_ro": "Poznań",
      "ru_ru": "Познань",
      "sk_sk": "Poznaň",
      "sl_sl": "Poznanj",
      "sr_sp": "Poznanj",
      "sr_sr": "Познањ",
      "sv_se": "Poznań",
      "tr_tr": "Poznań",
      "uk_uk": "Познань",
      "vi_vn": "Poznań",
      "zh_cn": "波兹南",
      "zh_tw": "波茲南"
    }
  },
  {
    "Name": "Bratislava",
    "Country": "slovakia",
    "X": 24023.1719,
    "Y": 14410.8945,
    "InGameId": "bratislava",
    "LocalizationToken": "bratislava",
    "CountryId": 11,
    "LocalizedNames": {
      "bg_bg": "Братислава",
      "ca_es": "Bratislava",
      "cs_cz": "Bratislava",
      "da_dk": "Bratislava",
      "de_de": "Pressburg",
      "el_gr": "Μπρατισλάβα",
      "en_gb": "Bratislava",
      "en_us": "Bratislava",
      "es_es": "Bratislava",
      "es_la": "Bratislava",
      "et_ee": "Bratislava",
      "eu_es": "Bratislava",
      "fi_fi": "Bratislava",
      "fr_ca": "Bratislava",
      "fr_fr": "Bratislava",
      "gl_es": "Bratislava",
      "hr_hr": "Bratislava",
      "hu_hu": "Pozsony",
      "it_it": "Bratislava",
      "ja_jp": "ブラチスラヴァ",
      "ka_ge": "ბრატისლავა",
      "ko_kr": "브라티슬라바",
      "lt_lt": "Bratislava",
      "lv_lv": "Bratislava",
      "mk_mk": "Братислава",
      "nl_nl": "Bratislava",
      "no_no": "Bratislava",
      "pl_pl": "Bratysława",
      "pl_si": "Bratysława",
      "pt_br": "Bratislava",
      "pt_pt": "Bratislava",
      "ro_ro": "Bratislava",
      "ru_ru": "Братислава",
      "sk_sk": "Bratislava",
      "sl_sl": "Bratislava",
      "sr_sp": "Bratislava",
      "sr_sr": "Братислава",
      "sv_se": "Bratislava",
      "tr_tr": "Bratislava",
      "uk_uk": "Братислава",
      "vi_vn": "Bratislava",
      "zh_cn": "布拉迪斯拉发",
      "zh_tw": "布拉提斯拉瓦"
    }
  },
  {
    "Name": "Osijek",
    "Country": "croatia",
    "X": 31000.4785,
    "Y": 28372.9473,
    "InGameId": "osijek",
    "LocalizationToken": "osijek",
    "CountryId": 19,
    "LocalizedNames": {
      "bg_bg": "Osijek",
      "ca_es": "Osijek",
      "cs_cz": "Osijek",
      "da_dk": "Osijek",
      "de_de": "Osijek",
      "el_gr": "Όσιγεκ",
      "en_gb": "Osijek",
      "en_us": "Osijek",
      "es_es": "Osijek",
      "es_la": "Osijek",
      "et_ee": "Osijek",
      "eu_es": "Osijek",
      "fi_fi": "Osijek",
      "fr_ca": "Osijek",
      "fr_fr": "Osijek",
      "gl_es": "Osijek",
      "hr_hr": "Osijek",
      "hu_hu": "Eszék",
      "it_it": "Osijek",
      "ja_jp": "オシエク",
      "ka_ge": "ოსიეკი",
      "ko_kr": "오시예크",
      "lt_lt": "Osijekas",
      "lv_lv": "Osijek",
      "mk_mk": "Осиек",
      "nl_nl": "Osijek",
      "no_no": "Osijek",
      "pl_pl": "Osijek",
      "pl_si": "Osijek",
      "pt_br": "Osijek",
      "pt_pt": "Osijek",
      "ro_ro": "Osijek",
      "ru_ru": "Осиек",
      "sk_sk": "Osijek",
      "sl_sl": "Osijek",
      "sr_sp": "Osijek",
      "sr_sr": "Осијек",
      "sv_se": "Osijek",
      "tr_tr": "Osijek",
      "uk_uk": "Осієк",
      "vi_vn": "Osijek",
      "zh_cn": "奥西耶克",
      "zh_tw": "奧西耶克"
    }
  },
  {
    "Name": "Szeged",
    "Country": "hungary",
    "X": 36318.0938,
    "Y": 23928.0742,
    "InGameId": "szeged",
    "LocalizationToken": "szeged",
    "CountryId": 13,
    "LocalizedNames": {
      "bg_bg": "Сегед",
      "ca_es": "Szeged",
      "cs_cz": "Szeged",
      "da_dk": "Szeged",
      "de_de": "Szegedin",
      "el_gr": "Σέγκεντ",
      "en_gb": "Szeged",
      "en_us": "Szeged",
      "es_es": "Szeged",
      "es_la": "Szeged",
      "et_ee": "Szeged",
      "eu_es": "Szeged",
      "fi_fi": "Szeged",
      "fr_ca": "Szeged",
      "fr_fr": "Szeged",
      "gl_es": "Szeged",
      "hr_hr": "Segedin",
      "hu_hu": "Szeged",
      "it_it": "Szeged",
      "ja_jp": "セゲド",
      "ka_ge": "სეგედი",
      "ko_kr": "세게드",
      "lt_lt": "Segedas",
      "lv_lv": "Segeda",
      "mk_mk": "Сегед",
      "nl_nl": "Szeged",
      "no_no": "Szeged",
      "pl_pl": "Segedyn",
      "pl_si": "Segedyn",
      "pt_br": "Szeged",
      "pt_pt": "Szeged",
      "ro_ro": "Seghedin",
      "ru_ru": "Сегед",
      "sk_sk": "Segedín",
      "sl_sl": "Szeged",
      "sr_sp": "Segedin",
      "sr_sr": "Сегедин",
      "sv_se": "Szeged",
      "tr_tr": "Segedin",
      "uk_uk": "Сегед",
      "vi_vn": "Szeged",
      "zh_cn": "塞格德",
      "zh_tw": "塞格德"
    }
  },
  {
    "Name": "Reșița",
    "Country": "romania",
    "X": 43090.2344,
    "Y": 28534.457,
    "InGameId": "resita",
    "LocalizationToken": "resita",
    "CountryId": 37,
    "LocalizedNames": {
      "bg_bg": "Решица",
      "ca_es": "Reșița",
      "cs_cz": "Rešice",
      "da_dk": "Reșița",
      "de_de": "Reschitza",
      "el_gr": "Ρέσιτσα",
      "en_gb": "Reșița",
      "en_us": "Reșița",
      "es_es": "Reșița",
      "es_la": "Reșița",
      "et_ee": "Reșița",
      "eu_es": "Reșița",
      "fi_fi": "Reșița",
      "fr_ca": "Reșița",
      "fr_fr": "Reșița",
      "gl_es": "Reșița",
      "hr_hr": "Reșița",
      "hu_hu": "Resicabánya",
      "it_it": "Reșița",
      "ja_jp": "レシツァ",
      "ka_ge": "რეშიცა",
      "ko_kr": "레시차",
      "lt_lt": "Rešica",
      "lv_lv": "Reșița",
      "mk_mk": "Решица",
      "nl_nl": "Reșița",
      "no_no": "Reșița",
      "pl_pl": "Resita",
      "pl_si": "Resita",
      "pt_br": "Reșița",
      "pt_pt": "Reșița",
      "ro_ro": "Reşiţa",
      "ru_ru": "Решица",
      "sk_sk": "Reșița",
      "sl_sl": "Reșița",
      "sr_sp": "Rešica",
      "sr_sr": "Решица",
      "sv_se": "Reșița",
      "tr_tr": "Reşita",
      "uk_uk": "Решица",
      "vi_vn": "Reșița",
      "zh_cn": "雷希察",
      "zh_tw": "雷希察"
    }
  },
  {
    "Name": "Ниш",
    "Country": "serbia",
    "X": 44790.0234,
    "Y": 39326.83,
    "InGameId": "nis",
    "LocalizationToken": "nis",
    "CountryId": 40,
    "LocalizedNames": {
      "bg_bg": "Niš",
      "ca_es": "Niš",
      "cs_cz": "Niš",
      "da_dk": "Niš",
      "de_de": "Niš",
      "el_gr": "Νις",
      "en_gb": "Niš",
      "en_us": "Niš",
      "es_es": "Niš",
      "es_la": "Niš",
      "et_ee": "Niš",
      "eu_es": "Niš",
      "fi_fi": "Niš",
      "fr_ca": "Niš",
      "fr_fr": "Niš",
      "gl_es": "Niš",
      "hr_hr": "Niš",
      "hu_hu": "Niš",
      "it_it": "Niš",
      "ja_jp": "ニシュ",
      "ka_ge": "ნიში",
      "ko_kr": "니시",
      "lt_lt": "Niša",
      "lv_lv": "Niš",
      "mk_mk": "Ниш",
      "nl_nl": "Niš",
      "no_no": "Niš",
      "pl_pl": "Nisz",
      "pl_si": "Nisz",
      "pt_br": "Niš",
      "pt_pt": "Niš",
      "ro_ro": "Niš",
      "ru_ru": "Ниш",
      "sk_sk": "Niš",
      "sl_sl": "Niš",
      "sr_sp": "Niš",
      "sr_sr": "Ниш",
      "sv_se": "Niš",
      "tr_tr": "Niş",
      "uk_uk": "Ниш",
      "vi_vn": "Niš",
      "zh_cn": "尼什",
      "zh_tw": "尼什"
    }
  },
  {
    "Name": "Псков",
    "Country": "russia",
    "X": 55868.2,
    "Y": -44822.4922,
    "InGameId": "pskov",
    "LocalizationToken": "pskov",
    "CountryId": 38,
    "LocalizedNames": {
      "bg_bg": "Псков",
      "ca_es": "Pskov",
      "cs_cz": "Pskov",
      "da_dk": "Pskov",
      "de_de": "Pskow",
      "el_gr": "Πσκοφ",
      "en_gb": "Pskov",
      "en_us": "Pskov",
      "es_es": "Pskov",
      "es_la": "Pskov",
      "et_ee": "Pihkva",
      "eu_es": "Pskov",
      "fi_fi": "Pihkova",
      "fr_ca": "Pskov",
      "fr_fr": "Pskov",
      "gl_es": "Pskov",
      "hr_hr": "Pskov",
      "hu_hu": "Pszkov",
      "it_it": "Pskov",
      "ja_jp": "プスコフ",
      "ka_ge": "ფსკოვი",
      "ko_kr": "프스코프",
      "lt_lt": "Pskovas",
      "lv_lv": "Pleskava",
      "mk_mk": "Псков",
      "nl_nl": "Pskov",
      "no_no": "Pskov",
      "pl_pl": "Psków",
      "pl_si": "Psków",
      "pt_br": "Pskov",
      "pt_pt": "Pskov",
      "ro_ro": "Pskov",
      "ru_ru": "Псков",
      "sk_sk": "Pskov",
      "sl_sl": "Pskov",
      "sr_sp": "Pskov",
      "sr_sr": "Псков",
      "sv_se": "Pskov",
      "tr_tr": "Pskov",
      "uk_uk": "Псков",
      "vi_vn": "Pskov",
      "zh_cn": "普斯科夫",
      "zh_tw": "普斯科夫"
    }
  },
  {
    "Name": "Луга",
    "Country": "russia",
    "X": 59346.33,
    "Y": -50665.293,
    "InGameId": "luga",
    "LocalizationToken": "luga",
    "CountryId": 38,
    "LocalizedNames": {
      "bg_bg": "Луга",
      "ca_es": "Luga",
      "cs_cz": "Luga",
      "da_dk": "Luga",
      "de_de": "Luga",
      "el_gr": "Λούγκα",
      "en_gb": "Luga",
      "en_us": "Luga",
      "es_es": "Luga",
      "es_la": "Luga",
      "et_ee": "Luga",
      "eu_es": "Luga",
      "fi_fi": "Laukaa",
      "fr_ca": "Louga",
      "fr_fr": "Louga",
      "gl_es": "Luga",
      "hr_hr": "Luga",
      "hu_hu": "Luga",
      "it_it": "Luga",
      "ja_jp": "ルーガ",
      "ka_ge": "ლუგა",
      "ko_kr": "루가",
      "lt_lt": "Luga",
      "lv_lv": "Luga",
      "mk_mk": "Луга",
      "nl_nl": "Luga",
      "no_no": "Luga",
      "pl_pl": "Ługa",
      "pl_si": "Ługa",
      "pt_br": "Luga",
      "pt_pt": "Luga",
      "ro_ro": "Luga",
      "ru_ru": "Луга",
      "sk_sk": "Luga",
      "sl_sl": "Luga",
      "sr_sp": "Luga",
      "sr_sr": "Луга",
      "sv_se": "Luga",
      "tr_tr": "Luga",
      "uk_uk": "Луга",
      "vi_vn": "Luga",
      "zh_cn": "卢加",
      "zh_tw": "盧加"
    }
  },
  {
    "Name": "Kassel",
    "Country": "germany",
    "X": -3381.44531,
    "Y": -4418.828,
    "InGameId": "kassel",
    "LocalizationToken": "kassel",
    "CountryId": 5,
    "LocalizedNames": {
      "bg_bg": "Касел",
      "ca_es": "Kassel",
      "cs_cz": "Kassel",
      "da_dk": "Kassel",
      "de_de": "Kassel",
      "el_gr": "Κάσελ",
      "en_gb": "Kassel",
      "en_us": "Kassel",
      "es_es": "Kassel",
      "es_la": "Kassel",
      "et_ee": "Kassel",
      "eu_es": "Kassel",
      "fi_fi": "Kassel",
      "fr_ca": "Cassel",
      "fr_fr": "Cassel",
      "gl_es": "Kassel",
      "hr_hr": "Kassel",
      "hu_hu": "Kassel",
      "it_it": "Kassel",
      "ja_jp": "カッセル",
      "ka_ge": "კასელი",
      "ko_kr": "카셀",
      "lt_lt": "Kaselis",
      "lv_lv": "Kasele",
      "mk_mk": "Касел",
      "nl_nl": "Kassel",
      "no_no": "Kassel",
      "pl_pl": "Kassel",
      "pl_si": "Kassel",
      "pt_br": "Kassel",
      "pt_pt": "Kassel",
      "ro_ro": "Kassel",
      "ru_ru": "Кассель",
      "sk_sk": "Kassel",
      "sl_sl": "Kassel",
      "sr_sp": "Kasel",
      "sr_sr": "Касел",
      "sv_se": "Kassel",
      "tr_tr": "Kassel",
      "uk_uk": "Кассель",
      "vi_vn": "Kassel",
      "zh_cn": "卡塞尔",
      "zh_tw": "卡瑟爾"
    }
  },
  {
    "Name": "Hannover",
    "Country": "germany",
    "X": -3001.80322,
    "Y": -10199.2979,
    "InGameId": "hannover",
    "LocalizationToken": "hannover",
    "CountryId": 5,
    "LocalizedNames": {
      "bg_bg": "Хановер",
      "ca_es": "Hannover",
      "cs_cz": "Hannover",
      "da_dk": "Hannover",
      "de_de": "Hannover",
      "el_gr": "Ανόβερο",
      "en_gb": "Hanover",
      "en_us": "Hanover",
      "es_es": "Hannover",
      "es_la": "Hannover",
      "et_ee": "Hannover",
      "eu_es": "Hannover",
      "fi_fi": "Hannover",
      "fr_ca": "Hanovre",
      "fr_fr": "Hanovre",
      "gl_es": "Hannover",
      "hr_hr": "Hannover",
      "hu_hu": "Hannover",
      "it_it": "Hannover",
      "ja_jp": "ハノーファー",
      "ka_ge": "ჰანოვერი",
      "ko_kr": "하노버",
      "lt_lt": "Hanoveris",
      "lv_lv": "Hannovere",
      "mk_mk": "Хановер",
      "nl_nl": "Hannover",
      "no_no": "Hannover",
      "pl_pl": "Hanower",
      "pl_si": "Hanower",
      "pt_br": "Hanôver",
      "pt_pt": "Hanover",
      "ro_ro": "Hanovra",
      "ru_ru": "Ганновер",
      "sk_sk": "Hanover",
      "sl_sl": "Hanover",
      "sr_sp": "Hanover",
      "sr_sr": "Хановер",
      "sv_se": "Hannover",
      "tr_tr": "Hannover",
      "uk_uk": "Ганновер",
      "vi_vn": "Hanover",
      "zh_cn": "汉诺威",
      "zh_tw": "漢諾威"
    }
  },
  {
    "Name": "Hamburg",
    "Country": "germany",
    "X": -2996.37036,
    "Y": -17401.61,
    "InGameId": "hamburg",
    "LocalizationToken": "hamburg",
    "CountryId": 5,
    "LocalizedNames": {
      "bg_bg": "Хамбург",
      "ca_es": "Hamburg",
      "cs_cz": "Hamburk",
      "da_dk": "Hamborg",
      "de_de": "Hamburg",
      "el_gr": "Αμβούργο",
      "en_gb": "Hamburg",
      "en_us": "Hamburg",
      "es_es": "Hamburgo",
      "es_la": "Hamburgo",
      "et_ee": "Hamburg",
      "eu_es": "Hanburgo",
      "fi_fi": "Hampuri",
      "fr_ca": "Hambourg",
      "fr_fr": "Hambourg",
      "gl_es": "Hamburgo",
      "hr_hr": "Hamburg",
      "hu_hu": "Hamburg",
      "it_it": "Amburgo",
      "ja_jp": "ハンブルク",
      "ka_ge": "ჰამბურგი",
      "ko_kr": "함부르크",
      "lt_lt": "Hamburgas",
      "lv_lv": "Hamburga",
      "mk_mk": "Хамбург",
      "nl_nl": "Hamburg",
      "no_no": "Hamburg",
      "pl_pl": "Hamburg",
      "pl_si": "Hamburg",
      "pt_br": "Hamburgo",
      "pt_pt": "Hamburgo",
      "ro_ro": "Hamburg",
      "ru_ru": "Гамбург",
      "sk_sk": "Hamburg",
      "sl_sl": "Hamburg",
      "sr_sp": "Hambur",
      "sr_sr": "Хамбур",
      "sv_se": "Hamburg",
      "tr_tr": "Hamburg",
      "uk_uk": "Гамбург",
      "vi_vn": "Hamburg",
      "zh_cn": "汉堡",
      "zh_tw": "漢堡"
    }
  },
  {
    "Name": "Frankfurt am Main",
    "Country": "germany",
    "X": -6983.73828,
    "Y": 1597.04688,
    "InGameId": "frankfurt",
    "LocalizationToken": "frankfurt_am_main",
    "CountryId": 5,
    "LocalizedNames": {
      "bg_bg": "Франкфурт на Майн",
      "ca_es": "Frankfurt del Main",
      "cs_cz": "Frankfurt nad Mohanem",
      "da_dk": "Frankfurt am Main",
      "de_de": "Frankfurt am Main",
      "el_gr": "Φρανκφούρτη",
      "en_gb": "Frankfurt am Main",
      "en_us": "Frankfurt am Main",
      "es_es": "Fráncfort del Meno",
      "es_la": "Fráncfort del Meno",
      "et_ee": "Frankfurt Maini ääres",
      "eu_es": "Frankfurt am Main",
      "fi_fi": "Frankfurt am Main",
      "fr_ca": "Francfort-sur-le-Main",
      "fr_fr": "Francfort-sur-le-Main",
      "gl_es": "Frankfurt am Main",
      "hr_hr": "Frankfurt na Majni",
      "hu_hu": "Frankfurt am Main",
      "it_it": "Francoforte sul Meno",
      "ja_jp": "フランクフルト・アム・マイン",
      "ka_ge": "მაინის ფრანკფურტი",
      "ko_kr": "프랑크푸르트 암 마인",
      "lt_lt": "Frankfurtas prie Maino",
      "lv_lv": "Frankfurte pie Mainas",
      "mk_mk": "Франкфурт на Мајна",
      "nl_nl": "Frankfurt am Main",
      "no_no": "Frankfurt am Main",
      "pl_pl": "Frankfurt nad Menem",
      "pl_si": "Frankfurt nad Menem",
      "pt_br": "Frankfurt am Main",
      "pt_pt": "Frankfurt am Main",
      "ro_ro": "Frankfurt pe Main",
      "ru_ru": "Франкфурт-на-Майне",
      "sk_sk": "Frankfurt nad Mohanom",
      "sl_sl": "Frankfurt ob Majni",
      "sr_sp": "Frankfurt na Majni",
      "sr_sr": "Франкфурт на Мајни",
      "sv_se": "Frankfurt am Main",
      "tr_tr": "Frankfurt",
      "uk_uk": "Франкфурт-на-Майні",
      "vi_vn": "Frankfurt am Main",
      "zh_cn": "法兰克福",
      "zh_tw": "美茵河畔法蘭克福"
    }
  },
  {
    "Name": "Mannheim",
    "Country": "germany",
    "X": -8322.992,
    "Y": 5545.828,
    "InGameId": "mannheim",
    "LocalizationToken": "mannheim",
    "CountryId": 5,
    "LocalizedNames": {
      "bg_bg": "Манхайм",
      "ca_es": "Mannheim",
      "cs_cz": "Mannheim",
      "da_dk": "Mannheim",
      "de_de": "Mannheim",
      "el_gr": "Μάνχαϊμ",
      "en_gb": "Mannheim",
      "en_us": "Mannheim",
      "es_es": "Mannheim",
      "es_la": "Mannheim",
      "et_ee": "Mannheim",
      "eu_es": "Mannheim",
      "fi_fi": "Mannheim",
      "fr_ca": "Mannheim",
      "fr_fr": "Mannheim",
      "gl_es": "Mannheim",
      "hr_hr": "Mannheim",
      "hu_hu": "Mannheim",
      "it_it": "Mannheim",
      "ja_jp": "マンハイム",
      "ka_ge": "მანჰაიმი",
      "ko_kr": "만하임",
      "lt_lt": "Mannheim",
      "lv_lv": "Mannheim",
      "mk_mk": "Манхајм",
      "nl_nl": "Mannheim",
      "no_no": "Mannheim",
      "pl_pl": "Mannheim",
      "pl_si": "Mannheim",
      "pt_br": "Mannheim",
      "pt_pt": "Mannheim",
      "ro_ro": "Mannheim",
      "ru_ru": "Мангейм",
      "sk_sk": "Mannheim",
      "sl_sl": "Mannheim",
      "sr_sp": "Manhajm",
      "sr_sr": "Манхајм",
      "sv_se": "Mannheim",
      "tr_tr": "Mannheim",
      "uk_uk": "Мангайм",
      "vi_vn": "Mannheim",
      "zh_cn": "曼海姆",
      "zh_tw": "曼海姆"
    }
  },
  {
    "Name": "Stuttgart",
    "Country": "germany",
    "X": -5746.2876,
    "Y": 9265.669,
    "InGameId": "stuttgart",
    "LocalizationToken": "stuttgart",
    "CountryId": 5,
    "LocalizedNames": {
      "bg_bg": "Щутгарт",
      "ca_es": "Stuttgart",
      "cs_cz": "Stuttgart",
      "da_dk": "Stuttgart",
      "de_de": "Stuttgart",
      "el_gr": "Στουτγκάρδη",
      "en_gb": "Stuttgart",
      "en_us": "Stuttgart",
      "es_es": "Stuttgart",
      "es_la": "Stuttgart",
      "et_ee": "Stuttgart",
      "eu_es": "Stuttgart",
      "fi_fi": "Stuttgart",
      "fr_ca": "Stuttgart",
      "fr_fr": "Stuttgart",
      "gl_es": "Stuttgart",
      "hr_hr": "Stuttgart",
      "hu_hu": "Stuttgart",
      "it_it": "Stoccarda",
      "ja_jp": "シュトゥットガルト",
      "ka_ge": "შტუტგარტი",
      "ko_kr": "슈투트가르트",
      "lt_lt": "Štutgartas",
      "lv_lv": "Štutgarte",
      "mk_mk": "Штутгард",
      "nl_nl": "Stuttgart",
      "no_no": "Stuttgart",
      "pl_pl": "Stuttgart",
      "pl_si": "Stuttgart",
      "pt_br": "Stuttgart",
      "pt_pt": "Estugarda",
      "ro_ro": "Stuttgart",
      "ru_ru": "Штутгарт",
      "sk_sk": "Štuttgart",
      "sl_sl": "Stuttgart",
      "sr_sp": "Štutgart",
      "sr_sr": "Штутгарт",
      "sv_se": "Stuttgart",
      "tr_tr": "Stuttgart",
      "uk_uk": "Штутгарт",
      "vi_vn": "Stuttgart",
      "zh_cn": "斯图加特",
      "zh_tw": "司徒加特"
    }
  },
  {
    "Name": "Milano",
    "Country": "italy",
    "X": -7916.57031,
    "Y": 28693.5625,
    "InGameId": "milano",
    "LocalizationToken": "milano",
    "CountryId": 6,
    "LocalizedNames": {
      "bg_bg": "Милан",
      "ca_es": "Milà",
      "cs_cz": "Miláno",
      "da_dk": "Milan",
      "de_de": "Mailand",
      "el_gr": "Μιλάνο",
      "en_gb": "Milan",
      "en_us": "Milan",
      "es_es": "Milán",
      "es_la": "Milán",
      "et_ee": "Milano",
      "eu_es": "Milan",
      "fi_fi": "Milano",
      "fr_ca": "Milan",
      "fr_fr": "Milan",
      "gl_es": "Milán",
      "hr_hr": "Milano",
      "hu_hu": "Milánó",
      "it_it": "Milano",
      "ja_jp": "ミラノ",
      "ka_ge": "მილანი",
      "ko_kr": "밀라노",
      "lt_lt": "Milanas",
      "lv_lv": "Milāna",
      "mk_mk": "Милано",
      "nl_nl": "Milaan",
      "no_no": "Milano",
      "pl_pl": "Mediolan",
      "pl_si": "Mediolan",
      "pt_br": "Milão",
      "pt_pt": "Milão",
      "ro_ro": "Milano",
      "ru_ru": "Милан",
      "sk_sk": "Miláno",
      "sl_sl": "Milano",
      "sr_sp": "Milano",
      "sr_sr": "Милано",
      "sv_se": "Milano",
      "tr_tr": "Milano",
      "uk_uk": "Мілан",
      "vi_vn": "Milan",
      "zh_cn": "米兰",
      "zh_tw": "米蘭"
    }
  },
  {
    "Name": "Olbia",
    "Country": "italy",
    "X": -7765.93359,
    "Y": 54715.4063,
    "InGameId": "olbia",
    "LocalizationToken": "olbia",
    "CountryId": 6,
    "LocalizedNames": {
      "bg_bg": "Олбия",
      "ca_es": "Olbia",
      "cs_cz": "Olbia",
      "da_dk": "Olbia",
      "de_de": "Olbia",
      "el_gr": "Όλμπια",
      "en_gb": "Olbia",
      "en_us": "Olbia",
      "es_es": "Olbia",
      "es_la": "Olbia",
      "et_ee": "Olbia",
      "eu_es": "Olbia",
      "fi_fi": "Olbia",
      "fr_ca": "Olbia",
      "fr_fr": "Olbia",
      "gl_es": "Olbia",
      "hr_hr": "Olbia",
      "hu_hu": "Olbia",
      "it_it": "Olbia",
      "ja_jp": "オルビア",
      "ka_ge": "ოლბია",
      "ko_kr": "올비아",
      "lt_lt": "Olbija",
      "lv_lv": "Olbia",
      "mk_mk": "Олбија",
      "nl_nl": "Olbia",
      "no_no": "Olbia",
      "pl_pl": "Olbia",
      "pl_si": "Olbia",
      "pt_br": "Olbia",
      "pt_pt": "Ólbia",
      "ro_ro": "Olbia",
      "ru_ru": "Ольбия",
      "sk_sk": "Olbia",
      "sl_sl": "Olbia",
      "sr_sp": "Olbija",
      "sr_sr": "Олбија",
      "sv_se": "Olbia",
      "tr_tr": "Olbia",
      "uk_uk": "Ольбія",
      "vi_vn": "Olbia",
      "zh_cn": "奥尔比亚",
      "zh_tw": "奧比亞"
    }
  },
  {
    "Name": "Osnabrück",
    "Country": "germany",
    "X": -8120.60547,
    "Y": -9933.598,
    "InGameId": "osnabruck",
    "LocalizationToken": "osnabruck",
    "CountryId": 5,
    "LocalizedNames": {
      "bg_bg": "Оснабрюк",
      "ca_es": "Osnabrück",
      "cs_cz": "Osnabrück",
      "da_dk": "Osnabrück",
      "de_de": "Osnabrück",
      "el_gr": "Όσναμπρουκ",
      "en_gb": "Osnabrück",
      "en_us": "Osnabrück",
      "es_es": "Osnabrück",
      "es_la": "Osnabrück",
      "et_ee": "Osnabrück",
      "eu_es": "Osnabrück",
      "fi_fi": "Osnabrück",
      "fr_ca": "Osnabrück",
      "fr_fr": "Osnabrück",
      "gl_es": "Osnabrück",
      "hr_hr": "Osnabrück",
      "hu_hu": "Osnabrück",
      "it_it": "Osnabrück",
      "ja_jp": "オスナブリュック",
      "ka_ge": "ოსნაბრიუკი",
      "ko_kr": "오스나브뤼크",
      "lt_lt": "Osnabriukas",
      "lv_lv": "Osnabrika",
      "mk_mk": "Оснабрик",
      "nl_nl": "Osnabrück",
      "no_no": "Osnabrück",
      "pl_pl": "Osnabrück",
      "pl_si": "Osnabrück",
      "pt_br": "Osnabrück",
      "pt_pt": "Osnabrück",
      "ro_ro": "Osnabrück",
      "ru_ru": "Оснабрюк",
      "sk_sk": "Osnabrück",
      "sl_sl": "Osnabrück",
      "sr_sp": "Osnabrik",
      "sr_sr": "Оснабрик",
      "sv_se": "Osnabrück",
      "tr_tr": "Osnabrück",
      "uk_uk": "Оснабрюк",
      "vi_vn": "Osnabrück",
      "zh_cn": "奥斯纳布吕克",
      "zh_tw": "奧斯納布魯克"
    }
  },
  {
    "Name": "Bremen",
    "Country": "germany",
    "X": -4970.53125,
    "Y": -14856.2617,
    "InGameId": "bremen",
    "LocalizationToken": "bremen",
    "CountryId": 5,
    "LocalizedNames": {
      "bg_bg": "Бремен",
      "ca_es": "Bremen",
      "cs_cz": "Brémy",
      "da_dk": "Bremen",
      "de_de": "Bremen",
      "el_gr": "Βρέμη",
      "en_gb": "Bremen",
      "en_us": "Bremen",
      "es_es": "Bremen",
      "es_la": "Bremen",
      "et_ee": "Bremen",
      "eu_es": "Bremen",
      "fi_fi": "Bremen",
      "fr_ca": "Brême",
      "fr_fr": "Brême",
      "gl_es": "Bremen",
      "hr_hr": "Bremen",
      "hu_hu": "Bréma",
      "it_it": "Brema",
      "ja_jp": "ブレーメン",
      "ka_ge": "ბრემენი",
      "ko_kr": "브레멘",
      "lt_lt": "Bremenas",
      "lv_lv": "Brēmene",
      "mk_mk": "Бремен",
      "nl_nl": "Bremen",
      "no_no": "Bremen",
      "pl_pl": "Brema",
      "pl_si": "Brema",
      "pt_br": "Bremen",
      "pt_pt": "Bremen",
      "ro_ro": "Bremen",
      "ru_ru": "Бремен",
      "sk_sk": "Brémy",
      "sl_sl": "Bremen",
      "sr_sp": "Bremen",
      "sr_sr": "Бремен",
      "sv_se": "Bremen",
      "tr_tr": "Bremen",
      "uk_uk": "Бремен",
      "vi_vn": "Bremen",
      "zh_cn": "不莱梅",
      "zh_tw": "布萊梅"
    }
  },
  {
    "Name": "Strasbourg",
    "Country": "france",
    "X": -11387.07,
    "Y": 10537.2656,
    "InGameId": "strasbourg",
    "LocalizationToken": "strasbourg",
    "CountryId": 4,
    "LocalizedNames": {
      "bg_bg": "Страсбург",
      "ca_es": "Estrasburg",
      "cs_cz": "Štrasburk",
      "da_dk": "Strasbourg",
      "de_de": "Straßburg",
      "el_gr": "Στρασβούργο",
      "en_gb": "Strasbourg",
      "en_us": "Strasbourg",
      "es_es": "Estrasburgo",
      "es_la": "Estrasburgo",
      "et_ee": "Strasbourg",
      "eu_es": "Estrasburgo",
      "fi_fi": "Strasbourg",
      "fr_ca": "Strasbourg",
      "fr_fr": "Strasbourg",
      "gl_es": "Estrasburgo",
      "hr_hr": "Strasbourg",
      "hu_hu": "Strasbourg",
      "it_it": "Strasburgo",
      "ja_jp": "ストラスブール",
      "ka_ge": "სტრასბურგი",
      "ko_kr": "스트라스부르",
      "lt_lt": "Strasbūras",
      "lv_lv": "Strasbūra",
      "mk_mk": "Стразбур",
      "nl_nl": "Straatsburg",
      "no_no": "Strasbourg",
      "pl_pl": "Strasburg",
      "pl_si": "Strasburg",
      "pt_br": "Estrasburgo",
      "pt_pt": "Estrasburgo",
      "ro_ro": "Strasbourg",
      "ru_ru": "Страсбург",
      "sk_sk": "Štrasburg",
      "sl_sl": "Strasbourg",
      "sr_sp": "Strazbur",
      "sr_sr": "Стразбур",
      "sv_se": "Strasbourg",
      "tr_tr": "Strazburg",
      "uk_uk": "Страсбург",
      "vi_vn": "Strasbourg",
      "zh_cn": "斯特拉斯堡",
      "zh_tw": "史特拉斯堡"
    }
  },
  {
    "Name": "Zürich",
    "Country": "switzerland",
    "X": -8773.934,
    "Y": 17498.34,
    "InGameId": "zurich",
    "LocalizationToken": "zurich",
    "CountryId": 9,
    "LocalizedNames": {
      "bg_bg": "Цюрих",
      "ca_es": "Zuric",
      "cs_cz": "Curych",
      "da_dk": "Zürich",
      "de_de": "Zürich",
      "el_gr": "Ζυρίχη",
      "en_gb": "Zürich",
      "en_us": "Zürich",
      "es_es": "Zúrich",
      "es_la": "Zúrich",
      "et_ee": "Zürich",
      "eu_es": "Zürich",
      "fi_fi": "Zürich",
      "fr_ca": "Zurich",
      "fr_fr": "Zurich",
      "gl_es": "Zúric",
      "hr_hr": "Zürich",
      "hu_hu": "Zürich",
      "it_it": "Zurigo",
      "ja_jp": "チューリッヒ",
      "ka_ge": "ციურიხი",
      "ko_kr": "취리히",
      "lt_lt": "Ciurichas",
      "lv_lv": "Cīrihe",
      "mk_mk": "Цирих",
      "nl_nl": "Zürich",
      "no_no": "Zürich",
      "pl_pl": "Zurych",
      "pl_si": "Zurych",
      "pt_br": "Zurique",
      "pt_pt": "Zurique",
      "ro_ro": "Zürich",
      "ru_ru": "Цюрих",
      "sk_sk": "Zürich",
      "sl_sl": "Zürich",
      "sr_sp": "Cirih",
      "sr_sr": "Цирих",
      "sv_se": "Zürich",
      "tr_tr": "Zürih",
      "uk_uk": "Цюрих",
      "vi_vn": "Zürich",
      "zh_cn": "苏黎世",
      "zh_tw": "蘇黎世"
    }
  },
  {
    "Name": "Genova",
    "Country": "italy",
    "X": -9232.379,
    "Y": 34875.9766,
    "InGameId": "genova",
    "LocalizationToken": "genova",
    "CountryId": 6,
    "LocalizedNames": {
      "bg_bg": "Генуа",
      "ca_es": "Gènova",
      "cs_cz": "Janov",
      "da_dk": "Genova",
      "de_de": "Genua",
      "el_gr": "Γένοβα",
      "en_gb": "Genoa",
      "en_us": "Genoa",
      "es_es": "Génova",
      "es_la": "Génova",
      "et_ee": "Genova",
      "eu_es": "Genova",
      "fi_fi": "Genova",
      "fr_ca": "Gènes",
      "fr_fr": "Gènes",
      "gl_es": "Xénova",
      "hr_hr": "Genova",
      "hu_hu": "Genova",
      "it_it": "Genova",
      "ja_jp": "ジェノヴァ",
      "ka_ge": "გენუა",
      "ko_kr": "제노바",
      "lt_lt": "Genuja",
      "lv_lv": "Dženova",
      "mk_mk": "Џенова",
      "nl_nl": "Genua",
      "no_no": "Genova",
      "pl_pl": "Genua",
      "pl_si": "Genua",
      "pt_br": "Gênova",
      "pt_pt": "Génova",
      "ro_ro": "Genova",
      "ru_ru": "Генуя",
      "sk_sk": "Janov",
      "sl_sl": "Genova",
      "sr_sp": "Đenova",
      "sr_sr": "Ђенова",
      "sv_se": "Genua",
      "tr_tr": "Cenova",
      "uk_uk": "Генуя",
      "vi_vn": "Genoa",
      "zh_cn": "热那亚",
      "zh_tw": "熱那亞"
    }
  },
  {
    "Name": "Sassari",
    "Country": "italy",
    "X": -11556.4141,
    "Y": 55186.8633,
    "InGameId": "sassari",
    "LocalizationToken": "sassari",
    "CountryId": 6,
    "LocalizedNames": {
      "bg_bg": "Сасари",
      "ca_es": "Sàsser",
      "cs_cz": "Sassari",
      "da_dk": "Sassari",
      "de_de": "Sassari",
      "el_gr": "Σάσσαρι",
      "en_gb": "Sassari",
      "en_us": "Sassari",
      "es_es": "Sácer",
      "es_la": "Sácer",
      "et_ee": "Sassari",
      "eu_es": "Sassari",
      "fi_fi": "Sassari",
      "fr_ca": "Sassari",
      "fr_fr": "Sassari",
      "gl_es": "Sassari",
      "hr_hr": "Sassari",
      "hu_hu": "Sassari",
      "it_it": "Sassari",
      "ja_jp": "サッサリ",
      "ka_ge": "სასარი",
      "ko_kr": "사사리",
      "lt_lt": "Sasaris",
      "lv_lv": "Sassari",
      "mk_mk": "Сасари",
      "nl_nl": "Sassari",
      "no_no": "Sassari",
      "pl_pl": "Sassari",
      "pl_si": "Sassari",
      "pt_br": "Sassari",
      "pt_pt": "Sássari",
      "ro_ro": "Sassari",
      "ru_ru": "Сассари",
      "sk_sk": "Sassari",
      "sl_sl": "Sassari",
      "sr_sp": "Sasari",
      "sr_sr": "Сасари",
      "sv_se": "Sassari",
      "tr_tr": "Sassari",
      "uk_uk": "Сассарі",
      "vi_vn": "Sassari",
      "zh_cn": "萨萨里",
      "zh_tw": "薩薩里"
    }
  },
  {
    "Name": "Bonifacio",
    "Country": "france",
    "X": -8719.895,
    "Y": 51934.8164,
    "InGameId": "bonifacio",
    "LocalizationToken": "bonifacio",
    "CountryId": 4,
    "LocalizedNames": {
      "bg_bg": "Бонифачо",
      "ca_es": "Bunifaziu",
      "cs_cz": "Bonifacio",
      "da_dk": "Bonifacio",
      "de_de": "Bonifacio",
      "el_gr": "Μπονιφασιό",
      "en_gb": "Bonifacio",
      "en_us": "Bonifacio",
      "es_es": "Bonifacio",
      "es_la": "Bonifacio",
      "et_ee": "Bonifacio",
      "eu_es": "Bonifacio",
      "fi_fi": "Bonifacio",
      "fr_ca": "Bonifacio",
      "fr_fr": "Bonifacio",
      "gl_es": "Bonifacio",
      "hr_hr": "Bonifacio",
      "hu_hu": "Bonifacio",
      "it_it": "Bonifacio",
      "ja_jp": "ボニファシオ",
      "ka_ge": "ბონიფაჩო",
      "ko_kr": "보니파시오",
      "lt_lt": "Bonifacijus",
      "lv_lv": "Bonifacio",
      "mk_mk": "Бонифачо",
      "nl_nl": "Bonifacio",
      "no_no": "Bonifacio",
      "pl_pl": "Bonifacio",
      "pl_si": "Bonifacio",
      "pt_br": "Bonifacio",
      "pt_pt": "Bonifácio",
      "ro_ro": "Bonifacio",
      "ru_ru": "Бонифачо",
      "sk_sk": "Bonifacio",
      "sl_sl": "Bonifacio",
      "sr_sp": "Bonifačo",
      "sr_sr": "Бонифачо",
      "sv_se": "Bonifacio",
      "tr_tr": "Bonifacio",
      "uk_uk": "Боніфачо",
      "vi_vn": "Bonifacio",
      "zh_cn": "博尼法乔",
      "zh_tw": "博尼法喬"
    }
  },
  {
    "Name": "Dortmund",
    "Country": "germany",
    "X": -11102.1914,
    "Y": -6914.77734,
    "InGameId": "dortmund",
    "LocalizationToken": "dortmund",
    "CountryId": 5,
    "LocalizedNames": {
      "bg_bg": "Дортмунд",
      "ca_es": "Dortmund",
      "cs_cz": "Dortmund",
      "da_dk": "Dortmund",
      "de_de": "Dortmund",
      "el_gr": "Ντόρτμουντ",
      "en_gb": "Dortmund",
      "en_us": "Dortmund",
      "es_es": "Dortmund",
      "es_la": "Dortmund",
      "et_ee": "Dortmund",
      "eu_es": "Dortmund",
      "fi_fi": "Dortmund",
      "fr_ca": "Dortmund",
      "fr_fr": "Dortmund",
      "gl_es": "Dortmund",
      "hr_hr": "Dortmund",
      "hu_hu": "Dortmund",
      "it_it": "Dortmund",
      "ja_jp": "ドルトムント",
      "ka_ge": "დორტმუნდი",
      "ko_kr": "도르트문트",
      "lt_lt": "Dortmundas",
      "lv_lv": "Dortmunde",
      "mk_mk": "Дортмунд",
      "nl_nl": "Dortmund",
      "no_no": "Dortmund",
      "pl_pl": "Dortmund",
      "pl_si": "Dortmund",
      "pt_br": "Dortmund",
      "pt_pt": "Dortmund",
      "ro_ro": "Dortmund",
      "ru_ru": "Дортмунд",
      "sk_sk": "Dortmund",
      "sl_sl": "Dortmund",
      "sr_sp": "Dortmund",
      "sr_sr": "Дортмунд",
      "sv_se": "Dortmund",
      "tr_tr": "Dortmund",
      "uk_uk": "Дортмунд",
      "vi_vn": "Dortmund",
      "zh_cn": "多特蒙德",
      "zh_tw": "多特蒙德"
    }
  },
  {
    "Name": "Werlte",
    "Country": "germany",
    "X": -9597.352,
    "Y": -13020.4766,
    "InGameId": "werlte",
    "LocalizationToken": "werlte",
    "CountryId": 5,
    "LocalizedNames": {
      "bg_bg": "Верлте",
      "ca_es": "Werlte",
      "cs_cz": "Werlte",
      "da_dk": "Werlte",
      "de_de": "Werlte",
      "el_gr": "Βέρλτε",
      "en_gb": "Werlte",
      "en_us": "Werlte",
      "es_es": "Werlte",
      "es_la": "Werlte",
      "et_ee": "Werlte",
      "eu_es": "Werlte",
      "fi_fi": "Werlte",
      "fr_ca": "Werlte",
      "fr_fr": "Werlte",
      "gl_es": "Werlte",
      "hr_hr": "Werlte",
      "hu_hu": "Werlte",
      "it_it": "Werlte",
      "ja_jp": "ヴェルテ",
      "ka_ge": "ვერლტე",
      "ko_kr": "웰터",
      "lt_lt": "Verltas",
      "lv_lv": "Werlte",
      "mk_mk": "Верлте",
      "nl_nl": "Werlte",
      "no_no": "Werlte",
      "pl_pl": "Werlte",
      "pl_si": "Werlte",
      "pt_br": "Werlte",
      "pt_pt": "Werlte",
      "ro_ro": "Werlte",
      "ru_ru": "Верльте",
      "sk_sk": "Werlte",
      "sl_sl": "Werlte",
      "sr_sp": "Verlte",
      "sr_sr": "Верлте",
      "sv_se": "Werlte",
      "tr_tr": "Werlte",
      "uk_uk": "Верльте",
      "vi_vn": "Werlte",
      "zh_cn": "韦尔特",
      "zh_tw": "韋爾特"
    }
  },
  {
    "Name": "Bern",
    "Country": "switzerland",
    "X": -13180.9141,
    "Y": 19609.9766,
    "InGameId": "bern",
    "LocalizationToken": "bern",
    "CountryId": 9,
    "LocalizedNames": {
      "bg_bg": "Берн",
      "ca_es": "Berna",
      "cs_cz": "Bern",
      "da_dk": "Bern",
      "de_de": "Bern",
      "el_gr": "Βέρνη",
      "en_gb": "Bern",
      "en_us": "Bern",
      "es_es": "Berna",
      "es_la": "Berna",
      "et_ee": "Bern",
      "eu_es": "Berna",
      "fi_fi": "Bern",
      "fr_ca": "Berne",
      "fr_fr": "Berne",
      "gl_es": "Berna",
      "hr_hr": "Bern",
      "hu_hu": "Bern",
      "it_it": "Berna",
      "ja_jp": "ベルン",
      "ka_ge": "ბერნი",
      "ko_kr": "베른",
      "lt_lt": "Bernas",
      "lv_lv": "Berne",
      "mk_mk": "Берн",
      "nl_nl": "Bern",
      "no_no": "Bern",
      "pl_pl": "Berno",
      "pl_si": "Berno",
      "pt_br": "Berna",
      "pt_pt": "Berna",
      "ro_ro": "Berna",
      "ru_ru": "Берн",
      "sk_sk": "Bern",
      "sl_sl": "Bern",
      "sr_sp": "Bern",
      "sr_sr": "Берн",
      "sv_se": "Bern",
      "tr_tr": "Bern",
      "uk_uk": "Берн",
      "vi_vn": "Bern",
      "zh_cn": "伯尔尼",
      "zh_tw": "伯恩"
    }
  },
  {
    "Name": "Torino",
    "Country": "italy",
    "X": -13222.375,
    "Y": 30211.8828,
    "InGameId": "torino",
    "LocalizationToken": "torino",
    "CountryId": 6,
    "LocalizedNames": {
      "bg_bg": "Торино",
      "ca_es": "Torí",
      "cs_cz": "Turín",
      "da_dk": "Torino",
      "de_de": "Turin",
      "el_gr": "Τορίνο",
      "en_gb": "Turin",
      "en_us": "Turin",
      "es_es": "Turín",
      "es_la": "Turín",
      "et_ee": "Torino",
      "eu_es": "Turin",
      "fi_fi": "Torino",
      "fr_ca": "Turin",
      "fr_fr": "Turin",
      "gl_es": "Turín",
      "hr_hr": "Torino",
      "hu_hu": "Torinó",
      "it_it": "Torino",
      "ja_jp": "トリノ",
      "ka_ge": "ტურინი",
      "ko_kr": "토리노",
      "lt_lt": "Turinas",
      "lv_lv": "Turīna",
      "mk_mk": "Торино",
      "nl_nl": "Turijn",
      "no_no": "Torino",
      "pl_pl": "Turyn",
      "pl_si": "Turyn",
      "pt_br": "Turim",
      "pt_pt": "Turim",
      "ro_ro": "Torino",
      "ru_ru": "Турин",
      "sk_sk": "Turín",
      "sl_sl": "Torino",
      "sr_sp": "Torino",
      "sr_sr": "Торино",
      "sv_se": "Turin",
      "tr_tr": "Torino",
      "uk_uk": "Турин",
      "vi_vn": "Turin",
      "zh_cn": "都灵",
      "zh_tw": "杜林"
    }
  },
  {
    "Name": "Köln",
    "Country": "germany",
    "X": -13750.918,
    "Y": -3352.879,
    "InGameId": "koln",
    "LocalizationToken": "koln",
    "CountryId": 5,
    "LocalizedNames": {
      "bg_bg": "Кьолн",
      "ca_es": "Colònia",
      "cs_cz": "Kolín",
      "da_dk": "Køln",
      "de_de": "Köln",
      "el_gr": "Κολωνία",
      "en_gb": "Cologne",
      "en_us": "Cologne",
      "es_es": "Colonia",
      "es_la": "Colonia",
      "et_ee": "Köln",
      "eu_es": "Kolonia",
      "fi_fi": "Köln",
      "fr_ca": "Cologne",
      "fr_fr": "Cologne",
      "gl_es": "Colonia",
      "hr_hr": "Köln",
      "hu_hu": "Köln",
      "it_it": "Colonia",
      "ja_jp": "ケルン",
      "ka_ge": "კელნი",
      "ko_kr": "쾰른",
      "lt_lt": "Kelnas",
      "lv_lv": "Ķelne",
      "mk_mk": "Келн",
      "nl_nl": "Keulen",
      "no_no": "Köln",
      "pl_pl": "Kolonia",
      "pl_si": "Kolonia",
      "pt_br": "Colônia",
      "pt_pt": "Colónia",
      "ro_ro": "Köln",
      "ru_ru": "Кёльн",
      "sk_sk": "Kolín nad Rýnom",
      "sl_sl": "Köln",
      "sr_sp": "Keln",
      "sr_sr": "Келн",
      "sv_se": "Köln",
      "tr_tr": "Köln",
      "uk_uk": "Кельн",
      "vi_vn": "Cologne",
      "zh_cn": "科隆",
      "zh_tw": "科隆"
    }
  },
  {
    "Name": "Duisburg",
    "Country": "germany",
    "X": -13663.7188,
    "Y": -6978.492,
    "InGameId": "duisburg",
    "LocalizationToken": "duisburg",
    "CountryId": 5,
    "LocalizedNames": {
      "bg_bg": "Дуйсбург",
      "ca_es": "Duisburg",
      "cs_cz": "Duisburg",
      "da_dk": "Duisburg",
      "de_de": "Duisburg",
      "el_gr": "Ντούισμπουργκ",
      "en_gb": "Duisburg",
      "en_us": "Duisburg",
      "es_es": "Duisburgo",
      "es_la": "Duisburgo",
      "et_ee": "Duisburg",
      "eu_es": "Duisburg",
      "fi_fi": "Duisburg",
      "fr_ca": "Duisbourg",
      "fr_fr": "Duisbourg",
      "gl_es": "Duisburgo",
      "hr_hr": "Duisburg",
      "hu_hu": "Duisburg",
      "it_it": "Duisburg",
      "ja_jp": "デュースブルク",
      "ka_ge": "დუისბურგი",
      "ko_kr": "뒤스부르크",
      "lt_lt": "Duisburgas",
      "lv_lv": "Dīsburga",
      "mk_mk": "Дисбург",
      "nl_nl": "Duisburg",
      "no_no": "Duisburg",
      "pl_pl": "Duisburg",
      "pl_si": "Duisburg",
      "pt_br": "Duisburgo",
      "pt_pt": "Duisburg",
      "ro_ro": "Duisburg",
      "ru_ru": "Дуйсбург",
      "sk_sk": "Duisburg",
      "sl_sl": "Duisburg",
      "sr_sp": "Duizburg",
      "sr_sr": "Дуизбург",
      "sv_se": "Duisburg",
      "tr_tr": "Duisburg",
      "uk_uk": "Дуйсбург",
      "vi_vn": "Duisburg",
      "zh_cn": "杜伊斯堡",
      "zh_tw": "杜易斯堡"
    }
  },
  {
    "Name": "Düsseldorf",
    "Country": "germany",
    "X": -13977.3047,
    "Y": -4719.72656,
    "InGameId": "dusseldorf",
    "LocalizationToken": "dusseldorf",
    "CountryId": 5,
    "LocalizedNames": {
      "bg_bg": "Дюселдорф",
      "ca_es": "Düsseldorf",
      "cs_cz": "Düsseldorf",
      "da_dk": "Düsseldorf",
      "de_de": "Düsseldorf",
      "el_gr": "Ντίσελντορφ",
      "en_gb": "Düsseldorf",
      "en_us": "Düsseldorf",
      "es_es": "Düsseldorf",
      "es_la": "Düsseldorf",
      "et_ee": "Düsseldorf",
      "eu_es": "Düsseldorf",
      "fi_fi": "Düsseldorf",
      "fr_ca": "Düsseldorf",
      "fr_fr": "Düsseldorf",
      "gl_es": "Düsseldorf",
      "hr_hr": "Düsseldorf",
      "hu_hu": "Düsseldorf",
      "it_it": "Düsseldorf",
      "ja_jp": "デュッセルドルフ",
      "ka_ge": "დუსელდორფი",
      "ko_kr": "뒤셀도르프",
      "lt_lt": "Diuseldorfas",
      "lv_lv": "Dīseldorfa",
      "mk_mk": "Диселдорф",
      "nl_nl": "Düsseldorf",
      "no_no": "Düsseldorf",
      "pl_pl": "Düsseldorf",
      "pl_si": "Düsseldorf",
      "pt_br": "Düsseldorf",
      "pt_pt": "Düsseldorf",
      "ro_ro": "Düsseldorf",
      "ru_ru": "Дюссельдорф",
      "sk_sk": "Düsseldorf",
      "sl_sl": "Düsseldorf",
      "sr_sp": "Diseldorf",
      "sr_sr": "Диселдорф",
      "sv_se": "Düsseldorf",
      "tr_tr": "Düsseldorf",
      "uk_uk": "Дюссельдорф",
      "vi_vn": "Düsseldorf",
      "zh_cn": "杜塞尔多夫",
      "zh_tw": "杜塞爾多夫"
    }
  },
  {
    "Name": "Groningen",
    "Country": "netherlands",
    "X": -12787.7578,
    "Y": -15988.7813,
    "InGameId": "groningen",
    "LocalizationToken": "groningen",
    "CountryId": 7,
    "LocalizedNames": {
      "bg_bg": "Гронинген",
      "ca_es": "Groningen",
      "cs_cz": "Groningen",
      "da_dk": "Groningen",
      "de_de": "Groningen",
      "el_gr": "Χρόνινγκεν",
      "en_gb": "Groningen",
      "en_us": "Groningen",
      "es_es": "Groninga",
      "es_la": "Groninga",
      "et_ee": "Groningen",
      "eu_es": "Groninga",
      "fi_fi": "Groningen",
      "fr_ca": "Groningue",
      "fr_fr": "Groningue",
      "gl_es": "Groninga",
      "hr_hr": "Groningen",
      "hu_hu": "Groningen",
      "it_it": "Groningen",
      "ja_jp": "フローニンゲン",
      "ka_ge": "გრონინგენი",
      "ko_kr": "흐로닝언",
      "lt_lt": "Groningenas",
      "lv_lv": "Groningena",
      "mk_mk": "Гронинген",
      "nl_nl": "Groningen",
      "no_no": "Groningen",
      "pl_pl": "Groningen",
      "pl_si": "Groningen",
      "pt_br": "Groningen",
      "pt_pt": "Groningen",
      "ro_ro": "Groningen",
      "ru_ru": "Гронинген",
      "sk_sk": "Groningen",
      "sl_sl": "Groningen",
      "sr_sp": "Groningen",
      "sr_sr": "Гронинген",
      "sv_se": "Groningen",
      "tr_tr": "Groningen",
      "uk_uk": "Гронінген",
      "vi_vn": "Groningen",
      "zh_cn": "格罗宁根",
      "zh_tw": "格羅寧根"
    }
  },
  {
    "Name": "Luxembourg",
    "Country": "luxembourg",
    "X": -16384.34,
    "Y": 3937.84766,
    "InGameId": "luxembourg",
    "LocalizationToken": "luxembourg_city",
    "CountryId": 12,
    "LocalizedNames": {
      "bg_bg": "Люксембург",
      "ca_es": "Luxemburg",
      "cs_cz": "Lucemburk",
      "da_dk": "Luxembourg",
      "de_de": "Luxemburg",
      "el_gr": "Λουξεμβούργο",
      "en_gb": "Luxembourg",
      "en_us": "Luxembourg",
      "es_es": "Luxemburgo",
      "es_la": "Luxemburgo",
      "et_ee": "Luksemburg",
      "eu_es": "Luxenburgo",
      "fi_fi": "Luxemburg",
      "fr_ca": "Luxembourg",
      "fr_fr": "Luxembourg",
      "gl_es": "Luxemburgo",
      "hr_hr": "Luksemburg",
      "hu_hu": "Luxembourg",
      "it_it": "Lussemburgo",
      "ja_jp": "ルクセンブルク",
      "ka_ge": "ლუქსემბურგი",
      "ko_kr": "룩셈부르크",
      "lt_lt": "Luksemburgas",
      "lv_lv": "Luxemburga",
      "mk_mk": "Луксембург",
      "nl_nl": "Luxemburg",
      "no_no": "Luxembourg",
      "pl_pl": "Luksemburg",
      "pl_si": "Luksemburg",
      "pt_br": "Luxemburgo",
      "pt_pt": "Luxemburgo",
      "ro_ro": "Luxemburg",
      "ru_ru": "Люксембург",
      "sk_sk": "Luxemburg",
      "sl_sl": "Luksemburg",
      "sr_sp": "Luksemburg",
      "sr_sr": "Луксембург",
      "sv_se": "Luxemburg",
      "tr_tr": "Lüksemburg",
      "uk_uk": "Люксембург",
      "vi_vn": "Luxembourg",
      "zh_cn": "卢森堡",
      "zh_tw": "盧森堡"
    }
  },
  {
    "Name": "Metz",
    "Country": "france",
    "X": -16594.625,
    "Y": 7093.59,
    "InGameId": "metz",
    "LocalizationToken": "metz",
    "CountryId": 4,
    "LocalizedNames": {
      "bg_bg": "Мец",
      "ca_es": "Metz",
      "cs_cz": "Mety",
      "da_dk": "Metz",
      "de_de": "Metz",
      "el_gr": "Μετς",
      "en_gb": "Metz",
      "en_us": "Metz",
      "es_es": "Metz",
      "es_la": "Metz",
      "et_ee": "Metz",
      "eu_es": "Metz",
      "fi_fi": "Metz",
      "fr_ca": "Metz",
      "fr_fr": "Metz",
      "gl_es": "Metz",
      "hr_hr": "Metz",
      "hu_hu": "Metz",
      "it_it": "Metz",
      "ja_jp": "メス",
      "ka_ge": "მეცი",
      "ko_kr": "메스",
      "lt_lt": "Mecas",
      "lv_lv": "Meca",
      "mk_mk": "Мец",
      "nl_nl": "Metz",
      "no_no": "Metz",
      "pl_pl": "Metz",
      "pl_si": "Metz",
      "pt_br": "Metz",
      "pt_pt": "Metz",
      "ro_ro": "Metz",
      "ru_ru": "Мец",
      "sk_sk": "Méty",
      "sl_sl": "Metz",
      "sr_sp": "Mec",
      "sr_sr": "Мец",
      "sv_se": "Metz",
      "tr_tr": "Metz",
      "uk_uk": "Мец",
      "vi_vn": "Metz",
      "zh_cn": "梅斯",
      "zh_tw": "梅斯"
    }
  },
  {
    "Name": "Genève",
    "Country": "switzerland",
    "X": -18549.2578,
    "Y": 23246.8945,
    "InGameId": "geneve",
    "LocalizationToken": "geneve",
    "CountryId": 9,
    "LocalizedNames": {
      "bg_bg": "Женева",
      "ca_es": "Ginebra",
      "cs_cz": "Ženeva",
      "da_dk": "Genève",
      "de_de": "Genf",
      "el_gr": "Γενεύη",
      "en_gb": "Geneva",
      "en_us": "Geneva",
      "es_es": "Ginebra",
      "es_la": "Ginebra",
      "et_ee": "Genf",
      "eu_es": "Geneva",
      "fi_fi": "Geneve",
      "fr_ca": "Genève",
      "fr_fr": "Genève",
      "gl_es": "Xenebra",
      "hr_hr": "Ženeva",
      "hu_hu": "Genf",
      "it_it": "Ginevra",
      "ja_jp": "ジュネーヴ",
      "ka_ge": "ჟენევა",
      "ko_kr": "제네바",
      "lt_lt": "Ženeva",
      "lv_lv": "Ženēva",
      "mk_mk": "Женева",
      "nl_nl": "Genève",
      "no_no": "Genève",
      "pl_pl": "Genewa",
      "pl_si": "Genewa",
      "pt_br": "Genebra",
      "pt_pt": "Genebra",
      "ro_ro": "Geneva",
      "ru_ru": "Женева",
      "sk_sk": "Ženeva",
      "sl_sl": "Ženeva",
      "sr_sp": "Ženeva",
      "sr_sr": "Женева",
      "sv_se": "Genève",
      "tr_tr": "Cenevre",
      "uk_uk": "Женева",
      "vi_vn": "Geneva",
      "zh_cn": "日内瓦",
      "zh_tw": "日內瓦"
    }
  },
  {
    "Name": "Liège",
    "Country": "belgium",
    "X": -17581.6484,
    "Y": -1501.37891,
    "InGameId": "liege",
    "LocalizationToken": "liege",
    "CountryId": 2,
    "LocalizedNames": {
      "bg_bg": "Лиеж",
      "ca_es": "Lieja",
      "cs_cz": "Lutych",
      "da_dk": "Liège",
      "de_de": "Lüttich",
      "el_gr": "Λιέγη",
      "en_gb": "Liège",
      "en_us": "Liège",
      "es_es": "Lieja",
      "es_la": "Lieja",
      "et_ee": "Liège",
      "eu_es": "Lieja",
      "fi_fi": "Liège",
      "fr_ca": "Liège",
      "fr_fr": "Liège",
      "gl_es": "Liexa",
      "hr_hr": "Liège",
      "hu_hu": "Liège",
      "it_it": "Liegi",
      "ja_jp": "リエージュ",
      "ka_ge": "ლიეჟი",
      "ko_kr": "리에주",
      "lt_lt": "Lježas",
      "lv_lv": "Ljēža",
      "mk_mk": "Лиеж",
      "nl_nl": "Luik",
      "no_no": "Liège",
      "pl_pl": "Liège",
      "pl_si": "Liège",
      "pt_br": "Liège",
      "pt_pt": "Liège",
      "ro_ro": "Liège",
      "ru_ru": "Льеж",
      "sk_sk": "Liège",
      "sl_sl": "Liège",
      "sr_sp": "Lijež",
      "sr_sr": "Лијеж",
      "sv_se": "Liège",
      "tr_tr": "Liège",
      "uk_uk": "Льєж",
      "vi_vn": "Liège",
      "zh_cn": "列日",
      "zh_tw": "列日"
    }
  },
  {
    "Name": "Amsterdam",
    "Country": "netherlands",
    "X": -19062.1328,
    "Y": -12231.3359,
    "InGameId": "amsterdam",
    "LocalizationToken": "amsterdam",
    "CountryId": 7,
    "LocalizedNames": {
      "bg_bg": "Амстердам",
      "ca_es": "Amsterdam",
      "cs_cz": "Amsterdam",
      "da_dk": "Amsterdam",
      "de_de": "Amsterdam",
      "el_gr": "Άμστερνταμ",
      "en_gb": "Amsterdam",
      "en_us": "Amsterdam",
      "es_es": "Ámsterdam",
      "es_la": "Ámsterdam",
      "et_ee": "Amsterdam",
      "eu_es": "Amsterdam",
      "fi_fi": "Amsterdam",
      "fr_ca": "Amsterdam",
      "fr_fr": "Amsterdam",
      "gl_es": "Ámsterdam",
      "hr_hr": "Amsterdam",
      "hu_hu": "Amszterdam",
      "it_it": "Amsterdam",
      "ja_jp": "アムステルダム",
      "ka_ge": "ამსტერდამი",
      "ko_kr": "암스테르담",
      "lt_lt": "Amsterdamas",
      "lv_lv": "Amsterdama",
      "mk_mk": "Амстердам",
      "nl_nl": "Amsterdam",
      "no_no": "Amsterdam",
      "pl_pl": "Amsterdam",
      "pl_si": "Amsterdam",
      "pt_br": "Amsterdã",
      "pt_pt": "Amesterdão",
      "ro_ro": "Amsterdam",
      "ru_ru": "Амстердам",
      "sk_sk": "Amsterdam",
      "sl_sl": "Amsterdam",
      "sr_sp": "Amsterdam",
      "sr_sr": "Амстердам",
      "sv_se": "Amsterdam",
      "tr_tr": "Amsterdam",
      "uk_uk": "Амстердам",
      "vi_vn": "Amsterdam",
      "zh_cn": "阿姆斯特丹",
      "zh_tw": "阿姆斯特丹"
    }
  },
  {
    "Name": "Dijon",
    "Country": "france",
    "X": -22820.1523,
    "Y": 16285.375,
    "InGameId": "dijon",
    "LocalizationToken": "dijon",
    "CountryId": 4,
    "LocalizedNames": {
      "bg_bg": "Дижон",
      "ca_es": "Dijon",
      "cs_cz": "Dijon",
      "da_dk": "Dijon",
      "de_de": "Dijon",
      "el_gr": "Ντιζόν",
      "en_gb": "Dijon",
      "en_us": "Dijon",
      "es_es": "Dijón",
      "es_la": "Dijón",
      "et_ee": "Dijon",
      "eu_es": "Dijon",
      "fi_fi": "Dijon",
      "fr_ca": "Dijon",
      "fr_fr": "Dijon",
      "gl_es": "Dijon",
      "hr_hr": "Dijon",
      "hu_hu": "Dijon",
      "it_it": "Dijon",
      "ja_jp": "ディジョン",
      "ka_ge": "დიჟონი",
      "ko_kr": "디종",
      "lt_lt": "Dižonas",
      "lv_lv": "Dižona",
      "mk_mk": "Дижон",
      "nl_nl": "Dijon",
      "no_no": "Dijon",
      "pl_pl": "Dijon",
      "pl_si": "Dijon",
      "pt_br": "Dijon",
      "pt_pt": "Dijon",
      "ro_ro": "Dijon",
      "ru_ru": "Дижон",
      "sk_sk": "Dijon",
      "sl_sl": "Dijon",
      "sr_sp": "Dižon",
      "sr_sr": "Дижон",
      "sv_se": "Dijon",
      "tr_tr": "Dijon",
      "uk_uk": "Діжон",
      "vi_vn": "Dijon",
      "zh_cn": "第戎",
      "zh_tw": "第戎"
    }
  },
  {
    "Name": "Lyon",
    "Country": "france",
    "X": -24477.9531,
    "Y": 24957.9023,
    "InGameId": "lyon",
    "LocalizationToken": "lyon",
    "CountryId": 4,
    "LocalizedNames": {
      "bg_bg": "Лион",
      "ca_es": "Lió",
      "cs_cz": "Lyon",
      "da_dk": "Lyon",
      "de_de": "Lyon",
      "el_gr": "Λυών",
      "en_gb": "Lyon",
      "en_us": "Lyon",
      "es_es": "Lyon",
      "es_la": "Lyon",
      "et_ee": "Lyon",
      "eu_es": "Lyon",
      "fi_fi": "Lyon",
      "fr_ca": "Lyon",
      "fr_fr": "Lyon",
      "gl_es": "Lión",
      "hr_hr": "Lyon",
      "hu_hu": "Lyon",
      "it_it": "Lione",
      "ja_jp": "リヨン",
      "ka_ge": "ლიონი",
      "ko_kr": "리옹",
      "lt_lt": "Lionas",
      "lv_lv": "Liona",
      "mk_mk": "Лион",
      "nl_nl": "Lyon",
      "no_no": "Lyon",
      "pl_pl": "Lyon",
      "pl_si": "Lyon",
      "pt_br": "Lyon",
      "pt_pt": "Lyon",
      "ro_ro": "Lyon",
      "ru_ru": "Лион",
      "sk_sk": "Lyon",
      "sl_sl": "Lyon",
      "sr_sp": "Lion",
      "sr_sr": "Лион",
      "sv_se": "Lyon",
      "tr_tr": "Lyon",
      "uk_uk": "Ліон",
      "vi_vn": "Lyon",
      "zh_cn": "里昂",
      "zh_tw": "里昂"
    }
  },
  {
    "Name": "Brussel",
    "Country": "belgium",
    "X": -22026.1719,
    "Y": -3705.15625,
    "InGameId": "brussel",
    "LocalizationToken": "brussel",
    "CountryId": 2,
    "LocalizedNames": {
      "bg_bg": "Брюксел",
      "ca_es": "Brussel·les",
      "cs_cz": "Brusel",
      "da_dk": "Bruxelles",
      "de_de": "Brüssel",
      "el_gr": "Βρυξέλλες",
      "en_gb": "Brussels",
      "en_us": "Brussels",
      "es_es": "Bruselas",
      "es_la": "Bruselas",
      "et_ee": "Brüssel",
      "eu_es": "Brusela",
      "fi_fi": "Bryssel",
      "fr_ca": "Bruxelles",
      "fr_fr": "Bruxelles",
      "gl_es": "Bruxelas",
      "hr_hr": "Bruxelles",
      "hu_hu": "Brüsszel",
      "it_it": "Bruxelles",
      "ja_jp": "ブリュッセル",
      "ka_ge": "ბრიუსელი",
      "ko_kr": "브뤼셀",
      "lt_lt": "Bruselis",
      "lv_lv": "Brisele",
      "mk_mk": "Брисел",
      "nl_nl": "Brussel",
      "no_no": "Brussel",
      "pl_pl": "Bruksela",
      "pl_si": "Bruksela",
      "pt_br": "Bruxelas",
      "pt_pt": "Bruxelas",
      "ro_ro": "Bruxelles",
      "ru_ru": "Брюссель",
      "sk_sk": "Brusel",
      "sl_sl": "Bruselj",
      "sr_sp": "Brisel",
      "sr_sr": "Брисел",
      "sv_se": "Bryssel",
      "tr_tr": "Brüksel",
      "uk_uk": "Брюссель",
      "vi_vn": "Brussels",
      "zh_cn": "布鲁塞尔",
      "zh_tw": "布魯塞爾"
    }
  },
  {
    "Name": "Rotterdam",
    "Country": "netherlands",
    "X": -20456.0781,
    "Y": -9455.93,
    "InGameId": "rotterdam",
    "LocalizationToken": "rotterdam",
    "CountryId": 7,
    "LocalizedNames": {
      "bg_bg": "Ротердам",
      "ca_es": "Rotterdam",
      "cs_cz": "Rotterdam",
      "da_dk": "Rotterdam",
      "de_de": "Rotterdam",
      "el_gr": "Ρότερνταμ",
      "en_gb": "Rotterdam",
      "en_us": "Rotterdam",
      "es_es": "Róterdam",
      "es_la": "Róterdam",
      "et_ee": "Rotterdam",
      "eu_es": "Rotterdam",
      "fi_fi": "Rotterdam",
      "fr_ca": "Rotterdam",
      "fr_fr": "Rotterdam",
      "gl_es": "Rotterdam",
      "hr_hr": "Rotterdam",
      "hu_hu": "Rotterdam",
      "it_it": "Rotterdam",
      "ja_jp": "ロッテルダム",
      "ka_ge": "როტერდამი",
      "ko_kr": "로테르담",
      "lt_lt": "Roterdamas",
      "lv_lv": "Roterdama",
      "mk_mk": "Ротердам",
      "nl_nl": "Rotterdam",
      "no_no": "Rotterdam",
      "pl_pl": "Rotterdam",
      "pl_si": "Rotterdam",
      "pt_br": "Roterdã",
      "pt_pt": "Roterdão",
      "ro_ro": "Rotterdam",
      "ru_ru": "Роттердам",
      "sk_sk": "Rotterdam",
      "sl_sl": "Rotterdam",
      "sr_sp": "Roterdam",
      "sr_sr": "Ротердам",
      "sv_se": "Rotterdam",
      "tr_tr": "Rotterdam",
      "uk_uk": "Роттердам",
      "vi_vn": "Rotterdam",
      "zh_cn": "鹿特丹",
      "zh_tw": "鹿特丹"
    }
  },
  {
    "Name": "Reims",
    "Country": "france",
    "X": -24104.89,
    "Y": 5610.01563,
    "InGameId": "reims",
    "LocalizationToken": "reims",
    "CountryId": 4,
    "LocalizedNames": {
      "bg_bg": "Реймс",
      "ca_es": "Reims",
      "cs_cz": "Remeš",
      "da_dk": "Reims",
      "de_de": "Reims",
      "el_gr": "Ρενς",
      "en_gb": "Reims",
      "en_us": "Reims",
      "es_es": "Reims",
      "es_la": "Reims",
      "et_ee": "Reims",
      "eu_es": "Reims",
      "fi_fi": "Reims",
      "fr_ca": "Reims",
      "fr_fr": "Reims",
      "gl_es": "Reims",
      "hr_hr": "Reims",
      "hu_hu": "Reims",
      "it_it": "Reims",
      "ja_jp": "ランス",
      "ka_ge": "რეიმსი",
      "ko_kr": "랭스",
      "lt_lt": "Renas",
      "lv_lv": "Reimsa",
      "mk_mk": "Ремс",
      "nl_nl": "Reims",
      "no_no": "Reims",
      "pl_pl": "Reims",
      "pl_si": "Reims",
      "pt_br": "Reims",
      "pt_pt": "Reims",
      "ro_ro": "Reims",
      "ru_ru": "Реймс",
      "sk_sk": "Remeš",
      "sl_sl": "Reims",
      "sr_sp": "Rems",
      "sr_sr": "Ремс",
      "sv_se": "Reims",
      "tr_tr": "Reims",
      "uk_uk": "Реймс",
      "vi_vn": "Reims",
      "zh_cn": "兰斯",
      "zh_tw": "漢斯"
    }
  },
  {
    "Name": "Lille",
    "Country": "france",
    "X": -26839.5156,
    "Y": -2930.40625,
    "InGameId": "lille",
    "LocalizationToken": "lille",
    "CountryId": 4,
    "LocalizedNames": {
      "bg_bg": "Лил",
      "ca_es": "Lilla",
      "cs_cz": "Lille",
      "da_dk": "Lille",
      "de_de": "Lille",
      "el_gr": "Λιλ",
      "en_gb": "Lille",
      "en_us": "Lille",
      "es_es": "Lille",
      "es_la": "Lille",
      "et_ee": "Lille",
      "eu_es": "Lille",
      "fi_fi": "Lille",
      "fr_ca": "Lille",
      "fr_fr": "Lille",
      "gl_es": "Lille",
      "hr_hr": "Lille",
      "hu_hu": "Lille",
      "it_it": "Lilla",
      "ja_jp": "リール",
      "ka_ge": "ლილი",
      "ko_kr": "릴",
      "lt_lt": "Lilis",
      "lv_lv": "Lille",
      "mk_mk": "Лил",
      "nl_nl": "Rijsel",
      "no_no": "Lille",
      "pl_pl": "Lille",
      "pl_si": "Lille",
      "pt_br": "Lille",
      "pt_pt": "Lille",
      "ro_ro": "Lille",
      "ru_ru": "Лилль",
      "sk_sk": "Lille",
      "sl_sl": "Lille",
      "sr_sp": "Lil",
      "sr_sr": "Лил",
      "sv_se": "Lille",
      "tr_tr": "Lille",
      "uk_uk": "Лілль",
      "vi_vn": "Lille",
      "zh_cn": "里尔",
      "zh_tw": "里耳"
    }
  },
  {
    "Name": "Paris",
    "Country": "france",
    "X": -31427.4727,
    "Y": 6366.074,
    "InGameId": "paris",
    "LocalizationToken": "paris",
    "CountryId": 4,
    "LocalizedNames": {
      "bg_bg": "Париж",
      "ca_es": "París",
      "cs_cz": "Paříž",
      "da_dk": "Paris",
      "de_de": "Paris",
      "el_gr": "Παρίσι",
      "en_gb": "Paris",
      "en_us": "Paris",
      "es_es": "París",
      "es_la": "París",
      "et_ee": "Pariis",
      "eu_es": "Paris",
      "fi_fi": "Pariisi",
      "fr_ca": "Paris",
      "fr_fr": "Paris",
      "gl_es": "París",
      "hr_hr": "Pariz",
      "hu_hu": "Párizs",
      "it_it": "Parigi",
      "ja_jp": "パリ",
      "ka_ge": "პარიზი",
      "ko_kr": "파리",
      "lt_lt": "Paryžius",
      "lv_lv": "Parīze",
      "mk_mk": "Париз",
      "nl_nl": "Parijs",
      "no_no": "Paris",
      "pl_pl": "Paryż",
      "pl_si": "Paryż",
      "pt_br": "Paris",
      "pt_pt": "Paris",
      "ro_ro": "Paris",
      "ru_ru": "Париж",
      "sk_sk": "Paríž",
      "sl_sl": "Pariz",
      "sr_sp": "Pariz",
      "sr_sr": "Париз",
      "sv_se": "Paris",
      "tr_tr": "Paris",
      "uk_uk": "Париж",
      "vi_vn": "Paris",
      "zh_cn": "巴黎",
      "zh_tw": "巴黎"
    }
  },
  {
    "Name": "Calais",
    "Country": "france",
    "X": -31140.5273,
    "Y": -5505.76563,
    "InGameId": "calais",
    "LocalizationToken": "calais",
    "CountryId": 4,
    "LocalizedNames": {
      "bg_bg": "Кале",
      "ca_es": "Calais",
      "cs_cz": "Calais",
      "da_dk": "Calais",
      "de_de": "Calais",
      "el_gr": "Καλαί",
      "en_gb": "Calais",
      "en_us": "Calais",
      "es_es": "Calais",
      "es_la": "Calais",
      "et_ee": "Calais",
      "eu_es": "Calais",
      "fi_fi": "Calais",
      "fr_ca": "Calais",
      "fr_fr": "Calais",
      "gl_es": "Calais",
      "hr_hr": "Calais",
      "hu_hu": "Calais",
      "it_it": "Calais",
      "ja_jp": "カレー",
      "ka_ge": "კალე",
      "ko_kr": "칼레",
      "lt_lt": "Kalė",
      "lv_lv": "Kalē",
      "mk_mk": "Кале",
      "nl_nl": "Calais",
      "no_no": "Calais",
      "pl_pl": "Calais",
      "pl_si": "Calais",
      "pt_br": "Calais",
      "pt_pt": "Calais",
      "ro_ro": "Calais",
      "ru_ru": "Кале",
      "sk_sk": "Calais",
      "sl_sl": "Calais",
      "sr_sp": "Kale",
      "sr_sr": "Кале",
      "sv_se": "Calais",
      "tr_tr": "Calais",
      "uk_uk": "Кале",
      "vi_vn": "Calais",
      "zh_cn": "加来",
      "zh_tw": "加萊"
    }
  },
  {
    "Name": "Felixstowe",
    "Country": "uk",
    "X": -31964.6836,
    "Y": -14857.3438,
    "InGameId": "felixstowe",
    "LocalizationToken": "felixstowe",
    "CountryId": 10,
    "LocalizedNames": {
      "bg_bg": "Феликстоу",
      "ca_es": "Felixstowe",
      "cs_cz": "Felixstowe",
      "da_dk": "Felixstowe",
      "de_de": "Felixstowe",
      "el_gr": "Φέλιξστοου",
      "en_gb": "Felixstowe",
      "en_us": "Felixstowe",
      "es_es": "Felixstowe",
      "es_la": "Felixstowe",
      "et_ee": "Felixstowe",
      "eu_es": "Felixstowe",
      "fi_fi": "Felixstowe",
      "fr_ca": "Felixstowe",
      "fr_fr": "Felixstowe",
      "gl_es": "Felixstowe",
      "hr_hr": "Felixstowe",
      "hu_hu": "Felixstowe",
      "it_it": "Felixstowe",
      "ja_jp": "フェリックストー",
      "ka_ge": "ფელიქსტოუ",
      "ko_kr": "펠릭스토우",
      "lt_lt": "Filikstou",
      "lv_lv": "Fīlikstova",
      "mk_mk": "Филикстоу",
      "nl_nl": "Felixstowe",
      "no_no": "Felixstowe",
      "pl_pl": "Felixstowe",
      "pl_si": "Felixstowe",
      "pt_br": "Felixstowe",
      "pt_pt": "Felixstowe",
      "ro_ro": "Felixstowe",
      "ru_ru": "Филикстоу",
      "sk_sk": "Felixstowe",
      "sl_sl": "Felixstowe",
      "sr_sp": "Felikstou",
      "sr_sr": "Феликстоу",
      "sv_se": "Felixstowe",
      "tr_tr": "Felixstowe",
      "uk_uk": "Філікстоу",
      "vi_vn": "Felixstowe",
      "zh_cn": "费利克斯托港",
      "zh_tw": "菲力斯杜"
    }
  },
  {
    "Name": "Dover",
    "Country": "uk",
    "X": -33772.0742,
    "Y": -8554.48,
    "InGameId": "dover",
    "LocalizationToken": "dover",
    "CountryId": 10,
    "LocalizedNames": {
      "bg_bg": "Дувър",
      "ca_es": "Dover",
      "cs_cz": "Dover",
      "da_dk": "Dover",
      "de_de": "Dover",
      "el_gr": "Ντόβερ",
      "en_gb": "Dover",
      "en_us": "Dover",
      "es_es": "Dover",
      "es_la": "Dover",
      "et_ee": "Dover",
      "eu_es": "Dover",
      "fi_fi": "Dover",
      "fr_ca": "Douvres",
      "fr_fr": "Douvres",
      "gl_es": "Dover",
      "hr_hr": "Dover",
      "hu_hu": "Dover",
      "it_it": "Dover",
      "ja_jp": "ドーバー",
      "ka_ge": "დუვრი",
      "ko_kr": "도버",
      "lt_lt": "Doveris",
      "lv_lv": "Duvra",
      "mk_mk": "Довер",
      "nl_nl": "Dover",
      "no_no": "Dover",
      "pl_pl": "Dover",
      "pl_si": "Dover",
      "pt_br": "Dover",
      "pt_pt": "Dover",
      "ro_ro": "Dover",
      "ru_ru": "Дувр",
      "sk_sk": "Dover",
      "sl_sl": "Dover",
      "sr_sp": "Dover",
      "sr_sr": "Довер",
      "sv_se": "Dover",
      "tr_tr": "Dover",
      "uk_uk": "Дувр",
      "vi_vn": "Dover",
      "zh_cn": "多佛",
      "zh_tw": "多弗"
    }
  },
  {
    "Name": "London",
    "Country": "uk",
    "X": -40196.9922,
    "Y": -12259.34,
    "InGameId": "london",
    "LocalizationToken": "london",
    "CountryId": 10,
    "LocalizedNames": {
      "bg_bg": "Лондон",
      "ca_es": "Londres",
      "cs_cz": "Londýn",
      "da_dk": "London",
      "de_de": "London",
      "el_gr": "Λονδίνο",
      "en_gb": "London",
      "en_us": "London",
      "es_es": "Londres",
      "es_la": "Londres",
      "et_ee": "London",
      "eu_es": "Londres",
      "fi_fi": "Lontoo",
      "fr_ca": "Londres",
      "fr_fr": "Londres",
      "gl_es": "Londres",
      "hr_hr": "London",
      "hu_hu": "London",
      "it_it": "Londra",
      "ja_jp": "ロンドン",
      "ka_ge": "ლონდონი",
      "ko_kr": "런던",
      "lt_lt": "Londonas",
      "lv_lv": "Londona",
      "mk_mk": "Лондон",
      "nl_nl": "Londen",
      "no_no": "London",
      "pl_pl": "Londyn",
      "pl_si": "Londyn",
      "pt_br": "Londres",
      "pt_pt": "Londres",
      "ro_ro": "Londra",
      "ru_ru": "Лондон",
      "sk_sk": "Londýn",
      "sl_sl": "London",
      "sr_sp": "London",
      "sr_sr": "Лондон",
      "sv_se": "London",
      "tr_tr": "Londra",
      "uk_uk": "Лондон",
      "vi_vn": "London",
      "zh_cn": "伦敦",
      "zh_tw": "倫敦"
    }
  },
  {
    "Name": "Cambridge",
    "Country": "uk",
    "X": -37322.8125,
    "Y": -16539.5234,
    "InGameId": "cambridge",
    "LocalizationToken": "cambridge",
    "CountryId": 10,
    "LocalizedNames": {
      "bg_bg": "Кембридж",
      "ca_es": "Cambridge",
      "cs_cz": "Cambridge",
      "da_dk": "Cambridge",
      "de_de": "Cambridge",
      "el_gr": "Κέιμπριτζ",
      "en_gb": "Cambridge",
      "en_us": "Cambridge",
      "es_es": "Cambridge",
      "es_la": "Cambridge",
      "et_ee": "Cambridge",
      "eu_es": "Cambridge",
      "fi_fi": "Cambridge",
      "fr_ca": "Cambridge",
      "fr_fr": "Cambridge",
      "gl_es": "Cambridge",
      "hr_hr": "Cambridge",
      "hu_hu": "Cambridge",
      "it_it": "Cambridge",
      "ja_jp": "ケンブリッジ",
      "ka_ge": "კემბრიჯი",
      "ko_kr": "케임브리지",
      "lt_lt": "Kembridžas",
      "lv_lv": "Kembridža",
      "mk_mk": "Кембриџ",
      "nl_nl": "Cambridge",
      "no_no": "Cambridge",
      "pl_pl": "Cambridge",
      "pl_si": "Cambridge",
      "pt_br": "Cambridge",
      "pt_pt": "Cambridge",
      "ro_ro": "Cambridge",
      "ru_ru": "Кембридж",
      "sk_sk": "Cambridge",
      "sl_sl": "Cambridge",
      "sr_sp": "Kembridž",
      "sr_sr": "Кембриџ",
      "sv_se": "Cambridge",
      "tr_tr": "Cambridge",
      "uk_uk": "Кембридж",
      "vi_vn": "Cambridge",
      "zh_cn": "剑桥",
      "zh_tw": "劍橋"
    }
  },
  {
    "Name": "Grimsby",
    "Country": "uk",
    "X": -36868.0234,
    "Y": -27345.3086,
    "InGameId": "grimsby",
    "LocalizationToken": "grimsby",
    "CountryId": 10,
    "LocalizedNames": {
      "bg_bg": "Гримзби",
      "ca_es": "Grimsby",
      "cs_cz": "Grimsby",
      "da_dk": "Grimsby",
      "de_de": "Grimsby",
      "el_gr": "Γκρίμσμπι",
      "en_gb": "Grimsby",
      "en_us": "Grimsby",
      "es_es": "Grimsby",
      "es_la": "Grimsby",
      "et_ee": "Grimsby",
      "eu_es": "Grimsby",
      "fi_fi": "Grimsby",
      "fr_ca": "Grimsby",
      "fr_fr": "Grimsby",
      "gl_es": "Grimsby",
      "hr_hr": "Grimsby",
      "hu_hu": "Grimsby",
      "it_it": "Grimsby",
      "ja_jp": "グリムズビー",
      "ka_ge": "გრიმსბი",
      "ko_kr": "그림즈비",
      "lt_lt": "Grimsbis",
      "lv_lv": "Grimsbi",
      "mk_mk": "Гримсби",
      "nl_nl": "Grimsby",
      "no_no": "Grimsby",
      "pl_pl": "Grimsby",
      "pl_si": "Grimsby",
      "pt_br": "Grimsby",
      "pt_pt": "Grimsby",
      "ro_ro": "Grimsby",
      "ru_ru": "Гримсби",
      "sk_sk": "Grimsby",
      "sl_sl": "Grimsby",
      "sr_sp": "Grimsbi",
      "sr_sr": "Гримсби",
      "sv_se": "Grimsby",
      "tr_tr": "Grimsby",
      "uk_uk": "Грімcбі",
      "vi_vn": "Grimsby",
      "zh_cn": "格里姆斯比",
      "zh_tw": "格林斯比"
    }
  },
  {
    "Name": "Newcastle-upon-Tyne",
    "Country": "uk",
    "X": -40128.168,
    "Y": -39307.8438,
    "InGameId": "newcastle",
    "LocalizationToken": "newcastle_upon_tyne",
    "CountryId": 10,
    "LocalizedNames": {
      "bg_bg": "Нюкасъл ъпон Тайн",
      "ca_es": "Newcastle upon Tyne",
      "cs_cz": "Newcastle upon Tyne",
      "da_dk": "Newcastle upon Tyne",
      "de_de": "Newcastle upon Tyne",
      "el_gr": "Νιούκασλ",
      "en_gb": "Newcastle upon Tyne",
      "en_us": "Newcastle upon Tyne",
      "es_es": "Newcastle upon Tyne",
      "es_la": "Newcastle upon Tyne",
      "et_ee": "Newcastle upon Tyne",
      "eu_es": "Newcastle upon Tyne",
      "fi_fi": "Newcastle upon Tyne",
      "fr_ca": "Newcastle-upon-Tyne",
      "fr_fr": "Newcastle-upon-Tyne",
      "gl_es": "Newcastle upon Tyne",
      "hr_hr": "Newcastle upon Tyne",
      "hu_hu": "Newcastle upon Tyne",
      "it_it": "Newcastle upon Tyne",
      "ja_jp": "ニューカッスル・アポン・タイン",
      "ka_ge": "ნიუკასლ-აპონ-ტაინი",
      "ko_kr": "뉴캐슬 어폰 타인",
      "lt_lt": "Niukaslas prie Taino",
      "lv_lv": "Ņūkasla pie Tainas",
      "mk_mk": "Њукасл на Тајн",
      "nl_nl": "Newcastle upon Tyne",
      "no_no": "Newcastle upon Tyne",
      "pl_pl": "Newcastle upon Tyne",
      "pl_si": "Newcastle upon Tyne",
      "pt_br": "Newcastle upon Tyne",
      "pt_pt": "Newcastle upon Tyne",
      "ro_ro": "Newcastle",
      "ru_ru": "Ньюкасл-апон-Тайн",
      "sk_sk": "Newcastle upon Tyne",
      "sl_sl": "Newcastle upon Tyne",
      "sr_sp": "Njukasl na Tajnu",
      "sr_sr": "Њукасл на Тајну",
      "sv_se": "Newcastle upon Tyne",
      "tr_tr": "Newcastle",
      "uk_uk": "Ньюкасл-апон-Тайн",
      "vi_vn": "Newcastle upon Tyne",
      "zh_cn": "泰恩河畔纽卡斯尔",
      "zh_tw": "泰恩河畔新堡"
    }
  },
  {
    "Name": "Aberdeen",
    "Country": "uk",
    "X": -39596.793,
    "Y": -56040.47,
    "InGameId": "aberdeen",
    "LocalizationToken": "aberdeen",
    "CountryId": 10,
    "LocalizedNames": {
      "bg_bg": "Абърдийн",
      "ca_es": "Aberdeen",
      "cs_cz": "Aberdeen",
      "da_dk": "Aberdeen",
      "de_de": "Aberdeen",
      "el_gr": "Αμπερντήν",
      "en_gb": "Aberdeen",
      "en_us": "Aberdeen",
      "es_es": "Aberdeen",
      "es_la": "Aberdeen",
      "et_ee": "Aberdeen",
      "eu_es": "Aberdeen",
      "fi_fi": "Aberdeen",
      "fr_ca": "Aberdeen",
      "fr_fr": "Aberdeen",
      "gl_es": "Aberdeen",
      "hr_hr": "Aberdeen",
      "hu_hu": "Aberdeen",
      "it_it": "Aberdeen",
      "ja_jp": "アバディーン",
      "ka_ge": "აბერდინი",
      "ko_kr": "애버딘",
      "lt_lt": "Aberdenas",
      "lv_lv": "Aberdīna",
      "mk_mk": "Абердин",
      "nl_nl": "Aberdeen",
      "no_no": "Aberdeen",
      "pl_pl": "Aberdeen",
      "pl_si": "Aberdeen",
      "pt_br": "Aberdeen",
      "pt_pt": "Aberdeen",
      "ro_ro": "Aberdeen",
      "ru_ru": "Абердин",
      "sk_sk": "Aberdeen",
      "sl_sl": "Aberdeen",
      "sr_sp": "Aberdin",
      "sr_sr": "Абердин",
      "sv_se": "Aberdeen",
      "tr_tr": "Aberdeen",
      "uk_uk": "Абердин",
      "vi_vn": "Aberdeen",
      "zh_cn": "阿伯丁",
      "zh_tw": "亞伯丁"
    }
  },
  {
    "Name": "Sheffield",
    "Country": "uk",
    "X": -43088.88,
    "Y": -27107.6914,
    "InGameId": "sheffield",
    "LocalizationToken": "sheffield",
    "CountryId": 10,
    "LocalizedNames": {
      "bg_bg": "Шефилд",
      "ca_es": "Sheffield",
      "cs_cz": "Sheffield",
      "da_dk": "Sheffield",
      "de_de": "Sheffield",
      "el_gr": "Σέφιλντ",
      "en_gb": "Sheffield",
      "en_us": "Sheffield",
      "es_es": "Sheffield",
      "es_la": "Sheffield",
      "et_ee": "Sheffield",
      "eu_es": "Sheffield",
      "fi_fi": "Sheffield",
      "fr_ca": "Sheffield",
      "fr_fr": "Sheffield",
      "gl_es": "Sheffield",
      "hr_hr": "Sheffield",
      "hu_hu": "Sheffield",
      "it_it": "Sheffield",
      "ja_jp": "シェフィールド",
      "ka_ge": "შეფილდი",
      "ko_kr": "셰필드",
      "lt_lt": "Šefildas",
      "lv_lv": "Šefilda",
      "mk_mk": "Шефилд",
      "nl_nl": "Sheffield",
      "no_no": "Sheffield",
      "pl_pl": "Sheffield",
      "pl_si": "Sheffield",
      "pt_br": "Sheffield",
      "pt_pt": "Sheffield",
      "ro_ro": "Sheffield",
      "ru_ru": "Шеффилд",
      "sk_sk": "Sheffield",
      "sl_sl": "Sheffield",
      "sr_sp": "Šefild",
      "sr_sr": "Шефилд",
      "sv_se": "Sheffield",
      "tr_tr": "Sheffield",
      "uk_uk": "Шеффілд",
      "vi_vn": "Sheffield",
      "zh_cn": "谢菲尔德",
      "zh_tw": "雪非耳"
    }
  },
  {
    "Name": "Southampton",
    "Country": "uk",
    "X": -46838.8672,
    "Y": -7893.371,
    "InGameId": "southampton",
    "LocalizationToken": "southampton",
    "CountryId": 10,
    "LocalizedNames": {
      "bg_bg": "Саутхамптън",
      "ca_es": "Southampton",
      "cs_cz": "Southampton",
      "da_dk": "Southampton",
      "de_de": "Southampton",
      "el_gr": "Σαουθάμπτον",
      "en_gb": "Southampton",
      "en_us": "Southampton",
      "es_es": "Southampton",
      "es_la": "Southampton",
      "et_ee": "Southampton",
      "eu_es": "Southampton",
      "fi_fi": "Southampton",
      "fr_ca": "Southampton",
      "fr_fr": "Southampton",
      "gl_es": "Southampton",
      "hr_hr": "Southampton",
      "hu_hu": "Southampton",
      "it_it": "Southampton",
      "ja_jp": "サウサンプトン",
      "ka_ge": "საუთჰემპტონი",
      "ko_kr": "사우샘프턴",
      "lt_lt": "Sautamptonas",
      "lv_lv": "Sauthemptona",
      "mk_mk": "Саутемптон",
      "nl_nl": "Southampton",
      "no_no": "Southampton",
      "pl_pl": "Southampton",
      "pl_si": "Southampton",
      "pt_br": "Southampton",
      "pt_pt": "Southampton",
      "ro_ro": "Southampton",
      "ru_ru": "Саутгемптон",
      "sk_sk": "Southampton",
      "sl_sl": "Southampton",
      "sr_sp": "Sauthempton",
      "sr_sr": "Саутхемптон",
      "sv_se": "Southampton",
      "tr_tr": "Southampton",
      "uk_uk": "Саутгемптон",
      "vi_vn": "Southampton",
      "zh_cn": "南安普敦",
      "zh_tw": "南安普敦"
    }
  },
  {
    "Name": "Birmingham",
    "Country": "uk",
    "X": -46451.25,
    "Y": -20943.125,
    "InGameId": "birmingham",
    "LocalizationToken": "birmingham",
    "CountryId": 10,
    "LocalizedNames": {
      "bg_bg": "Бирмингам",
      "ca_es": "Birmingham",
      "cs_cz": "Birmingham",
      "da_dk": "Birmingham",
      "de_de": "Birmingham",
      "el_gr": "Μπέρμιγχαμ",
      "en_gb": "Birmingham",
      "en_us": "Birmingham",
      "es_es": "Birmingham",
      "es_la": "Birmingham",
      "et_ee": "Birmingham",
      "eu_es": "Birmingham",
      "fi_fi": "Birmingham",
      "fr_ca": "Birmingham",
      "fr_fr": "Birmingham",
      "gl_es": "Birmingham",
      "hr_hr": "Birmingham",
      "hu_hu": "Birmingham",
      "it_it": "Birmingham",
      "ja_jp": "バーミンガム",
      "ka_ge": "ბირმინგემი",
      "ko_kr": "버밍엄",
      "lt_lt": "Birmingemas",
      "lv_lv": "Birmingema",
      "mk_mk": "Бирмингем",
      "nl_nl": "Birmingham",
      "no_no": "Birmingham",
      "pl_pl": "Birmingham",
      "pl_si": "Birmingham",
      "pt_br": "Birmingham",
      "pt_pt": "Birmingham",
      "ro_ro": "Birmingham",
      "ru_ru": "Бирмингем",
      "sk_sk": "Birmingham",
      "sl_sl": "Birmingham",
      "sr_sp": "Birmingem",
      "sr_sr": "Бирмингем",
      "sv_se": "Birmingham",
      "tr_tr": "Birmingham",
      "uk_uk": "Бірмінгем",
      "vi_vn": "Birmingham",
      "zh_cn": "伯明翰",
      "zh_tw": "伯明罕"
    }
  },
  {
    "Name": "Manchester",
    "Country": "uk",
    "X": -46298.7773,
    "Y": -28585.1445,
    "InGameId": "manchester",
    "LocalizationToken": "manchester",
    "CountryId": 10,
    "LocalizedNames": {
      "bg_bg": "Манчестър",
      "ca_es": "Manchester",
      "cs_cz": "Manchester",
      "da_dk": "Manchester",
      "de_de": "Manchester",
      "el_gr": "Μάντσεστερ",
      "en_gb": "Manchester",
      "en_us": "Manchester",
      "es_es": "Mánchester",
      "es_la": "Mánchester",
      "et_ee": "Manchester",
      "eu_es": "Manchester",
      "fi_fi": "Manchester",
      "fr_ca": "Manchester",
      "fr_fr": "Manchester",
      "gl_es": "Manchester",
      "hr_hr": "Manchester",
      "hu_hu": "Manchester",
      "it_it": "Manchester",
      "ja_jp": "マンチェスター",
      "ka_ge": "მანჩესტერი",
      "ko_kr": "맨체스터",
      "lt_lt": "Mančesteris",
      "lv_lv": "Mančestra",
      "mk_mk": "Манчестер",
      "nl_nl": "Manchester",
      "no_no": "Manchester",
      "pl_pl": "Manchester",
      "pl_si": "Manchester",
      "pt_br": "Manchester",
      "pt_pt": "Manchester",
      "ro_ro": "Manchester",
      "ru_ru": "Манчестер",
      "sk_sk": "Manchester",
      "sl_sl": "Manchester",
      "sr_sp": "Mančester",
      "sr_sr": "Манчестер",
      "sv_se": "Manchester",
      "tr_tr": "Manchester",
      "uk_uk": "Манчестер",
      "vi_vn": "Manchester",
      "zh_cn": "曼彻斯特",
      "zh_tw": "曼徹斯特"
    }
  },
  {
    "Name": "Carlisle",
    "Country": "uk",
    "X": -46439.3,
    "Y": -40093.6,
    "InGameId": "carlisle",
    "LocalizationToken": "carlisle",
    "CountryId": 10,
    "LocalizedNames": {
      "bg_bg": "Карлайл",
      "ca_es": "Carlisle",
      "cs_cz": "Carlisle",
      "da_dk": "Carlisle",
      "de_de": "Carlisle",
      "el_gr": "Καρλάιλ",
      "en_gb": "Carlisle",
      "en_us": "Carlisle",
      "es_es": "Carlisle",
      "es_la": "Carlisle",
      "et_ee": "Carlisle",
      "eu_es": "Carlisle",
      "fi_fi": "Carlisle",
      "fr_ca": "Carlisle",
      "fr_fr": "Carlisle",
      "gl_es": "Carlisle",
      "hr_hr": "Carlisle",
      "hu_hu": "Carlisle",
      "it_it": "Carlisle",
      "ja_jp": "カーライル",
      "ka_ge": "კარლაილი",
      "ko_kr": "칼라일",
      "lt_lt": "Karlailas",
      "lv_lv": "Kārlaila",
      "mk_mk": "Карлајл",
      "nl_nl": "Carlisle",
      "no_no": "Carlisle",
      "pl_pl": "Carlisle",
      "pl_si": "Carlisle",
      "pt_br": "Carlisle",
      "pt_pt": "Carlisle",
      "ro_ro": "Carlisle",
      "ru_ru": "Карлайл",
      "sk_sk": "Carlisle",
      "sl_sl": "Carlisle",
      "sr_sp": "Karlajl",
      "sr_sr": "Карлајл",
      "sv_se": "Carlisle",
      "tr_tr": "Carlisle",
      "uk_uk": "Карлайл",
      "vi_vn": "Carlisle",
      "zh_cn": "卡莱尔",
      "zh_tw": "卡萊爾"
    }
  },
  {
    "Name": "Edinburgh",
    "Country": "uk",
    "X": -45400.8672,
    "Y": -47889.7969,
    "InGameId": "edinburgh",
    "LocalizationToken": "edinburgh",
    "CountryId": 10,
    "LocalizedNames": {
      "bg_bg": "Единбург",
      "ca_es": "Edimburg",
      "cs_cz": "Edinburgh",
      "da_dk": "Edinburgh",
      "de_de": "Edinburgh",
      "el_gr": "Εδιμβούργο",
      "en_gb": "Edinburgh",
      "en_us": "Edinburgh",
      "es_es": "Edimburgo",
      "es_la": "Edimburgo",
      "et_ee": "Edinburgh",
      "eu_es": "Edinburgo",
      "fi_fi": "Edinburgh",
      "fr_ca": "Édimbourg",
      "fr_fr": "Édimbourg",
      "gl_es": "Edimburgo",
      "hr_hr": "Edinburgh",
      "hu_hu": "Edinburgh",
      "it_it": "Edimburgo",
      "ja_jp": "エディンバラ",
      "ka_ge": "ედინბურგი",
      "ko_kr": "에든버러",
      "lt_lt": "Edinburgas",
      "lv_lv": "Edinburga",
      "mk_mk": "Единбург",
      "nl_nl": "Edinburgh",
      "no_no": "Edinburgh",
      "pl_pl": "Edynburg",
      "pl_si": "Edynburg",
      "pt_br": "Edimburgo",
      "pt_pt": "Edimburgo",
      "ro_ro": "Edinburgh",
      "ru_ru": "Эдинбург",
      "sk_sk": "Edinburgh",
      "sl_sl": "Edinburgh",
      "sr_sp": "Edinburg",
      "sr_sr": "Единбург",
      "sv_se": "Edinburgh",
      "tr_tr": "Edinburgh",
      "uk_uk": "Единбург",
      "vi_vn": "Edinburgh",
      "zh_cn": "爱丁堡",
      "zh_tw": "愛丁堡"
    }
  },
  {
    "Name": "Liverpool",
    "Country": "uk",
    "X": -49621.86,
    "Y": -29861.957,
    "InGameId": "liverpool",
    "LocalizationToken": "liverpool",
    "CountryId": 10,
    "LocalizedNames": {
      "bg_bg": "Ливърпул",
      "ca_es": "Liverpool",
      "cs_cz": "Liverpool",
      "da_dk": "Liverpool",
      "de_de": "Liverpool",
      "el_gr": "Λίβερπουλ",
      "en_gb": "Liverpool",
      "en_us": "Liverpool",
      "es_es": "Liverpool",
      "es_la": "Liverpool",
      "et_ee": "Liverpool",
      "eu_es": "Liverpool",
      "fi_fi": "Liverpool",
      "fr_ca": "Liverpool",
      "fr_fr": "Liverpool",
      "gl_es": "Liverpool",
      "hr_hr": "Liverpool",
      "hu_hu": "Liverpool",
      "it_it": "Liverpool",
      "ja_jp": "リヴァプール",
      "ka_ge": "ლივერპული",
      "ko_kr": "리버풀",
      "lt_lt": "Liverpulis",
      "lv_lv": "Liverpūle",
      "mk_mk": "Ливерпул",
      "nl_nl": "Liverpool",
      "no_no": "Liverpool",
      "pl_pl": "Liverpool",
      "pl_si": "Liverpool",
      "pt_br": "Liverpool",
      "pt_pt": "Liverpool",
      "ro_ro": "Liverpool",
      "ru_ru": "Ливерпуль",
      "sk_sk": "Liverpool",
      "sl_sl": "Liverpool",
      "sr_sp": "Liverpul",
      "sr_sr": "Ливерпул",
      "sv_se": "Liverpool",
      "tr_tr": "Liverpool",
      "uk_uk": "Ліверпуль",
      "vi_vn": "Liverpool",
      "zh_cn": "利物浦",
      "zh_tw": "利物浦"
    }
  },
  {
    "Name": "Glasgow",
    "Country": "uk",
    "X": -51134.8242,
    "Y": -49241.7461,
    "InGameId": "glasgow",
    "LocalizationToken": "glasgow",
    "CountryId": 10,
    "LocalizedNames": {
      "bg_bg": "Гласгоу",
      "ca_es": "Glasgow",
      "cs_cz": "Glasgow",
      "da_dk": "Glasgow",
      "de_de": "Glasgow",
      "el_gr": "Γλασκώβη",
      "en_gb": "Glasgow",
      "en_us": "Glasgow",
      "es_es": "Glasgow",
      "es_la": "Glasgow",
      "et_ee": "Glasgow",
      "eu_es": "Glasgow",
      "fi_fi": "Glasgow",
      "fr_ca": "Glasgow",
      "fr_fr": "Glasgow",
      "gl_es": "Glasgow",
      "hr_hr": "Glasgow",
      "hu_hu": "Glasgow",
      "it_it": "Glasgow",
      "ja_jp": "グラスゴー",
      "ka_ge": "გლაზგო",
      "ko_kr": "글래스고",
      "lt_lt": "Glazgas",
      "lv_lv": "Glāzgova",
      "mk_mk": "Глазгов",
      "nl_nl": "Glasgow",
      "no_no": "Glasgow",
      "pl_pl": "Glasgow",
      "pl_si": "Glasgow",
      "pt_br": "Glasgow",
      "pt_pt": "Glasgow",
      "ro_ro": "Glasgow",
      "ru_ru": "Глазго",
      "sk_sk": "Glasgow",
      "sl_sl": "Glasgow",
      "sr_sp": "Glazgov",
      "sr_sr": "Глазгов",
      "sv_se": "Glasgow",
      "tr_tr": "Glasgow",
      "uk_uk": "Глазго",
      "vi_vn": "Glasgow",
      "zh_cn": "格拉斯哥",
      "zh_tw": "格拉斯哥"
    }
  },
  {
    "Name": "Cardiff",
    "Country": "uk",
    "X": -54555.4023,
    "Y": -15118.57,
    "InGameId": "cardiff",
    "LocalizationToken": "cardiff",
    "CountryId": 10,
    "LocalizedNames": {
      "bg_bg": "Кардиф",
      "ca_es": "Cardiff",
      "cs_cz": "Cardiff",
      "da_dk": "Cardiff",
      "de_de": "Cardiff",
      "el_gr": "Κάρντιφ",
      "en_gb": "Cardiff",
      "en_us": "Cardiff",
      "es_es": "Cardiff",
      "es_la": "Cardiff",
      "et_ee": "Cardiff",
      "eu_es": "Cardiff",
      "fi_fi": "Cardiff",
      "fr_ca": "Cardiff",
      "fr_fr": "Cardiff",
      "gl_es": "Cardiff",
      "hr_hr": "Cardiff",
      "hu_hu": "Cardiff",
      "it_it": "Cardiff",
      "ja_jp": "カーディフ",
      "ka_ge": "კარდიფი",
      "ko_kr": "카디프",
      "lt_lt": "Kardifas",
      "lv_lv": "Kārdifa",
      "mk_mk": "Кардиф",
      "nl_nl": "Cardiff",
      "no_no": "Cardiff",
      "pl_pl": "Cardiff",
      "pl_si": "Cardiff",
      "pt_br": "Cardiff",
      "pt_pt": "Cardiff",
      "ro_ro": "Cardiff",
      "ru_ru": "Кардифф",
      "sk_sk": "Cardiff",
      "sl_sl": "Cardiff",
      "sr_sp": "Kardif",
      "sr_sr": "Кардиф",
      "sv_se": "Cardiff",
      "tr_tr": "Cardiff",
      "uk_uk": "Кардіфф",
      "vi_vn": "Cardiff",
      "zh_cn": "卡迪夫",
      "zh_tw": "卡地夫"
    }
  },
  {
    "Name": "Swansea",
    "Country": "uk",
    "X": -57646.13,
    "Y": -17652.07,
    "InGameId": "swansea",
    "LocalizationToken": "swansea",
    "CountryId": 10,
    "LocalizedNames": {
      "bg_bg": "Суонзи",
      "ca_es": "Swansea",
      "cs_cz": "Swansea",
      "da_dk": "Swansea",
      "de_de": "Swansea",
      "el_gr": "Σουόνσι",
      "en_gb": "Swansea",
      "en_us": "Swansea",
      "es_es": "Swansea",
      "es_la": "Swansea",
      "et_ee": "Swansea",
      "eu_es": "Swansea",
      "fi_fi": "Swansea",
      "fr_ca": "Swansea",
      "fr_fr": "Swansea",
      "gl_es": "Swansea",
      "hr_hr": "Swansea",
      "hu_hu": "Swansea",
      "it_it": "Swansea",
      "ja_jp": "スウォンジー",
      "ka_ge": "სუონსი",
      "ko_kr": "스완지",
      "lt_lt": "Svonsis",
      "lv_lv": "Svonzi",
      "mk_mk": "Свонзи",
      "nl_nl": "Swansea",
      "no_no": "Swansea",
      "pl_pl": "Swansea",
      "pl_si": "Swansea",
      "pt_br": "Swansea",
      "pt_pt": "Swansea",
      "ro_ro": "Swansea",
      "ru_ru": "Суонси",
      "sk_sk": "Swansea",
      "sl_sl": "Swansea",
      "sr_sp": "Svonzi",
      "sr_sr": "Свонзи",
      "sv_se": "Swansea",
      "tr_tr": "Swansea",
      "uk_uk": "Суонсі",
      "vi_vn": "Swansea",
      "zh_cn": "斯旺西",
      "zh_tw": "斯萬西"
    }
  },
  {
    "Name": "Plymouth",
    "Country": "uk",
    "X": -60566.5,
    "Y": -8502.445,
    "InGameId": "plymouth",
    "LocalizationToken": "plymouth",
    "CountryId": 10,
    "LocalizedNames": {
      "bg_bg": "Плимът",
      "ca_es": "Plymouth",
      "cs_cz": "Plymouth",
      "da_dk": "Plymouth",
      "de_de": "Plymouth",
      "el_gr": "Πλύμουθ",
      "en_gb": "Plymouth",
      "en_us": "Plymouth",
      "es_es": "Plymouth",
      "es_la": "Plymouth",
      "et_ee": "Plymouth",
      "eu_es": "Plymouth",
      "fi_fi": "Plymouth",
      "fr_ca": "Plymouth",
      "fr_fr": "Plymouth",
      "gl_es": "Plymouth",
      "hr_hr": "Plymouth",
      "hu_hu": "Plymouth",
      "it_it": "Plymouth",
      "ja_jp": "プリマス",
      "ka_ge": "პლიმუთი",
      "ko_kr": "플리머스",
      "lt_lt": "Plimutas",
      "lv_lv": "Plīmute",
      "mk_mk": "Плимут",
      "nl_nl": "Plymouth",
      "no_no": "Plymouth",
      "pl_pl": "Plymouth",
      "pl_si": "Plymouth",
      "pt_br": "Plymouth",
      "pt_pt": "Plymouth",
      "ro_ro": "Plymouth",
      "ru_ru": "Плимут",
      "sk_sk": "Plymouth",
      "sl_sl": "Plymouth",
      "sr_sp": "Plimut",
      "sr_sr": "Плимут",
      "sv_se": "Plymouth",
      "tr_tr": "Plymouth",
      "uk_uk": "Плімут",
      "vi_vn": "Plymouth",
      "zh_cn": "普利茅斯",
      "zh_tw": "普利茅斯"
    }
  },
  {
    "Name": "Timișoara",
    "Country": "romania",
    "X": 40552.2148,
    "Y": 26320.0117,
    "InGameId": "timisoara",
    "LocalizationToken": "timisoara",
    "CountryId": 37,
    "LocalizedNames": {
      "bg_bg": "Тимишоара",
      "ca_es": "Timișoara",
      "cs_cz": "Temešvár",
      "da_dk": "Timișoara",
      "de_de": "Temeswar",
      "el_gr": "Τιμισοάρα",
      "en_gb": "Timișoara",
      "en_us": "Timișoara",
      "es_es": "Timișoara",
      "es_la": "Timișoara",
      "et_ee": "Timișoara",
      "eu_es": "Timișoara",
      "fi_fi": "Timișoara",
      "fr_ca": "Timișoara",
      "fr_fr": "Timișoara",
      "gl_es": "Timișoara",
      "hr_hr": "Temišvar",
      "hu_hu": "Temesvár",
      "it_it": "Timișoara",
      "ja_jp": "ティミショアラ",
      "ka_ge": "ტიმიშოარა",
      "ko_kr": "티미쇼아라",
      "lt_lt": "Timišoara",
      "lv_lv": "Timișoara",
      "mk_mk": "Темишвар",
      "nl_nl": "Timișoara",
      "no_no": "Timișoara",
      "pl_pl": "Timisoara",
      "pl_si": "Timisoara",
      "pt_br": "Timisoara",
      "pt_pt": "Timișoara",
      "ro_ro": "Timişoara",
      "ru_ru": "Тимишоара",
      "sk_sk": "Temešvár",
      "sl_sl": "Timișoara",
      "sr_sp": "Temišvar",
      "sr_sr": "Темишвар",
      "sv_se": "Timișoara",
      "tr_tr": "Tamışvar",
      "uk_uk": "Тімішоара",
      "vi_vn": "Timișoara",
      "zh_cn": "蒂米什瓦拉",
      "zh_tw": "蒂米什瓦拉"
    }
  },
  {
    "Name": "Cluj-Napoca",
    "Country": "romania",
    "X": 49028.207,
    "Y": 17957.05,
    "InGameId": "cluj_napoca",
    "LocalizationToken": "cluj_napoca",
    "CountryId": 37,
    "LocalizedNames": {
      "bg_bg": "Клуж-Напока",
      "ca_es": "Cluj-Napoca",
      "cs_cz": "Kluž",
      "da_dk": "Cluj-Napoca",
      "de_de": "Klausenburg",
      "el_gr": "Κλουζ-Ναπόκα",
      "en_gb": "Cluj-Napoca",
      "en_us": "Cluj-Napoca",
      "es_es": "Cluj-Napoca",
      "es_la": "Cluj-Napoca",
      "et_ee": "Cluj-Napoca",
      "eu_es": "Cluj-Napoca",
      "fi_fi": "Cluj-Napoca",
      "fr_ca": "Cluj-Napoca",
      "fr_fr": "Cluj-Napoca",
      "gl_es": "Cluj-Napoca",
      "hr_hr": "Cluj-Napoca",
      "hu_hu": "Kolozsvár",
      "it_it": "Cluj-Napoca",
      "ja_jp": "クルジュ=ナポカ",
      "ka_ge": "კლუჟ-ნაპოკა",
      "ko_kr": "클루지나포카",
      "lt_lt": "Kluž-Napoka",
      "lv_lv": "Cluj-Napoca",
      "mk_mk": "Клуж-Напока",
      "nl_nl": "Cluj-Napoca",
      "no_no": "Cluj-Napoca",
      "pl_pl": "Kluż-Napoka",
      "pl_si": "Kluż-Napoka",
      "pt_br": "Cluj-Napoca",
      "pt_pt": "Cluj-Napoca",
      "ro_ro": "Cluj-Napoca",
      "ru_ru": "Клуж-Напока",
      "sk_sk": "Kluž",
      "sl_sl": "Cluj-Napoca",
      "sr_sp": "Kluž",
      "sr_sr": "Клуж",
      "sv_se": "Cluj-Napoca",
      "tr_tr": "Kaloşvar",
      "uk_uk": "Клуж-Напока",
      "vi_vn": "Cluj-Napoca",
      "zh_cn": "克卢日-纳波卡",
      "zh_tw": "克盧日-納波卡"
    }
  },
  {
    "Name": "Hunedoara",
    "Country": "romania",
    "X": 48141.7344,
    "Y": 25594.707,
    "InGameId": "hunedoara",
    "LocalizationToken": "hunedoara",
    "CountryId": 37,
    "LocalizedNames": {
      "bg_bg": "Хунедоара",
      "ca_es": "Hunedoara",
      "cs_cz": "Hunedoara",
      "da_dk": "Hunedoara",
      "de_de": "Eisenmarkt",
      "el_gr": "Χουνεντοάρα",
      "en_gb": "Hunedoara",
      "en_us": "Hunedoara",
      "es_es": "Hunedoara",
      "es_la": "Hunedoara",
      "et_ee": "Hunedoara",
      "eu_es": "Hunedoara",
      "fi_fi": "Hunedoara",
      "fr_ca": "Hunedoara",
      "fr_fr": "Hunedoara",
      "gl_es": "Hunedoara",
      "hr_hr": "Hunedoara",
      "hu_hu": "Vajdahunyad",
      "it_it": "Hunedoara",
      "ja_jp": "フネドアラ",
      "ka_ge": "ჰუნედოარა",
      "ko_kr": "후네도아라",
      "lt_lt": "Hunedoara",
      "lv_lv": "Hunedoara",
      "mk_mk": "Хунедоара",
      "nl_nl": "Hunedoara",
      "no_no": "Hunedoara",
      "pl_pl": "Hunedoara",
      "pl_si": "Hunedoara",
      "pt_br": "Hunedoara",
      "pt_pt": "Hunedoara",
      "ro_ro": "Hunedoara",
      "ru_ru": "Хунедоара",
      "sk_sk": "Hunedoara",
      "sl_sl": "Hunedoara",
      "sr_sp": "Hunedoara",
      "sr_sr": "Хунедоара",
      "sv_se": "Hunedoara",
      "tr_tr": "Hunedoara",
      "uk_uk": "Хунедоара",
      "vi_vn": "Hunedoara",
      "zh_cn": "胡内多阿拉",
      "zh_tw": "胡內多阿拉"
    }
  },
  {
    "Name": "Craiova",
    "Country": "romania",
    "X": 50918.418,
    "Y": 32508.39,
    "InGameId": "craiova",
    "LocalizationToken": "craiova",
    "CountryId": 37,
    "LocalizedNames": {
      "bg_bg": "Крайова",
      "ca_es": "Craiova",
      "cs_cz": "Krajova",
      "da_dk": "Craiova",
      "de_de": "Krajowa",
      "el_gr": "Κραϊόβα",
      "en_gb": "Craiova",
      "en_us": "Craiova",
      "es_es": "Craiova",
      "es_la": "Craiova",
      "et_ee": "Craiova",
      "eu_es": "Craiova",
      "fi_fi": "Craiova",
      "fr_ca": "Craiova",
      "fr_fr": "Craiova",
      "gl_es": "Craiova",
      "hr_hr": "Craiova",
      "hu_hu": "Craiova",
      "it_it": "Craiova",
      "ja_jp": "クラヨーヴァ",
      "ka_ge": "კრაიოვა",
      "ko_kr": "크라이오바",
      "lt_lt": "Krajova",
      "lv_lv": "Craiova",
      "mk_mk": "Крајова",
      "nl_nl": "Craiova",
      "no_no": "Craiova",
      "pl_pl": "Krajowa",
      "pl_si": "Krajowa",
      "pt_br": "Craiova",
      "pt_pt": "Craiova",
      "ro_ro": "Craiova",
      "ru_ru": "Крайова",
      "sk_sk": "Craiova",
      "sl_sl": "Craiova",
      "sr_sp": "Krajova",
      "sr_sr": "Крајова",
      "sv_se": "Craiova",
      "tr_tr": "Craiova",
      "uk_uk": "Крайова",
      "vi_vn": "Craiova",
      "zh_cn": "克拉约瓦",
      "zh_tw": "克拉約瓦"
    }
  },
  {
    "Name": "София",
    "Country": "bulgaria",
    "X": 50404.66,
    "Y": 41995.52,
    "InGameId": "sofia",
    "LocalizationToken": "sofia",
    "CountryId": 18,
    "LocalizedNames": {
      "bg_bg": "София",
      "ca_es": "Sofia",
      "cs_cz": "Sofie",
      "da_dk": "Sofia",
      "de_de": "Sofia",
      "el_gr": "Σόφια",
      "en_gb": "Sofia",
      "en_us": "Sofia",
      "es_es": "Sofía",
      "es_la": "Sofía",
      "et_ee": "Sofia",
      "eu_es": "Sofia",
      "fi_fi": "Sofia",
      "fr_ca": "Sofia",
      "fr_fr": "Sofia",
      "gl_es": "Sofía",
      "hr_hr": "Sofija",
      "hu_hu": "Szófia",
      "it_it": "Sofia",
      "ja_jp": "ソフィア",
      "ka_ge": "სოფია",
      "ko_kr": "소피아",
      "lt_lt": "Sofija",
      "lv_lv": "Sofia",
      "mk_mk": "Софија",
      "nl_nl": "Sofia",
      "no_no": "Sofia",
      "pl_pl": "Sofia",
      "pl_si": "Sofia",
      "pt_br": "Sófia",
      "pt_pt": "Sófia",
      "ro_ro": "Sofia",
      "ru_ru": "София",
      "sk_sk": "Sofia",
      "sl_sl": "Sofija",
      "sr_sp": "Sofija",
      "sr_sr": "Софија",
      "sv_se": "Sofia",
      "tr_tr": "Sofya",
      "uk_uk": "Софія",
      "vi_vn": "Sofia",
      "zh_cn": "索菲亚",
      "zh_tw": "索非亞"
    }
  },
  {
    "Name": "Перник",
    "Country": "bulgaria",
    "X": 47848.12,
    "Y": 44712.83,
    "InGameId": "pernik",
    "LocalizationToken": "pernik",
    "CountryId": 18,
    "LocalizedNames": {
      "bg_bg": "Перник",
      "ca_es": "Pernik",
      "cs_cz": "Pernik",
      "da_dk": "Pernik",
      "de_de": "Pernik",
      "el_gr": "Πέρνικ",
      "en_gb": "Pernik",
      "en_us": "Pernik",
      "es_es": "Pernik",
      "es_la": "Pernik",
      "et_ee": "Pernik",
      "eu_es": "Pernik",
      "fi_fi": "Pernik",
      "fr_ca": "Pernik",
      "fr_fr": "Pernik",
      "gl_es": "Pernik",
      "hr_hr": "Pernik",
      "hu_hu": "Pernik",
      "it_it": "Pernik",
      "ja_jp": "ペルニク",
      "ka_ge": "პერნიკი",
      "ko_kr": "페르니크",
      "lt_lt": "Pernikas",
      "lv_lv": "Pernik",
      "mk_mk": "Перник",
      "nl_nl": "Pernik",
      "no_no": "Pernik",
      "pl_pl": "Pernik",
      "pl_si": "Pernik",
      "pt_br": "Pernik",
      "pt_pt": "Pernik",
      "ro_ro": "Pernik",
      "ru_ru": "Перник",
      "sk_sk": "Pernik",
      "sl_sl": "Pernik",
      "sr_sp": "Pernik",
      "sr_sr": "Перник",
      "sv_se": "Pernik",
      "tr_tr": "Pernik",
      "uk_uk": "Перник",
      "vi_vn": "Pernik",
      "zh_cn": "佩尔尼克",
      "zh_tw": "佩爾尼克"
    }
  },
  {
    "Name": "Târgu Mureș",
    "Country": "romania",
    "X": 52709.5547,
    "Y": 19237.0742,
    "InGameId": "targu_mures",
    "LocalizationToken": "targu_mures",
    "CountryId": 37,
    "LocalizedNames": {
      "bg_bg": "Търгу Муреш",
      "ca_es": "Tîrgu Mureş",
      "cs_cz": "Târgu Mureș",
      "da_dk": "Târgu Mureș",
      "de_de": "Neumarkt am Mieresch",
      "el_gr": "Τίργκου Μούρες",
      "en_gb": "Târgu Mureș",
      "en_us": "Târgu Mureș",
      "es_es": "Târgu Mureș",
      "es_la": "Târgu Mureș",
      "et_ee": "Târgu Mureș",
      "eu_es": "Târgu Mureș",
      "fi_fi": "Târgu Mureș",
      "fr_ca": "Târgu Mureș",
      "fr_fr": "Târgu Mureș",
      "gl_es": "Târgu Mureș",
      "hr_hr": "Târgu Mureș",
      "hu_hu": "Marosvásárhely",
      "it_it": "Târgu Mureș",
      "ja_jp": "トゥルグ・ムレシュ",
      "ka_ge": "ტირგუ-მურეში",
      "ko_kr": "트르구무레슈",
      "lt_lt": "Tirgu Murešas",
      "lv_lv": "Târgu Mureș",
      "mk_mk": "Таргу Муреш",
      "nl_nl": "Târgu Mureș",
      "no_no": "Târgu Mureș",
      "pl_pl": "Targu Mures",
      "pl_si": "Targu Mures",
      "pt_br": "Târgu Mureș",
      "pt_pt": "Târgu Mureș",
      "ro_ro": "Târgu Mureş",
      "ru_ru": "Тыргу-Муреш",
      "sk_sk": "Târgu Mureș",
      "sl_sl": "Târgu Mureș",
      "sr_sp": "Turgu Mureš",
      "sr_sr": "Тргу Муреш",
      "sv_se": "Târgu Mureș",
      "tr_tr": "Targu Mureş",
      "uk_uk": "Тиргу-Муреш",
      "vi_vn": "Târgu Mureș",
      "zh_cn": "特尔古穆列什",
      "zh_tw": "特爾古穆列什"
    }
  },
  {
    "Name": "Козлодуй",
    "Country": "bulgaria",
    "X": 52508.0273,
    "Y": 36077.168,
    "InGameId": "kozloduy",
    "LocalizationToken": "kozloduy",
    "CountryId": 18,
    "LocalizedNames": {
      "bg_bg": "Козлодуй",
      "ca_es": "Kozloduy",
      "cs_cz": "Kozloduj",
      "da_dk": "Kozloduy",
      "de_de": "Kozloduy",
      "el_gr": "Κοζλοντούι",
      "en_gb": "Kozloduy",
      "en_us": "Kozloduy",
      "es_es": "Kozloduy",
      "es_la": "Kozloduy",
      "et_ee": "Kozloduy",
      "eu_es": "Kozloduy",
      "fi_fi": "Kozloduy",
      "fr_ca": "Kozlodouy",
      "fr_fr": "Kozlodouy",
      "gl_es": "Kozloduy",
      "hr_hr": "Kozloduy",
      "hu_hu": "Kozloduj",
      "it_it": "Kozloduy",
      "ja_jp": "コズロドゥイ",
      "ka_ge": "კოზლოდუი",
      "ko_kr": "코즐로두이",
      "lt_lt": "Kozlodujus",
      "lv_lv": "Kozloduy",
      "mk_mk": "Козлодуј",
      "nl_nl": "Kozloduy",
      "no_no": "Kozloduy",
      "pl_pl": "Kozłoduj",
      "pl_si": "Kozłoduj",
      "pt_br": "Kozloduj",
      "pt_pt": "Kozloduy",
      "ro_ro": "Kozlodui",
      "ru_ru": "Козлодуй",
      "sk_sk": "Kozloduj",
      "sl_sl": "Kozloduy",
      "sr_sp": "Kozloduj",
      "sr_sr": "Козлодуј",
      "sv_se": "Kozloduy",
      "tr_tr": "Kozloduy",
      "uk_uk": "Козлодуй",
      "vi_vn": "Kozloduy",
      "zh_cn": "科兹洛杜伊",
      "zh_tw": "科茲洛杜伊"
    }
  },
  {
    "Name": "Пирдоп",
    "Country": "bulgaria",
    "X": 55687.74,
    "Y": 42040.9766,
    "InGameId": "pirdop",
    "LocalizationToken": "pirdop",
    "CountryId": 18,
    "LocalizedNames": {
      "bg_bg": "Пирдоп",
      "ca_es": "Pirdop",
      "cs_cz": "Pirdop",
      "da_dk": "Pirdop",
      "de_de": "Pirdop",
      "el_gr": "Πίρντοπ",
      "en_gb": "Pirdop",
      "en_us": "Pirdop",
      "es_es": "Pirdop",
      "es_la": "Pirdop",
      "et_ee": "Pirdop",
      "eu_es": "Pirdop",
      "fi_fi": "Pirdop",
      "fr_ca": "Pirdop",
      "fr_fr": "Pirdop",
      "gl_es": "Pirdop",
      "hr_hr": "Pirdop",
      "hu_hu": "Pirdop",
      "it_it": "Pirdop",
      "ja_jp": "ピルドップ",
      "ka_ge": "პირდოპი",
      "ko_kr": "피르도프",
      "lt_lt": "Pirdopas",
      "lv_lv": "Pirdop",
      "mk_mk": "Пирдоп",
      "nl_nl": "Pirdop",
      "no_no": "Pirdop",
      "pl_pl": "Pirdop",
      "pl_si": "Pirdop",
      "pt_br": "Pirdop",
      "pt_pt": "Pirdop",
      "ro_ro": "Pirdop",
      "ru_ru": "Пирдоп",
      "sk_sk": "Pirdop",
      "sl_sl": "Pirdop",
      "sr_sp": "Pirdop",
      "sr_sr": "Пирдоп",
      "sv_se": "Pirdop",
      "tr_tr": "Pirdop",
      "uk_uk": "Пирдоп",
      "vi_vn": "Pirdop",
      "zh_cn": "皮尔多普",
      "zh_tw": "皮爾多普"
    }
  },
  {
    "Name": "Brașov",
    "Country": "romania",
    "X": 58002.2344,
    "Y": 23332.7227,
    "InGameId": "brasov",
    "LocalizationToken": "brasov",
    "CountryId": 37,
    "LocalizedNames": {
      "bg_bg": "Брашов",
      "ca_es": "Brașov",
      "cs_cz": "Brašov",
      "da_dk": "Brașov",
      "de_de": "Kronstadt",
      "el_gr": "Μπρασόβ",
      "en_gb": "Brașov",
      "en_us": "Brașov",
      "es_es": "Brașov",
      "es_la": "Brașov",
      "et_ee": "Brașov",
      "eu_es": "Brașov",
      "fi_fi": "Brașov",
      "fr_ca": "Brașov",
      "fr_fr": "Brașov",
      "gl_es": "Brașov",
      "hr_hr": "Brașov",
      "hu_hu": "Brassó",
      "it_it": "Brașov",
      "ja_jp": "ブラショヴ",
      "ka_ge": "ბრაშოვი",
      "ko_kr": "브라쇼브",
      "lt_lt": "Brašovas",
      "lv_lv": "Brașov",
      "mk_mk": "Брашов",
      "nl_nl": "Brașov",
      "no_no": "Brașov",
      "pl_pl": "Braszów",
      "pl_si": "Braszów",
      "pt_br": "Brasov",
      "pt_pt": "Brașov",
      "ro_ro": "Braşov",
      "ru_ru": "Брашов",
      "sk_sk": "Brašov",
      "sl_sl": "Brașov",
      "sr_sp": "Brašov",
      "sr_sr": "Брашов",
      "sv_se": "Brașov",
      "tr_tr": "Braşov",
      "uk_uk": "Брашов",
      "vi_vn": "Brașov",
      "zh_cn": "布拉索夫",
      "zh_tw": "布拉索夫"
    }
  },
  {
    "Name": "Pitești",
    "Country": "romania",
    "X": 55747.4141,
    "Y": 29392.3711,
    "InGameId": "pitesti",
    "LocalizationToken": "pitesti",
    "CountryId": 37,
    "LocalizedNames": {
      "bg_bg": "Питещи",
      "ca_es": "Pitești",
      "cs_cz": "Pitešť",
      "da_dk": "Pitești",
      "de_de": "Pitești",
      "el_gr": "Πιτέστι",
      "en_gb": "Pitești",
      "en_us": "Pitești",
      "es_es": "Pitești",
      "es_la": "Pitești",
      "et_ee": "Pitești",
      "eu_es": "Pitești",
      "fi_fi": "Pitești",
      "fr_ca": "Pitești",
      "fr_fr": "Pitești",
      "gl_es": "Pitești",
      "hr_hr": "Pitești",
      "hu_hu": "Pitești",
      "it_it": "Pitești",
      "ja_jp": "ピテシュティ",
      "ka_ge": "პიტეშტი",
      "ko_kr": "피테슈티",
      "lt_lt": "Piteštis",
      "lv_lv": "Pitești",
      "mk_mk": "Питешт",
      "nl_nl": "Pitești",
      "no_no": "Pitești",
      "pl_pl": "Pitesti",
      "pl_si": "Pitesti",
      "pt_br": "Pitești",
      "pt_pt": "Pitești",
      "ro_ro": "Piteşti",
      "ru_ru": "Питешти",
      "sk_sk": "Pitești",
      "sl_sl": "Pitești",
      "sr_sp": "Pitešti",
      "sr_sr": "Питешти",
      "sv_se": "Pitești",
      "tr_tr": "Piteşti",
      "uk_uk": "Пітешті",
      "vi_vn": "Pitești",
      "zh_cn": "皮特什蒂",
      "zh_tw": "皮特什蒂"
    }
  },
  {
    "Name": "Плевен",
    "Country": "bulgaria",
    "X": 56844.457,
    "Y": 37037.4258,
    "InGameId": "pleven",
    "LocalizationToken": "pleven",
    "CountryId": 18,
    "LocalizedNames": {
      "bg_bg": "Плевен",
      "ca_es": "Pleven",
      "cs_cz": "Plevno",
      "da_dk": "Pleven",
      "de_de": "Plewen",
      "el_gr": "Πλέβεν",
      "en_gb": "Pleven",
      "en_us": "Pleven",
      "es_es": "Pleven",
      "es_la": "Pleven",
      "et_ee": "Pleven",
      "eu_es": "Pleven",
      "fi_fi": "Pleven",
      "fr_ca": "Pleven",
      "fr_fr": "Pleven",
      "gl_es": "Pleven",
      "hr_hr": "Pleven",
      "hu_hu": "Plevna",
      "it_it": "Pleven",
      "ja_jp": "プレヴェン",
      "ka_ge": "პლევენი",
      "ko_kr": "플레벤",
      "lt_lt": "Plevenas",
      "lv_lv": "Pleven",
      "mk_mk": "Плевен",
      "nl_nl": "Pleven",
      "no_no": "Pleven",
      "pl_pl": "Plewen",
      "pl_si": "Plewen",
      "pt_br": "Pleven",
      "pt_pt": "Pleven",
      "ro_ro": "Plevna",
      "ru_ru": "Плевен",
      "sk_sk": "Pleven",
      "sl_sl": "Pleven",
      "sr_sp": "Pleven",
      "sr_sr": "Плевен",
      "sv_se": "Pleven",
      "tr_tr": "Plevne",
      "uk_uk": "Плевен",
      "vi_vn": "Pleven",
      "zh_cn": "普列文",
      "zh_tw": "普列文"
    }
  },
  {
    "Name": "Карлово",
    "Country": "bulgaria",
    "X": 57506.26,
    "Y": 41637.6055,
    "InGameId": "karlovo",
    "LocalizationToken": "karlovo",
    "CountryId": 18,
    "LocalizedNames": {
      "bg_bg": "Карлово",
      "ca_es": "Karlovo",
      "cs_cz": "Karlovo",
      "da_dk": "Karlovo",
      "de_de": "Karlovo",
      "el_gr": "Κάρλοβο",
      "en_gb": "Karlovo",
      "en_us": "Karlovo",
      "es_es": "Karlovo",
      "es_la": "Karlovo",
      "et_ee": "Karlovo",
      "eu_es": "Karlovo",
      "fi_fi": "Karlovo",
      "fr_ca": "Karlovo",
      "fr_fr": "Karlovo",
      "gl_es": "Karlovo",
      "hr_hr": "Karlovo",
      "hu_hu": "Karlovo",
      "it_it": "Karlovo",
      "ja_jp": "カルロヴォ",
      "ka_ge": "კარლოვო",
      "ko_kr": "카를로보",
      "lt_lt": "Karlovas",
      "lv_lv": "Karlovo",
      "mk_mk": "Карлово",
      "nl_nl": "Karlovo",
      "no_no": "Karlovo",
      "pl_pl": "Karłowo",
      "pl_si": "Karłowo",
      "pt_br": "Karlovo",
      "pt_pt": "Karlovo",
      "ro_ro": "Karlovo",
      "ru_ru": "Карлово",
      "sk_sk": "Karlovo",
      "sl_sl": "Karlovo",
      "sr_sp": "Karlovo",
      "sr_sr": "Карлово",
      "sv_se": "Karlovo",
      "tr_tr": "Karlıova",
      "uk_uk": "Карлово",
      "vi_vn": "Karlovo",
      "zh_cn": "卡尔洛沃",
      "zh_tw": "卡爾洛沃"
    }
  },
  {
    "Name": "Пловдив",
    "Country": "bulgaria",
    "X": 56984.668,
    "Y": 44949.293,
    "InGameId": "plovdiv",
    "LocalizationToken": "plovdiv",
    "CountryId": 18,
    "LocalizedNames": {
      "bg_bg": "Пловдив",
      "ca_es": "Plovdiv",
      "cs_cz": "Plovdiv",
      "da_dk": "Plovdiv",
      "de_de": "Plowdiw",
      "el_gr": "Φιλιππούπολη",
      "en_gb": "Plovdiv",
      "en_us": "Plovdiv",
      "es_es": "Plovdiv",
      "es_la": "Plovdiv",
      "et_ee": "Plovdiv",
      "eu_es": "Plovdiv",
      "fi_fi": "Plovdiv",
      "fr_ca": "Plovdiv",
      "fr_fr": "Plovdiv",
      "gl_es": "Plovdiv",
      "hr_hr": "Plovdiv",
      "hu_hu": "Plovdiv",
      "it_it": "Plovdiv",
      "ja_jp": "プロヴディフ",
      "ka_ge": "პლოვდივი",
      "ko_kr": "플로브디프",
      "lt_lt": "Plovdivas",
      "lv_lv": "Plovdiv",
      "mk_mk": "Пловдив",
      "nl_nl": "Plovdiv",
      "no_no": "Plovdiv",
      "pl_pl": "Płowdiw",
      "pl_si": "Płowdiw",
      "pt_br": "Plovdiv",
      "pt_pt": "Plovdiv",
      "ro_ro": "Plovdiv",
      "ru_ru": "Пловдив",
      "sk_sk": "Plovdiv",
      "sl_sl": "Plovdiv",
      "sr_sp": "Plovdiv",
      "sr_sr": "Пловдив",
      "sv_se": "Plovdiv",
      "tr_tr": "Filibe",
      "uk_uk": "Пловдив",
      "vi_vn": "Plovdiv",
      "zh_cn": "普罗夫迪夫",
      "zh_tw": "普羅夫迪夫"
    }
  },
  {
    "Name": "Bacău",
    "Country": "romania",
    "X": 61887.293,
    "Y": 18223.84,
    "InGameId": "bacau",
    "LocalizationToken": "bacau",
    "CountryId": 37,
    "LocalizedNames": {
      "bg_bg": "Бакъу",
      "ca_es": "Bacău",
      "cs_cz": "Bacău",
      "da_dk": "Bacău",
      "de_de": "Barchau",
      "el_gr": "Μπακάου",
      "en_gb": "Bacău",
      "en_us": "Bacău",
      "es_es": "Bacău",
      "es_la": "Bacău",
      "et_ee": "Bacău",
      "eu_es": "Bacău",
      "fi_fi": "Bacău",
      "fr_ca": "Bacău",
      "fr_fr": "Bacău",
      "gl_es": "Bacău",
      "hr_hr": "Bacău",
      "hu_hu": "Bákó",
      "it_it": "Bacău",
      "ja_jp": "バカウ",
      "ka_ge": "ბაკეუ",
      "ko_kr": "바커우",
      "lt_lt": "Bakeu",
      "lv_lv": "Bacău",
      "mk_mk": "Бакау",
      "nl_nl": "Bacău",
      "no_no": "Bacău",
      "pl_pl": "Bacau",
      "pl_si": "Bacau",
      "pt_br": "Bacău",
      "pt_pt": "Bacău",
      "ro_ro": "Bacău",
      "ru_ru": "Бакэу",
      "sk_sk": "Bacău",
      "sl_sl": "Bacău",
      "sr_sp": "Bakau",
      "sr_sr": "Бакау",
      "sv_se": "Bacău",
      "tr_tr": "Bacău",
      "uk_uk": "Бакеу",
      "vi_vn": "Bacău",
      "zh_cn": "巴克乌",
      "zh_tw": "巴克烏"
    }
  },
  {
    "Name": "București",
    "Country": "romania",
    "X": 60720.85,
    "Y": 31233.5,
    "InGameId": "bucuresti",
    "LocalizationToken": "bucuresti",
    "CountryId": 37,
    "LocalizedNames": {
      "bg_bg": "Букурещ",
      "ca_es": "Bucarest",
      "cs_cz": "Bukurešť",
      "da_dk": "Bukarest",
      "de_de": "Bukarest",
      "el_gr": "Βουκουρέστι",
      "en_gb": "Bucharest",
      "en_us": "Bucharest",
      "es_es": "Bucarest",
      "es_la": "Bucarest",
      "et_ee": "Bukarest",
      "eu_es": "Bukarest",
      "fi_fi": "Bukarest",
      "fr_ca": "Bucarest",
      "fr_fr": "Bucarest",
      "gl_es": "Bucarest",
      "hr_hr": "Bukurešt",
      "hu_hu": "Bukarest",
      "it_it": "Bucarest",
      "ja_jp": "ブカレスト",
      "ka_ge": "ბუქარესტი",
      "ko_kr": "부쿠레슈티",
      "lt_lt": "Bukareštas",
      "lv_lv": "Bucharest",
      "mk_mk": "Букурешт",
      "nl_nl": "Boekarest",
      "no_no": "București",
      "pl_pl": "Bukareszt",
      "pl_si": "Bukareszt",
      "pt_br": "Bucareste",
      "pt_pt": "Bucareste",
      "ro_ro": "Bucureşti",
      "ru_ru": "Бухарест",
      "sk_sk": "Bukurešť",
      "sl_sl": "Bukarešta",
      "sr_sp": "Bukurešt",
      "sr_sr": "Букурешт",
      "sv_se": "Bukarest",
      "tr_tr": "Bükreş",
      "uk_uk": "Бухарест",
      "vi_vn": "Bucharest",
      "zh_cn": "布加勒斯特",
      "zh_tw": "布加勒斯特"
    }
  },
  {
    "Name": "Русе",
    "Country": "bulgaria",
    "X": 60953.87,
    "Y": 34599.6328,
    "InGameId": "ruse",
    "LocalizationToken": "ruse",
    "CountryId": 18,
    "LocalizedNames": {
      "bg_bg": "Русе",
      "ca_es": "Ruse",
      "cs_cz": "Ruse",
      "da_dk": "Ruse",
      "de_de": "Russe",
      "el_gr": "Ρούσε",
      "en_gb": "Ruse",
      "en_us": "Ruse",
      "es_es": "Ruse",
      "es_la": "Ruse",
      "et_ee": "Ruse",
      "eu_es": "Ruse",
      "fi_fi": "Ruse",
      "fr_ca": "Roussé",
      "fr_fr": "Roussé",
      "gl_es": "Ruse",
      "hr_hr": "Ruse",
      "hu_hu": "Rusze",
      "it_it": "Ruse",
      "ja_jp": "ルセ",
      "ka_ge": "რუსე",
      "ko_kr": "루세",
      "lt_lt": "Rusė",
      "lv_lv": "Ruse",
      "mk_mk": "Русе",
      "nl_nl": "Roese",
      "no_no": "Ruse",
      "pl_pl": "Ruse",
      "pl_si": "Ruse",
      "pt_br": "Ruse",
      "pt_pt": "Ruse",
      "ro_ro": "Ruse",
      "ru_ru": "Русе",
      "sk_sk": "Ruse",
      "sl_sl": "Ruse",
      "sr_sp": "Ruse",
      "sr_sr": "Русе",
      "sv_se": "Ruse",
      "tr_tr": "Rusçuk",
      "uk_uk": "Русе",
      "vi_vn": "Ruse",
      "zh_cn": "鲁塞",
      "zh_tw": "魯塞"
    }
  },
  {
    "Name": "Велико Търново",
    "Country": "bulgaria",
    "X": 60463.2578,
    "Y": 38164.33,
    "InGameId": "veli_tarnovo",
    "LocalizationToken": "veliko_tarnovo",
    "CountryId": 18,
    "LocalizedNames": {
      "bg_bg": "Велико Търново",
      "ca_es": "Veliko Tarnovo",
      "cs_cz": "Veliko Tarnovo",
      "da_dk": "Veliko Tarnovo",
      "de_de": "Weliko Tarnowo",
      "el_gr": "Βελίκο Τάρνοβο",
      "en_gb": "Veliko Tarnovo",
      "en_us": "Veliko Tarnovo",
      "es_es": "Veliko Tarnovo",
      "es_la": "Veliko Tarnovo",
      "et_ee": "Veliko Tarnovo",
      "eu_es": "Veliko Tarnovo",
      "fi_fi": "Veliko Tarnovo",
      "fr_ca": "Veliko Tarnovo",
      "fr_fr": "Veliko Tarnovo",
      "gl_es": "Veliko Tarnovo",
      "hr_hr": "Veliko Trnovo",
      "hu_hu": "Veliko Tarnovo",
      "it_it": "Veliko Tarnovo",
      "ja_jp": "ヴェリコ・タルノヴォ",
      "ka_ge": "ველიკო-ტირნოვო",
      "ko_kr": "벨리코 터르노보",
      "lt_lt": "Veliko Tirnovas",
      "lv_lv": "Veliko Tarnovo",
      "mk_mk": "Велико Трново",
      "nl_nl": "Veliko Tarnovo",
      "no_no": "Veliko Tarnovo",
      "pl_pl": "Wielkie Tyrnowo",
      "pl_si": "Wielkie Tyrnowo",
      "pt_br": "Veliko Tarnovo",
      "pt_pt": "Veliko Tarnovo",
      "ro_ro": "Veliko Tărnovo",
      "ru_ru": "Велико-Тырново",
      "sk_sk": "Veliko Tărnovo",
      "sl_sl": "Veliko Trnovo",
      "sr_sp": "Veliko Trnovo",
      "sr_sr": "Велико Трново",
      "sv_se": "Veliko Tarnovo",
      "tr_tr": "Tırnova",
      "uk_uk": "Велико-Тирново",
      "vi_vn": "Veliko Tarnovo",
      "zh_cn": "大特尔诺沃",
      "zh_tw": "大特爾諾沃"
    }
  },
  {
    "Name": "Iași",
    "Country": "romania",
    "X": 63246.293,
    "Y": 13443.7422,
    "InGameId": "iasi",
    "LocalizationToken": "iasi",
    "CountryId": 37,
    "LocalizedNames": {
      "bg_bg": "Яши",
      "ca_es": "Iași",
      "cs_cz": "Jasy",
      "da_dk": "Iași",
      "de_de": "Jassy",
      "el_gr": "Ιάσιο",
      "en_gb": "Iași",
      "en_us": "Iași",
      "es_es": "Iași",
      "es_la": "Iași",
      "et_ee": "Iași",
      "eu_es": "Iași",
      "fi_fi": "Iași",
      "fr_ca": "Iași",
      "fr_fr": "Iași",
      "gl_es": "Iași",
      "hr_hr": "Iași",
      "hu_hu": "Jászvásár",
      "it_it": "Iași",
      "ja_jp": "ヤシ",
      "ka_ge": "იასი",
      "ko_kr": "이아시",
      "lt_lt": "Jasai",
      "lv_lv": "Iași",
      "mk_mk": "Јаш",
      "nl_nl": "Iași",
      "no_no": "Iași",
      "pl_pl": "Jassy",
      "pl_si": "Jassy",
      "pt_br": "Iași",
      "pt_pt": "Iași",
      "ro_ro": "Iaşi",
      "ru_ru": "Яссы",
      "sk_sk": "Jasy",
      "sl_sl": "Iași",
      "sr_sp": "Jaši",
      "sr_sr": "Јаши",
      "sv_se": "Iași",
      "tr_tr": "Yaş",
      "uk_uk": "Яси",
      "vi_vn": "Iași",
      "zh_cn": "雅西",
      "zh_tw": "雅西"
    }
  },
  {
    "Name": "Galați",
    "Country": "romania",
    "X": 66046.03,
    "Y": 23352.875,
    "InGameId": "galati",
    "LocalizationToken": "galati",
    "CountryId": 37,
    "LocalizedNames": {
      "bg_bg": "Галати",
      "ca_es": "Galați",
      "cs_cz": "Galac",
      "da_dk": "Galați",
      "de_de": "Galatz",
      "el_gr": "Γκαλάτσι",
      "en_gb": "Galați",
      "en_us": "Galați",
      "es_es": "Galați",
      "es_la": "Galați",
      "et_ee": "Galați",
      "eu_es": "Galați",
      "fi_fi": "Galați",
      "fr_ca": "Galați",
      "fr_fr": "Galați",
      "gl_es": "Galați",
      "hr_hr": "Galați",
      "hu_hu": "Galac",
      "it_it": "Galați",
      "ja_jp": "ガラツィ",
      "ka_ge": "გალატი",
      "ko_kr": "갈라치",
      "lt_lt": "Galacis",
      "lv_lv": "Galați",
      "mk_mk": "Галати",
      "nl_nl": "Galați",
      "no_no": "Galați",
      "pl_pl": "Gałacz",
      "pl_si": "Gałacz",
      "pt_br": "Galați",
      "pt_pt": "Galați",
      "ro_ro": "Galaţi",
      "ru_ru": "Галац",
      "sk_sk": "Galați",
      "sl_sl": "Galați",
      "sr_sp": "Galaci",
      "sr_sr": "Галаци",
      "sv_se": "Galați",
      "tr_tr": "Galati",
      "uk_uk": "Галац",
      "vi_vn": "Galați",
      "zh_cn": "加拉茨",
      "zh_tw": "加拉茨"
    }
  },
  {
    "Name": "Călărași",
    "Country": "romania",
    "X": 65124.5,
    "Y": 30737.7188,
    "InGameId": "calarasi",
    "LocalizationToken": "calarasi",
    "CountryId": 37,
    "LocalizedNames": {
      "bg_bg": "Кълъраш",
      "ca_es": "Călărași",
      "cs_cz": "Kalaraš",
      "da_dk": "Călărași",
      "de_de": "Călărași",
      "el_gr": "Καλαράσι",
      "en_gb": "Călărași",
      "en_us": "Călărași",
      "es_es": "Călărași",
      "es_la": "Călărași",
      "et_ee": "Călărași",
      "eu_es": "Călărași",
      "fi_fi": "Călărași",
      "fr_ca": "Călărași",
      "fr_fr": "Călărași",
      "gl_es": "Călărași",
      "hr_hr": "Călărași",
      "hu_hu": "Călărași",
      "it_it": "Călărași",
      "ja_jp": "カララシ",
      "ka_ge": "კელერაში",
      "ko_kr": "컬러라시",
      "lt_lt": "Kelerašis",
      "lv_lv": "Călărași",
      "mk_mk": "Калараши",
      "nl_nl": "Călărași",
      "no_no": "Călărași",
      "pl_pl": "Calarasi",
      "pl_si": "Calarasi",
      "pt_br": "Călărași",
      "pt_pt": "Călărași",
      "ro_ro": "Călăraşi",
      "ru_ru": "Кэлэраши",
      "sk_sk": "Călărași",
      "sl_sl": "Călărași",
      "sr_sp": "Kalaraši",
      "sr_sr": "Калараши",
      "sv_se": "Călărași",
      "tr_tr": "Calaraşi",
      "uk_uk": "Келераш",
      "vi_vn": "Călărași",
      "zh_cn": "克勒拉希",
      "zh_tw": "克勒拉希"
    }
  },
  {
    "Name": "Edirne",
    "Country": "turkey",
    "X": 64295.918,
    "Y": 46409.043,
    "InGameId": "edirne",
    "LocalizationToken": "edirne",
    "CountryId": 44,
    "LocalizedNames": {
      "bg_bg": "Едирне",
      "ca_es": "Edirne",
      "cs_cz": "Edirne",
      "da_dk": "Edirne",
      "de_de": "Edirne",
      "el_gr": "Αδριανούπολη",
      "en_gb": "Edirne",
      "en_us": "Edirne",
      "es_es": "Edirne",
      "es_la": "Edirne",
      "et_ee": "Edirne",
      "eu_es": "Edirne",
      "fi_fi": "Edirne",
      "fr_ca": "Edirne",
      "fr_fr": "Edirne",
      "gl_es": "Edirne",
      "hr_hr": "Edirne",
      "hu_hu": "Edirne",
      "it_it": "Edirne",
      "ja_jp": "エディルネ",
      "ka_ge": "ედირნე",
      "ko_kr": "에디르네",
      "lt_lt": "Edirnė",
      "lv_lv": "Edirne",
      "mk_mk": "Одрин",
      "nl_nl": "Edirne",
      "no_no": "Edirne",
      "pl_pl": "Edirne",
      "pl_si": "Edirne",
      "pt_br": "Edirne",
      "pt_pt": "Edirne",
      "ro_ro": "Edirne",
      "ru_ru": "Эдирне",
      "sk_sk": "Edirne",
      "sl_sl": "Odrín",
      "sr_sp": "Jedrene",
      "sr_sr": "Једрене",
      "sv_se": "Edirne",
      "tr_tr": "Edirne",
      "uk_uk": "Едірне",
      "vi_vn": "Edirne",
      "zh_cn": "埃迪尔内",
      "zh_tw": "愛第尼"
    }
  },
  {
    "Name": "Cernavodă",
    "Country": "romania",
    "X": 68929.36,
    "Y": 29395.2461,
    "InGameId": "cernavoda",
    "LocalizationToken": "cernavoda",
    "CountryId": 37,
    "LocalizedNames": {
      "bg_bg": "Черна вода",
      "ca_es": "Cernavodă",
      "cs_cz": "Cernavodă",
      "da_dk": "Cernavodă",
      "de_de": "Cernavodă",
      "el_gr": "Τσερναβόντα",
      "en_gb": "Cernavodă",
      "en_us": "Cernavodă",
      "es_es": "Cernavodă",
      "es_la": "Cernavodă",
      "et_ee": "Cernavodă",
      "eu_es": "Cernavodă",
      "fi_fi": "Cernavodă",
      "fr_ca": "Cernavodă",
      "fr_fr": "Cernavodă",
      "gl_es": "Cernavodă",
      "hr_hr": "Cernavodă",
      "hu_hu": "Csernavoda",
      "it_it": "Cernavodă",
      "ja_jp": "チェルナヴォダ",
      "ka_ge": "ჩერნავოდე",
      "ko_kr": "체르나보더",
      "lt_lt": "Černavoda",
      "lv_lv": "Cernavodă",
      "mk_mk": "Чернавода",
      "nl_nl": "Cernavodă",
      "no_no": "Cernavodă",
      "pl_pl": "Cernavodă",
      "pl_si": "Cernavodă",
      "pt_br": "Cernavodă",
      "pt_pt": "Cernavodă",
      "ro_ro": "Cernavodă",
      "ru_ru": "Чернаводэ",
      "sk_sk": "Cernavoda",
      "sl_sl": "Cernavodă",
      "sr_sp": "Černavoda",
      "sr_sr": "Чернавода",
      "sv_se": "Cernavoda",
      "tr_tr": "Boğazköy",
      "uk_uk": "Чернаводе",
      "vi_vn": "Cernavodă",
      "zh_cn": "切尔纳沃德",
      "zh_tw": "切爾納沃德"
    }
  },
  {
    "Name": "Constanța",
    "Country": "romania",
    "X": 71381.54,
    "Y": 30500.7344,
    "InGameId": "constanta",
    "LocalizationToken": "constanta",
    "CountryId": 37,
    "LocalizedNames": {
      "bg_bg": "Кюстенджа",
      "ca_es": "Constanța",
      "cs_cz": "Konstanca",
      "da_dk": "Constanța",
      "de_de": "Konstanza",
      "el_gr": "Κωνστάντζα",
      "en_gb": "Constanța",
      "en_us": "Constanța",
      "es_es": "Constanza",
      "es_la": "Constanza",
      "et_ee": "Constanța",
      "eu_es": "Konstantza",
      "fi_fi": "Constanța",
      "fr_ca": "Constanța",
      "fr_fr": "Constanța",
      "gl_es": "Constanza",
      "hr_hr": "Constanța",
      "hu_hu": "Konstanca",
      "it_it": "Constanța",
      "ja_jp": "コンスタンツァ",
      "ka_ge": "კონსტანცა",
      "ko_kr": "콘스탄차",
      "lt_lt": "Konstanta",
      "lv_lv": "Constanța",
      "mk_mk": "Константа",
      "nl_nl": "Constanța",
      "no_no": "Constanța",
      "pl_pl": "Konstanca",
      "pl_si": "Konstanca",
      "pt_br": "Constança",
      "pt_pt": "Constança",
      "ro_ro": "Constanţa",
      "ru_ru": "Констанца",
      "sk_sk": "Konstanca",
      "sl_sl": "Constanța",
      "sr_sp": "Konstanca",
      "sr_sr": "Констанца",
      "sv_se": "Constanța",
      "tr_tr": "Köstence",
      "uk_uk": "Констанца",
      "vi_vn": "Constanța",
      "zh_cn": "康斯坦察",
      "zh_tw": "康斯坦察"
    }
  },
  {
    "Name": "Mangalia",
    "Country": "romania",
    "X": 71227.59,
    "Y": 32960.7266,
    "InGameId": "mangalia",
    "LocalizationToken": "mangalia",
    "CountryId": 37,
    "LocalizedNames": {
      "bg_bg": "Мангалия",
      "ca_es": "Mangalia",
      "cs_cz": "Mangalia",
      "da_dk": "Mangalia",
      "de_de": "Mangalia",
      "el_gr": "Μανγκάλια",
      "en_gb": "Mangalia",
      "en_us": "Mangalia",
      "es_es": "Mangalia",
      "es_la": "Mangalia",
      "et_ee": "Mangalia",
      "eu_es": "Mangalia",
      "fi_fi": "Mangalia",
      "fr_ca": "Mangalia",
      "fr_fr": "Mangalia",
      "gl_es": "Mangalia",
      "hr_hr": "Mangalia",
      "hu_hu": "Mangalia",
      "it_it": "Mangalia",
      "ja_jp": "マンガリア",
      "ka_ge": "მანგალია",
      "ko_kr": "만갈리아",
      "lt_lt": "Mangalija",
      "lv_lv": "Mangalia",
      "mk_mk": "Мангалија",
      "nl_nl": "Mangalia",
      "no_no": "Mangalia",
      "pl_pl": "Mangalia",
      "pl_si": "Mangalia",
      "pt_br": "Mangalia",
      "pt_pt": "Mangalia",
      "ro_ro": "Mangalia",
      "ru_ru": "Мангалия",
      "sk_sk": "Mangalia",
      "sl_sl": "Mangalija",
      "sr_sp": "Mangalija",
      "sr_sr": "Мангалија",
      "sv_se": "Mangalia",
      "tr_tr": "Mankalya",
      "uk_uk": "Мангалія",
      "vi_vn": "Mangalia",
      "zh_cn": "曼加利亚",
      "zh_tw": "曼加利亞"
    }
  },
  {
    "Name": "Варна",
    "Country": "bulgaria",
    "X": 69393.3,
    "Y": 35442.1055,
    "InGameId": "varna",
    "LocalizationToken": "varna",
    "CountryId": 18,
    "LocalizedNames": {
      "bg_bg": "Варна",
      "ca_es": "Varna",
      "cs_cz": "Varna",
      "da_dk": "Varna",
      "de_de": "Varna",
      "el_gr": "Βάρνα",
      "en_gb": "Varna",
      "en_us": "Varna",
      "es_es": "Varna",
      "es_la": "Varna",
      "et_ee": "Varna",
      "eu_es": "Varna",
      "fi_fi": "Varna",
      "fr_ca": "Varna",
      "fr_fr": "Varna",
      "gl_es": "Varna",
      "hr_hr": "Varna",
      "hu_hu": "Várna",
      "it_it": "Varna",
      "ja_jp": "ヴァルナ",
      "ka_ge": "ვარნა",
      "ko_kr": "바르나",
      "lt_lt": "Varna",
      "lv_lv": "Varna",
      "mk_mk": "Варна",
      "nl_nl": "Varna",
      "no_no": "Varna",
      "pl_pl": "Warna",
      "pl_si": "Warna",
      "pt_br": "Varna",
      "pt_pt": "Varna",
      "ro_ro": "Varna",
      "ru_ru": "Варна",
      "sk_sk": "Varna",
      "sl_sl": "Varna",
      "sr_sp": "Varna",
      "sr_sr": "Варна",
      "sv_se": "Varna",
      "tr_tr": "Varna",
      "uk_uk": "Варна",
      "vi_vn": "Varna",
      "zh_cn": "瓦尔纳",
      "zh_tw": "瓦爾納"
    }
  },
  {
    "Name": "Бургас",
    "Country": "bulgaria",
    "X": 66797.05,
    "Y": 39860.543,
    "InGameId": "burgas",
    "LocalizationToken": "burgas",
    "CountryId": 18,
    "LocalizedNames": {
      "bg_bg": "Бургас",
      "ca_es": "Burgàs",
      "cs_cz": "Burgas",
      "da_dk": "Burgas",
      "de_de": "Burgas",
      "el_gr": "Μπουργκάς",
      "en_gb": "Burgas",
      "en_us": "Burgas",
      "es_es": "Burgas",
      "es_la": "Burgas",
      "et_ee": "Burgas",
      "eu_es": "Burgas",
      "fi_fi": "Burgas",
      "fr_ca": "Bourgas",
      "fr_fr": "Bourgas",
      "gl_es": "Burgas",
      "hr_hr": "Burgas",
      "hu_hu": "Burgasz",
      "it_it": "Burgas",
      "ja_jp": "ブルガス",
      "ka_ge": "ბურგასი",
      "ko_kr": "부르가스",
      "lt_lt": "Burgasas",
      "lv_lv": "Burgas",
      "mk_mk": "Бургас",
      "nl_nl": "Boergas",
      "no_no": "Burgas",
      "pl_pl": "Burgas",
      "pl_si": "Burgas",
      "pt_br": "Burgas",
      "pt_pt": "Burgas",
      "ro_ro": "Burgas",
      "ru_ru": "Бургас",
      "sk_sk": "Burgas",
      "sl_sl": "Burgas",
      "sr_sp": "Burgas",
      "sr_sr": "Бургас",
      "sv_se": "Burgas",
      "tr_tr": "Burgaz",
      "uk_uk": "Бурґас",
      "vi_vn": "Burgas",
      "zh_cn": "布尔加斯",
      "zh_tw": "布爾加斯"
    }
  },
  {
    "Name": "Tekirdağ",
    "Country": "turkey",
    "X": 68190.42,
    "Y": 48967.668,
    "InGameId": "tekirdag",
    "LocalizationToken": "tekirdag",
    "CountryId": 44,
    "LocalizedNames": {
      "bg_bg": "Текирдаг",
      "ca_es": "Tekirdağ",
      "cs_cz": "Tekirdağ",
      "da_dk": "Tekirdağ",
      "de_de": "Tekirdağ",
      "el_gr": "Ραιδεστός",
      "en_gb": "Tekirdağ",
      "en_us": "Tekirdağ",
      "es_es": "Tekirdağ",
      "es_la": "Tekirdağ",
      "et_ee": "Tekirdağ",
      "eu_es": "Tekirdağ",
      "fi_fi": "Tekirdağ",
      "fr_ca": "Tekirdağ",
      "fr_fr": "Tekirdağ",
      "gl_es": "Tekirdağ",
      "hr_hr": "Tekirdağ",
      "hu_hu": "Rodostó",
      "it_it": "Tekirdağ",
      "ja_jp": "テキルダー",
      "ka_ge": "თექირდაღი",
      "ko_kr": "테키르다",
      "lt_lt": "Tekirdahas",
      "lv_lv": "Tekirdağ",
      "mk_mk": "Текирдаг",
      "nl_nl": "Tekirdağ",
      "no_no": "Tekirdağ",
      "pl_pl": "Tekirdag",
      "pl_si": "Tekirdag",
      "pt_br": "Tekirdağ",
      "pt_pt": "Tekirdağ",
      "ro_ro": "Tekirdag",
      "ru_ru": "Текирдаг",
      "sk_sk": "Tekirdağ",
      "sl_sl": "Tekirdağ",
      "sr_sp": "Tekirdag",
      "sr_sr": "Текирдаг",
      "sv_se": "Tekirdağ",
      "tr_tr": "Tekirdağ",
      "uk_uk": "Текірдаг",
      "vi_vn": "Tekirdağ",
      "zh_cn": "泰基尔达",
      "zh_tw": "泰基爾達"
    }
  },
  {
    "Name": "İstanbul",
    "Country": "turkey",
    "X": 73653.375,
    "Y": 45623.83,
    "InGameId": "istanbul",
    "LocalizationToken": "istanbul",
    "CountryId": 44,
    "LocalizedNames": {
      "bg_bg": "Истанбул",
      "ca_es": "Istanbul",
      "cs_cz": "Istanbul",
      "da_dk": "Istanbul",
      "de_de": "Istanbul",
      "el_gr": "Κωνσταντινούπολη",
      "en_gb": "İstanbul",
      "en_us": "İstanbul",
      "es_es": "Estambul",
      "es_la": "Estambul",
      "et_ee": "Istanbul",
      "eu_es": "Istanbul",
      "fi_fi": "Istanbul",
      "fr_ca": "Istanbul",
      "fr_fr": "Istanbul",
      "gl_es": "Istambul",
      "hr_hr": "Istanbul",
      "hu_hu": "Isztambul",
      "it_it": "Istanbul",
      "ja_jp": "イスタンブール",
      "ka_ge": "სტამბოლი",
      "ko_kr": "이스탄불",
      "lt_lt": "Stambulas",
      "lv_lv": "İstanbul",
      "mk_mk": "Истанбул",
      "nl_nl": "Istanboel",
      "no_no": "Istanbul",
      "pl_pl": "Stambuł",
      "pl_si": "Stambuł",
      "pt_br": "Istambul",
      "pt_pt": "Istambul",
      "ro_ro": "Istanbul",
      "ru_ru": "Стамбул",
      "sk_sk": "Istanbul",
      "sl_sl": "Carigrad",
      "sr_sp": "İstanbul",
      "sr_sr": "Истанбул",
      "sv_se": "Istanbul",
      "tr_tr": "İstanbul",
      "uk_uk": "Стамбул",
      "vi_vn": "İstanbul",
      "zh_cn": "伊斯坦布尔",
      "zh_tw": "伊斯坦堡"
    }
  },
  {
    "Name": "Novo Mesto",
    "Country": "slovenia",
    "X": 16101.6846,
    "Y": 27209.02,
    "InGameId": "novo_mesto",
    "LocalizationToken": "novo_mesto",
    "CountryId": 41,
    "LocalizedNames": {
      "bg_bg": "Novo Mesto",
      "ca_es": "Novo Mesto",
      "cs_cz": "Novo Mesto",
      "da_dk": "Novo Mesto",
      "de_de": "Novo mesto",
      "el_gr": "Νόβο Μέστο",
      "en_gb": "Novo Mesto",
      "en_us": "Novo Mesto",
      "es_es": "Novo Mesto",
      "es_la": "Novo Mesto",
      "et_ee": "Novo Mesto",
      "eu_es": "Novo Mesto",
      "fi_fi": "Novo Mesto",
      "fr_ca": "Novo Mesto",
      "fr_fr": "Novo Mesto",
      "gl_es": "Novo Mesto",
      "hr_hr": "Novo Mesto",
      "hu_hu": "Novo mesto",
      "it_it": "Novo Mesto",
      "ja_jp": "ノヴォ・メスト",
      "ka_ge": "ნოვო-მესტო",
      "ko_kr": "노보메스토",
      "lt_lt": "Novo Mesto",
      "lv_lv": "Novo Mesto",
      "mk_mk": "Ново Место",
      "nl_nl": "Novo Mesto",
      "no_no": "Novo Mesto",
      "pl_pl": "Novo mesto",
      "pl_si": "Novo mesto",
      "pt_br": "Novo Mesto",
      "pt_pt": "Novo Mesto",
      "ro_ro": "Novo mesto",
      "ru_ru": "Ново-Место",
      "sk_sk": "Novo Mesto",
      "sl_sl": "Novo Mesto",
      "sr_sp": "Novo Mesto",
      "sr_sr": "Ново Место",
      "sv_se": "Novo Mesto",
      "tr_tr": "Novo Mesta",
      "uk_uk": "Ново Место",
      "vi_vn": "Novo Mesto",
      "zh_cn": "新梅斯托",
      "zh_tw": "新梅斯托"
    }
  },
  {
    "Name": "Zadar",
    "Country": "croatia",
    "X": 16790.4063,
    "Y": 36096.53,
    "InGameId": "zadar",
    "LocalizationToken": "zadar",
    "CountryId": 19,
    "LocalizedNames": {
      "bg_bg": "Zadar",
      "ca_es": "Zadar",
      "cs_cz": "Zadar",
      "da_dk": "Zadar",
      "de_de": "Zadar",
      "el_gr": "Ζαντάρ",
      "en_gb": "Zadar",
      "en_us": "Zadar",
      "es_es": "Zadar",
      "es_la": "Zadar",
      "et_ee": "Zadar",
      "eu_es": "Zadar",
      "fi_fi": "Zadar",
      "fr_ca": "Zadar",
      "fr_fr": "Zadar",
      "gl_es": "Zadar",
      "hr_hr": "Zadar",
      "hu_hu": "Zára",
      "it_it": "Zadar",
      "ja_jp": "ザダル",
      "ka_ge": "ზადარი",
      "ko_kr": "자다르",
      "lt_lt": "Zadaras",
      "lv_lv": "Zadar",
      "mk_mk": "Задар",
      "nl_nl": "Zadar",
      "no_no": "Zadar",
      "pl_pl": "Zadar",
      "pl_si": "Zadar",
      "pt_br": "Zadar",
      "pt_pt": "Zadar",
      "ro_ro": "Zadar",
      "ru_ru": "Задар",
      "sk_sk": "Zadar",
      "sl_sl": "Zadar",
      "sr_sp": "Zadar",
      "sr_sr": "Задар",
      "sv_se": "Zadar",
      "tr_tr": "Zadar",
      "uk_uk": "Задар",
      "vi_vn": "Zadar",
      "zh_cn": "扎达尔",
      "zh_tw": "扎達爾"
    }
  },
  {
    "Name": "Zagreb",
    "Country": "croatia",
    "X": 21030.5723,
    "Y": 26455.02,
    "InGameId": "zagreb",
    "LocalizationToken": "zagreb",
    "CountryId": 19,
    "LocalizedNames": {
      "bg_bg": "Zagreb",
      "ca_es": "Zagreb",
      "cs_cz": "Záhřeb",
      "da_dk": "Zagreb",
      "de_de": "Zagreb",
      "el_gr": "Ζάγκρεμπ",
      "en_gb": "Zagreb",
      "en_us": "Zagreb",
      "es_es": "Zagreb",
      "es_la": "Zagreb",
      "et_ee": "Zagreb",
      "eu_es": "Zagreb",
      "fi_fi": "Zagreb",
      "fr_ca": "Zagreb",
      "fr_fr": "Zagreb",
      "gl_es": "Zagreb",
      "hr_hr": "Zagreb",
      "hu_hu": "Zágráb",
      "it_it": "Zagreb",
      "ja_jp": "ザグレブ",
      "ka_ge": "ზაგრები",
      "ko_kr": "자그레브",
      "lt_lt": "Zagrebas",
      "lv_lv": "Zagreb",
      "mk_mk": "Загреб",
      "nl_nl": "Zagreb",
      "no_no": "Zagreb",
      "pl_pl": "Zagrzeb",
      "pl_si": "Zagrzeb",
      "pt_br": "Zagreb",
      "pt_pt": "Zagrebe",
      "ro_ro": "Zagreb",
      "ru_ru": "Загреб",
      "sk_sk": "Záhreb",
      "sl_sl": "Zagreb",
      "sr_sp": "Zagreb",
      "sr_sr": "Загреб",
      "sv_se": "Zagreb",
      "tr_tr": "Zagreb",
      "uk_uk": "Загреб",
      "vi_vn": "Zagreb",
      "zh_cn": "萨格勒布",
      "zh_tw": "札格瑞布"
    }
  },
  {
    "Name": "Бихаћ",
    "Country": "bosnia",
    "X": 20200.42,
    "Y": 32348.88,
    "InGameId": "bihac",
    "LocalizationToken": "bihac",
    "CountryId": 17,
    "LocalizedNames": {
      "bg_bg": "Bihać",
      "ca_es": "Bihać",
      "cs_cz": "Bihać",
      "da_dk": "Bihać",
      "de_de": "Bihać",
      "el_gr": "Μπίχατς",
      "en_gb": "Bihać",
      "en_us": "Bihać",
      "es_es": "Bihać",
      "es_la": "Bihać",
      "et_ee": "Bihać",
      "eu_es": "Bihać",
      "fi_fi": "Bihać",
      "fr_ca": "Bihać",
      "fr_fr": "Bihać",
      "gl_es": "Bihać",
      "hr_hr": "Bihać",
      "hu_hu": "Bihács",
      "it_it": "Bihać",
      "ja_jp": "ビハチ",
      "ka_ge": "ბიჰაჩი",
      "ko_kr": "비하치",
      "lt_lt": "Bihačas",
      "lv_lv": "Bihać",
      "mk_mk": "Бихаќ",
      "nl_nl": "Bihać",
      "no_no": "Bihać",
      "pl_pl": "Bihać",
      "pl_si": "Bihać",
      "pt_br": "Bihać",
      "pt_pt": "Bihać",
      "ro_ro": "Bihać",
      "ru_ru": "Бихач",
      "sk_sk": "Bihać",
      "sl_sl": "Bihać",
      "sr_sp": "Bihać",
      "sr_sr": "Бихаћ",
      "sv_se": "Bihać",
      "tr_tr": "Bihaç",
      "uk_uk": "Бихач",
      "vi_vn": "Bihać",
      "zh_cn": "比哈奇",
      "zh_tw": "比哈奇"
    }
  },
  {
    "Name": "Split",
    "Country": "croatia",
    "X": 21267.3711,
    "Y": 39421.2539,
    "InGameId": "split",
    "LocalizationToken": "split",
    "CountryId": 19,
    "LocalizedNames": {
      "bg_bg": "Split",
      "ca_es": "Split",
      "cs_cz": "Split",
      "da_dk": "Split",
      "de_de": "Split",
      "el_gr": "Σπλιτ",
      "en_gb": "Split",
      "en_us": "Split",
      "es_es": "Split",
      "es_la": "Split",
      "et_ee": "Split",
      "eu_es": "Split",
      "fi_fi": "Split",
      "fr_ca": "Split",
      "fr_fr": "Split",
      "gl_es": "Split",
      "hr_hr": "Split",
      "hu_hu": "Split",
      "it_it": "Split",
      "ja_jp": "スプリト",
      "ka_ge": "სპლიტი",
      "ko_kr": "스플리트",
      "lt_lt": "Splitas",
      "lv_lv": "Split",
      "mk_mk": "Сплит",
      "nl_nl": "Split",
      "no_no": "Split",
      "pl_pl": "Split",
      "pl_si": "Split",
      "pt_br": "Split",
      "pt_pt": "Split",
      "ro_ro": "Split",
      "ru_ru": "Сплит",
      "sk_sk": "Split",
      "sl_sl": "Split",
      "sr_sp": "Split",
      "sr_sr": "Сплит",
      "sv_se": "Split",
      "tr_tr": "Split",
      "uk_uk": "Спліт",
      "vi_vn": "Split",
      "zh_cn": "斯普利特",
      "zh_tw": "史普利特"
    }
  },
  {
    "Name": "Бања Лука",
    "Country": "bosnia",
    "X": 25242.043,
    "Y": 32435.61,
    "InGameId": "banja_luka",
    "LocalizationToken": "banja_luka",
    "CountryId": 17,
    "LocalizedNames": {
      "bg_bg": "Banja Luka",
      "ca_es": "Banja Luka",
      "cs_cz": "Banja Luka",
      "da_dk": "Banja Luka",
      "de_de": "Banja Luka",
      "el_gr": "Μπάνια Λούκα",
      "en_gb": "Banja Luka",
      "en_us": "Banja Luka",
      "es_es": "Bania Luka",
      "es_la": "Bania Luka",
      "et_ee": "Banja Luka",
      "eu_es": "Banja Luka",
      "fi_fi": "Banja Luka",
      "fr_ca": "Banja Luka",
      "fr_fr": "Banja Luka",
      "gl_es": "Banja Luka",
      "hr_hr": "Banja Luka",
      "hu_hu": "Banja Luka",
      "it_it": "Banja Luka",
      "ja_jp": "バニャ・ルカ",
      "ka_ge": "ბანია-ლუკა",
      "ko_kr": "바냐루카",
      "lt_lt": "Banja Luka",
      "lv_lv": "Banja Luka",
      "mk_mk": "Бања Лука",
      "nl_nl": "Banja Luka",
      "no_no": "Banja Luka",
      "pl_pl": "Banja Luka",
      "pl_si": "Banja Luka",
      "pt_br": "Banja Luka",
      "pt_pt": "Banja Luka",
      "ro_ro": "Banja Luka",
      "ru_ru": "Баня-Лука",
      "sk_sk": "Banja Luka",
      "sl_sl": "Banja Luka",
      "sr_sp": "Banja Luka",
      "sr_sr": "Бања Лука",
      "sv_se": "Banja Luka",
      "tr_tr": "Banja Luka",
      "uk_uk": "Баня-Лука",
      "vi_vn": "Banja Luka",
      "zh_cn": "巴尼亚卢卡",
      "zh_tw": "巴尼亞盧卡"
    }
  },
  {
    "Name": "Сарајево",
    "Country": "bosnia",
    "X": 29927.2813,
    "Y": 37528.95,
    "InGameId": "sarajevo",
    "LocalizationToken": "sarajevo",
    "CountryId": 17,
    "LocalizedNames": {
      "bg_bg": "Sarajevo",
      "ca_es": "Sarajevo",
      "cs_cz": "Sarajevo",
      "da_dk": "Sarajevo",
      "de_de": "Sarajewo",
      "el_gr": "Σαράγεβο",
      "en_gb": "Sarajevo",
      "en_us": "Sarajevo",
      "es_es": "Sarajevo",
      "es_la": "Sarajevo",
      "et_ee": "Sarajevo",
      "eu_es": "Sarajevo",
      "fi_fi": "Sarajevo",
      "fr_ca": "Sarajevo",
      "fr_fr": "Sarajevo",
      "gl_es": "Sarajevo",
      "hr_hr": "Sarajevo",
      "hu_hu": "Szarajevó",
      "it_it": "Sarajevo",
      "ja_jp": "サラエヴォ",
      "ka_ge": "სარაევო",
      "ko_kr": "사라예보",
      "lt_lt": "Sarajevas",
      "lv_lv": "Sarajevo",
      "mk_mk": "Сараево",
      "nl_nl": "Sarajevo",
      "no_no": "Sarajevo",
      "pl_pl": "Sarajewo",
      "pl_si": "Sarajewo",
      "pt_br": "Sarajevo",
      "pt_pt": "Sarajevo",
      "ro_ro": "Sarajevo",
      "ru_ru": "Сараево",
      "sk_sk": "Sarajevo",
      "sl_sl": "Sarajevo",
      "sr_sp": "Sarajevo",
      "sr_sr": "Сарајево",
      "sv_se": "Sarajevo",
      "tr_tr": "Saraybosna",
      "uk_uk": "Сараєво",
      "vi_vn": "Sarajevo",
      "zh_cn": "萨拉热窝",
      "zh_tw": "塞拉耶佛"
    }
  },
  {
    "Name": "Зеница",
    "Country": "bosnia",
    "X": 28225.2344,
    "Y": 35576.4023,
    "InGameId": "zenica",
    "LocalizationToken": "zenica",
    "CountryId": 17,
    "LocalizedNames": {
      "bg_bg": "Zenica",
      "ca_es": "Zenica",
      "cs_cz": "Zenica",
      "da_dk": "Zenica",
      "de_de": "Zenica",
      "el_gr": "Ζένιτσα",
      "en_gb": "Zenica",
      "en_us": "Zenica",
      "es_es": "Zenica",
      "es_la": "Zenica",
      "et_ee": "Zenica",
      "eu_es": "Zenica",
      "fi_fi": "Zenica",
      "fr_ca": "Zenica",
      "fr_fr": "Zenica",
      "gl_es": "Zenica",
      "hr_hr": "Zenica",
      "hu_hu": "Zenica",
      "it_it": "Zenica",
      "ja_jp": "ゼニツァ",
      "ka_ge": "ზენიცა",
      "ko_kr": "제니차",
      "lt_lt": "Zenica",
      "lv_lv": "Zenica",
      "mk_mk": "Зеница",
      "nl_nl": "Zenica",
      "no_no": "Zenica",
      "pl_pl": "Zenica",
      "pl_si": "Zenica",
      "pt_br": "Zenica",
      "pt_pt": "Zenica",
      "ro_ro": "Zenica",
      "ru_ru": "Зеница",
      "sk_sk": "Zenica",
      "sl_sl": "Zenica",
      "sr_sp": "Zenica",
      "sr_sr": "Зеница",
      "sv_se": "Zenica",
      "tr_tr": "Zeniça",
      "uk_uk": "Зениця",
      "vi_vn": "Zenica",
      "zh_cn": "泽尼察",
      "zh_tw": "澤尼察"
    }
  },
  {
    "Name": "Мостар",
    "Country": "bosnia",
    "X": 27775.5156,
    "Y": 40503.6367,
    "InGameId": "mostar",
    "LocalizationToken": "mostar",
    "CountryId": 17,
    "LocalizedNames": {
      "bg_bg": "Mostar",
      "ca_es": "Mostar",
      "cs_cz": "Mostar",
      "da_dk": "Mostar",
      "de_de": "Mostar",
      "el_gr": "Μόσταρ",
      "en_gb": "Mostar",
      "en_us": "Mostar",
      "es_es": "Mostar",
      "es_la": "Mostar",
      "et_ee": "Mostar",
      "eu_es": "Mostar",
      "fi_fi": "Mostar",
      "fr_ca": "Mostar",
      "fr_fr": "Mostar",
      "gl_es": "Mostar",
      "hr_hr": "Mostar",
      "hu_hu": "Mostar",
      "it_it": "Mostar",
      "ja_jp": "モスタル",
      "ka_ge": "მოსტარი",
      "ko_kr": "모스타르",
      "lt_lt": "Mostaras",
      "lv_lv": "Mostar",
      "mk_mk": "Мостар",
      "nl_nl": "Mostar",
      "no_no": "Mostar",
      "pl_pl": "Mostar",
      "pl_si": "Mostar",
      "pt_br": "Mostar",
      "pt_pt": "Mostar",
      "ro_ro": "Mostar",
      "ru_ru": "Мостар",
      "sk_sk": "Mostar",
      "sl_sl": "Mostar",
      "sr_sp": "Mostar",
      "sr_sr": "Мостар",
      "sv_se": "Mostar",
      "tr_tr": "Mostar",
      "uk_uk": "Мостар",
      "vi_vn": "Mostar",
      "zh_cn": "莫斯塔尔",
      "zh_tw": "莫斯塔爾"
    }
  },
  {
    "Name": "Тузла",
    "Country": "bosnia",
    "X": 31410.6367,
    "Y": 33603.3633,
    "InGameId": "tuzla",
    "LocalizationToken": "tuzla",
    "CountryId": 17,
    "LocalizedNames": {
      "bg_bg": "Tuzla",
      "ca_es": "Tuzla",
      "cs_cz": "Tuzla",
      "da_dk": "Tuzla",
      "de_de": "Tuzla",
      "el_gr": "Τούζλα",
      "en_gb": "Tuzla",
      "en_us": "Tuzla",
      "es_es": "Tuzla",
      "es_la": "Tuzla",
      "et_ee": "Tuzla",
      "eu_es": "Tuzla",
      "fi_fi": "Tuzla",
      "fr_ca": "Tuzla",
      "fr_fr": "Tuzla",
      "gl_es": "Tuzla",
      "hr_hr": "Tuzla",
      "hu_hu": "Tuzla",
      "it_it": "Tuzla",
      "ja_jp": "トゥズラ",
      "ka_ge": "ტუზლა",
      "ko_kr": "투즐라",
      "lt_lt": "Tuzla",
      "lv_lv": "Tuzla",
      "mk_mk": "Тузла",
      "nl_nl": "Tuzla",
      "no_no": "Tuzla",
      "pl_pl": "Tuzla",
      "pl_si": "Tuzla",
      "pt_br": "Tuzla",
      "pt_pt": "Tuzla",
      "ro_ro": "Tuzla",
      "ru_ru": "Тузла",
      "sk_sk": "Tuzla",
      "sl_sl": "Tuzla",
      "sr_sp": "Tuzla",
      "sr_sr": "Тузла",
      "sv_se": "Tuzla",
      "tr_tr": "Tuzla",
      "uk_uk": "Тузла",
      "vi_vn": "Tuzla",
      "zh_cn": "图兹拉",
      "zh_tw": "土茲拉"
    }
  },
  {
    "Name": "Каракај",
    "Country": "bosnia",
    "X": 33799.2656,
    "Y": 33203.37,
    "InGameId": "karakaj",
    "LocalizationToken": "karakaj",
    "CountryId": 17,
    "LocalizedNames": {
      "bg_bg": "Karakaj",
      "ca_es": "Karakaj",
      "cs_cz": "Karakaj",
      "da_dk": "Karakaj",
      "de_de": "Karakaj",
      "el_gr": "Καρακάι",
      "en_gb": "Karakaj",
      "en_us": "Karakaj",
      "es_es": "Karakaj",
      "es_la": "Karakaj",
      "et_ee": "Karakaj",
      "eu_es": "Karakaj",
      "fi_fi": "Karakaj",
      "fr_ca": "Karakaj",
      "fr_fr": "Karakaj",
      "gl_es": "Karakaj",
      "hr_hr": "Karakaj",
      "hu_hu": "Karakaj",
      "it_it": "Karakaj",
      "ja_jp": "カラカジ",
      "ka_ge": "კარაკაი",
      "ko_kr": "카라카이",
      "lt_lt": "Karakaja",
      "lv_lv": "Karakaj",
      "mk_mk": "Каракај",
      "nl_nl": "Karakaj",
      "no_no": "Karakaj",
      "pl_pl": "Karakaj",
      "pl_si": "Karakaj",
      "pt_br": "Karakaj",
      "pt_pt": "Karakaj",
      "ro_ro": "Karakaj",
      "ru_ru": "Каракай",
      "sk_sk": "Karakaj",
      "sl_sl": "Karakaj",
      "sr_sp": "Karakaj",
      "sr_sr": "Каракај",
      "sv_se": "Karakaj",
      "tr_tr": "Karakaş",
      "uk_uk": "Каракай",
      "vi_vn": "Karakaj",
      "zh_cn": "卡拉卡伊",
      "zh_tw": "卡拉卡伊"
    }
  },
  {
    "Name": "Никшић",
    "Country": "montenegro",
    "X": 32904.23,
    "Y": 42799.1953,
    "InGameId": "niksic",
    "LocalizationToken": "niksic",
    "CountryId": 34,
    "LocalizedNames": {
      "bg_bg": "Nikšić",
      "ca_es": "Nikšić",
      "cs_cz": "Nikšić",
      "da_dk": "Nikšić",
      "de_de": "Nikšić",
      "el_gr": "Νίκσικ",
      "en_gb": "Nikšić",
      "en_us": "Nikšić",
      "es_es": "Nikšić",
      "es_la": "Nikšić",
      "et_ee": "Nikšić",
      "eu_es": "Nikšić",
      "fi_fi": "Nikšić",
      "fr_ca": "Nikšić",
      "fr_fr": "Nikšić",
      "gl_es": "Nikšić",
      "hr_hr": "Nikšić",
      "hu_hu": "Nikšić",
      "it_it": "Nikšić",
      "ja_jp": "ニクシッチ",
      "ka_ge": "ნიკშიცი",
      "ko_kr": "닉시치",
      "lt_lt": "Nikšičas",
      "lv_lv": "Nikšić",
      "mk_mk": "Никшиќ",
      "nl_nl": "Nikšić",
      "no_no": "Nikšić",
      "pl_pl": "Niksic",
      "pl_si": "Niksic",
      "pt_br": "Nikšić",
      "pt_pt": "Nikšić",
      "ro_ro": "Nikšić",
      "ru_ru": "Никшич",
      "sk_sk": "Nikšić",
      "sl_sl": "Nikšić",
      "sr_sp": "Nikšić",
      "sr_sr": "Никшић",
      "sv_se": "Nikšić",
      "tr_tr": "Nikşiç",
      "uk_uk": "Никшич",
      "vi_vn": "Nikšić",
      "zh_cn": "尼克希奇",
      "zh_tw": "尼克希奇"
    }
  },
  {
    "Name": "Подгорица",
    "Country": "montenegro",
    "X": 33036.5664,
    "Y": 44220.69,
    "InGameId": "podgorica",
    "LocalizationToken": "podgorica",
    "CountryId": 34,
    "LocalizedNames": {
      "bg_bg": "Podgorica",
      "ca_es": "Podgorica",
      "cs_cz": "Podgorica",
      "da_dk": "Podgorica",
      "de_de": "Podgorica",
      "el_gr": "Ποντγκόριτσα",
      "en_gb": "Podgorica",
      "en_us": "Podgorica",
      "es_es": "Podgorica",
      "es_la": "Podgorica",
      "et_ee": "Podgorica",
      "eu_es": "Podgorica",
      "fi_fi": "Podgorica",
      "fr_ca": "Podgorica",
      "fr_fr": "Podgorica",
      "gl_es": "Podgorica",
      "hr_hr": "Podgorica",
      "hu_hu": "Podgorica",
      "it_it": "Podgorica",
      "ja_jp": "ポドゴリツァ",
      "ka_ge": "პოდგორიცა",
      "ko_kr": "포드고리차",
      "lt_lt": "Podgorica",
      "lv_lv": "Podgorica",
      "mk_mk": "Подгорица",
      "nl_nl": "Podgorica",
      "no_no": "Podgorica",
      "pl_pl": "Podgorica",
      "pl_si": "Podgorica",
      "pt_br": "Podgorica",
      "pt_pt": "Podgorica",
      "ro_ro": "Podgoriţa",
      "ru_ru": "Подгорица",
      "sk_sk": "Podgorica",
      "sl_sl": "Podgorica",
      "sr_sp": "Podgorica",
      "sr_sr": "Подгорица",
      "sv_se": "Podgorica",
      "tr_tr": "Podgoritsa",
      "uk_uk": "Подгориця",
      "vi_vn": "Podgorica",
      "zh_cn": "波德戈里察",
      "zh_tw": "波德里查"
    }
  },
  {
    "Name": "Нови Сад",
    "Country": "serbia",
    "X": 35308.9,
    "Y": 29357.498,
    "InGameId": "novi_sad",
    "LocalizationToken": "novi_sad",
    "CountryId": 40,
    "LocalizedNames": {
      "bg_bg": "Novi Sad",
      "ca_es": "Novi Sad",
      "cs_cz": "Novi Sad",
      "da_dk": "Novi Sad",
      "de_de": "Neusatz",
      "el_gr": "Νόβι Σάντ",
      "en_gb": "Novi Sad",
      "en_us": "Novi Sad",
      "es_es": "Novi Sad",
      "es_la": "Novi Sad",
      "et_ee": "Novi Sad",
      "eu_es": "Novi Sad",
      "fi_fi": "Novi Sad",
      "fr_ca": "Novi Sad",
      "fr_fr": "Novi Sad",
      "gl_es": "Novi Sad",
      "hr_hr": "Novi Sad",
      "hu_hu": "Újvidék",
      "it_it": "Novi Sad",
      "ja_jp": "ノヴィ・サド",
      "ka_ge": "ნოვი-სადი",
      "ko_kr": "노비사드",
      "lt_lt": "Novi Sadas",
      "lv_lv": "Novi Sad",
      "mk_mk": "Нови Сад",
      "nl_nl": "Novi Sad",
      "no_no": "Novi Sad",
      "pl_pl": "Nowy Sad",
      "pl_si": "Nowy Sad",
      "pt_br": "Novi Sad",
      "pt_pt": "Novi Sad",
      "ro_ro": "Novi Sad",
      "ru_ru": "Нови-Сад",
      "sk_sk": "Nový Sad",
      "sl_sl": "Novi Sad",
      "sr_sp": "Novi Sad",
      "sr_sr": "Нови Сад",
      "sv_se": "Novi Sad",
      "tr_tr": "Novi Sad",
      "uk_uk": "Нови Сад",
      "vi_vn": "Novi Sad",
      "zh_cn": "诺维萨德",
      "zh_tw": "諾維薩德"
    }
  },
  {
    "Name": "Београд",
    "Country": "serbia",
    "X": 38497.7461,
    "Y": 31433.1973,
    "InGameId": "beograd",
    "LocalizationToken": "beograd",
    "CountryId": 40,
    "LocalizedNames": {
      "bg_bg": "Belgrade",
      "ca_es": "Belgrad",
      "cs_cz": "Bělehrad",
      "da_dk": "Beograd",
      "de_de": "Belgrad",
      "el_gr": "Βελιγράδι",
      "en_gb": "Belgrade",
      "en_us": "Belgrade",
      "es_es": "Belgrado",
      "es_la": "Belgrado",
      "et_ee": "Belgrade",
      "eu_es": "Belgrado",
      "fi_fi": "Belgrade",
      "fr_ca": "Belgrade",
      "fr_fr": "Belgrade",
      "gl_es": "Belgrade",
      "hr_hr": "Beograd",
      "hu_hu": "Belgrád",
      "it_it": "Belgrado",
      "ja_jp": "ベオグラード",
      "ka_ge": "ბელგრადი",
      "ko_kr": "베오그라드",
      "lt_lt": "Belgradas",
      "lv_lv": "Belgrade",
      "mk_mk": "Белград",
      "nl_nl": "Belgrado",
      "no_no": "Beograd",
      "pl_pl": "Belgrad",
      "pl_si": "Belgrad",
      "pt_br": "Belgrado",
      "pt_pt": "Belgrado",
      "ro_ro": "Belgrad",
      "ru_ru": "Белград",
      "sk_sk": "Belehrad",
      "sl_sl": "Beograd",
      "sr_sp": "Beograd",
      "sr_sr": "Београд",
      "sv_se": "Belgrade",
      "tr_tr": "Belgrad",
      "uk_uk": "Белград",
      "vi_vn": "Belgrade",
      "zh_cn": "贝尔格莱德",
      "zh_tw": "貝爾格勒"
    }
  },
  {
    "Name": "Бијело Поље",
    "Country": "montenegro",
    "X": 36073.5859,
    "Y": 41721.8477,
    "InGameId": "bijelo_polje",
    "LocalizationToken": "bijelo_polje",
    "CountryId": 34,
    "LocalizedNames": {
      "bg_bg": "Bijelo Polje",
      "ca_es": "Bijelo Polje",
      "cs_cz": "Bijelo Polje",
      "da_dk": "Bijelo Polje",
      "de_de": "Bijelo Polje",
      "el_gr": "Μπιέλο Πόλιε",
      "en_gb": "Bijelo Polje",
      "en_us": "Bijelo Polje",
      "es_es": "Bijelo Polje",
      "es_la": "Bijelo Polje",
      "et_ee": "Bijelo Polje",
      "eu_es": "Bijelo Polje",
      "fi_fi": "Bijelo Polje",
      "fr_ca": "Bijelo Polje",
      "fr_fr": "Bijelo Polje",
      "gl_es": "Bijelo Polje",
      "hr_hr": "Bijelo Polje",
      "hu_hu": "Bijelo Polje",
      "it_it": "Bijelo Polje",
      "ja_jp": "ビイェロ・ポリェ",
      "ka_ge": "ბიელო-პოლე",
      "ko_kr": "비옐로폴레",
      "lt_lt": "Bijelo Polje",
      "lv_lv": "Bijelo Polje",
      "mk_mk": "Бијело Поље",
      "nl_nl": "Bijelo Polje",
      "no_no": "Bijelo Polje",
      "pl_pl": "Bijelo Polje",
      "pl_si": "Bijelo Polje",
      "pt_br": "Bijelo Polje",
      "pt_pt": "Bijelo Polje",
      "ro_ro": "Bijelo Polje",
      "ru_ru": "Биело-Поле",
      "sk_sk": "Bijelo Polje",
      "sl_sl": "Belo Polje",
      "sr_sp": "Bijelo Polje",
      "sr_sr": "Бијело Поље",
      "sv_se": "Bijelo Polje",
      "tr_tr": "Akova",
      "uk_uk": "Бієло-Полє",
      "vi_vn": "Bijelo Polje",
      "zh_cn": "比耶洛波列",
      "zh_tw": "比耶洛波列"
    }
  },
  {
    "Name": "Tirana",
    "Country": "albania",
    "X": 37720.4063,
    "Y": 50471.625,
    "InGameId": "tirana",
    "LocalizationToken": "tirana",
    "CountryId": 14,
    "LocalizedNames": {
      "bg_bg": "Tirana",
      "ca_es": "Tirana",
      "cs_cz": "Tirana",
      "da_dk": "Tirana",
      "de_de": "Tirana",
      "el_gr": "Τίρανα",
      "en_gb": "Tirana",
      "en_us": "Tirana",
      "es_es": "Tirana",
      "es_la": "Tirana",
      "et_ee": "Tirana",
      "eu_es": "Tirana",
      "fi_fi": "Tirana",
      "fr_ca": "Tirana",
      "fr_fr": "Tirana",
      "gl_es": "Tirana",
      "hr_hr": "Tirana",
      "hu_hu": "Tirana",
      "it_it": "Tirana",
      "ja_jp": "ティラナ",
      "ka_ge": "ტირანა",
      "ko_kr": "티라나",
      "lt_lt": "Tirana",
      "lv_lv": "Tirana",
      "mk_mk": "Тирана",
      "nl_nl": "Tirana",
      "no_no": "Tirana",
      "pl_pl": "Tirana",
      "pl_si": "Tirana",
      "pt_br": "Tirana",
      "pt_pt": "Tirana",
      "ro_ro": "Tirana",
      "ru_ru": "Тирана",
      "sk_sk": "Tirana",
      "sl_sl": "Tirana",
      "sr_sp": "Tirana",
      "sr_sr": "Тирана",
      "sv_se": "Tirana",
      "tr_tr": "Tiran",
      "uk_uk": "Тирана",
      "vi_vn": "Tirana",
      "zh_cn": "地拉那",
      "zh_tw": "地拉那"
    }
  },
  {
    "Name": "Durrës",
    "Country": "albania",
    "X": 35460.5625,
    "Y": 51403.8164,
    "InGameId": "durres",
    "LocalizationToken": "durres",
    "CountryId": 14,
    "LocalizedNames": {
      "bg_bg": "Durrës",
      "ca_es": "Durrës",
      "cs_cz": "Drač",
      "da_dk": "Durrës",
      "de_de": "Durrës",
      "el_gr": "Δυρράχιο",
      "en_gb": "Durrës",
      "en_us": "Durrës",
      "es_es": "Durrës",
      "es_la": "Durrës",
      "et_ee": "Durrës",
      "eu_es": "Durrës",
      "fi_fi": "Durrës",
      "fr_ca": "Durrës",
      "fr_fr": "Durrës",
      "gl_es": "Durrës",
      "hr_hr": "Durrës",
      "hu_hu": "Durrës",
      "it_it": "Durrës",
      "ja_jp": "ドゥラス",
      "ka_ge": "დურესი",
      "ko_kr": "두러스",
      "lt_lt": "Duras",
      "lv_lv": "Durrës",
      "mk_mk": "Драч",
      "nl_nl": "Durrës",
      "no_no": "Durrës",
      "pl_pl": "Durres",
      "pl_si": "Durres",
      "pt_br": "Durrës",
      "pt_pt": "Durrës",
      "ro_ro": "Durrës",
      "ru_ru": "Дуррес",
      "sk_sk": "Drač",
      "sl_sl": "Durrës",
      "sr_sp": "Drač",
      "sr_sr": "Драч",
      "sv_se": "Durrës",
      "tr_tr": "Dıraç",
      "uk_uk": "Дуррес",
      "vi_vn": "Durrës",
      "zh_cn": "都拉斯",
      "zh_tw": "杜勒斯"
    }
  },
  {
    "Name": "Fier",
    "Country": "albania",
    "X": 37012.01,
    "Y": 53891.4453,
    "InGameId": "fier",
    "LocalizationToken": "fier",
    "CountryId": 14,
    "LocalizedNames": {
      "bg_bg": "Fier",
      "ca_es": "Fier",
      "cs_cz": "Fier",
      "da_dk": "Fier",
      "de_de": "Fier",
      "el_gr": "Φιέρι",
      "en_gb": "Fier",
      "en_us": "Fier",
      "es_es": "Fier",
      "es_la": "Fier",
      "et_ee": "Fier",
      "eu_es": "Fier",
      "fi_fi": "Fier",
      "fr_ca": "Fier",
      "fr_fr": "Fier",
      "gl_es": "Fier",
      "hr_hr": "Fier",
      "hu_hu": "Fier",
      "it_it": "Fier",
      "ja_jp": "フィエル",
      "ka_ge": "ფიერი",
      "ko_kr": "피어르",
      "lt_lt": "Fiera",
      "lv_lv": "Fier",
      "mk_mk": "Фиер",
      "nl_nl": "Fier",
      "no_no": "Fier",
      "pl_pl": "Fier",
      "pl_si": "Fier",
      "pt_br": "Fier",
      "pt_pt": "Fier",
      "ro_ro": "Fier",
      "ru_ru": "Фиери",
      "sk_sk": "Fier",
      "sl_sl": "Fier",
      "sr_sp": "Fjer",
      "sr_sr": "Фјер",
      "sv_se": "Fier",
      "tr_tr": "Fier",
      "uk_uk": "Фієрі",
      "vi_vn": "Fier",
      "zh_cn": "费里",
      "zh_tw": "非夏爾州"
    }
  },
  {
    "Name": "Vlorë",
    "Country": "albania",
    "X": 35951.03,
    "Y": 56421.03,
    "InGameId": "vlore",
    "LocalizationToken": "vlore",
    "CountryId": 14,
    "LocalizedNames": {
      "bg_bg": "Vlorë",
      "ca_es": "Vlorë",
      "cs_cz": "Vlora",
      "da_dk": "Vlorë",
      "de_de": "Vlorë",
      "el_gr": "Αυλώνας",
      "en_gb": "Vlorë",
      "en_us": "Vlorë",
      "es_es": "Vlorë",
      "es_la": "Vlorë",
      "et_ee": "Vlorë",
      "eu_es": "Vlorë",
      "fi_fi": "Vlorë",
      "fr_ca": "Vlorë",
      "fr_fr": "Vlorë",
      "gl_es": "Vlorë",
      "hr_hr": "Vlora",
      "hu_hu": "Vlorë",
      "it_it": "Vlorë",
      "ja_jp": "ヴロラ",
      "ka_ge": "ვლორა",
      "ko_kr": "블로러",
      "lt_lt": "Vlorė",
      "lv_lv": "Vlorë",
      "mk_mk": "Валона",
      "nl_nl": "Vlorë",
      "no_no": "Vlorë",
      "pl_pl": "Wlora",
      "pl_si": "Wlora",
      "pt_br": "Vlorë",
      "pt_pt": "Vlorë",
      "ro_ro": "Vlorë",
      "ru_ru": "Влёра",
      "sk_sk": "Vlorë",
      "sl_sl": "Vlora",
      "sr_sp": "Valona",
      "sr_sr": "Валона",
      "sv_se": "Vlorë",
      "tr_tr": "Avlonya",
      "uk_uk": "Вльора",
      "vi_vn": "Vlorë",
      "zh_cn": "发罗拉",
      "zh_tw": "夫羅勒"
    }
  },
  {
    "Name": "Крагујевац",
    "Country": "serbia",
    "X": 39881.0273,
    "Y": 36012.5078,
    "InGameId": "kragujevac",
    "LocalizationToken": "kragujevac",
    "CountryId": 40,
    "LocalizedNames": {
      "bg_bg": "Kragujevac",
      "ca_es": "Kragujevac",
      "cs_cz": "Kragujevac",
      "da_dk": "Kragujevac",
      "de_de": "Kragujevac",
      "el_gr": "Κραγούγιεβατς",
      "en_gb": "Kragujevac",
      "en_us": "Kragujevac",
      "es_es": "Kragujevac",
      "es_la": "Kragujevac",
      "et_ee": "Kragujevac",
      "eu_es": "Kragujevac",
      "fi_fi": "Kragujevac",
      "fr_ca": "Kragujevac",
      "fr_fr": "Kragujevac",
      "gl_es": "Kragujevac",
      "hr_hr": "Kragujevac",
      "hu_hu": "Kragujevac",
      "it_it": "Kragujevac",
      "ja_jp": "クラグイェヴァツ",
      "ka_ge": "კრაგუევაცი",
      "ko_kr": "크라구예바츠",
      "lt_lt": "Kragujevacas",
      "lv_lv": "Kragujevac",
      "mk_mk": "Крагуевац",
      "nl_nl": "Kragujevac",
      "no_no": "Kragujevac",
      "pl_pl": "Kragujevac",
      "pl_si": "Kragujevac",
      "pt_br": "Kragujevac",
      "pt_pt": "Kragujevac",
      "ro_ro": "Kragujevac",
      "ru_ru": "Крагуевац",
      "sk_sk": "Kragujevac",
      "sl_sl": "Kragujevac",
      "sr_sp": "Kragujevac",
      "sr_sr": "Крагујевац",
      "sv_se": "Kragujevac",
      "tr_tr": "Karacofça",
      "uk_uk": "Крагуєваць",
      "vi_vn": "Kragujevac",
      "zh_cn": "克拉古耶瓦茨",
      "zh_tw": "克拉古耶瓦茨"
    }
  },
  {
    "Name": "Prishtinë",
    "Country": "kosovo",
    "X": 41761.44,
    "Y": 42808.79,
    "InGameId": "pristina",
    "LocalizationToken": "pristina",
    "CountryId": 204,
    "LocalizedNames": {
      "bg_bg": "Pristina",
      "ca_es": "Pristina",
      "cs_cz": "Priština",
      "da_dk": "Pristina",
      "de_de": "Pristina",
      "el_gr": "Πρίστινα",
      "en_gb": "Pristina",
      "en_us": "Pristina",
      "es_es": "Pristina",
      "es_la": "Pristina",
      "et_ee": "Pristina",
      "eu_es": "Pristina",
      "fi_fi": "Pristina",
      "fr_ca": "Pristina",
      "fr_fr": "Pristina",
      "gl_es": "Pristina",
      "hr_hr": "Priština",
      "hu_hu": "Pristina",
      "it_it": "Pristina",
      "ja_jp": "プリシュティナ",
      "ka_ge": "პრიშტინა",
      "ko_kr": "프리슈티나",
      "lt_lt": "Priština",
      "lv_lv": "Pristina",
      "mk_mk": "Приштина",
      "nl_nl": "Pristina",
      "no_no": "Pristina",
      "pl_pl": "Prisztina",
      "pl_si": "Prisztina",
      "pt_br": "Pristina",
      "pt_pt": "Pristina",
      "ro_ro": "Priştina",
      "ru_ru": "Приштина",
      "sk_sk": "Priština",
      "sl_sl": "Priština",
      "sr_sp": "Priština",
      "sr_sr": "Приштина",
      "sv_se": "Pristina",
      "tr_tr": "Priştine",
      "uk_uk": "Приштина",
      "vi_vn": "Pristina",
      "zh_cn": "普里什蒂纳",
      "zh_tw": "普里斯提納"
    }
  },
  {
    "Name": "Скопје",
    "Country": "macedonia",
    "X": 42714.0352,
    "Y": 47345.2031,
    "InGameId": "skopje",
    "LocalizationToken": "skopje",
    "CountryId": 203,
    "LocalizedNames": {
      "bg_bg": "Skopje",
      "ca_es": "Skopje",
      "cs_cz": "Skopje",
      "da_dk": "Skopje",
      "de_de": "Skopje",
      "el_gr": "Σκόπια",
      "en_gb": "Skopje",
      "en_us": "Skopje",
      "es_es": "Skopje",
      "es_la": "Skopje",
      "et_ee": "Skopje",
      "eu_es": "Skopje",
      "fi_fi": "Skopje",
      "fr_ca": "Skopje",
      "fr_fr": "Skopje",
      "gl_es": "Skopje",
      "hr_hr": "Skopje",
      "hu_hu": "Szkopje",
      "it_it": "Skopje",
      "ja_jp": "スコピエ",
      "ka_ge": "სკოპიე",
      "ko_kr": "스코페",
      "lt_lt": "Skopjė",
      "lv_lv": "Skopje",
      "mk_mk": "Скопје",
      "nl_nl": "Skopje",
      "no_no": "Skopje",
      "pl_pl": "Skopje",
      "pl_si": "Skopje",
      "pt_br": "Skopje",
      "pt_pt": "Skopje",
      "ro_ro": "Skopje",
      "ru_ru": "Скопье",
      "sk_sk": "Skopje",
      "sl_sl": "Skopje",
      "sr_sp": "Skoplje",
      "sr_sr": "Скопље",
      "sv_se": "Skopje",
      "tr_tr": "Üsküp",
      "uk_uk": "Скоп'є",
      "vi_vn": "Skopje",
      "zh_cn": "斯科普里",
      "zh_tw": "史高比耶"
    }
  },
  {
    "Name": "Битола",
    "Country": "macedonia",
    "X": 44483.2461,
    "Y": 52420.3828,
    "InGameId": "bitola",
    "LocalizationToken": "bitola",
    "CountryId": 203,
    "LocalizedNames": {
      "bg_bg": "Bitola",
      "ca_es": "Bitola",
      "cs_cz": "Bitola",
      "da_dk": "Bitola",
      "de_de": "Bitola",
      "el_gr": "Μπίτολα",
      "en_gb": "Bitola",
      "en_us": "Bitola",
      "es_es": "Bitola",
      "es_la": "Bitola",
      "et_ee": "Bitola",
      "eu_es": "Bitola",
      "fi_fi": "Bitola",
      "fr_ca": "Bitola",
      "fr_fr": "Bitola",
      "gl_es": "Bitola",
      "hr_hr": "Bitola",
      "hu_hu": "Bitola",
      "it_it": "Bitola",
      "ja_jp": "ビトラ",
      "ka_ge": "ბიტოლა",
      "ko_kr": "비톨라",
      "lt_lt": "Bitola",
      "lv_lv": "Bitola",
      "mk_mk": "Битола",
      "nl_nl": "Bitola",
      "no_no": "Bitola",
      "pl_pl": "Bitola",
      "pl_si": "Bitola",
      "pt_br": "Bitola",
      "pt_pt": "Bitola",
      "ro_ro": "Bitola",
      "ru_ru": "Битола",
      "sk_sk": "Bitola",
      "sl_sl": "Bitola",
      "sr_sp": "Bitola",
      "sr_sr": "Битољ",
      "sv_se": "Bitola",
      "tr_tr": "Manastır",
      "uk_uk": "Бітола",
      "vi_vn": "Bitola",
      "zh_cn": "比托拉",
      "zh_tw": "比托拉"
    }
  },
  {
    "Name": "Калининград",
    "Country": "russia",
    "X": 34315.05,
    "Y": -23251.5039,
    "InGameId": "kaliningrad",
    "LocalizationToken": "kaliningrad",
    "CountryId": 38,
    "LocalizedNames": {
      "bg_bg": "Калининград",
      "ca_es": "Kaliningrad",
      "cs_cz": "Kaliningrad",
      "da_dk": "Kaliningrad",
      "de_de": "Kaliningrad",
      "el_gr": "Καλίνινγκραντ",
      "en_gb": "Kaliningrad",
      "en_us": "Kaliningrad",
      "es_es": "Kaliningrado",
      "es_la": "Kaliningrado",
      "et_ee": "Kaliningrad",
      "eu_es": "Kaliningrad",
      "fi_fi": "Kaliningrad",
      "fr_ca": "Kaliningrad",
      "fr_fr": "Kaliningrad",
      "gl_es": "Kaliningrado",
      "hr_hr": "Kalinjingrad",
      "hu_hu": "Kalinyingrád",
      "it_it": "Kaliningrad",
      "ja_jp": "カリーニングラード",
      "ka_ge": "კალინინგრადი",
      "ko_kr": "칼리닌그라드",
      "lt_lt": "Karaliaučius",
      "lv_lv": "Kaļiņingrada",
      "mk_mk": "Калининград",
      "nl_nl": "Kaliningrad",
      "no_no": "Kaliningrad",
      "pl_pl": "Królewiec",
      "pl_si": "Królewiec",
      "pt_br": "Kaliningrado",
      "pt_pt": "Kaliningrad",
      "ro_ro": "Kaliningrad",
      "ru_ru": "Калининград",
      "sk_sk": "Kaliningrad",
      "sl_sl": "Kaliningrad",
      "sr_sp": "Kalinjingrad",
      "sr_sr": "Калињинград",
      "sv_se": "Kaliningrad",
      "tr_tr": "Kaliningrad",
      "uk_uk": "Калінінград",
      "vi_vn": "Kaliningrad",
      "zh_cn": "加里宁格勒",
      "zh_tw": "加里寧格勒"
    }
  },
  {
    "Name": "Liepāja",
    "Country": "latvia",
    "X": 34204.3672,
    "Y": -34145.0625,
    "InGameId": "liepaja",
    "LocalizationToken": "liepaja",
    "CountryId": 28,
    "LocalizedNames": {
      "bg_bg": "Лиепая",
      "ca_es": "Liepāja",
      "cs_cz": "Liepāja",
      "da_dk": "Liepāja",
      "de_de": "Libau",
      "el_gr": "Λιέπαγια",
      "en_gb": "Liepāja",
      "en_us": "Liepāja",
      "es_es": "Liepāja",
      "es_la": "Liepāja",
      "et_ee": "Liepāja",
      "eu_es": "Liepāja",
      "fi_fi": "Liepāja",
      "fr_ca": "Liepāja",
      "fr_fr": "Liepāja",
      "gl_es": "Liepāja",
      "hr_hr": "Liepāja",
      "hu_hu": "Liepāja",
      "it_it": "Liepāja",
      "ja_jp": "リエパーヤ",
      "ka_ge": "ლიეპაია",
      "ko_kr": "리예파야",
      "lt_lt": "Liepoja",
      "lv_lv": "Liepāja",
      "mk_mk": "Лиепаја",
      "nl_nl": "Liepāja",
      "no_no": "Liepāja",
      "pl_pl": "Lipawa",
      "pl_si": "Lipawa",
      "pt_br": "Liepāja",
      "pt_pt": "Liepāja",
      "ro_ro": "Liepāja",
      "ru_ru": "Лиепая",
      "sk_sk": "Liepāja",
      "sl_sl": "Liepāja",
      "sr_sp": "Lijepaja",
      "sr_sr": "Лијепаја",
      "sv_se": "Liepāja",
      "tr_tr": "Libau",
      "uk_uk": "Лієпая",
      "vi_vn": "Liepāja",
      "zh_cn": "利耶帕亚",
      "zh_tw": "利耶帕亞"
    }
  },
  {
    "Name": "Naantali",
    "Country": "finland",
    "X": 34710.07,
    "Y": -56782.5234,
    "InGameId": "naantali",
    "LocalizationToken": "naantali",
    "CountryId": 23,
    "LocalizedNames": {
      "bg_bg": "Нантали",
      "ca_es": "Naantali",
      "cs_cz": "Naantali",
      "da_dk": "Naantali",
      "de_de": "Naantali",
      "el_gr": "Νάνταλι",
      "en_gb": "Naantali",
      "en_us": "Naantali",
      "es_es": "Naantali",
      "es_la": "Naantali",
      "et_ee": "Naantali",
      "eu_es": "Naantali",
      "fi_fi": "Naantali",
      "fr_ca": "Naantali",
      "fr_fr": "Naantali",
      "gl_es": "Naantali",
      "hr_hr": "Naantali",
      "hu_hu": "Naantali",
      "it_it": "Naantali",
      "ja_jp": "ナーンタリ",
      "ka_ge": "ნაანტალი",
      "ko_kr": "난탈리",
      "lt_lt": "Nantalis",
      "lv_lv": "Nāntali",
      "mk_mk": "Наантали",
      "nl_nl": "Naantali",
      "no_no": "Nådendal",
      "pl_pl": "Naantali",
      "pl_si": "Naantali",
      "pt_br": "Naantali",
      "pt_pt": "Naantali",
      "ro_ro": "Naantali",
      "ru_ru": "Наантали",
      "sk_sk": "Naantali",
      "sl_sl": "Naantali",
      "sr_sp": "Nantali",
      "sr_sr": "Нантали",
      "sv_se": "Nådendal",
      "tr_tr": "Naantali",
      "uk_uk": "Наанталі",
      "vi_vn": "Naantali",
      "zh_cn": "楠塔利",
      "zh_tw": "楠塔利"
    }
  },
  {
    "Name": "Pori",
    "Country": "finland",
    "X": 34762.21,
    "Y": -61827.7578,
    "InGameId": "pori",
    "LocalizationToken": "pori",
    "CountryId": 23,
    "LocalizedNames": {
      "bg_bg": "Пори",
      "ca_es": "Pori",
      "cs_cz": "Pori",
      "da_dk": "Pori",
      "de_de": "Pori",
      "el_gr": "Πόρι",
      "en_gb": "Pori",
      "en_us": "Pori",
      "es_es": "Pori",
      "es_la": "Pori",
      "et_ee": "Pori",
      "eu_es": "Pori",
      "fi_fi": "Pori",
      "fr_ca": "Pori",
      "fr_fr": "Pori",
      "gl_es": "Pori",
      "hr_hr": "Pori",
      "hu_hu": "Pori",
      "it_it": "Pori",
      "ja_jp": "ポリ",
      "ka_ge": "პორი",
      "ko_kr": "포리",
      "lt_lt": "Poris",
      "lv_lv": "Pori",
      "mk_mk": "Пори",
      "nl_nl": "Pori",
      "no_no": "Björneborg",
      "pl_pl": "Pori",
      "pl_si": "Pori",
      "pt_br": "Pori",
      "pt_pt": "Pori",
      "ro_ro": "Pori",
      "ru_ru": "Пори",
      "sk_sk": "Pori",
      "sl_sl": "Pori",
      "sr_sp": "Pori",
      "sr_sr": "Пори",
      "sv_se": "Björneborg",
      "tr_tr": "Pori",
      "uk_uk": "Порі",
      "vi_vn": "Pori",
      "zh_cn": "波里",
      "zh_tw": "波里"
    }
  },
  {
    "Name": "Olkiluoto",
    "Country": "finland",
    "X": 34005.1367,
    "Y": -60278.2773,
    "InGameId": "olkiluoto",
    "LocalizationToken": "olkiluoto",
    "CountryId": 23,
    "LocalizedNames": {
      "bg_bg": "Олкилуото",
      "ca_es": "Olkiluoto",
      "cs_cz": "Olkiluoto",
      "da_dk": "Olkiluoto",
      "de_de": "Olkiluoto",
      "el_gr": "Ολκιλουότο",
      "en_gb": "Olkiluoto",
      "en_us": "Olkiluoto",
      "es_es": "Olkiluoto",
      "es_la": "Olkiluoto",
      "et_ee": "Olkiluoto",
      "eu_es": "Olkiluoto",
      "fi_fi": "Olkiluoto",
      "fr_ca": "Olkiluoto",
      "fr_fr": "Olkiluoto",
      "gl_es": "Olkiluoto",
      "hr_hr": "Olkiluoto",
      "hu_hu": "Olkiluoto",
      "it_it": "Olkiluoto",
      "ja_jp": "オルキルオト",
      "ka_ge": "ოლკილუოტო",
      "ko_kr": "올킬루오토",
      "lt_lt": "Olkiluotas",
      "lv_lv": "Olkiluoto",
      "mk_mk": "Олкилуото",
      "nl_nl": "Olkiluoto",
      "no_no": "Olkiluoto",
      "pl_pl": "Olkiluoto",
      "pl_si": "Olkiluoto",
      "pt_br": "Olkiluoto",
      "pt_pt": "Olkiluoto",
      "ro_ro": "Olkiluoto",
      "ru_ru": "Олкилуото",
      "sk_sk": "Olkiluoto",
      "sl_sl": "Olkiluoto",
      "sr_sp": "Olkiluoto",
      "sr_sr": "Олкилуото",
      "sv_se": "Olkiluoto",
      "tr_tr": "Olkiluoto",
      "uk_uk": "Олкілуото",
      "vi_vn": "Olkiluoto",
      "zh_cn": "奥尔基卢奥托岛核电站",
      "zh_tw": "奧爾基洛托"
    }
  },
  {
    "Name": "Klaipėda",
    "Country": "lithuania",
    "X": 35592.6875,
    "Y": -29939.6875,
    "InGameId": "klaipeda",
    "LocalizationToken": "klaipeda",
    "CountryId": 30,
    "LocalizedNames": {
      "bg_bg": "Клайпеда",
      "ca_es": "Klaipėda",
      "cs_cz": "Klaipėda",
      "da_dk": "Klaipėda",
      "de_de": "Memel",
      "el_gr": "Κλάιπεντα",
      "en_gb": "Klaipėda",
      "en_us": "Klaipėda",
      "es_es": "Klaipėda",
      "es_la": "Klaipėda",
      "et_ee": "Klaipėda",
      "eu_es": "Klaipėda",
      "fi_fi": "Klaipėda",
      "fr_ca": "Klaipėda",
      "fr_fr": "Klaipėda",
      "gl_es": "Klaipėda",
      "hr_hr": "Klaipėda",
      "hu_hu": "Klaipėda",
      "it_it": "Klaipėda",
      "ja_jp": "クライペダ",
      "ka_ge": "კლაიპედა",
      "ko_kr": "클라이페다",
      "lt_lt": "Klaipėda",
      "lv_lv": "Klaipēda",
      "mk_mk": "Клајпеда",
      "nl_nl": "Klaipėda",
      "no_no": "Klaipėda",
      "pl_pl": "Kłajpeda",
      "pl_si": "Kłajpeda",
      "pt_br": "Klaipėda",
      "pt_pt": "Klaipėda",
      "ro_ro": "Klaipėda",
      "ru_ru": "Клайпеда",
      "sk_sk": "Klaipėda",
      "sl_sl": "Klaipėda",
      "sr_sp": "Klajpeda",
      "sr_sr": "Клајпеда",
      "sv_se": "Klaipėda",
      "tr_tr": "Klaypeda",
      "uk_uk": "Клайпеда",
      "vi_vn": "Klaipėda",
      "zh_cn": "克莱佩达",
      "zh_tw": "克萊佩達"
    }
  },
  {
    "Name": "Mažeikiai",
    "Country": "lithuania",
    "X": 39184.2969,
    "Y": -33079.7539,
    "InGameId": "mazeikiai",
    "LocalizationToken": "mazeikiai",
    "CountryId": 30,
    "LocalizedNames": {
      "bg_bg": "Мазейкяй",
      "ca_es": "Mažeikiai",
      "cs_cz": "Mažeikiai",
      "da_dk": "Mažeikiai",
      "de_de": "Moscheiken",
      "el_gr": "Μαζέικιαϊ",
      "en_gb": "Mažeikiai",
      "en_us": "Mažeikiai",
      "es_es": "Mažeikiai",
      "es_la": "Mažeikiai",
      "et_ee": "Mažeikiai",
      "eu_es": "Mažeikiai",
      "fi_fi": "Mažeikiai",
      "fr_ca": "Mažeikiai",
      "fr_fr": "Mažeikiai",
      "gl_es": "Mažeikiai",
      "hr_hr": "Mažeikiai",
      "hu_hu": "Mažeikiai",
      "it_it": "Mažeikiai",
      "ja_jp": "マジェイケイ",
      "ka_ge": "მაჟეიკიაი",
      "ko_kr": "마제이카이아이",
      "lt_lt": "Mažeikiai",
      "lv_lv": "Mažeiķi",
      "mk_mk": "Мажејкјај",
      "nl_nl": "Mažeikiai",
      "no_no": "Mažeikiai",
      "pl_pl": "Możejki",
      "pl_si": "Możejki",
      "pt_br": "Mažeikiai",
      "pt_pt": "Mažeikiai",
      "ro_ro": "Mažeikiai",
      "ru_ru": "Мажейкяй",
      "sk_sk": "Mažeikiai",
      "sl_sl": "Mažeikiai",
      "sr_sp": "Mažejkjaj",
      "sr_sr": "Мажејкјај",
      "sv_se": "Mažeikiai",
      "tr_tr": "Mazeikiai",
      "uk_uk": "Мажейкяй",
      "vi_vn": "Mažeikiai",
      "zh_cn": "马热伊基艾",
      "zh_tw": "馬熱伊基艾"
    }
  },
  {
    "Name": "Ventspils",
    "Country": "latvia",
    "X": 35879.62,
    "Y": -39182.1328,
    "InGameId": "ventspils",
    "LocalizationToken": "ventspils",
    "CountryId": 28,
    "LocalizedNames": {
      "bg_bg": "Вентспилс",
      "ca_es": "Ventspils",
      "cs_cz": "Ventspils",
      "da_dk": "Ventspils",
      "de_de": "Windau",
      "el_gr": "Βέντσπιλς",
      "en_gb": "Ventspils",
      "en_us": "Ventspils",
      "es_es": "Ventspils",
      "es_la": "Ventspils",
      "et_ee": "Ventspils",
      "eu_es": "Ventspils",
      "fi_fi": "Ventspils",
      "fr_ca": "Ventspils",
      "fr_fr": "Ventspils",
      "gl_es": "Ventspils",
      "hr_hr": "Ventspils",
      "hu_hu": "Ventspils",
      "it_it": "Ventspils",
      "ja_jp": "ヴェンツピルス",
      "ka_ge": "ვენტსპილსი",
      "ko_kr": "벤츠필스",
      "lt_lt": "Ventspilis",
      "lv_lv": "Ventspils",
      "mk_mk": "Вентспилс",
      "nl_nl": "Ventspils",
      "no_no": "Ventspils",
      "pl_pl": "Windawa",
      "pl_si": "Windawa",
      "pt_br": "Ventspils",
      "pt_pt": "Ventspils",
      "ro_ro": "Ventspils",
      "ru_ru": "Вентспилс",
      "sk_sk": "Ventspils",
      "sl_sl": "Ventspils",
      "sr_sp": "Ventspils",
      "sr_sr": "Вентспилс",
      "sv_se": "Ventspils",
      "tr_tr": "Ventspils",
      "uk_uk": "Вентспілс",
      "vi_vn": "Ventspils",
      "zh_cn": "文茨皮尔斯",
      "zh_tw": "文次匹爾斯"
    }
  },
  {
    "Name": "Turku",
    "Country": "finland",
    "X": 36022.72,
    "Y": -56376.15,
    "InGameId": "turku",
    "LocalizationToken": "turku",
    "CountryId": 23,
    "LocalizedNames": {
      "bg_bg": "Турку",
      "ca_es": "Turku",
      "cs_cz": "Turku",
      "da_dk": "Turku",
      "de_de": "Turku",
      "el_gr": "Τούρκου",
      "en_gb": "Turku",
      "en_us": "Turku",
      "es_es": "Turku",
      "es_la": "Turku",
      "et_ee": "Turu",
      "eu_es": "Turku",
      "fi_fi": "Turku",
      "fr_ca": "Turku",
      "fr_fr": "Turku",
      "gl_es": "Turku",
      "hr_hr": "Turku",
      "hu_hu": "Turku",
      "it_it": "Turku",
      "ja_jp": "トゥルク",
      "ka_ge": "ტურკუ",
      "ko_kr": "투르쿠",
      "lt_lt": "Turku",
      "lv_lv": "Turku",
      "mk_mk": "Турку",
      "nl_nl": "Turku",
      "no_no": "Åbo",
      "pl_pl": "Turku",
      "pl_si": "Turku",
      "pt_br": "Turku",
      "pt_pt": "Turku",
      "ro_ro": "Turku",
      "ru_ru": "Турку",
      "sk_sk": "Turku",
      "sl_sl": "Turku",
      "sr_sp": "Turku",
      "sr_sr": "Турку",
      "sv_se": "Åbo",
      "tr_tr": "Turku",
      "uk_uk": "Турку",
      "vi_vn": "Turku",
      "zh_cn": "图尔库",
      "zh_tw": "土庫"
    }
  },
  {
    "Name": "Šiauliai",
    "Country": "lithuania",
    "X": 42563.6328,
    "Y": -31373.9414,
    "InGameId": "siauliai",
    "LocalizationToken": "siauliai",
    "CountryId": 30,
    "LocalizedNames": {
      "bg_bg": "Шауляй",
      "ca_es": "Šiauliai",
      "cs_cz": "Šiauliai",
      "da_dk": "Šiauliai",
      "de_de": "Schaulen",
      "el_gr": "Σιαουλιάι",
      "en_gb": "Šiauliai",
      "en_us": "Šiauliai",
      "es_es": "Šiauliai",
      "es_la": "Šiauliai",
      "et_ee": "Šiauliai",
      "eu_es": "Šiauliai",
      "fi_fi": "Šiauliai",
      "fr_ca": "Šiauliai",
      "fr_fr": "Šiauliai",
      "gl_es": "Šiauliai",
      "hr_hr": "Šiauliai",
      "hu_hu": "Šiauliai",
      "it_it": "Šiauliai",
      "ja_jp": "シャウレイ",
      "ka_ge": "შიაულიაი",
      "ko_kr": "시아울리아이",
      "lt_lt": "Šiauliai",
      "lv_lv": "Šauļi",
      "mk_mk": "Шјауљај",
      "nl_nl": "Šiauliai",
      "no_no": "Šiauliai",
      "pl_pl": "Szawle",
      "pl_si": "Szawle",
      "pt_br": "Šiauliai",
      "pt_pt": "Šiauliai",
      "ro_ro": "Šiauliai",
      "ru_ru": "Шяуляй",
      "sk_sk": "Šiauliai",
      "sl_sl": "Šiauliai",
      "sr_sp": "Šjauljaj",
      "sr_sr": "Шјауљај",
      "sv_se": "Šiauliai",
      "tr_tr": "Šiauliai",
      "uk_uk": "Шяуляй",
      "vi_vn": "Šiauliai",
      "zh_cn": "希奥利艾",
      "zh_tw": "希奧利艾"
    }
  },
  {
    "Name": "Paldiski",
    "Country": "estonia",
    "X": 42144.875,
    "Y": -50332.0742,
    "InGameId": "paldiski",
    "LocalizationToken": "paldiski",
    "CountryId": 22,
    "LocalizedNames": {
      "bg_bg": "Палдиски",
      "ca_es": "Paldiski",
      "cs_cz": "Paldiski",
      "da_dk": "Paldiski",
      "de_de": "Paldiski",
      "el_gr": "Πάλντισκι",
      "en_gb": "Paldiski",
      "en_us": "Paldiski",
      "es_es": "Paldiski",
      "es_la": "Paldiski",
      "et_ee": "Paldiski",
      "eu_es": "Paldiski",
      "fi_fi": "Paldiski",
      "fr_ca": "Paldiski",
      "fr_fr": "Paldiski",
      "gl_es": "Paldiski",
      "hr_hr": "Paldiski",
      "hu_hu": "Paldiski",
      "it_it": "Paldiski",
      "ja_jp": "パルティスキ",
      "ka_ge": "პალდისკი",
      "ko_kr": "팔디스키",
      "lt_lt": "Paldiskis",
      "lv_lv": "Paldiski",
      "mk_mk": "Палдиски",
      "nl_nl": "Paldiski",
      "no_no": "Paldiski",
      "pl_pl": "Paldiski",
      "pl_si": "Paldiski",
      "pt_br": "Paldiski",
      "pt_pt": "Paldiski",
      "ro_ro": "Paldiski",
      "ru_ru": "Палдиски",
      "sk_sk": "Paldiski",
      "sl_sl": "Paldiski",
      "sr_sp": "Paldiski",
      "sr_sr": "Палдиски",
      "sv_se": "Paldiski",
      "tr_tr": "Paldiski",
      "uk_uk": "Палдіскі",
      "vi_vn": "Paldiski",
      "zh_cn": "帕尔迪斯基",
      "zh_tw": "帕爾迪斯基"
    }
  },
  {
    "Name": "Tampere",
    "Country": "finland",
    "X": 40138.7227,
    "Y": -62610.8672,
    "InGameId": "tampere",
    "LocalizationToken": "tampere",
    "CountryId": 23,
    "LocalizedNames": {
      "bg_bg": "Тампере",
      "ca_es": "Tampere",
      "cs_cz": "Tampere",
      "da_dk": "Tampere",
      "de_de": "Tampere",
      "el_gr": "Τάμπερε",
      "en_gb": "Tampere",
      "en_us": "Tampere",
      "es_es": "Tampere",
      "es_la": "Tampere",
      "et_ee": "Tampere",
      "eu_es": "Tampere",
      "fi_fi": "Tampere",
      "fr_ca": "Tampere",
      "fr_fr": "Tampere",
      "gl_es": "Tampere",
      "hr_hr": "Tampere",
      "hu_hu": "Tampere",
      "it_it": "Tampere",
      "ja_jp": "タンペレ",
      "ka_ge": "ტამპერე",
      "ko_kr": "탐페레",
      "lt_lt": "Tamperė",
      "lv_lv": "Tampere",
      "mk_mk": "Тампере",
      "nl_nl": "Tampere",
      "no_no": "Tammerfors",
      "pl_pl": "Tampere",
      "pl_si": "Tampere",
      "pt_br": "Tampere",
      "pt_pt": "Tampere",
      "ro_ro": "Tampere",
      "ru_ru": "Тампере",
      "sk_sk": "Tampere",
      "sl_sl": "Tampere",
      "sr_sp": "Tampere",
      "sr_sr": "Тампере",
      "sv_se": "Tammerfors",
      "tr_tr": "Tampere",
      "uk_uk": "Тампере",
      "vi_vn": "Tampere",
      "zh_cn": "坦佩雷",
      "zh_tw": "坦佩雷"
    }
  },
  {
    "Name": "Kaunas",
    "Country": "lithuania",
    "X": 44413.8828,
    "Y": -26267.8555,
    "InGameId": "kaunas",
    "LocalizationToken": "kaunas",
    "CountryId": 30,
    "LocalizedNames": {
      "bg_bg": "Каунас",
      "ca_es": "Kaunas",
      "cs_cz": "Kaunas",
      "da_dk": "Kaunas",
      "de_de": "Kaunas",
      "el_gr": "Κάουνας",
      "en_gb": "Kaunas",
      "en_us": "Kaunas",
      "es_es": "Kaunas",
      "es_la": "Kaunas",
      "et_ee": "Kaunas",
      "eu_es": "Kaunas",
      "fi_fi": "Kaunas",
      "fr_ca": "Kaunas",
      "fr_fr": "Kaunas",
      "gl_es": "Kaunas",
      "hr_hr": "Kaunas",
      "hu_hu": "Kaunas",
      "it_it": "Kaunas",
      "ja_jp": "カウナス",
      "ka_ge": "კაუნასი",
      "ko_kr": "카우나스",
      "lt_lt": "Kaunas",
      "lv_lv": "Kauņa",
      "mk_mk": "Каунас",
      "nl_nl": "Kaunas",
      "no_no": "Kaunas",
      "pl_pl": "Kowno",
      "pl_si": "Kowno",
      "pt_br": "Kaunas",
      "pt_pt": "Kaunas",
      "ro_ro": "Kaunas",
      "ru_ru": "Каунас",
      "sk_sk": "Kaunas",
      "sl_sl": "Kaunas",
      "sr_sp": "Kaunas",
      "sr_sr": "Каунас",
      "sv_se": "Kaunas",
      "tr_tr": "Kaunas",
      "uk_uk": "Каунас",
      "vi_vn": "Kaunas",
      "zh_cn": "考纳斯",
      "zh_tw": "考納斯"
    }
  },
  {
    "Name": "Panevėžys",
    "Country": "lithuania",
    "X": 45652.2031,
    "Y": -30282.9375,
    "InGameId": "panevezys",
    "LocalizationToken": "panevezys",
    "CountryId": 30,
    "LocalizedNames": {
      "bg_bg": "Паневежис",
      "ca_es": "Panevėžys",
      "cs_cz": "Panevėžys",
      "da_dk": "Panevėžys",
      "de_de": "Ponewiesch",
      "el_gr": "Πανεβεζίς",
      "en_gb": "Panevėžys",
      "en_us": "Panevėžys",
      "es_es": "Panevėžys",
      "es_la": "Panevėžys",
      "et_ee": "Panevėžys",
      "eu_es": "Panevėžys",
      "fi_fi": "Panevėžys",
      "fr_ca": "Panevėžys",
      "fr_fr": "Panevėžys",
      "gl_es": "Panevėžys",
      "hr_hr": "Panevežis",
      "hu_hu": "Panevėžys",
      "it_it": "Panevėžys",
      "ja_jp": "パネヴェジース",
      "ka_ge": "პანევეჟისი",
      "ko_kr": "파네베지스",
      "lt_lt": "Panevėžys",
      "lv_lv": "Panevēža",
      "mk_mk": "Паневежис",
      "nl_nl": "Panevėžys",
      "no_no": "Panevėžys",
      "pl_pl": "Poniewież",
      "pl_si": "Poniewież",
      "pt_br": "Panevėžys",
      "pt_pt": "Panevėžys",
      "ro_ro": "Panevėžys",
      "ru_ru": "Паневежис",
      "sk_sk": "Panevėžys",
      "sl_sl": "Panevėžys",
      "sr_sp": "Panevežis",
      "sr_sr": "Паневежис",
      "sv_se": "Panevėžys",
      "tr_tr": "Panevėžys",
      "uk_uk": "Паневежис",
      "vi_vn": "Panevėžys",
      "zh_cn": "帕涅韦日斯",
      "zh_tw": "帕涅維日斯"
    }
  },
  {
    "Name": "Rīga",
    "Country": "latvia",
    "X": 43827.875,
    "Y": -37984.81,
    "InGameId": "riga",
    "LocalizationToken": "riga",
    "CountryId": 28,
    "LocalizedNames": {
      "bg_bg": "Рига",
      "ca_es": "Riga",
      "cs_cz": "Riga",
      "da_dk": "Riga",
      "de_de": "Riga",
      "el_gr": "Ρίγα",
      "en_gb": "Riga",
      "en_us": "Riga",
      "es_es": "Riga",
      "es_la": "Riga",
      "et_ee": "Riia",
      "eu_es": "Riga",
      "fi_fi": "Riika",
      "fr_ca": "Riga",
      "fr_fr": "Riga",
      "gl_es": "Riga",
      "hr_hr": "Riga",
      "hu_hu": "Riga",
      "it_it": "Riga",
      "ja_jp": "リガ",
      "ka_ge": "რიგა",
      "ko_kr": "리가",
      "lt_lt": "Ryga",
      "lv_lv": "Rīga",
      "mk_mk": "Рига",
      "nl_nl": "Riga",
      "no_no": "Riga",
      "pl_pl": "Ryga",
      "pl_si": "Ryga",
      "pt_br": "Riga",
      "pt_pt": "Riga",
      "ro_ro": "Riga",
      "ru_ru": "Рига",
      "sk_sk": "Riga",
      "sl_sl": "Riga",
      "sr_sp": "Riga",
      "sr_sr": "Рига",
      "sv_se": "Riga",
      "tr_tr": "Riga",
      "uk_uk": "Рига",
      "vi_vn": "Riga",
      "zh_cn": "里加",
      "zh_tw": "里加"
    }
  },
  {
    "Name": "Valmiera",
    "Country": "latvia",
    "X": 47622.93,
    "Y": -41103.87,
    "InGameId": "valmiera",
    "LocalizationToken": "valmiera",
    "CountryId": 28,
    "LocalizedNames": {
      "bg_bg": "Валмиера",
      "ca_es": "Valmiera",
      "cs_cz": "Valmiera",
      "da_dk": "Valmiera",
      "de_de": "Wolmar",
      "el_gr": "Βάλμιερα",
      "en_gb": "Valmiera",
      "en_us": "Valmiera",
      "es_es": "Valmiera",
      "es_la": "Valmiera",
      "et_ee": "Valmiera",
      "eu_es": "Valmiera",
      "fi_fi": "Valmiera",
      "fr_ca": "Valmiera",
      "fr_fr": "Valmiera",
      "gl_es": "Valmiera",
      "hr_hr": "Valmiera",
      "hu_hu": "Valmiera",
      "it_it": "Valmiera",
      "ja_jp": "ヴァルミエラ",
      "ka_ge": "ვალმიერა",
      "ko_kr": "발미에라",
      "lt_lt": "Valmiera",
      "lv_lv": "Valmiera",
      "mk_mk": "Валмиера",
      "nl_nl": "Valmiera",
      "no_no": "Valmiera",
      "pl_pl": "Valmiera",
      "pl_si": "Valmiera",
      "pt_br": "Valmiera",
      "pt_pt": "Valmiera",
      "ro_ro": "Valmiera",
      "ru_ru": "Валмиера",
      "sk_sk": "Valmiera",
      "sl_sl": "Valmiera",
      "sr_sp": "Valmijera",
      "sr_sr": "Валмијера",
      "sv_se": "Valmiera",
      "tr_tr": "Valmiera",
      "uk_uk": "Валмієра",
      "vi_vn": "Valmiera",
      "zh_cn": "瓦尔米耶拉",
      "zh_tw": "瓦爾米耶拉"
    }
  },
  {
    "Name": "Pärnu",
    "Country": "estonia",
    "X": 44076.6445,
    "Y": -45757.83,
    "InGameId": "parnu",
    "LocalizationToken": "parnu",
    "CountryId": 22,
    "LocalizedNames": {
      "bg_bg": "Пярну",
      "ca_es": "Pärnu",
      "cs_cz": "Pärnu",
      "da_dk": "Pärnu",
      "de_de": "Pernau",
      "el_gr": "Πάρνου",
      "en_gb": "Pärnu",
      "en_us": "Pärnu",
      "es_es": "Pärnu",
      "es_la": "Pärnu",
      "et_ee": "Pärnu",
      "eu_es": "Pärnu",
      "fi_fi": "Pärnu",
      "fr_ca": "Pärnu",
      "fr_fr": "Pärnu",
      "gl_es": "Pärnu",
      "hr_hr": "Pärnu",
      "hu_hu": "Pärnu",
      "it_it": "Pärnu",
      "ja_jp": "パルヌ",
      "ka_ge": "პიარნუ",
      "ko_kr": "패르누",
      "lt_lt": "Pernu",
      "lv_lv": "Pērnava",
      "mk_mk": "Пјарну",
      "nl_nl": "Pärnu",
      "no_no": "Pärnu",
      "pl_pl": "Parnawa",
      "pl_si": "Parnawa",
      "pt_br": "Pärnu",
      "pt_pt": "Pärnu",
      "ro_ro": "Pärnu",
      "ru_ru": "Пярну",
      "sk_sk": "Pärnu",
      "sl_sl": "Pärnu",
      "sr_sp": "Parnu",
      "sr_sr": "Парну",
      "sv_se": "Pärnu",
      "tr_tr": "Parnu",
      "uk_uk": "Пярну",
      "vi_vn": "Pärnu",
      "zh_cn": "派尔努",
      "zh_tw": "派爾努"
    }
  },
  {
    "Name": "Tallinn",
    "Country": "estonia",
    "X": 43213.3633,
    "Y": -51613.7539,
    "InGameId": "tallinn",
    "LocalizationToken": "tallinn",
    "CountryId": 22,
    "LocalizedNames": {
      "bg_bg": "Талин",
      "ca_es": "Tallinn",
      "cs_cz": "Tallinn",
      "da_dk": "Tallinn",
      "de_de": "Tallinn",
      "el_gr": "Ταλίν",
      "en_gb": "Tallinn",
      "en_us": "Tallinn",
      "es_es": "Tallin",
      "es_la": "Tallin",
      "et_ee": "Tallinn",
      "eu_es": "Tallinn",
      "fi_fi": "Tallinna",
      "fr_ca": "Tallinn",
      "fr_fr": "Tallinn",
      "gl_es": "Talín",
      "hr_hr": "Tallinn",
      "hu_hu": "Tallinn",
      "it_it": "Tallinn",
      "ja_jp": "タリン",
      "ka_ge": "ტალინი",
      "ko_kr": "탈린",
      "lt_lt": "Tallinn",
      "lv_lv": "Tallinn",
      "mk_mk": "Талин",
      "nl_nl": "Tallinn",
      "no_no": "Tallinn",
      "pl_pl": "Tallinn",
      "pl_si": "Tallinn",
      "pt_br": "Talin",
      "pt_pt": "Talim",
      "ro_ro": "Tallinn",
      "ru_ru": "Таллин",
      "sk_sk": "Talin",
      "sl_sl": "Talin",
      "sr_sp": "Talin",
      "sr_sr": "Талин",
      "sv_se": "Tallinn",
      "tr_tr": "Tallin",
      "uk_uk": "Таллінн",
      "vi_vn": "Tallinn",
      "zh_cn": "塔林",
      "zh_tw": "塔林"
    }
  },
  {
    "Name": "Helsinki",
    "Country": "finland",
    "X": 44470.2266,
    "Y": -56161.6328,
    "InGameId": "helsinki",
    "LocalizationToken": "helsinki",
    "CountryId": 23,
    "LocalizedNames": {
      "bg_bg": "Хелзинки",
      "ca_es": "Hèlsinki",
      "cs_cz": "Helsinky",
      "da_dk": "Helsingfors",
      "de_de": "Helsinki",
      "el_gr": "Ελσίνκι",
      "en_gb": "Helsinki",
      "en_us": "Helsinki",
      "es_es": "Helsinki",
      "es_la": "Helsinki",
      "et_ee": "Helsingi",
      "eu_es": "Helsinki",
      "fi_fi": "Helsinki",
      "fr_ca": "Helsinki",
      "fr_fr": "Helsinki",
      "gl_es": "Helsinqui",
      "hr_hr": "Helsinki",
      "hu_hu": "Helsinki",
      "it_it": "Helsinki",
      "ja_jp": "ヘルシンキ",
      "ka_ge": "ჰელსინკი",
      "ko_kr": "헬싱키",
      "lt_lt": "Helsinkis",
      "lv_lv": "Helsinki",
      "mk_mk": "Хелсинки",
      "nl_nl": "Helsinki",
      "no_no": "Helsingfors",
      "pl_pl": "Helsinki",
      "pl_si": "Helsinki",
      "pt_br": "Helsinque",
      "pt_pt": "Helsínquia",
      "ro_ro": "Helsinki",
      "ru_ru": "Хельсинки",
      "sk_sk": "Helsinki",
      "sl_sl": "Helsinki",
      "sr_sp": "Helsinki",
      "sr_sr": "Хелсинки",
      "sv_se": "Helsingfors",
      "tr_tr": "Helsinki",
      "uk_uk": "Гельсінкі",
      "vi_vn": "Helsinki",
      "zh_cn": "赫尔辛基",
      "zh_tw": "赫爾辛基"
    }
  },
  {
    "Name": "Loviisa",
    "Country": "finland",
    "X": 47567.2852,
    "Y": -57357.9258,
    "InGameId": "loviisa",
    "LocalizationToken": "loviisa",
    "CountryId": 23,
    "LocalizedNames": {
      "bg_bg": "Ловииса",
      "ca_es": "Loviisa",
      "cs_cz": "Loviisa",
      "da_dk": "Loviisa",
      "de_de": "Loviisa",
      "el_gr": "Λοβίσα",
      "en_gb": "Loviisa",
      "en_us": "Loviisa",
      "es_es": "Loviisa",
      "es_la": "Loviisa",
      "et_ee": "Loviisa",
      "eu_es": "Loviisa",
      "fi_fi": "Loviisa",
      "fr_ca": "Loviisa",
      "fr_fr": "Loviisa",
      "gl_es": "Loviisa",
      "hr_hr": "Loviisa",
      "hu_hu": "Loviisa",
      "it_it": "Loviisa",
      "ja_jp": "ロヴィーサ",
      "ka_ge": "ლოვიისა",
      "ko_kr": "로비사",
      "lt_lt": "Loviisa",
      "lv_lv": "Loviisa",
      "mk_mk": "Ловиса",
      "nl_nl": "Loviisa",
      "no_no": "Lovisa",
      "pl_pl": "Loviisa",
      "pl_si": "Loviisa",
      "pt_br": "Loviisa",
      "pt_pt": "Loviisa",
      "ro_ro": "Loviisa",
      "ru_ru": "Ловийса",
      "sk_sk": "Loviisa",
      "sl_sl": "Loviisa",
      "sr_sp": "Lovisa",
      "sr_sr": "Ловиса",
      "sv_se": "Loviisa",
      "tr_tr": "Loviisa",
      "uk_uk": "Ловійса",
      "vi_vn": "Loviisa",
      "zh_cn": "洛维萨",
      "zh_tw": "洛維薩"
    }
  },
  {
    "Name": "Lahti",
    "Country": "finland",
    "X": 45129.68,
    "Y": -61177.1445,
    "InGameId": "lahti",
    "LocalizationToken": "lahti",
    "CountryId": 23,
    "LocalizedNames": {
      "bg_bg": "Лахти",
      "ca_es": "Lahti",
      "cs_cz": "Lahti",
      "da_dk": "Lahti",
      "de_de": "Lahti",
      "el_gr": "Λάχτι",
      "en_gb": "Lahti",
      "en_us": "Lahti",
      "es_es": "Lahti",
      "es_la": "Lahti",
      "et_ee": "Lahti",
      "eu_es": "Lahti",
      "fi_fi": "Lahti",
      "fr_ca": "Lahti",
      "fr_fr": "Lahti",
      "gl_es": "Lahti",
      "hr_hr": "Lahti",
      "hu_hu": "Lahti",
      "it_it": "Lahti",
      "ja_jp": "ラハティ",
      "ka_ge": "ლაჰტი",
      "ko_kr": "라티",
      "lt_lt": "Lahtis",
      "lv_lv": "Lahti",
      "mk_mk": "Лахти",
      "nl_nl": "Lahti",
      "no_no": "Lahtis",
      "pl_pl": "Lahti",
      "pl_si": "Lahti",
      "pt_br": "Lahti",
      "pt_pt": "Lahti",
      "ro_ro": "Lahti",
      "ru_ru": "Лахти",
      "sk_sk": "Lahti",
      "sl_sl": "Lahti",
      "sr_sp": "Lahti",
      "sr_sr": "Лахти",
      "sv_se": "Lahtis",
      "tr_tr": "Lahti",
      "uk_uk": "Лахті",
      "vi_vn": "Lahti",
      "zh_cn": "拉赫蒂",
      "zh_tw": "拉赫蒂"
    }
  },
  {
    "Name": "Vilnius",
    "Country": "lithuania",
    "X": 49462.4375,
    "Y": -25905.7852,
    "InGameId": "vilnius",
    "LocalizationToken": "vilnius",
    "CountryId": 30,
    "LocalizedNames": {
      "bg_bg": "Вилнюс",
      "ca_es": "Vílnius",
      "cs_cz": "Vilnius",
      "da_dk": "Vilnius",
      "de_de": "Wilna",
      "el_gr": "Βίλνιους",
      "en_gb": "Vilnius",
      "en_us": "Vilnius",
      "es_es": "Vilna",
      "es_la": "Vilna",
      "et_ee": "Vilnius",
      "eu_es": "Vilnius",
      "fi_fi": "Vilna",
      "fr_ca": "Vilnius",
      "fr_fr": "Vilnius",
      "gl_es": "Vilnius",
      "hr_hr": "Vilnius",
      "hu_hu": "Vilnius",
      "it_it": "Vilnius",
      "ja_jp": "ヴィリニュス",
      "ka_ge": "ვილნიუსი",
      "ko_kr": "빌뉴스",
      "lt_lt": "Vilnius",
      "lv_lv": "Viļņa",
      "mk_mk": "Вилнус",
      "nl_nl": "Vilnius",
      "no_no": "Vilnius",
      "pl_pl": "Wilno",
      "pl_si": "Wilno",
      "pt_br": "Vilnius",
      "pt_pt": "Vilnius",
      "ro_ro": "Vilnius",
      "ru_ru": "Вильнюс",
      "sk_sk": "Vilnius",
      "sl_sl": "Vilna",
      "sr_sp": "Vilnjus",
      "sr_sr": "Вилњус",
      "sv_se": "Vilnius",
      "tr_tr": "Vilna",
      "uk_uk": "Вільнюс",
      "vi_vn": "Vilnius",
      "zh_cn": "维尔纽斯",
      "zh_tw": "維爾紐斯"
    }
  },
  {
    "Name": "Utena",
    "Country": "lithuania",
    "X": 50128.05,
    "Y": -30060.5469,
    "InGameId": "utena",
    "LocalizationToken": "utena",
    "CountryId": 30,
    "LocalizedNames": {
      "bg_bg": "Утена",
      "ca_es": "Utena",
      "cs_cz": "Utena",
      "da_dk": "Utena",
      "de_de": "Utenen",
      "el_gr": "Ουτενά",
      "en_gb": "Utena",
      "en_us": "Utena",
      "es_es": "Utena",
      "es_la": "Utena",
      "et_ee": "Utena",
      "eu_es": "Utena",
      "fi_fi": "Utena",
      "fr_ca": "Utena",
      "fr_fr": "Utena",
      "gl_es": "Utena",
      "hr_hr": "Utena",
      "hu_hu": "Utena",
      "it_it": "Utena",
      "ja_jp": "ウテナ",
      "ka_ge": "უტენა",
      "ko_kr": "우테나",
      "lt_lt": "Utena",
      "lv_lv": "Utena",
      "mk_mk": "Утена",
      "nl_nl": "Utena",
      "no_no": "Utena",
      "pl_pl": "Uciana",
      "pl_si": "Uciana",
      "pt_br": "Utena",
      "pt_pt": "Utena",
      "ro_ro": "Utena",
      "ru_ru": "Утена",
      "sk_sk": "Utena",
      "sl_sl": "Utena",
      "sr_sp": "Utena",
      "sr_sr": "Утена",
      "sv_se": "Utena",
      "tr_tr": "Utena",
      "uk_uk": "Утена",
      "vi_vn": "Utena",
      "zh_cn": "乌泰纳",
      "zh_tw": "烏田納"
    }
  },
  {
    "Name": "Tartu",
    "Country": "estonia",
    "X": 50707.88,
    "Y": -46878.1367,
    "InGameId": "tartu",
    "LocalizationToken": "tartu",
    "CountryId": 22,
    "LocalizedNames": {
      "bg_bg": "Тарту",
      "ca_es": "Tartu",
      "cs_cz": "Tartu",
      "da_dk": "Tartu",
      "de_de": "Dorpat",
      "el_gr": "Τάρτου",
      "en_gb": "Tartu",
      "en_us": "Tartu",
      "es_es": "Tartu",
      "es_la": "Tartu",
      "et_ee": "Tartu",
      "eu_es": "Tartu",
      "fi_fi": "Tartto",
      "fr_ca": "Tartu",
      "fr_fr": "Tartu",
      "gl_es": "Tartu",
      "hr_hr": "Tartu",
      "hu_hu": "Tartu",
      "it_it": "Tartu",
      "ja_jp": "タルトゥ",
      "ka_ge": "ტარტუ",
      "ko_kr": "타르투",
      "lt_lt": "Tartu",
      "lv_lv": "Tartu",
      "mk_mk": "Тарту",
      "nl_nl": "Tartu",
      "no_no": "Tartu",
      "pl_pl": "Tartu",
      "pl_si": "Tartu",
      "pt_br": "Tartu",
      "pt_pt": "Tartu",
      "ro_ro": "Tartu",
      "ru_ru": "Тарту",
      "sk_sk": "Tartu",
      "sl_sl": "Tartu",
      "sr_sp": "Tartu",
      "sr_sr": "Тарту",
      "sv_se": "Tartu",
      "tr_tr": "Tartu",
      "uk_uk": "Тарту",
      "vi_vn": "Tartu",
      "zh_cn": "塔尔图",
      "zh_tw": "塔爾圖"
    }
  },
  {
    "Name": "Kunda",
    "Country": "estonia",
    "X": 49435.1641,
    "Y": -52624.81,
    "InGameId": "kunda",
    "LocalizationToken": "kunda",
    "CountryId": 22,
    "LocalizedNames": {
      "bg_bg": "Кунда",
      "ca_es": "Kunda",
      "cs_cz": "Kunda",
      "da_dk": "Kunda",
      "de_de": "Kunda",
      "el_gr": "Κούντα",
      "en_gb": "Kunda",
      "en_us": "Kunda",
      "es_es": "Kunda",
      "es_la": "Kunda",
      "et_ee": "Kunda",
      "eu_es": "Kunda",
      "fi_fi": "Kunda",
      "fr_ca": "Kunda",
      "fr_fr": "Kunda",
      "gl_es": "Kunda",
      "hr_hr": "Kunda",
      "hu_hu": "Kunda",
      "it_it": "Kunda",
      "ja_jp": "クンダ",
      "ka_ge": "კუნდა",
      "ko_kr": "쿤다",
      "lt_lt": "Kunda",
      "lv_lv": "Kunda",
      "mk_mk": "Кунда",
      "nl_nl": "Kunda",
      "no_no": "Kunda",
      "pl_pl": "Kunda",
      "pl_si": "Kunda",
      "pt_br": "Kunda",
      "pt_pt": "Kunda",
      "ro_ro": "Kunda",
      "ru_ru": "Кунда",
      "sk_sk": "Kunda",
      "sl_sl": "Kunda",
      "sr_sp": "Kunda",
      "sr_sr": "Кунда",
      "sv_se": "Kunda",
      "tr_tr": "Kunda",
      "uk_uk": "Кунда",
      "vi_vn": "Kunda",
      "zh_cn": "昆达",
      "zh_tw": "昆達"
    }
  },
  {
    "Name": "Kotka",
    "Country": "finland",
    "X": 49619.93,
    "Y": -58615.79,
    "InGameId": "kotka",
    "LocalizationToken": "kotka",
    "CountryId": 23,
    "LocalizedNames": {
      "bg_bg": "Котка",
      "ca_es": "Kotka",
      "cs_cz": "Kotka",
      "da_dk": "Kotka",
      "de_de": "Kotka",
      "el_gr": "Κότκα",
      "en_gb": "Kotka",
      "en_us": "Kotka",
      "es_es": "Kotka",
      "es_la": "Kotka",
      "et_ee": "Kotka",
      "eu_es": "Kotka",
      "fi_fi": "Kotka",
      "fr_ca": "Kotka",
      "fr_fr": "Kotka",
      "gl_es": "Kotka",
      "hr_hr": "Kotka",
      "hu_hu": "Kotka",
      "it_it": "Kotka",
      "ja_jp": "コトカ",
      "ka_ge": "კოტკა",
      "ko_kr": "코트카",
      "lt_lt": "Kotka",
      "lv_lv": "Kotka",
      "mk_mk": "Котка",
      "nl_nl": "Kotka",
      "no_no": "Kotka",
      "pl_pl": "Kotka",
      "pl_si": "Kotka",
      "pt_br": "Kotka",
      "pt_pt": "Kotka",
      "ro_ro": "Kotka",
      "ru_ru": "Котка",
      "sk_sk": "Kotka",
      "sl_sl": "Kotka",
      "sr_sp": "Kotka",
      "sr_sr": "Котка",
      "sv_se": "Kotka",
      "tr_tr": "Kotka",
      "uk_uk": "Котка",
      "vi_vn": "Kotka",
      "zh_cn": "科特卡",
      "zh_tw": "科特卡"
    }
  },
  {
    "Name": "Kouvola",
    "Country": "finland",
    "X": 48376.125,
    "Y": -60829.0742,
    "InGameId": "kouvola",
    "LocalizationToken": "kouvola",
    "CountryId": 23,
    "LocalizedNames": {
      "bg_bg": "Кувола",
      "ca_es": "Kouvola",
      "cs_cz": "Kouvola",
      "da_dk": "Kouvola",
      "de_de": "Kouvola",
      "el_gr": "Κούβολα",
      "en_gb": "Kouvola",
      "en_us": "Kouvola",
      "es_es": "Kouvola",
      "es_la": "Kouvola",
      "et_ee": "Kouvola",
      "eu_es": "Kouvola",
      "fi_fi": "Kouvola",
      "fr_ca": "Kouvola",
      "fr_fr": "Kouvola",
      "gl_es": "Kouvola",
      "hr_hr": "Kouvola",
      "hu_hu": "Kouvola",
      "it_it": "Kouvola",
      "ja_jp": "コウヴォラ",
      "ka_ge": "კოუვოლა",
      "ko_kr": "코우볼라",
      "lt_lt": "Kouvola",
      "lv_lv": "Kouvola",
      "mk_mk": "Коувола",
      "nl_nl": "Kouvola",
      "no_no": "Kouvola",
      "pl_pl": "Kouvola",
      "pl_si": "Kouvola",
      "pt_br": "Kouvola",
      "pt_pt": "Kouvola",
      "ro_ro": "Kouvola",
      "ru_ru": "Коувола",
      "sk_sk": "Kouvola",
      "sl_sl": "Kouvola",
      "sr_sp": "Kouvola",
      "sr_sr": "Коувола",
      "sv_se": "Kouvola",
      "tr_tr": "Kouvola",
      "uk_uk": "Коувола",
      "vi_vn": "Kouvola",
      "zh_cn": "科沃拉",
      "zh_tw": "科沃拉"
    }
  },
  {
    "Name": "Daugavpils",
    "Country": "latvia",
    "X": 52325.2773,
    "Y": -32925.78,
    "InGameId": "daugavpils",
    "LocalizationToken": "daugavpils",
    "CountryId": 28,
    "LocalizedNames": {
      "bg_bg": "Даугавпилс",
      "ca_es": "Daugavpils",
      "cs_cz": "Daugavpils",
      "da_dk": "Daugavpils",
      "de_de": "Dünaburg",
      "el_gr": "Ντάουγκαβπιλς",
      "en_gb": "Daugavpils",
      "en_us": "Daugavpils",
      "es_es": "Daugavpils",
      "es_la": "Daugavpils",
      "et_ee": "Daugavpils",
      "eu_es": "Daugavpils",
      "fi_fi": "Väinänlinna",
      "fr_ca": "Daugavpils",
      "fr_fr": "Daugavpils",
      "gl_es": "Daugavpils",
      "hr_hr": "Daugavpils",
      "hu_hu": "Daugavpils",
      "it_it": "Daugavpils",
      "ja_jp": "ダウガフピルス",
      "ka_ge": "დაუგავპილსი",
      "ko_kr": "다우가우필스",
      "lt_lt": "Daugpilis",
      "lv_lv": "Daugavpils",
      "mk_mk": "Даугавпилс",
      "nl_nl": "Daugavpils",
      "no_no": "Daugavpils",
      "pl_pl": "Dyneburg",
      "pl_si": "Dyneburg",
      "pt_br": "Daugavpils",
      "pt_pt": "Daugavpils",
      "ro_ro": "Daugavpils",
      "ru_ru": "Даугавпилс",
      "sk_sk": "Daugavpils",
      "sl_sl": "Daugavpils",
      "sr_sp": "Daugavpils",
      "sr_sr": "Даугавпилс",
      "sv_se": "Daugavpils",
      "tr_tr": "Daugavpils",
      "uk_uk": "Даугавпілс",
      "vi_vn": "Daugavpils",
      "zh_cn": "陶格夫匹尔斯",
      "zh_tw": "陶格夫匹爾斯"
    }
  },
  {
    "Name": "Rēzekne",
    "Country": "latvia",
    "X": 54948.31,
    "Y": -36100.7422,
    "InGameId": "rezekne",
    "LocalizationToken": "rezekne",
    "CountryId": 28,
    "LocalizedNames": {
      "bg_bg": "Резекне",
      "ca_es": "Rēzekne",
      "cs_cz": "Rēzekne",
      "da_dk": "Rēzekne",
      "de_de": "Rositten",
      "el_gr": "Ρέζεκνε",
      "en_gb": "Rēzekne",
      "en_us": "Rēzekne",
      "es_es": "Rēzekne",
      "es_la": "Rēzekne",
      "et_ee": "Rēzekne",
      "eu_es": "Rēzekne",
      "fi_fi": "Rēzekne",
      "fr_ca": "Rēzekne",
      "fr_fr": "Rēzekne",
      "gl_es": "Rēzekne",
      "hr_hr": "Rēzekne",
      "hu_hu": "Rēzekne",
      "it_it": "Rēzekne",
      "ja_jp": "レーゼクネ",
      "ka_ge": "რეზეკნე",
      "ko_kr": "레제크네",
      "lt_lt": "Rėzeknė",
      "lv_lv": "Rēzekne",
      "mk_mk": "Резекне",
      "nl_nl": "Rēzekne",
      "no_no": "Rēzekne",
      "pl_pl": "Rzeżyca",
      "pl_si": "Rzeżyca",
      "pt_br": "Rēzekne",
      "pt_pt": "Rēzekne",
      "ro_ro": "Rēzekne",
      "ru_ru": "Резекне",
      "sk_sk": "Rēzekne",
      "sl_sl": "Rēzekne",
      "sr_sp": "Rezekne",
      "sr_sr": "Резекне",
      "sv_se": "Rēzekne",
      "tr_tr": "Rēzekne",
      "uk_uk": "Резекне",
      "vi_vn": "Rēzekne",
      "zh_cn": "雷泽克内",
      "zh_tw": "雷澤克內"
    }
  },
  {
    "Name": "Narva",
    "Country": "estonia",
    "X": 53982.0352,
    "Y": -52882.44,
    "InGameId": "narva",
    "LocalizationToken": "narva",
    "CountryId": 22,
    "LocalizedNames": {
      "bg_bg": "Нарва",
      "ca_es": "Narva",
      "cs_cz": "Narva",
      "da_dk": "Narva",
      "de_de": "Narwa",
      "el_gr": "Νάρβα",
      "en_gb": "Narva",
      "en_us": "Narva",
      "es_es": "Narva",
      "es_la": "Narva",
      "et_ee": "Narva",
      "eu_es": "Narva",
      "fi_fi": "Narva",
      "fr_ca": "Narva",
      "fr_fr": "Narva",
      "gl_es": "Narva",
      "hr_hr": "Narva",
      "hu_hu": "Narva",
      "it_it": "Narva",
      "ja_jp": "ナルヴァ",
      "ka_ge": "ნარვა",
      "ko_kr": "나르바",
      "lt_lt": "Narva",
      "lv_lv": "Narva",
      "mk_mk": "Нарва",
      "nl_nl": "Narva",
      "no_no": "Narva",
      "pl_pl": "Narwa",
      "pl_si": "Narwa",
      "pt_br": "Narva",
      "pt_pt": "Narva",
      "ro_ro": "Narva",
      "ru_ru": "Нарва",
      "sk_sk": "Narva",
      "sl_sl": "Narva",
      "sr_sp": "Narva",
      "sr_sr": "Нарва",
      "sv_se": "Narva",
      "tr_tr": "Narva",
      "uk_uk": "Нарва",
      "vi_vn": "Narva",
      "zh_cn": "纳尔瓦",
      "zh_tw": "納瓦"
    }
  },
  {
    "Name": "Сосновый Бор",
    "Country": "russia",
    "X": 54699.02,
    "Y": -54784.6172,
    "InGameId": "sosnovy_bor",
    "LocalizationToken": "sosnovy_bor",
    "CountryId": 38,
    "LocalizedNames": {
      "bg_bg": "Соснови Бор",
      "ca_es": "Sosnovy Bor",
      "cs_cz": "Sosnovyj Bor",
      "da_dk": "Sosnovy Bor",
      "de_de": "Sosnowy Bor",
      "el_gr": "Σοσνόρυ Μπορ",
      "en_gb": "Sosnovy Bor",
      "en_us": "Sosnovy Bor",
      "es_es": "Sosnovy Bor",
      "es_la": "Sosnovy Bor",
      "et_ee": "Sosnovõi Bor",
      "eu_es": "Sosnovy Bor",
      "fi_fi": "Sosnovyi Bor",
      "fr_ca": "Sosnovy Bor",
      "fr_fr": "Sosnovy Bor",
      "gl_es": "Sosnovy Bor",
      "hr_hr": "Sosnovy Bor",
      "hu_hu": "Szosznovij Bor",
      "it_it": "Sosnovy Bor",
      "ja_jp": "ソスノヴイ ボール",
      "ka_ge": "სოსნოვი-ბორი",
      "ko_kr": "소스노비보르",
      "lt_lt": "Sosnovy Boras",
      "lv_lv": "Sosnovijbora",
      "mk_mk": "Соснови Бор",
      "nl_nl": "Sosnovy Bor",
      "no_no": "Sosnovy Bor",
      "pl_pl": "Sosnowy Bór",
      "pl_si": "Sosnowy Bór",
      "pt_br": "Sosnovy Bor",
      "pt_pt": "Sosnovy Bor",
      "ro_ro": "Sosnovîi Bor",
      "ru_ru": "Сосновый Бор",
      "sk_sk": "Sosnovy Bor",
      "sl_sl": "Sosnovi Bor",
      "sr_sp": "Sosnovi Bor",
      "sr_sr": "Соснови Бор",
      "sv_se": "Sosnovyj Bor",
      "tr_tr": "Sosnovy Bor",
      "uk_uk": "Сосновий Бор",
      "vi_vn": "Sosnovy Bor",
      "zh_cn": "索斯诺维波尔",
      "zh_tw": "索斯諾維博爾"
    }
  },
  {
    "Name": "Выборг",
    "Country": "russia",
    "X": 53965.14,
    "Y": -60641.9336,
    "InGameId": "vyborg",
    "LocalizationToken": "vyborg",
    "CountryId": 38,
    "LocalizedNames": {
      "bg_bg": "Виборг",
      "ca_es": "Víborg",
      "cs_cz": "Vyborg",
      "da_dk": "Vyborg",
      "de_de": "Wyborg",
      "el_gr": "Βίμποργκ",
      "en_gb": "Vyborg",
      "en_us": "Vyborg",
      "es_es": "Víborg",
      "es_la": "Víborg",
      "et_ee": "Viiburi",
      "eu_es": "Vyborg",
      "fi_fi": "Viipuri",
      "fr_ca": "Vyborg",
      "fr_fr": "Vyborg",
      "gl_es": "Viburgo",
      "hr_hr": "Vyborg",
      "hu_hu": "Viborg",
      "it_it": "Vyborg",
      "ja_jp": "ヴィボルグ",
      "ka_ge": "ვიბორგი",
      "ko_kr": "비보르크",
      "lt_lt": "Vyborgas",
      "lv_lv": "Viborga",
      "mk_mk": "Виборг",
      "nl_nl": "Vyborg",
      "no_no": "Vyborg",
      "pl_pl": "Wyborg",
      "pl_si": "Wyborg",
      "pt_br": "Vyborg",
      "pt_pt": "Vyborg",
      "ro_ro": "Vyborg",
      "ru_ru": "Выборг",
      "sk_sk": "Vyborg",
      "sl_sl": "Vyborg",
      "sr_sp": "Viborg",
      "sr_sr": "Виборг",
      "sv_se": "Viborg",
      "tr_tr": "Vyborg",
      "uk_uk": "Виборг",
      "vi_vn": "Vyborg",
      "zh_cn": "维堡",
      "zh_tw": "維堡"
    }
  },
  {
    "Name": "Санкт-Петербург",
    "Country": "russia",
    "X": 59623.6953,
    "Y": -57210.7734,
    "InGameId": "petersburg",
    "LocalizationToken": "saint_petersburg",
    "CountryId": 38,
    "LocalizedNames": {
      "bg_bg": "Санкт Петербург",
      "ca_es": "Sant Petersburg",
      "cs_cz": "Petrohrad",
      "da_dk": "Sankt Petersborg",
      "de_de": "Sankt Petersburg",
      "el_gr": "Αγία Πετρούπολη",
      "en_gb": "Saint Petersburg",
      "en_us": "Saint Petersburg",
      "es_es": "San Petersburgo",
      "es_la": "San Petersburgo",
      "et_ee": "Peterburi",
      "eu_es": "San Petersburgo",
      "fi_fi": "Pietari",
      "fr_ca": "Saint-Pétersbourg",
      "fr_fr": "Saint-Pétersbourg",
      "gl_es": "San Petersburgo",
      "hr_hr": "Sankt-Peterburg",
      "hu_hu": "Szentpétervár",
      "it_it": "San Pietroburgo",
      "ja_jp": "サンクトペテルブルク",
      "ka_ge": "სანქტ-პეტერბურგი",
      "ko_kr": "상트페테르부르크",
      "lt_lt": "Sankt Petersburgas",
      "lv_lv": "Sanktpēterburga",
      "mk_mk": "Сент Петерсбург",
      "nl_nl": "Sint Petersburg",
      "no_no": "Sankt Petersburg",
      "pl_pl": "Sankt Petersburg",
      "pl_si": "Sankt Petersburg",
      "pt_br": "São Petersburgo",
      "pt_pt": "São Petersburgo",
      "ro_ro": "Sankt Petersburg",
      "ru_ru": "Санкт-Петербург",
      "sk_sk": "Petrohrad",
      "sl_sl": "Sankt Peterburg",
      "sr_sp": "Sankt Peterburg",
      "sr_sr": "Санкт Петербург",
      "sv_se": "Sankt Petersburg",
      "tr_tr": "Saint Petersburg",
      "uk_uk": "Санкт-Петербург",
      "vi_vn": "Saint Petersburg",
      "zh_cn": "圣彼得堡",
      "zh_tw": "聖彼得堡"
    }
  },
  {
    "Name": "Katowice",
    "Country": "poland",
    "X": 30278.66,
    "Y": 1988.70313,
    "InGameId": "katowice",
    "LocalizationToken": "katowice",
    "CountryId": 8,
    "LocalizedNames": {
      "bg_bg": "Катовице",
      "ca_es": "Katowice",
      "cs_cz": "Katovice",
      "da_dk": "Katowice",
      "de_de": "Kattowitz",
      "el_gr": "Κατοβίτσε",
      "en_gb": "Katowice",
      "en_us": "Katowice",
      "es_es": "Katowice",
      "es_la": "Katowice",
      "et_ee": "Katovice",
      "eu_es": "Katowice",
      "fi_fi": "Katowice",
      "fr_ca": "Katowice",
      "fr_fr": "Katowice",
      "gl_es": "Katowice",
      "hr_hr": "Katowice",
      "hu_hu": "Katowice",
      "it_it": "Katowice",
      "ja_jp": "カトヴィツェ",
      "ka_ge": "კატოვიცე",
      "ko_kr": "카토비체",
      "lt_lt": "Katovicai",
      "lv_lv": "Katovice",
      "mk_mk": "Катовице",
      "nl_nl": "Katowice",
      "no_no": "Katowice",
      "pl_pl": "Katowice",
      "pl_si": "Katowice",
      "pt_br": "Katovice",
      "pt_pt": "Katowice",
      "ro_ro": "Katowice",
      "ru_ru": "Катовице",
      "sk_sk": "Katovice",
      "sl_sl": "Katovice",
      "sr_sp": "Katovice",
      "sr_sr": "Катовице",
      "sv_se": "Katowice",
      "tr_tr": "Katoviçe",
      "uk_uk": "Катовіце",
      "vi_vn": "Katowice",
      "zh_cn": "卡托维兹",
      "zh_tw": "卡托維治"
    }
  },
  {
    "Name": "Ostrava",
    "Country": "czech",
    "X": 27831.8125,
    "Y": 4715.97656,
    "InGameId": "ostrava",
    "LocalizationToken": "ostrava",
    "CountryId": 3,
    "LocalizedNames": {
      "bg_bg": "Острава",
      "ca_es": "Ostrava",
      "cs_cz": "Ostrava",
      "da_dk": "Ostrava",
      "de_de": "Ostrau",
      "el_gr": "Οστράβα",
      "en_gb": "Ostrava",
      "en_us": "Ostrava",
      "es_es": "Ostrava",
      "es_la": "Ostrava",
      "et_ee": "Ostrava",
      "eu_es": "Ostrava",
      "fi_fi": "Ostrava",
      "fr_ca": "Ostrava",
      "fr_fr": "Ostrava",
      "gl_es": "Ostrava",
      "hr_hr": "Ostrava",
      "hu_hu": "Ostrava",
      "it_it": "Ostrava",
      "ja_jp": "オストラヴァ",
      "ka_ge": "ოსტრავა",
      "ko_kr": "오스트라바",
      "lt_lt": "Ostrava",
      "lv_lv": "Ostrava",
      "mk_mk": "Острава",
      "nl_nl": "Ostrava",
      "no_no": "Ostrava",
      "pl_pl": "Ostrawa",
      "pl_si": "Ôstrawa",
      "pt_br": "Ostrava",
      "pt_pt": "Ostrava",
      "ro_ro": "Ostrava",
      "ru_ru": "Острава",
      "sk_sk": "Ostrava",
      "sl_sl": "Ostrava",
      "sr_sp": "Ostrava",
      "sr_sr": "Острава",
      "sv_se": "Ostrava",
      "tr_tr": "Ostrava",
      "uk_uk": "Острава",
      "vi_vn": "Ostrava",
      "zh_cn": "俄斯特拉发",
      "zh_tw": "奧斯特拉瓦"
    }
  },
  {
    "Name": "Pécs",
    "Country": "Hungary",
    "X": 28439.8047,
    "Y": 25941.5234,
    "InGameId": "pecs",
    "LocalizationToken": "pecs",
    "CountryId": 13,
    "LocalizedNames": {
      "bg_bg": "Печ",
      "ca_es": "Pécs",
      "cs_cz": "Pécs",
      "da_dk": "Pécs",
      "de_de": "Fünfkirchen",
      "el_gr": "Πετς",
      "en_gb": "Pécs",
      "en_us": "Pécs",
      "es_es": "Pécs",
      "es_la": "Pécs",
      "et_ee": "Pécs",
      "eu_es": "Pécs",
      "fi_fi": "Pécs",
      "fr_ca": "Pécs",
      "fr_fr": "Pécs",
      "gl_es": "Pécs",
      "hr_hr": "Pečuh",
      "hu_hu": "Pécs",
      "it_it": "Pécs",
      "ja_jp": "ペーチ",
      "ka_ge": "პეჩი",
      "ko_kr": "페치",
      "lt_lt": "Pėčas",
      "lv_lv": "Pēča",
      "mk_mk": "Печ",
      "nl_nl": "Pécs",
      "no_no": "Pécs",
      "pl_pl": "Pecz",
      "pl_si": "Pecz",
      "pt_br": "Pécs",
      "pt_pt": "Pécs",
      "ro_ro": "Pécs",
      "ru_ru": "Печ",
      "sk_sk": "Pécs",
      "sl_sl": "Pécs",
      "sr_sp": "Pečuj",
      "sr_sr": "Печуј",
      "sv_se": "Pécs",
      "tr_tr": "Peçuy",
      "uk_uk": "Печ",
      "vi_vn": "Pécs",
      "zh_cn": "佩奇",
      "zh_tw": "貝赤"
    }
  },
  {
    "Name": "Gdańsk",
    "Country": "poland",
    "X": 27642.9727,
    "Y": -21539.6641,
    "InGameId": "gdansk",
    "LocalizationToken": "gdansk",
    "CountryId": 8,
    "LocalizedNames": {
      "bg_bg": "Гданск",
      "ca_es": "Gdańsk",
      "cs_cz": "Gdaňsk",
      "da_dk": "Gdansk",
      "de_de": "Danzig",
      "el_gr": "Γκντανσκ",
      "en_gb": "Gdańsk",
      "en_us": "Gdańsk",
      "es_es": "Gdansk",
      "es_la": "Gdansk",
      "et_ee": "Gdańsk",
      "eu_es": "Gdańsk",
      "fi_fi": "Gdańsk",
      "fr_ca": "Gdańsk",
      "fr_fr": "Gdańsk",
      "gl_es": "Gdańsk",
      "hr_hr": "Gdanjsk",
      "hu_hu": "Gdańsk",
      "it_it": "Danzica",
      "ja_jp": "グダニスク",
      "ka_ge": "გდანსკი",
      "ko_kr": "그단스크",
      "lt_lt": "Gdanskas",
      "lv_lv": "Gdaņska",
      "mk_mk": "Гдањск",
      "nl_nl": "Gdańsk",
      "no_no": "Gdańsk",
      "pl_pl": "Gdańsk",
      "pl_si": "Gdańsk",
      "pt_br": "Gdańsk",
      "pt_pt": "Gdańsk",
      "ro_ro": "Gdańsk",
      "ru_ru": "Гданьск",
      "sk_sk": "Gdaňsk",
      "sl_sl": "Gdansk",
      "sr_sp": "Gdanjsk",
      "sr_sr": "Гдањск",
      "sv_se": "Gdańsk",
      "tr_tr": "Gdańsk",
      "uk_uk": "Гданськ",
      "vi_vn": "Gdańsk",
      "zh_cn": "格但斯克",
      "zh_tw": "格但斯克"
    }
  },
  {
    "Name": "Kraków",
    "Country": "poland",
    "X": 33970.125,
    "Y": 2757.55078,
    "InGameId": "krakow",
    "LocalizationToken": "krakow",
    "CountryId": 8,
    "LocalizedNames": {
      "bg_bg": "Краков",
      "ca_es": "Cracòvia",
      "cs_cz": "Krakov",
      "da_dk": "Kraków",
      "de_de": "Krakau",
      "el_gr": "Κρακοβία",
      "en_gb": "Kraków",
      "en_us": "Kraków",
      "es_es": "Cracovia",
      "es_la": "Cracovia",
      "et_ee": "Kraków",
      "eu_es": "Krakovia",
      "fi_fi": "Krakova",
      "fr_ca": "Cracovie",
      "fr_fr": "Cracovie",
      "gl_es": "Cracovia",
      "hr_hr": "Krakov",
      "hu_hu": "Krakkó",
      "it_it": "Cracovia",
      "ja_jp": "クラクフ",
      "ka_ge": "კრაკოვი",
      "ko_kr": "크라쿠프",
      "lt_lt": "Krokuva",
      "lv_lv": "Krakova",
      "mk_mk": "Краков",
      "nl_nl": "Kraków",
      "no_no": "Kraków",
      "pl_pl": "Kraków",
      "pl_si": "Krakōw",
      "pt_br": "Cracóvia",
      "pt_pt": "Cracóvia",
      "ro_ro": "Cracovia",
      "ru_ru": "Краков",
      "sk_sk": "Krakov",
      "sl_sl": "Krakov",
      "sr_sp": "Krakov",
      "sr_sr": "Краков",
      "sv_se": "Kraków",
      "tr_tr": "Krakov",
      "uk_uk": "Краків",
      "vi_vn": "Kraków",
      "zh_cn": "克拉科夫",
      "zh_tw": "克拉科夫"
    }
  },
  {
    "Name": "Banská Bystrica",
    "Country": "slovakia",
    "X": 32257.457,
    "Y": 10360.3516,
    "InGameId": "bystrica",
    "LocalizationToken": "banska_bystrica",
    "CountryId": 11,
    "LocalizedNames": {
      "bg_bg": "Банска Бистрица",
      "ca_es": "Banská Bystrica",
      "cs_cz": "Banská Bystrica",
      "da_dk": "Banská Bystrica",
      "de_de": "Neusohl",
      "el_gr": "Μπάνσκα Μπίστριτσα",
      "en_gb": "Banská Bystrica",
      "en_us": "Banská Bystrica",
      "es_es": "Banská Bystrica",
      "es_la": "Banská Bystrica",
      "et_ee": "Banská Bystrica",
      "eu_es": "Banská Bystrica",
      "fi_fi": "Banská Bystrica",
      "fr_ca": "Banská Bystrica",
      "fr_fr": "Banská Bystrica",
      "gl_es": "Banská Bystrica",
      "hr_hr": "Banska Bistrica",
      "hu_hu": "Besztercebánya",
      "it_it": "Banská Bystrica",
      "ja_jp": "バンスカー・ビストリツァ",
      "ka_ge": "ბანსკა-ბისტრიცა",
      "ko_kr": "반스카 비스트리차",
      "lt_lt": "Banska Bistrica",
      "lv_lv": "Banska Bistrica",
      "mk_mk": "Банска Бистрица",
      "nl_nl": "Banská Bystrica",
      "no_no": "Banská Bystrica",
      "pl_pl": "Bańska Bystrzyca",
      "pl_si": "Bańska Bystrzyca",
      "pt_br": "Banská Bystrica",
      "pt_pt": "Banská Bystrica",
      "ro_ro": "Banská Bystrica",
      "ru_ru": "Банска-Бистрица",
      "sk_sk": "Banská Bystrica",
      "sl_sl": "Banská Bystrica",
      "sr_sp": "Banska Bistrica",
      "sr_sr": "Банска Бистрица",
      "sv_se": "Banská Bystrica",
      "tr_tr": "Banská Bystrica",
      "uk_uk": "Банська Бистриця",
      "vi_vn": "Banská Bystrica",
      "zh_cn": "班斯卡-比斯特里察",
      "zh_tw": "班斯卡-比斯特里察"
    }
  },
  {
    "Name": "Budapest",
    "Country": "hungary",
    "X": 31817.6953,
    "Y": 17362.5977,
    "InGameId": "budapest",
    "LocalizationToken": "budapest",
    "CountryId": 13,
    "LocalizedNames": {
      "bg_bg": "Будапеща",
      "ca_es": "Budapest",
      "cs_cz": "Budapešť",
      "da_dk": "Budapest",
      "de_de": "Budapest",
      "el_gr": "Βουδαπέστη",
      "en_gb": "Budapest",
      "en_us": "Budapest",
      "es_es": "Budapest",
      "es_la": "Budapest",
      "et_ee": "Budapest",
      "eu_es": "Budapest",
      "fi_fi": "Budapest",
      "fr_ca": "Budapest",
      "fr_fr": "Budapest",
      "gl_es": "Budapest",
      "hr_hr": "Budimpešta",
      "hu_hu": "Budapest",
      "it_it": "Budapest",
      "ja_jp": "ブダペスト",
      "ka_ge": "ბუდაპეშტი",
      "ko_kr": "부다페스트",
      "lt_lt": "Budapeštas",
      "lv_lv": "Budapešta",
      "mk_mk": "Будимпешта",
      "nl_nl": "Boedapest",
      "no_no": "Budapest",
      "pl_pl": "Budapeszt",
      "pl_si": "Budapeszt",
      "pt_br": "Budapeste",
      "pt_pt": "Budapeste",
      "ro_ro": "Budapesta",
      "ru_ru": "Будапешт",
      "sk_sk": "Budapešť",
      "sl_sl": "Budimpešta",
      "sr_sp": "Budimpešta",
      "sr_sr": "Будимпешта",
      "sv_se": "Budapest",
      "tr_tr": "Budapeşte",
      "uk_uk": "Будапешт",
      "vi_vn": "Budapest",
      "zh_cn": "布达佩斯",
      "zh_tw": "布達佩斯"
    }
  },
  {
    "Name": "Łódź",
    "Country": "poland",
    "X": 31264.3281,
    "Y": -6664.043,
    "InGameId": "lodz",
    "LocalizationToken": "lodz",
    "CountryId": 8,
    "LocalizedNames": {
      "bg_bg": "Лодз",
      "ca_es": "Łódź",
      "cs_cz": "Lodž",
      "da_dk": "Lodz",
      "de_de": "Łódź",
      "el_gr": "Λοτζ",
      "en_gb": "Łódź",
      "en_us": "Łódź",
      "es_es": "Lodz",
      "es_la": "Lodz",
      "et_ee": "Łódź",
      "eu_es": "Łódź",
      "fi_fi": "Łódź",
      "fr_ca": "Łódź",
      "fr_fr": "Łódź",
      "gl_es": "Łódź",
      "hr_hr": "Łódź",
      "hu_hu": "Łódź",
      "it_it": "Łódź",
      "ja_jp": "ウッチ",
      "ka_ge": "ლოძი",
      "ko_kr": "우치",
      "lt_lt": "Lodzė",
      "lv_lv": "Lodza",
      "mk_mk": "Лоѓ",
      "nl_nl": "Łódź",
      "no_no": "Łódź",
      "pl_pl": "Łódź",
      "pl_si": "Łōdź",
      "pt_br": "Łódź",
      "pt_pt": "Łódź",
      "ro_ro": "Łódź",
      "ru_ru": "Лодзь",
      "sk_sk": "Lodž",
      "sl_sl": "Lódž",
      "sr_sp": "Lođ",
      "sr_sr": "Лођ",
      "sv_se": "Łódź",
      "tr_tr": "Lodz",
      "uk_uk": "Лодзь",
      "vi_vn": "Łódź",
      "zh_cn": "罗兹",
      "zh_tw": "羅茲"
    }
  },
  {
    "Name": "Olsztyn",
    "Country": "poland",
    "X": 34258.4727,
    "Y": -18406.0664,
    "InGameId": "olsztyn",
    "LocalizationToken": "olsztyn",
    "CountryId": 8,
    "LocalizedNames": {
      "bg_bg": "Олщин",
      "ca_es": "Olsztyn",
      "cs_cz": "Olštýn",
      "da_dk": "Olsztyn",
      "de_de": "Allenstein",
      "el_gr": "Όλστιν",
      "en_gb": "Olsztyn",
      "en_us": "Olsztyn",
      "es_es": "Olsztyn",
      "es_la": "Olsztyn",
      "et_ee": "Olsztyn",
      "eu_es": "Olsztyn",
      "fi_fi": "Olsztyn",
      "fr_ca": "Olsztyn",
      "fr_fr": "Olsztyn",
      "gl_es": "Olsztyn",
      "hr_hr": "Olsztyn",
      "hu_hu": "Olsztyn",
      "it_it": "Olsztyn",
      "ja_jp": "オルシュティン",
      "ka_ge": "ოლშტინი",
      "ko_kr": "올슈틴",
      "lt_lt": "Olštynas",
      "lv_lv": "Olština",
      "mk_mk": "Олштин",
      "nl_nl": "Olsztyn",
      "no_no": "Olsztyn",
      "pl_pl": "Olsztyn",
      "pl_si": "Olsztyn",
      "pt_br": "Olsztyn",
      "pt_pt": "Olsztyn",
      "ro_ro": "Olsztyn",
      "ru_ru": "Ольштын",
      "sk_sk": "Olštýn",
      "sl_sl": "Olsztyn",
      "sr_sp": "Olštin",
      "sr_sr": "Олштин",
      "sv_se": "Olsztyn",
      "tr_tr": "Olsztyn",
      "uk_uk": "Ольштин",
      "vi_vn": "Olsztyn",
      "zh_cn": "奥尔什丁",
      "zh_tw": "奧士廷"
    }
  },
  {
    "Name": "Košice",
    "Country": "slovakia",
    "X": 39343.793,
    "Y": 9974.258,
    "InGameId": "kosice",
    "LocalizationToken": "kosice",
    "CountryId": 11,
    "LocalizedNames": {
      "bg_bg": "Кошице",
      "ca_es": "Košice",
      "cs_cz": "Košice",
      "da_dk": "Košice",
      "de_de": "Kaschau",
      "el_gr": "Κόσιτσε",
      "en_gb": "Košice",
      "en_us": "Košice",
      "es_es": "Košice",
      "es_la": "Košice",
      "et_ee": "Košice",
      "eu_es": "Košice",
      "fi_fi": "Košice",
      "fr_ca": "Košice",
      "fr_fr": "Košice",
      "gl_es": "Košice",
      "hr_hr": "Košice",
      "hu_hu": "Kassa",
      "it_it": "Košice",
      "ja_jp": "コシツェ",
      "ka_ge": "კოშიცე",
      "ko_kr": "코시체",
      "lt_lt": "Košicė",
      "lv_lv": "Košice",
      "mk_mk": "Кошице",
      "nl_nl": "Košice",
      "no_no": "Košice",
      "pl_pl": "Koszyce",
      "pl_si": "Koszyce",
      "pt_br": "Košice",
      "pt_pt": "Košice",
      "ro_ro": "Košice",
      "ru_ru": "Кошице",
      "sk_sk": "Košice",
      "sl_sl": "Košice",
      "sr_sp": "Košice",
      "sr_sr": "Кошице",
      "sv_se": "Košice",
      "tr_tr": "Kösice",
      "uk_uk": "Кошице",
      "vi_vn": "Košice",
      "zh_cn": "科希策",
      "zh_tw": "科希策"
    }
  },
  {
    "Name": "Warszawa",
    "Country": "poland",
    "X": 36644.79,
    "Y": -10355.9609,
    "InGameId": "warszawa",
    "LocalizationToken": "warszawa",
    "CountryId": 8,
    "LocalizedNames": {
      "bg_bg": "Варшава",
      "ca_es": "Varsòvia",
      "cs_cz": "Varšava",
      "da_dk": "Warszawa",
      "de_de": "Warschau",
      "el_gr": "Βαρσοβία",
      "en_gb": "Warsaw",
      "en_us": "Warsaw",
      "es_es": "Varsovia",
      "es_la": "Varsovia",
      "et_ee": "Varssavi",
      "eu_es": "Varsovia",
      "fi_fi": "Varsova",
      "fr_ca": "Varsovie",
      "fr_fr": "Varsovie",
      "gl_es": "Varsovia",
      "hr_hr": "Varšava",
      "hu_hu": "Varsó",
      "it_it": "Varsavia",
      "ja_jp": "ワルシャワ",
      "ka_ge": "ვარშავა",
      "ko_kr": "바르샤바",
      "lt_lt": "Varšuva",
      "lv_lv": "Varšava",
      "mk_mk": "Варшава",
      "nl_nl": "Warschau",
      "no_no": "Warszawa",
      "pl_pl": "Warszawa",
      "pl_si": "Warszawa",
      "pt_br": "Varsóvia",
      "pt_pt": "Varsóvia",
      "ro_ro": "Varşovia",
      "ru_ru": "Варшава",
      "sk_sk": "Varšava",
      "sl_sl": "Varšava",
      "sr_sp": "Varšava",
      "sr_sr": "Варшава",
      "sv_se": "Warsaw",
      "tr_tr": "Varşova",
      "uk_uk": "Варшава",
      "vi_vn": "Warszawa",
      "zh_cn": "华沙",
      "zh_tw": "華沙"
    }
  },
  {
    "Name": "Debrecen",
    "Country": "hungary",
    "X": 41291.4531,
    "Y": 16863.582,
    "InGameId": "debrecen",
    "LocalizationToken": "debrecen",
    "CountryId": 13,
    "LocalizedNames": {
      "bg_bg": "Дебрецен",
      "ca_es": "Debrecen",
      "cs_cz": "Debrecín",
      "da_dk": "Debrecen",
      "de_de": "Debrezin",
      "el_gr": "Ντέμπρετσεν",
      "en_gb": "Debrecen",
      "en_us": "Debrecen",
      "es_es": "Debrecen",
      "es_la": "Debrecen",
      "et_ee": "Debrecen",
      "eu_es": "Debrecen",
      "fi_fi": "Debrecen",
      "fr_ca": "Debrecen",
      "fr_fr": "Debrecen",
      "gl_es": "Debrecen",
      "hr_hr": "Debrecin",
      "hu_hu": "Debrecen",
      "it_it": "Debrecen",
      "ja_jp": "デブレツェン",
      "ka_ge": "დებრეცენი",
      "ko_kr": "데브레첸",
      "lt_lt": "Debrecenas",
      "lv_lv": "Debrecena",
      "mk_mk": "Дебрекен",
      "nl_nl": "Debrecen",
      "no_no": "Debrecen",
      "pl_pl": "Debreczyn",
      "pl_si": "Debreczyn",
      "pt_br": "Debrecen",
      "pt_pt": "Debrecen",
      "ro_ro": "Debreţin",
      "ru_ru": "Дебрецен",
      "sk_sk": "Debrecín",
      "sl_sl": "Debrecen",
      "sr_sp": "Debrecin",
      "sr_sr": "Дебрецин",
      "sv_se": "Debrecen",
      "tr_tr": "Debrecen",
      "uk_uk": "Дебрецен",
      "vi_vn": "Debrecen",
      "zh_cn": "德布勒森",
      "zh_tw": "德布勒森"
    }
  },
  {
    "Name": "Lublin",
    "Country": "poland",
    "X": 42744.07,
    "Y": -4625.086,
    "InGameId": "lublin",
    "LocalizationToken": "lublin",
    "CountryId": 8,
    "LocalizedNames": {
      "bg_bg": "Люблин",
      "ca_es": "Lublín",
      "cs_cz": "Lublin",
      "da_dk": "Lublin",
      "de_de": "Lublin",
      "el_gr": "Λούμπλιν",
      "en_gb": "Lublin",
      "en_us": "Lublin",
      "es_es": "Lublin",
      "es_la": "Lublin",
      "et_ee": "Lublin",
      "eu_es": "Lublin",
      "fi_fi": "Lublin",
      "fr_ca": "Lublin",
      "fr_fr": "Lublin",
      "gl_es": "Lublin",
      "hr_hr": "Lublin",
      "hu_hu": "Lublin",
      "it_it": "Lublino",
      "ja_jp": "ルブリン",
      "ka_ge": "ლუბლინი",
      "ko_kr": "루블린",
      "lt_lt": "Lublinas",
      "lv_lv": "Ļubļina",
      "mk_mk": "Лублин",
      "nl_nl": "Lublin",
      "no_no": "Lublin",
      "pl_pl": "Lublin",
      "pl_si": "Lublin",
      "pt_br": "Lublin",
      "pt_pt": "Lublin",
      "ro_ro": "Lublin",
      "ru_ru": "Люблин",
      "sk_sk": "Lublin",
      "sl_sl": "Lublin",
      "sr_sp": "Lublin",
      "sr_sr": "Лублин",
      "sv_se": "Lublin",
      "tr_tr": "Lublin",
      "uk_uk": "Люблін",
      "vi_vn": "Lublin",
      "zh_cn": "卢布林",
      "zh_tw": "盧布林"
    }
  },
  {
    "Name": "Białystok",
    "Country": "poland",
    "X": 43598.27,
    "Y": -15603.52,
    "InGameId": "bialystok",
    "LocalizationToken": "bialystok",
    "CountryId": 8,
    "LocalizedNames": {
      "bg_bg": "Бялисток",
      "ca_es": "Bialystok",
      "cs_cz": "Bělostok",
      "da_dk": "Bialystok",
      "de_de": "Białystok",
      "el_gr": "Μπιάλιστοκ",
      "en_gb": "Bialystok",
      "en_us": "Białystok",
      "es_es": "Bialystok",
      "es_la": "Bialystok",
      "et_ee": "Białystok",
      "eu_es": "Bialystok",
      "fi_fi": "Białystok",
      "fr_ca": "Bialystok",
      "fr_fr": "Bialystok",
      "gl_es": "Białystok",
      "hr_hr": "Białystok",
      "hu_hu": "Białystok",
      "it_it": "Białystok",
      "ja_jp": "ビャウィストク",
      "ka_ge": "ბელოსტოკი",
      "ko_kr": "비아위스토크",
      "lt_lt": "Balstogė",
      "lv_lv": "Bjalistoka",
      "mk_mk": "Бјалисток",
      "nl_nl": "Białystok",
      "no_no": "Białystok",
      "pl_pl": "Białystok",
      "pl_si": "Białystok",
      "pt_br": "Białystok",
      "pt_pt": "Bialystok",
      "ro_ro": "Białystok",
      "ru_ru": "Белосток",
      "sk_sk": "Bielostok",
      "sl_sl": "Białystok",
      "sr_sp": "Bjalistok",
      "sr_sr": "Бјалисток",
      "sv_se": "Białystok",
      "tr_tr": "Bialystok",
      "uk_uk": "Білосток",
      "vi_vn": "Bialystok",
      "zh_cn": "比亚韦斯托克",
      "zh_tw": "比亞維斯托克"
    }
  },
  {
    "Name": "Bastia",
    "Country": "france",
    "X": -7539.672,
    "Y": 43566.22,
    "InGameId": "bastia",
    "LocalizationToken": "bastia",
    "CountryId": 4,
    "LocalizedNames": {
      "bg_bg": "Бастия",
      "ca_es": "Bastia",
      "cs_cz": "Bastia",
      "da_dk": "Bastia",
      "de_de": "Bastia",
      "el_gr": "Μπαστιά",
      "en_gb": "Bastia",
      "en_us": "Bastia",
      "es_es": "Bastia",
      "es_la": "Bastia",
      "et_ee": "Bastia",
      "eu_es": "Bastia",
      "fi_fi": "Bastia",
      "fr_ca": "Bastia",
      "fr_fr": "Bastia",
      "gl_es": "Bastia",
      "hr_hr": "Bastia",
      "hu_hu": "Bastia",
      "it_it": "Bastia",
      "ja_jp": "バスティア",
      "ka_ge": "ბასტია",
      "ko_kr": "바스티아",
      "lt_lt": "Bastija",
      "lv_lv": "Bastia",
      "mk_mk": "Бастија",
      "nl_nl": "Bastia",
      "no_no": "Bastia",
      "pl_pl": "Bastia",
      "pl_si": "Bastia",
      "pt_br": "Bastia",
      "pt_pt": "Bastia",
      "ro_ro": "Bastia",
      "ru_ru": "Бастия",
      "sk_sk": "Bastia",
      "sl_sl": "Bastia",
      "sr_sp": "Bastija",
      "sr_sr": "Бастија",
      "sv_se": "Bastia",
      "tr_tr": "Bastia",
      "uk_uk": "Бастія",
      "vi_vn": "Bastia",
      "zh_cn": "巴斯蒂亚",
      "zh_tw": "巴斯蒂亞"
    }
  },
  {
    "Name": "Porto-Vecchio",
    "Country": "france",
    "X": -8126.668,
    "Y": 50690.0469,
    "InGameId": "porto_vecchi",
    "LocalizationToken": "porto_vecchio",
    "CountryId": 4,
    "LocalizedNames": {
      "bg_bg": "Порто Вечио",
      "ca_es": "Portivechju",
      "cs_cz": "Porto-Vecchio",
      "da_dk": "Porto-Vecchio",
      "de_de": "Porto-Vecchio",
      "el_gr": "Πόρτο Βέκιο",
      "en_gb": "Porto-Vecchio",
      "en_us": "Porto-Vecchio",
      "es_es": "Porto Vecchio",
      "es_la": "Porto Vecchio",
      "et_ee": "Porto-Vecchio",
      "eu_es": "Portivechju",
      "fi_fi": "Porto-Vecchio",
      "fr_ca": "Porto-Vecchio",
      "fr_fr": "Porto-Vecchio",
      "gl_es": "Porto-Vecchio",
      "hr_hr": "Porto-Vecchio",
      "hu_hu": "Porto-Vecchio",
      "it_it": "Porto Vecchio",
      "ja_jp": "ポルト=ベッキオ",
      "ka_ge": "პორტო-ვეკიო",
      "ko_kr": "포르토 베키오",
      "lt_lt": "Porto Večjas",
      "lv_lv": "Porto-Vecchio",
      "mk_mk": "Порто-Векио",
      "nl_nl": "Porto-Vecchio",
      "no_no": "Porto-Vecchio",
      "pl_pl": "Porto-Vecchio",
      "pl_si": "Porto-Vecchio",
      "pt_br": "Porto-Vecchio",
      "pt_pt": "Porto-Vecchio",
      "ro_ro": "Porto-Vecchio",
      "ru_ru": "Порто-Веккьо",
      "sk_sk": "Porto-Vecchio",
      "sl_sl": "Porto-Vecchio",
      "sr_sp": "Porto-Večhio",
      "sr_sr": "Порто-Векио",
      "sv_se": "Porto-Vecchio",
      "tr_tr": "Porto-Vecchio",
      "uk_uk": "Порто-Веккіо",
      "vi_vn": "Porto-Vecchio",
      "zh_cn": "韦基奥港",
      "zh_tw": "韋基奧港"
    }
  },
  {
    "Name": "Calvi",
    "Country": "france",
    "X": -10635.9688,
    "Y": 45497.4,
    "InGameId": "calvi",
    "LocalizationToken": "calvi",
    "CountryId": 4,
    "LocalizedNames": {
      "bg_bg": "Калви",
      "ca_es": "Calvi",
      "cs_cz": "Calvi",
      "da_dk": "Calvi",
      "de_de": "Calvi",
      "el_gr": "Καλβί",
      "en_gb": "Calvi",
      "en_us": "Calvi",
      "es_es": "Calvi",
      "es_la": "Calvi",
      "et_ee": "Calvi",
      "eu_es": "Calvi",
      "fi_fi": "Calvi",
      "fr_ca": "Calvi",
      "fr_fr": "Calvi",
      "gl_es": "Calvi",
      "hr_hr": "Calvi",
      "hu_hu": "Calvi",
      "it_it": "Calvi",
      "ja_jp": "カルビ",
      "ka_ge": "კალვი",
      "ko_kr": "칼비",
      "lt_lt": "Kalvi",
      "lv_lv": "Calvi",
      "mk_mk": "Калви",
      "nl_nl": "Calvi",
      "no_no": "Calvi",
      "pl_pl": "Calvi",
      "pl_si": "Calvi",
      "pt_br": "Calvi",
      "pt_pt": "Calvi",
      "ro_ro": "Calvi",
      "ru_ru": "Кальви",
      "sk_sk": "Calvi",
      "sl_sl": "Calvi",
      "sr_sp": "Kalvi",
      "sr_sr": "Калви",
      "sv_se": "Calvi",
      "tr_tr": "Calvi",
      "uk_uk": "Кальві",
      "vi_vn": "Calvi",
      "zh_cn": "卡尔维",
      "zh_tw": "卡爾維"
    }
  },
  {
    "Name": "L'Île-Rousse",
    "Country": "france",
    "X": -9164.836,
    "Y": 44256.2,
    "InGameId": "lile_rousse",
    "LocalizationToken": "lile_rousse",
    "CountryId": 4,
    "LocalizedNames": {
      "bg_bg": "Л'ил-Рус",
      "ca_es": "L'Ìsula",
      "cs_cz": "L'Île-Rousse",
      "da_dk": "L'Île-Rousse",
      "de_de": "L'Île-Rousse",
      "el_gr": "Λ'Ιλ-Ρους",
      "en_gb": "L'Île-Rousse",
      "en_us": "L'Île-Rousse",
      "es_es": "L'Île-Rousse",
      "es_la": "L'Île-Rousse",
      "et_ee": "L'Île-Rousse",
      "eu_es": "L'Île-Rousse",
      "fi_fi": "L'Île-Rousse",
      "fr_ca": "L'Île-Rousse",
      "fr_fr": "L'Île-Rousse",
      "gl_es": "L'Île-Rousse",
      "hr_hr": "L'Île-Rousse",
      "hu_hu": "L'Île-Rousse",
      "it_it": "L'Île-Rousse",
      "ja_jp": "リル=ルッス",
      "ka_ge": "ლილ-რუსე",
      "ko_kr": "릴후스",
      "lt_lt": "Lil Rusas",
      "lv_lv": "L'Île-Rousse",
      "mk_mk": "Л'Ил Рус",
      "nl_nl": "L'Île-Rousse",
      "no_no": "L'Île-Rousse",
      "pl_pl": "L'Île-Rousse",
      "pl_si": "L'Île-Rousse",
      "pt_br": "L'Île-Rousse",
      "pt_pt": "L'Île-Rousse",
      "ro_ro": "L'Île-Rousse",
      "ru_ru": "Л'Иль-Рус",
      "sk_sk": "L'Île-Rousse",
      "sl_sl": "L'Île-Rousse",
      "sr_sp": "L'Il Rus",
      "sr_sr": "Л'Ил Рус",
      "sv_se": "L'Île-Rousse",
      "tr_tr": "L'Île-Rousse",
      "uk_uk": "Л'Іль-Русс",
      "vi_vn": "L'Île-Rousse",
      "zh_cn": "利勒鲁斯",
      "zh_tw": "盧斯島"
    }
  },
  {
    "Name": "Ajaccio",
    "Country": "france",
    "X": -10531.5977,
    "Y": 47850.7656,
    "InGameId": "ajaccio",
    "LocalizationToken": "ajaccio",
    "CountryId": 4,
    "LocalizedNames": {
      "bg_bg": "Аячо",
      "ca_es": "Aiacciu",
      "cs_cz": "Ajaccio",
      "da_dk": "Ajaccio",
      "de_de": "Ajaccio",
      "el_gr": "Αιάκειο",
      "en_gb": "Ajaccio",
      "en_us": "Ajaccio",
      "es_es": "Ajaccio",
      "es_la": "Ajaccio",
      "et_ee": "Ajaccio",
      "eu_es": "Ajaccio",
      "fi_fi": "Ajaccio",
      "fr_ca": "Ajaccio",
      "fr_fr": "Ajaccio",
      "gl_es": "Ajaccio",
      "hr_hr": "Ajaccio",
      "hu_hu": "Ajaccio",
      "it_it": "Ajaccio",
      "ja_jp": "アジャクシオ",
      "ka_ge": "აიაჩო",
      "ko_kr": "아작시오",
      "lt_lt": "Ajačas",
      "lv_lv": "Ajaccio",
      "mk_mk": "Ајачо",
      "nl_nl": "Ajaccio",
      "no_no": "Ajaccio",
      "pl_pl": "Ajaccio",
      "pl_si": "Ajaccio",
      "pt_br": "Ajaccio",
      "pt_pt": "Ajácio",
      "ro_ro": "Ajaccio",
      "ru_ru": "Аяччо",
      "sk_sk": "Ajaccio",
      "sl_sl": "Ajaccio",
      "sr_sp": "Ajačo",
      "sr_sr": "Ајачо",
      "sv_se": "Ajaccio",
      "tr_tr": "Ajaccio",
      "uk_uk": "Аяччо",
      "vi_vn": "Ajaccio",
      "zh_cn": "阿雅克肖",
      "zh_tw": "阿雅克肖"
    }
  },
  {
    "Name": "Nice",
    "Country": "france",
    "X": -15870.4922,
    "Y": 37786.04,
    "InGameId": "nice",
    "LocalizationToken": "nice",
    "CountryId": 4,
    "LocalizedNames": {
      "bg_bg": "Ница",
      "ca_es": "Niça",
      "cs_cz": "Nice",
      "da_dk": "Nice",
      "de_de": "Nizza",
      "el_gr": "Νίκαια",
      "en_gb": "Nice",
      "en_us": "Nice",
      "es_es": "Niza",
      "es_la": "Niza",
      "et_ee": "Nice",
      "eu_es": "Niza",
      "fi_fi": "Nizza",
      "fr_ca": "Nice",
      "fr_fr": "Nice",
      "gl_es": "Niza",
      "hr_hr": "Nica",
      "hu_hu": "Nizza",
      "it_it": "Nizza",
      "ja_jp": "ニース",
      "ka_ge": "ნიცა",
      "ko_kr": "니스",
      "lt_lt": "Nica",
      "lv_lv": "Nica",
      "mk_mk": "Ница",
      "nl_nl": "Nice",
      "no_no": "Nice",
      "pl_pl": "Nicea",
      "pl_si": "Nicea",
      "pt_br": "Nice",
      "pt_pt": "Nice",
      "ro_ro": "Nisa",
      "ru_ru": "Ницца",
      "sk_sk": "Nice",
      "sl_sl": "Nica",
      "sr_sp": "Nica",
      "sr_sr": "Ница",
      "sv_se": "Nice",
      "tr_tr": "Nis",
      "uk_uk": "Ніцца",
      "vi_vn": "Nice",
      "zh_cn": "尼斯",
      "zh_tw": "尼斯"
    }
  },
  {
    "Name": "Marseille",
    "Country": "france",
    "X": -24222.5313,
    "Y": 38440.668,
    "InGameId": "marseille",
    "LocalizationToken": "marseille",
    "CountryId": 4,
    "LocalizedNames": {
      "bg_bg": "Марсилия",
      "ca_es": "Marsella",
      "cs_cz": "Marseille",
      "da_dk": "Marseille",
      "de_de": "Marseille",
      "el_gr": "Μασσαλία",
      "en_gb": "Marseille",
      "en_us": "Marseille",
      "es_es": "Marsella",
      "es_la": "Marsella",
      "et_ee": "Marseille",
      "eu_es": "Marseilla",
      "fi_fi": "Marseille",
      "fr_ca": "Marseille",
      "fr_fr": "Marseille",
      "gl_es": "Marsella",
      "hr_hr": "Marseille",
      "hu_hu": "Marseille",
      "it_it": "Marsiglia",
      "ja_jp": "マルセイユ",
      "ka_ge": "მარსელი",
      "ko_kr": "마르세유",
      "lt_lt": "Marselis",
      "lv_lv": "Marseļa",
      "mk_mk": "Марсеј",
      "nl_nl": "Marseille",
      "no_no": "Marseille",
      "pl_pl": "Marsylia",
      "pl_si": "Marsylia",
      "pt_br": "Marselha",
      "pt_pt": "Marselha",
      "ro_ro": "Marsilia",
      "ru_ru": "Марсель",
      "sk_sk": "Marseille",
      "sl_sl": "Marseille",
      "sr_sp": "Marselj",
      "sr_sr": "Марсељ",
      "sv_se": "Marseille",
      "tr_tr": "Marsilya",
      "uk_uk": "Марсель",
      "vi_vn": "Marseille",
      "zh_cn": "马赛",
      "zh_tw": "馬賽"
    }
  },
  {
    "Name": "Saint-Alban-du-Rhône",
    "Country": "france",
    "X": -26203.71,
    "Y": 28716.2188,
    "InGameId": "alban",
    "LocalizationToken": "saint_alban_du_rhone",
    "CountryId": 4,
    "LocalizedNames": {
      "bg_bg": "Сен-Олбанс-дю-Рон",
      "ca_es": "Saint-Alban-du-Rhône",
      "cs_cz": "Saint-Alban-du-Rhône",
      "da_dk": "Saint-Alban-du-Rhône",
      "de_de": "Saint-Alban-du-Rhône",
      "el_gr": "Σαιντ-Αλμπάν-ντυ-Ρον",
      "en_gb": "Saint-Alban-du-Rhône",
      "en_us": "Saint-Alban-du-Rhône",
      "es_es": "Saint-Alban-du-Rhône",
      "es_la": "Saint-Alban-du-Rhône",
      "et_ee": "Saint-Alban-du-Rhône",
      "eu_es": "Saint-Alban-du-Rhône",
      "fi_fi": "Saint-Alban-du-Rhône",
      "fr_ca": "Saint-Alban-du-Rhône",
      "fr_fr": "Saint-Alban-du-Rhône",
      "gl_es": "Saint-Alban-du-Rhône",
      "hr_hr": "Saint-Alban-du-Rhône",
      "hu_hu": "Saint-Alban-du-Rhône",
      "it_it": "Saint-Alban-du-Rhône",
      "ja_jp": "サン=タルバン=デュ=ローヌ",
      "ka_ge": "სენტ-ოლბანს-დიუ-რონი",
      "ko_kr": "생 알방 듀 혼느",
      "lt_lt": "Saint-Alban-du-Rhône",
      "lv_lv": "Senalbana pie Ronas",
      "mk_mk": "Сент-Албан-дју-Рон",
      "nl_nl": "Saint-Alban-du-Rhône",
      "no_no": "Saint-Alban-du-Rhône",
      "pl_pl": "Saint-Alban-du-Rhône",
      "pl_si": "Saint-Alban-du-Rhône",
      "pt_br": "Saint-Alban-du-Rhône",
      "pt_pt": "Saint-Alban-du-Rhône",
      "ro_ro": "Saint-Alban-du-Rhône",
      "ru_ru": "Сент-Альбан-дю-Рон",
      "sk_sk": "Saint-Alban-du-Rhône",
      "sl_sl": "Saint-Alban-du-Rhône",
      "sr_sp": "Sveti Alban na Roni",
      "sr_sr": "Свети Албан на Рони",
      "sv_se": "Saint-Alban-du-Rhône",
      "tr_tr": "Saint-Alban-du-Rhône",
      "uk_uk": "Сент-Альбан-дю-Рон",
      "vi_vn": "Saint-Alban-du-Rhône",
      "zh_cn": "罗讷河畔圣阿尔邦核电站",
      "zh_tw": "羅訥河畔聖阿爾邦"
    }
  },
  {
    "Name": "Bourges",
    "Country": "france",
    "X": -32141.9219,
    "Y": 16080.375,
    "InGameId": "bourges",
    "LocalizationToken": "bourges",
    "CountryId": 4,
    "LocalizedNames": {
      "bg_bg": "Бурж",
      "ca_es": "Bourges",
      "cs_cz": "Bourges",
      "da_dk": "Bourges",
      "de_de": "Bourges",
      "el_gr": "Μπουρζ",
      "en_gb": "Bourges",
      "en_us": "Bourges",
      "es_es": "Bourges",
      "es_la": "Bourges",
      "et_ee": "Bourges",
      "eu_es": "Bourges",
      "fi_fi": "Bourges",
      "fr_ca": "Bourges",
      "fr_fr": "Bourges",
      "gl_es": "Bourges",
      "hr_hr": "Bourges",
      "hu_hu": "Bourges",
      "it_it": "Bourges",
      "ja_jp": "ブールジュ",
      "ka_ge": "ბურჟე",
      "ko_kr": "부르주",
      "lt_lt": "Buržas",
      "lv_lv": "Burža",
      "mk_mk": "Бурж",
      "nl_nl": "Bourges",
      "no_no": "Bourges",
      "pl_pl": "Bourges",
      "pl_si": "Bourges",
      "pt_br": "Bourges",
      "pt_pt": "Bourges",
      "ro_ro": "Bourges",
      "ru_ru": "Бурже",
      "sk_sk": "Bourges",
      "sl_sl": "Bourges",
      "sr_sp": "Burž",
      "sr_sr": "Бурж",
      "sv_se": "Bourges",
      "tr_tr": "Bourges",
      "uk_uk": "Бурж",
      "vi_vn": "Bourges",
      "zh_cn": "布尔日",
      "zh_tw": "布爾吉"
    }
  },
  {
    "Name": "Clermont-Ferrand",
    "Country": "france",
    "X": -31047.5742,
    "Y": 24228.3125,
    "InGameId": "clermont",
    "LocalizationToken": "clermont_ferrand",
    "CountryId": 4,
    "LocalizedNames": {
      "bg_bg": "Клермон-Феран",
      "ca_es": "Clarmont d'Alvèrnia",
      "cs_cz": "Clermont-Ferrand",
      "da_dk": "Clermont-Ferrand",
      "de_de": "Clermont-Ferrand",
      "el_gr": "Κλερμόν-Φεράν",
      "en_gb": "Clermont-Ferrand",
      "en_us": "Clermont-Ferrand",
      "es_es": "Clermont-Ferrand",
      "es_la": "Clermont-Ferrand",
      "et_ee": "Clermont-Ferrand",
      "eu_es": "Clermont-Ferrand",
      "fi_fi": "Clermont-Ferrand",
      "fr_ca": "Clermont-Ferrand",
      "fr_fr": "Clermont-Ferrand",
      "gl_es": "Clermont-Ferrand",
      "hr_hr": "Clermont-Ferrand",
      "hu_hu": "Clermont-Ferrand",
      "it_it": "Clermont-Ferrand",
      "ja_jp": "クレルモン=フェラン",
      "ka_ge": "კლერმონ-ფერანი",
      "ko_kr": "클레르몽페랑",
      "lt_lt": "Klermonas-Feranas",
      "lv_lv": "Klermonferāna",
      "mk_mk": "Клермон-Феран",
      "nl_nl": "Clermont-Ferrand",
      "no_no": "Clermont-Ferrand",
      "pl_pl": "Clermont-Ferrand",
      "pl_si": "Clermont-Ferrand",
      "pt_br": "Clermont-Ferrand",
      "pt_pt": "Clermont-Ferrand",
      "ro_ro": "Clermont-Ferrand",
      "ru_ru": "Клермон-Ферран",
      "sk_sk": "Clermont-Ferrand",
      "sl_sl": "Clermont-Ferrand",
      "sr_sp": "Klermon Feran",
      "sr_sr": "Клермон Феран",
      "sv_se": "Clermont-Ferrand",
      "tr_tr": "Clermont-Ferrand",
      "uk_uk": "Клермон-Ферран",
      "vi_vn": "Clermont-Ferrand",
      "zh_cn": "克莱蒙费朗",
      "zh_tw": "克萊蒙-費朗"
    }
  },
  {
    "Name": "Montpellier",
    "Country": "france",
    "X": -30502.4883,
    "Y": 35532.4922,
    "InGameId": "montpellier",
    "LocalizationToken": "montpellier",
    "CountryId": 4,
    "LocalizedNames": {
      "bg_bg": "Монпелие",
      "ca_es": "Montpeller",
      "cs_cz": "Montpellier",
      "da_dk": "Montpellier",
      "de_de": "Montpellier",
      "el_gr": "Μονπελιέ",
      "en_gb": "Montpellier",
      "en_us": "Montpellier",
      "es_es": "Montpellier",
      "es_la": "Montpellier",
      "et_ee": "Montpellier",
      "eu_es": "Montpellier",
      "fi_fi": "Montpellier",
      "fr_ca": "Montpellier",
      "fr_fr": "Montpellier",
      "gl_es": "Montpellier",
      "hr_hr": "Montpellier",
      "hu_hu": "Montpellier",
      "it_it": "Montpellier",
      "ja_jp": "モンペリエ",
      "ka_ge": "მონპელიე",
      "ko_kr": "몽펠리에",
      "lt_lt": "Monpeljė",
      "lv_lv": "Monpeljē",
      "mk_mk": "Монпеље",
      "nl_nl": "Montpellier",
      "no_no": "Montpellier",
      "pl_pl": "Montpellier",
      "pl_si": "Montpellier",
      "pt_br": "Montpellier",
      "pt_pt": "Montpellier",
      "ro_ro": "Montpellier",
      "ru_ru": "Монпелье",
      "sk_sk": "Montpellier",
      "sl_sl": "Montpellier",
      "sr_sp": "Monpelje",
      "sr_sr": "Монпеље",
      "sv_se": "Montpellier",
      "tr_tr": "Montpellier",
      "uk_uk": "Монпельє",
      "vi_vn": "Montpellier",
      "zh_cn": "蒙彼利埃",
      "zh_tw": "蒙佩利爾"
    }
  },
  {
    "Name": "Paluel",
    "Country": "france",
    "X": -33908.2422,
    "Y": -95.22266,
    "InGameId": "paluel",
    "LocalizationToken": "paluel",
    "CountryId": 4,
    "LocalizedNames": {
      "bg_bg": "Палюл",
      "ca_es": "Paluel",
      "cs_cz": "Paluel",
      "da_dk": "Paluel",
      "de_de": "Paluel",
      "el_gr": "Παλουέλ",
      "en_gb": "Paluel",
      "en_us": "Paluel",
      "es_es": "Paluel",
      "es_la": "Paluel",
      "et_ee": "Paluel",
      "eu_es": "Paluel",
      "fi_fi": "Paluel",
      "fr_ca": "Paluel",
      "fr_fr": "Paluel",
      "gl_es": "Paluel",
      "hr_hr": "Paluel",
      "hu_hu": "Paluel",
      "it_it": "Paluel",
      "ja_jp": "パリュエル",
      "ka_ge": "პალუელი",
      "ko_kr": "팔루엘",
      "lt_lt": "Paluel",
      "lv_lv": "Paluela",
      "mk_mk": "Палуел",
      "nl_nl": "Paluel",
      "no_no": "Paluel",
      "pl_pl": "Paluel",
      "pl_si": "Paluel",
      "pt_br": "Paluel",
      "pt_pt": "Paluel",
      "ro_ro": "Paluel",
      "ru_ru": "Палюэль",
      "sk_sk": "Paluel",
      "sl_sl": "Paluel",
      "sr_sp": "Palil",
      "sr_sr": "Палил",
      "sv_se": "Paluel",
      "tr_tr": "Paluel",
      "uk_uk": "Палюель",
      "vi_vn": "Paluel",
      "zh_cn": "帕吕埃尔核电站",
      "zh_tw": "帕盧埃爾"
    }
  },
  {
    "Name": "Saint-Laurent",
    "Country": "france",
    "X": -35899.75,
    "Y": 13234.0938,
    "InGameId": "laurent",
    "LocalizationToken": "saint_laurent",
    "CountryId": 4,
    "LocalizedNames": {
      "bg_bg": "Сен Лоран",
      "ca_es": "Saint-Laurent",
      "cs_cz": "Saint-Laurent",
      "da_dk": "Saint-Laurent",
      "de_de": "Saint-Laurent",
      "el_gr": "Σαιντ Λοράν",
      "en_gb": "Saint-Laurent",
      "en_us": "Saint-Laurent",
      "es_es": "Saint-Laurent",
      "es_la": "Saint-Laurent",
      "et_ee": "Saint-Laurent",
      "eu_es": "Saint-Laurent",
      "fi_fi": "Saint-Laurent",
      "fr_ca": "Saint-Laurent",
      "fr_fr": "Saint-Laurent",
      "gl_es": "Saint-Laurent",
      "hr_hr": "Saint-Laurent",
      "hu_hu": "Saint-Laurent",
      "it_it": "Saint-Laurent",
      "ja_jp": "サン=ローラン",
      "ka_ge": "სენ-ლორენი",
      "ko_kr": "생로랑",
      "lt_lt": "Saint-Laurent",
      "lv_lv": "Senlorensa",
      "mk_mk": "Свети-Лорен",
      "nl_nl": "Saint-Laurent",
      "no_no": "Saint-Laurent",
      "pl_pl": "Saint-Laurent",
      "pl_si": "Saint-Laurent",
      "pt_br": "Saint-Laurent",
      "pt_pt": "Saint-Laurent",
      "ro_ro": "Saint-Laurent",
      "ru_ru": "Сен-Лоран",
      "sk_sk": "Saint-Laurent",
      "sl_sl": "Sveti-Laurent",
      "sr_sp": "Sen Loran",
      "sr_sr": "Сен Лоран ди Марони",
      "sv_se": "Saint-Laurent",
      "tr_tr": "Saint-Laurent",
      "uk_uk": "Сен-Лоран",
      "vi_vn": "Saint-Laurent",
      "zh_cn": "圣洛朗核电站",
      "zh_tw": "聖羅宏"
    }
  },
  {
    "Name": "Le Havre",
    "Country": "france",
    "X": -39044.293,
    "Y": 845.3594,
    "InGameId": "lehavre",
    "LocalizationToken": "le_havre",
    "CountryId": 4,
    "LocalizedNames": {
      "bg_bg": "Хавър",
      "ca_es": "Le Havre",
      "cs_cz": "Le Havre",
      "da_dk": "Le Havre",
      "de_de": "Le Havre",
      "el_gr": "Χάβρη",
      "en_gb": "Le Havre",
      "en_us": "Le Havre",
      "es_es": "El Havre",
      "es_la": "El Havre",
      "et_ee": "Le Havre",
      "eu_es": "Le Havre",
      "fi_fi": "Le Havre",
      "fr_ca": "Le Havre",
      "fr_fr": "Le Havre",
      "gl_es": "Le Havre",
      "hr_hr": "Le Havre",
      "hu_hu": "Le Havre",
      "it_it": "Le Havre",
      "ja_jp": "ル・アーヴル",
      "ka_ge": "ჰავრი",
      "ko_kr": "르아브르",
      "lt_lt": "Havras",
      "lv_lv": "Havra",
      "mk_mk": "Авр",
      "nl_nl": "Le Havre",
      "no_no": "Le Havre",
      "pl_pl": "Hawr",
      "pl_si": "Hawr",
      "pt_br": "Le Havre",
      "pt_pt": "Le Havre",
      "ro_ro": "Le Havre",
      "ru_ru": "Гавр",
      "sk_sk": "Le Havre",
      "sl_sl": "Le Havre",
      "sr_sp": "Avr",
      "sr_sr": "Авр",
      "sv_se": "Le Havre",
      "tr_tr": "Le Havre",
      "uk_uk": "Гавр",
      "vi_vn": "Le Havre",
      "zh_cn": "勒阿弗尔",
      "zh_tw": "利哈佛"
    }
  },
  {
    "Name": "Le Mans",
    "Country": "france",
    "X": -40249.8633,
    "Y": 9198.309,
    "InGameId": "lemans",
    "LocalizationToken": "le_mans",
    "CountryId": 4,
    "LocalizedNames": {
      "bg_bg": "Льо Ман",
      "ca_es": "Le Mans",
      "cs_cz": "Le Mans",
      "da_dk": "Le Mans",
      "de_de": "Le Mans",
      "el_gr": "Λε Μαν",
      "en_gb": "Le Mans",
      "en_us": "Le Mans",
      "es_es": "Le Mans",
      "es_la": "Le Mans",
      "et_ee": "Le Mans",
      "eu_es": "Le Mans",
      "fi_fi": "Le Mans",
      "fr_ca": "Le Mans",
      "fr_fr": "Le Mans",
      "gl_es": "Le Mans",
      "hr_hr": "Le Mans",
      "hu_hu": "Le Mans",
      "it_it": "Le Mans",
      "ja_jp": "ル・マン",
      "ka_ge": "ლე-მანი",
      "ko_kr": "르망",
      "lt_lt": "Le Manas",
      "lv_lv": "Lemāna",
      "mk_mk": "Ле Ман",
      "nl_nl": "Le Mans",
      "no_no": "Le Mans",
      "pl_pl": "Le Mans",
      "pl_si": "Le Mans",
      "pt_br": "Le Mans",
      "pt_pt": "Le Mans",
      "ro_ro": "Le Mans",
      "ru_ru": "Ле-Ман",
      "sk_sk": "Le Mans",
      "sl_sl": "Le Mans",
      "sr_sp": "Le Man",
      "sr_sr": "Ле Ман",
      "sv_se": "Le Mans",
      "tr_tr": "Le Mans",
      "uk_uk": "Ле-Ман",
      "vi_vn": "Le Mans",
      "zh_cn": "勒芒",
      "zh_tw": "利曼"
    }
  },
  {
    "Name": "Civaux",
    "Country": "france",
    "X": -39279.8477,
    "Y": 17996.84,
    "InGameId": "civaux",
    "LocalizationToken": "civaux",
    "CountryId": 4,
    "LocalizedNames": {
      "bg_bg": "Сивокс",
      "ca_es": "Civaux",
      "cs_cz": "Civaux",
      "da_dk": "Civaux",
      "de_de": "Civaux",
      "el_gr": "Σιβώ",
      "en_gb": "Civaux",
      "en_us": "Civaux",
      "es_es": "Civaux",
      "es_la": "Civaux",
      "et_ee": "Civaux",
      "eu_es": "Civaux",
      "fi_fi": "Civaux",
      "fr_ca": "Civaux",
      "fr_fr": "Civaux",
      "gl_es": "Civaux",
      "hr_hr": "Civaux",
      "hu_hu": "Civaux",
      "it_it": "Civaux",
      "ja_jp": "シヴォー",
      "ka_ge": "სივო",
      "ko_kr": "시보",
      "lt_lt": "Civaux",
      "lv_lv": "Sivo",
      "mk_mk": "Сиву",
      "nl_nl": "Civaux",
      "no_no": "Civaux",
      "pl_pl": "Civaux",
      "pl_si": "Civaux",
      "pt_br": "Civaux",
      "pt_pt": "Civaux",
      "ro_ro": "Civaux",
      "ru_ru": "Сиво",
      "sk_sk": "Civaux",
      "sl_sl": "Civaux",
      "sr_sp": "Sivo",
      "sr_sr": "Сиво",
      "sv_se": "Civaux",
      "tr_tr": "Civaux",
      "uk_uk": "Сіво",
      "vi_vn": "Civaux",
      "zh_cn": "锡沃核电站",
      "zh_tw": "錫沃"
    }
  },
  {
    "Name": "Limoges",
    "Country": "france",
    "X": -37817.4023,
    "Y": 23211.8555,
    "InGameId": "limoges",
    "LocalizationToken": "limoges",
    "CountryId": 4,
    "LocalizedNames": {
      "bg_bg": "Лимож",
      "ca_es": "Llemotges",
      "cs_cz": "Limoges",
      "da_dk": "Limoges",
      "de_de": "Limoges",
      "el_gr": "Λιμόζ",
      "en_gb": "Limoges",
      "en_us": "Limoges",
      "es_es": "Limoges",
      "es_la": "Limoges",
      "et_ee": "Limoges",
      "eu_es": "Limoges",
      "fi_fi": "Limoges",
      "fr_ca": "Limoges",
      "fr_fr": "Limoges",
      "gl_es": "Limoges",
      "hr_hr": "Limoges",
      "hu_hu": "Limoges",
      "it_it": "Limoges",
      "ja_jp": "リモージュ",
      "ka_ge": "ლიმოჟი",
      "ko_kr": "리모주",
      "lt_lt": "Limožas",
      "lv_lv": "Limoža",
      "mk_mk": "Лимож",
      "nl_nl": "Limoges",
      "no_no": "Limoges",
      "pl_pl": "Limoges",
      "pl_si": "Limoges",
      "pt_br": "Limoges",
      "pt_pt": "Limoges",
      "ro_ro": "Limoges",
      "ru_ru": "Лимож",
      "sk_sk": "Limoges",
      "sl_sl": "Limoges",
      "sr_sp": "Limož",
      "sr_sr": "Лимож",
      "sv_se": "Limoges",
      "tr_tr": "Limoges",
      "uk_uk": "Лімож",
      "vi_vn": "Limoges",
      "zh_cn": "利摩日",
      "zh_tw": "利摩日"
    }
  },
  {
    "Name": "Toulouse",
    "Country": "france",
    "X": -40063.3828,
    "Y": 34895.46,
    "InGameId": "toulouse",
    "LocalizationToken": "toulouse",
    "CountryId": 4,
    "LocalizedNames": {
      "bg_bg": "Тулуза",
      "ca_es": "Tolosa",
      "cs_cz": "Toulouse",
      "da_dk": "Toulouse",
      "de_de": "Toulouse",
      "el_gr": "Τουλούζη",
      "en_gb": "Toulouse",
      "en_us": "Toulouse",
      "es_es": "Toulouse",
      "es_la": "Toulouse",
      "et_ee": "Toulouse",
      "eu_es": "Tolosa Okzitania",
      "fi_fi": "Toulouse",
      "fr_ca": "Toulouse",
      "fr_fr": "Toulouse",
      "gl_es": "Tolosa",
      "hr_hr": "Toulouse",
      "hu_hu": "Toulouse",
      "it_it": "Tolosa",
      "ja_jp": "トゥールーズ",
      "ka_ge": "ტულუზა",
      "ko_kr": "툴루즈",
      "lt_lt": "Tulūza",
      "lv_lv": "Tulūza",
      "mk_mk": "Тулуза",
      "nl_nl": "Toulouse",
      "no_no": "Toulouse",
      "pl_pl": "Tuluza",
      "pl_si": "Tuluza",
      "pt_br": "Toulouse",
      "pt_pt": "Toulouse",
      "ro_ro": "Toulouse",
      "ru_ru": "Тулуза",
      "sk_sk": "Toulouse",
      "sl_sl": "Toulouse",
      "sr_sp": "Tuluz",
      "sr_sr": "Тулуз",
      "sv_se": "Toulouse",
      "tr_tr": "Tulus",
      "uk_uk": "Тулуза",
      "vi_vn": "Toulouse",
      "zh_cn": "图卢兹",
      "zh_tw": "土魯斯"
    }
  },
  {
    "Name": "Golfech",
    "Country": "france",
    "X": -40845.5156,
    "Y": 31102.43,
    "InGameId": "golfech",
    "LocalizationToken": "golfech",
    "CountryId": 4,
    "LocalizedNames": {
      "bg_bg": "Голфич",
      "ca_es": "Golfuèg",
      "cs_cz": "Golfech",
      "da_dk": "Golfech",
      "de_de": "Golfech",
      "el_gr": "Γκολφές",
      "en_gb": "Golfech",
      "en_us": "Golfech",
      "es_es": "Golfech",
      "es_la": "Golfech",
      "et_ee": "Golfech",
      "eu_es": "Golfech",
      "fi_fi": "Golfech",
      "fr_ca": "Golfech",
      "fr_fr": "Golfech",
      "gl_es": "Golfech",
      "hr_hr": "Golfech",
      "hu_hu": "Golfech",
      "it_it": "Golfech",
      "ja_jp": "ゴルフシュ",
      "ka_ge": "გოლფეში",
      "ko_kr": "골페슈",
      "lt_lt": "Golfech",
      "lv_lv": "Golfeša",
      "mk_mk": "Голфеш",
      "nl_nl": "Golfech",
      "no_no": "Golfech",
      "pl_pl": "Golfech",
      "pl_si": "Golfech",
      "pt_br": "Golfech",
      "pt_pt": "Golfech",
      "ro_ro": "Golfech",
      "ru_ru": "Гольфеш",
      "sk_sk": "Golfech",
      "sl_sl": "Golfech",
      "sr_sp": "Golfeš",
      "sr_sr": "Голфеш",
      "sv_se": "Golfech",
      "tr_tr": "Golfech",
      "uk_uk": "Гольфеш",
      "vi_vn": "Golfech",
      "zh_cn": "戈尔费什核电站",
      "zh_tw": "戈爾費什"
    }
  },
  {
    "Name": "Rennes",
    "Country": "france",
    "X": -47239.95,
    "Y": 7144.48438,
    "InGameId": "rennes",
    "LocalizationToken": "rennes",
    "CountryId": 4,
    "LocalizedNames": {
      "bg_bg": "Рен",
      "ca_es": "Rennes",
      "cs_cz": "Rennes",
      "da_dk": "Rennes",
      "de_de": "Rennes",
      "el_gr": "Ρεν",
      "en_gb": "Rennes",
      "en_us": "Rennes",
      "es_es": "Rennes",
      "es_la": "Rennes",
      "et_ee": "Rennes",
      "eu_es": "Rennes",
      "fi_fi": "Rennes",
      "fr_ca": "Rennes",
      "fr_fr": "Rennes",
      "gl_es": "Rennes",
      "hr_hr": "Rennes",
      "hu_hu": "Rennes",
      "it_it": "Rennes",
      "ja_jp": "レンヌ",
      "ka_ge": "რენი",
      "ko_kr": "렌",
      "lt_lt": "Renas",
      "lv_lv": "Renna",
      "mk_mk": "Рен",
      "nl_nl": "Rennes",
      "no_no": "Rennes",
      "pl_pl": "Rennes",
      "pl_si": "Rennes",
      "pt_br": "Rennes",
      "pt_pt": "Rennes",
      "ro_ro": "Rennes",
      "ru_ru": "Рен",
      "sk_sk": "Rennes",
      "sl_sl": "Rennes",
      "sr_sp": "Ren",
      "sr_sr": "Рен",
      "sv_se": "Rennes",
      "tr_tr": "Rennes",
      "uk_uk": "Ренн",
      "vi_vn": "Rennes",
      "zh_cn": "雷恩",
      "zh_tw": "雷恩"
    }
  },
  {
    "Name": "Nantes",
    "Country": "france",
    "X": -47221.0742,
    "Y": 13091.9219,
    "InGameId": "nantes",
    "LocalizationToken": "nantes",
    "CountryId": 4,
    "LocalizedNames": {
      "bg_bg": "Нант",
      "ca_es": "Nantes",
      "cs_cz": "Nantes",
      "da_dk": "Nantes",
      "de_de": "Nantes",
      "el_gr": "Νάντη",
      "en_gb": "Nantes",
      "en_us": "Nantes",
      "es_es": "Nantes",
      "es_la": "Nantes",
      "et_ee": "Nantes",
      "eu_es": "Nantes",
      "fi_fi": "Nantes",
      "fr_ca": "Nantes",
      "fr_fr": "Nantes",
      "gl_es": "Nantes",
      "hr_hr": "Nantes",
      "hu_hu": "Nantes",
      "it_it": "Nantes",
      "ja_jp": "ナント",
      "ka_ge": "ნანტი",
      "ko_kr": "낭트",
      "lt_lt": "Nantas",
      "lv_lv": "Nante",
      "mk_mk": "Нант",
      "nl_nl": "Nantes",
      "no_no": "Nantes",
      "pl_pl": "Nantes",
      "pl_si": "Nantes",
      "pt_br": "Nantes",
      "pt_pt": "Nantes",
      "ro_ro": "Nantes",
      "ru_ru": "Нант",
      "sk_sk": "Nantes",
      "sl_sl": "Nantes",
      "sr_sp": "Nant",
      "sr_sr": "Нантес",
      "sv_se": "Nantes",
      "tr_tr": "Nantes",
      "uk_uk": "Нант",
      "vi_vn": "Nantes",
      "zh_cn": "南特",
      "zh_tw": "南特"
    }
  },
  {
    "Name": "La Rochelle",
    "Country": "france",
    "X": -46580.87,
    "Y": 19507.4219,
    "InGameId": "larochelle",
    "LocalizationToken": "la_rochelle",
    "CountryId": 4,
    "LocalizedNames": {
      "bg_bg": "Ла Рошел",
      "ca_es": "La Rochelle",
      "cs_cz": "La Rochelle",
      "da_dk": "La Rochelle",
      "de_de": "La Rochelle",
      "el_gr": "Λα Ροσέλ",
      "en_gb": "La Rochelle",
      "en_us": "La Rochelle",
      "es_es": "La Rochelle",
      "es_la": "La Rochelle",
      "et_ee": "La Rochelle",
      "eu_es": "Arroxela",
      "fi_fi": "La Rochelle",
      "fr_ca": "La Rochelle",
      "fr_fr": "La Rochelle",
      "gl_es": "La Rochelle",
      "hr_hr": "La Rochelle",
      "hu_hu": "La Rochelle",
      "it_it": "La Rochelle",
      "ja_jp": "ラ・ロシェル",
      "ka_ge": "ლა-როშელი",
      "ko_kr": "라로셀",
      "lt_lt": "La Rošelis",
      "lv_lv": "Larošela",
      "mk_mk": "Ла Рошел",
      "nl_nl": "La Rochelle",
      "no_no": "La Rochelle",
      "pl_pl": "La Rochelle",
      "pl_si": "La Rochelle",
      "pt_br": "La Rochelle",
      "pt_pt": "La Rochelle",
      "ro_ro": "La Rochelle",
      "ru_ru": "Ла-Рошель",
      "sk_sk": "La Rochelle",
      "sl_sl": "La Rochelle",
      "sr_sp": "La Rošel",
      "sr_sr": "Ла Рошел",
      "sv_se": "La Rochelle",
      "tr_tr": "La Rochelle",
      "uk_uk": "Ла-Рошель",
      "vi_vn": "La Rochelle",
      "zh_cn": "拉罗谢尔",
      "zh_tw": "拉羅謝爾"
    }
  },
  {
    "Name": "Bordeaux",
    "Country": "france",
    "X": -46188.6875,
    "Y": 27204.27,
    "InGameId": "bordeaux",
    "LocalizationToken": "bordeaux",
    "CountryId": 4,
    "LocalizedNames": {
      "bg_bg": "Бордо",
      "ca_es": "Bordeus",
      "cs_cz": "Bordeaux",
      "da_dk": "Bordeaux",
      "de_de": "Bordeaux",
      "el_gr": "Μπορντώ",
      "en_gb": "Bordeaux",
      "en_us": "Bordeaux",
      "es_es": "Burdeos",
      "es_la": "Burdeos",
      "et_ee": "Bordeaux",
      "eu_es": "Bordele",
      "fi_fi": "Bordeaux",
      "fr_ca": "Bordeaux",
      "fr_fr": "Bordeaux",
      "gl_es": "Bordeos",
      "hr_hr": "Bordeaux",
      "hu_hu": "Bordeaux",
      "it_it": "Bordeaux",
      "ja_jp": "ボルドー",
      "ka_ge": "ბორდო",
      "ko_kr": "보르도",
      "lt_lt": "Bordo",
      "lv_lv": "Bordo",
      "mk_mk": "Бордо",
      "nl_nl": "Bordeaux",
      "no_no": "Bordeaux",
      "pl_pl": "Bordeaux",
      "pl_si": "Bordeaux",
      "pt_br": "Bordeaux",
      "pt_pt": "Bordéus",
      "ro_ro": "Bordeaux",
      "ru_ru": "Бордо",
      "sk_sk": "Bordeaux",
      "sl_sl": "Bordeaux",
      "sr_sp": "Bordo",
      "sr_sr": "Бордо",
      "sv_se": "Bordeaux",
      "tr_tr": "Bordo",
      "uk_uk": "Бордо",
      "vi_vn": "Bordeaux",
      "zh_cn": "波尔多",
      "zh_tw": "波爾多"
    }
  },
  {
    "Name": "Bayonne",
    "Country": "france",
    "X": -52332.05,
    "Y": 33317.2578,
    "InGameId": "bayonne",
    "LocalizationToken": "bayonne",
    "CountryId": 4,
    "LocalizedNames": {
      "bg_bg": "Байон",
      "ca_es": "Baiona",
      "cs_cz": "Bayonne",
      "da_dk": "Bayonne",
      "de_de": "Bayonne",
      "el_gr": "Μπαγιόν",
      "en_gb": "Bayonne",
      "en_us": "Bayonne",
      "es_es": "Bayona",
      "es_la": "Bayona",
      "et_ee": "Bayonne",
      "eu_es": "Baiona",
      "fi_fi": "Bayonne",
      "fr_ca": "Bayonne",
      "fr_fr": "Bayonne",
      "gl_es": "Baiona",
      "hr_hr": "Bayonne",
      "hu_hu": "Bayonne",
      "it_it": "Bayonne",
      "ja_jp": "バイヨンヌ",
      "ka_ge": "ბაიონა",
      "ko_kr": "바욘",
      "lt_lt": "Bajona",
      "lv_lv": "Bayonne",
      "mk_mk": "Бајон",
      "nl_nl": "Bayonne",
      "no_no": "Bayonne",
      "pl_pl": "Bajonna",
      "pl_si": "Bajonna",
      "pt_br": "Bayonne",
      "pt_pt": "Bayonne",
      "ro_ro": "Bayonne",
      "ru_ru": "Байонна",
      "sk_sk": "Bayonne",
      "sl_sl": "Bayonne",
      "sr_sp": "Bajon",
      "sr_sr": "Бајон",
      "sv_se": "Bayonne",
      "tr_tr": "Bayonne",
      "uk_uk": "Байонна",
      "vi_vn": "Bayonne",
      "zh_cn": "巴约讷",
      "zh_tw": "巴約訥"
    }
  },
  {
    "Name": "Lacq",
    "Country": "france",
    "X": -49503.62,
    "Y": 35706.46,
    "InGameId": "lacq",
    "LocalizationToken": "lacq",
    "CountryId": 4,
    "LocalizedNames": {
      "bg_bg": "Лак",
      "ca_es": "Lac",
      "cs_cz": "Lacq",
      "da_dk": "Lacq",
      "de_de": "Lacq",
      "el_gr": "Λακ",
      "en_gb": "Lacq",
      "en_us": "Lacq",
      "es_es": "Lacq",
      "es_la": "Lacq",
      "et_ee": "Lacq",
      "eu_es": "Lacq",
      "fi_fi": "Lacq",
      "fr_ca": "Lacq",
      "fr_fr": "Lacq",
      "gl_es": "Lacq",
      "hr_hr": "Lacq",
      "hu_hu": "Lacq",
      "it_it": "Lacq",
      "ja_jp": "ラック",
      "ka_ge": "ლაქი",
      "ko_kr": "라크",
      "lt_lt": "Lakas",
      "lv_lv": "Lacq",
      "mk_mk": "Лак",
      "nl_nl": "Lacq",
      "no_no": "Lacq",
      "pl_pl": "Lacq",
      "pl_si": "Lacq",
      "pt_br": "Lacq",
      "pt_pt": "Lacq",
      "ro_ro": "Lacq",
      "ru_ru": "Лак",
      "sk_sk": "Lacq",
      "sl_sl": "Lacq",
      "sr_sp": "Lack",
      "sr_sr": "Лацк",
      "sv_se": "Lacq",
      "tr_tr": "Lacq",
      "uk_uk": "Лак",
      "vi_vn": "Lacq",
      "zh_cn": "拉克",
      "zh_tw": "拉克"
    }
  },
  {
    "Name": "Roscoff",
    "Country": "france",
    "X": -53997.99,
    "Y": 2795.289,
    "InGameId": "roscoff",
    "LocalizationToken": "roscoff",
    "CountryId": 4,
    "LocalizedNames": {
      "bg_bg": "Роскоф",
      "ca_es": "Roscoff",
      "cs_cz": "Roscoff",
      "da_dk": "Roscoff",
      "de_de": "Roscoff",
      "el_gr": "Ροσκόφ",
      "en_gb": "Roscoff",
      "en_us": "Roscoff",
      "es_es": "Roscoff",
      "es_la": "Roscoff",
      "et_ee": "Roscoff",
      "eu_es": "Roscoff",
      "fi_fi": "Roscoff",
      "fr_ca": "Roscoff",
      "fr_fr": "Roscoff",
      "gl_es": "Roscoff",
      "hr_hr": "Roscoff",
      "hu_hu": "Roscoff",
      "it_it": "Roscoff",
      "ja_jp": "ロスコフ",
      "ka_ge": "როსკოფი",
      "ko_kr": "로스코프",
      "lt_lt": "Roskofas",
      "lv_lv": "Roskofa",
      "mk_mk": "Роскоф",
      "nl_nl": "Roscoff",
      "no_no": "Roscoff",
      "pl_pl": "Roscoff",
      "pl_si": "Roscoff",
      "pt_br": "Roscoff",
      "pt_pt": "Roscoff",
      "ro_ro": "Roscoff",
      "ru_ru": "Роскоф",
      "sk_sk": "Roscoff",
      "sl_sl": "Roscoff",
      "sr_sp": "Roskof",
      "sr_sr": "Роскоф",
      "sv_se": "Roscoff",
      "tr_tr": "Roscoff",
      "uk_uk": "Роскофф",
      "vi_vn": "Roscoff",
      "zh_cn": "罗斯科夫",
      "zh_tw": "羅斯科夫"
    }
  },
  {
    "Name": "Brest",
    "Country": "france",
    "X": -57421.54,
    "Y": 3199.17969,
    "InGameId": "brest",
    "LocalizationToken": "brest",
    "CountryId": 4,
    "LocalizedNames": {
      "bg_bg": "Брест",
      "ca_es": "Brest",
      "cs_cz": "Brest",
      "da_dk": "Brest",
      "de_de": "Brest",
      "el_gr": "Βρέστη",
      "en_gb": "Brest",
      "en_us": "Brest",
      "es_es": "Brest",
      "es_la": "Brest",
      "et_ee": "Brest",
      "eu_es": "Brest",
      "fi_fi": "Brest",
      "fr_ca": "Brest",
      "fr_fr": "Brest",
      "gl_es": "Brest",
      "hr_hr": "Brest",
      "hu_hu": "Brest",
      "it_it": "Brest",
      "ja_jp": "ブレスト",
      "ka_ge": "ბრესტი",
      "ko_kr": "브레스트",
      "lt_lt": "Brestas",
      "lv_lv": "Bresta",
      "mk_mk": "Брест",
      "nl_nl": "Brest",
      "no_no": "Brest",
      "pl_pl": "Brest",
      "pl_si": "Brest",
      "pt_br": "Brest",
      "pt_pt": "Brest",
      "ro_ro": "Brest",
      "ru_ru": "Брест",
      "sk_sk": "Brest",
      "sl_sl": "Brest",
      "sr_sp": "Brest",
      "sr_sr": "Брест",
      "sv_se": "Brest",
      "tr_tr": "Brest",
      "uk_uk": "Брест",
      "vi_vn": "Brest",
      "zh_cn": "布雷斯特",
      "zh_tw": "布雷斯特"
    }
  },
  {
    "Name": "Barcelona",
    "Country": "spain",
    "X": -40360.1172,
    "Y": 47101.86,
    "InGameId": "barcelona",
    "LocalizationToken": "barcelona",
    "CountryId": 42,
    "LocalizedNames": {
      "bg_bg": "Барселона",
      "ca_es": "Barcelona",
      "cs_cz": "Barcelona",
      "da_dk": "Barcelona",
      "de_de": "Barcelona",
      "el_gr": "Βαρκελώνη",
      "en_gb": "Barcelona",
      "en_us": "Barcelona",
      "es_es": "Barcelona",
      "es_la": "Barcelona",
      "et_ee": "Barcelona",
      "eu_es": "Bartzelona",
      "fi_fi": "Barcelona",
      "fr_ca": "Barcelone",
      "fr_fr": "Barcelone",
      "gl_es": "Barcelona",
      "hr_hr": "Barcelona",
      "hu_hu": "Barcelona",
      "it_it": "Barcellona",
      "ja_jp": "バルセロナ",
      "ka_ge": "ბარსელონა",
      "ko_kr": "바르셀로나",
      "lt_lt": "Barselona",
      "lv_lv": "Barcelona",
      "mk_mk": "Барселона",
      "nl_nl": "Barcelona",
      "no_no": "Barcelona",
      "pl_pl": "Barcelona",
      "pl_si": "Barcelona",
      "pt_br": "Barcelona",
      "pt_pt": "Barcelona",
      "ro_ro": "Barcelona",
      "ru_ru": "Барселона",
      "sk_sk": "Barcelona",
      "sl_sl": "Barcelona",
      "sr_sp": "Barselona",
      "sr_sr": "Барселона",
      "sv_se": "Barcelona",
      "tr_tr": "Barselona",
      "uk_uk": "Барселона",
      "vi_vn": "Barcelona",
      "zh_cn": "巴塞罗那",
      "zh_tw": "巴塞隆納"
    }
  },
  {
    "Name": "Tarragona",
    "Country": "spain",
    "X": -43568.7266,
    "Y": 48597.62,
    "InGameId": "tarragona",
    "LocalizationToken": "tarragona",
    "CountryId": 42,
    "LocalizedNames": {
      "bg_bg": "Тарагона",
      "ca_es": "Tarragona",
      "cs_cz": "Tarragona",
      "da_dk": "Tarragona",
      "de_de": "Tarragona",
      "el_gr": "Ταραγόνα",
      "en_gb": "Tarragona",
      "en_us": "Tarragona",
      "es_es": "Tarragona",
      "es_la": "Tarragona",
      "et_ee": "Tarragona",
      "eu_es": "Tarragona",
      "fi_fi": "Tarragona",
      "fr_ca": "Tarragone",
      "fr_fr": "Tarragone",
      "gl_es": "Tarragona",
      "hr_hr": "Tarragona",
      "hu_hu": "Tarragona",
      "it_it": "Tarragona",
      "ja_jp": "タラゴナ",
      "ka_ge": "ტარაგონა",
      "ko_kr": "타라고나",
      "lt_lt": "Taragona",
      "lv_lv": "Tarragona",
      "mk_mk": "Тарагона",
      "nl_nl": "Tarragona",
      "no_no": "Tarragona",
      "pl_pl": "Tarragona",
      "pl_si": "Tarragona",
      "pt_br": "Tarragona",
      "pt_pt": "Tarragona",
      "ro_ro": "Tarragona",
      "ru_ru": "Таррагона",
      "sk_sk": "Tarragona",
      "sl_sl": "Tarragona",
      "sr_sp": "Taragona",
      "sr_sr": "Тарагона",
      "sv_se": "Tarragona",
      "tr_tr": "Tarragona",
      "uk_uk": "Таррагона",
      "vi_vn": "Tarragona",
      "zh_cn": "塔拉戈纳",
      "zh_tw": "塔拉戈納"
    }
  },
  {
    "Name": "Lleida",
    "Country": "spain",
    "X": -45830.9336,
    "Y": 44928.6641,
    "InGameId": "lleida",
    "LocalizationToken": "lleida",
    "CountryId": 42,
    "LocalizedNames": {
      "bg_bg": "Лерида",
      "ca_es": "Lleida",
      "cs_cz": "Lleida",
      "da_dk": "Lleida",
      "de_de": "Lleida",
      "el_gr": "Λιέιδα",
      "en_gb": "Lleida",
      "en_us": "Lleida",
      "es_es": "Lleida",
      "es_la": "Lleida",
      "et_ee": "Lleida",
      "eu_es": "Lleida",
      "fi_fi": "Lleida",
      "fr_ca": "Lérida",
      "fr_fr": "Lérida",
      "gl_es": "Lleida",
      "hr_hr": "Lleida",
      "hu_hu": "Lleida",
      "it_it": "Lleida",
      "ja_jp": "リェイダ",
      "ka_ge": "ლიეიდა",
      "ko_kr": "레리다",
      "lt_lt": "Lerida",
      "lv_lv": "Lleida",
      "mk_mk": "Љеида",
      "nl_nl": "Lleida",
      "no_no": "Lleida",
      "pl_pl": "Lleida",
      "pl_si": "Lleida",
      "pt_br": "Lérida",
      "pt_pt": "Lleida",
      "ro_ro": "Lleida",
      "ru_ru": "Льейда",
      "sk_sk": "Lleida",
      "sl_sl": "Lleida",
      "sr_sp": "Ljeida",
      "sr_sr": "Љеида",
      "sv_se": "Lleida",
      "tr_tr": "Lleida",
      "uk_uk": "Леріда",
      "vi_vn": "Lleida",
      "zh_cn": "莱里达",
      "zh_tw": "萊里達"
    }
  },
  {
    "Name": "Vandellòs",
    "Country": "spain",
    "X": -45807.7344,
    "Y": 50172.63,
    "InGameId": "vandellos",
    "LocalizationToken": "vandellos",
    "CountryId": 42,
    "LocalizedNames": {
      "bg_bg": "Ванделъс",
      "ca_es": "Vandellòs",
      "cs_cz": "Vandellòs",
      "da_dk": "Vandellòs",
      "de_de": "Vandellòs",
      "el_gr": "Βαντεγιός",
      "en_gb": "Vandellòs",
      "en_us": "Vandellòs",
      "es_es": "Vandellós",
      "es_la": "Vandellós",
      "et_ee": "Vandellòs",
      "eu_es": "Vandellòs",
      "fi_fi": "Vandellòs",
      "fr_ca": "Vandellòs",
      "fr_fr": "Vandellòs",
      "gl_es": "Vandellòs",
      "hr_hr": "Vandellòs",
      "hu_hu": "Vandellòs",
      "it_it": "Vandellòs",
      "ja_jp": "バンデリョス",
      "ka_ge": "ვანდელოსი",
      "ko_kr": "반델로스",
      "lt_lt": "Vandeljosas",
      "lv_lv": "Vandellòs",
      "mk_mk": "Вандељос",
      "nl_nl": "Vandellòs",
      "no_no": "Vandellòs",
      "pl_pl": "Vandellòs",
      "pl_si": "Vandellòs",
      "pt_br": "Vandellòs",
      "pt_pt": "Vandellòs",
      "ro_ro": "Vandellòs",
      "ru_ru": "Вандельос",
      "sk_sk": "Vandellòs",
      "sl_sl": "Vandellòs",
      "sr_sp": "Vandeljos",
      "sr_sr": "Вандељос",
      "sv_se": "Vandellòs",
      "tr_tr": "Vandellòs",
      "uk_uk": "Вандельос",
      "vi_vn": "Vandellòs",
      "zh_cn": "班德略斯",
      "zh_tw": "班德略斯"
    }
  },
  {
    "Name": "Zaragoza",
    "Country": "spain",
    "X": -51574.5234,
    "Y": 43273.8945,
    "InGameId": "zaragoza",
    "LocalizationToken": "zaragoza",
    "CountryId": 42,
    "LocalizedNames": {
      "bg_bg": "Сарагоса",
      "ca_es": "Saragossa",
      "cs_cz": "Zaragoza",
      "da_dk": "Zaragoza",
      "de_de": "Saragossa",
      "el_gr": "Σαραγόσα",
      "en_gb": "Zaragoza",
      "en_us": "Zaragoza",
      "es_es": "Zaragoza",
      "es_la": "Zaragoza",
      "et_ee": "Zaragoza",
      "eu_es": "Zaragoza",
      "fi_fi": "Zaragoza",
      "fr_ca": "Saragosse",
      "fr_fr": "Saragosse",
      "gl_es": "Zaragoza",
      "hr_hr": "Zaragoza",
      "hu_hu": "Zaragoza",
      "it_it": "Zaragoza",
      "ja_jp": "サラゴサ",
      "ka_ge": "სარაგოსა",
      "ko_kr": "사라고사",
      "lt_lt": "Saragosa",
      "lv_lv": "Zaragoza",
      "mk_mk": "Сарагоса",
      "nl_nl": "Zaragoza",
      "no_no": "Zaragoza",
      "pl_pl": "Saragossa",
      "pl_si": "Saragossa",
      "pt_br": "Saragoça",
      "pt_pt": "Saragoça",
      "ro_ro": "Zaragoza",
      "ru_ru": "Сарагоса",
      "sk_sk": "Zaragoza",
      "sl_sl": "Zaragoza",
      "sr_sp": "Saragosa",
      "sr_sr": "Сарагоса",
      "sv_se": "Zaragoza",
      "tr_tr": "Zaragoza",
      "uk_uk": "Сарагоса",
      "vi_vn": "Zaragoza",
      "zh_cn": "萨拉戈萨",
      "zh_tw": "札拉哥沙"
    }
  },
  {
    "Name": "Port de Sagunt",
    "Country": "spain",
    "X": -51026.0625,
    "Y": 55138.2227,
    "InGameId": "port_sagunt",
    "LocalizationToken": "port_de_sagunt",
    "CountryId": 42,
    "LocalizedNames": {
      "bg_bg": "Пристанище Сагунто",
      "ca_es": "Port de Sagunt",
      "cs_cz": "Port de Sagunt",
      "da_dk": "Port de Sagunt",
      "de_de": "Port de Sagunt",
      "el_gr": "Πορτ ντε Σαγούντ",
      "en_gb": "Port de Sagunt",
      "en_us": "Port de Sagunt",
      "es_es": "Puerto de Sagunto",
      "es_la": "Puerto de Sagunto",
      "et_ee": "Port de Sagunt",
      "eu_es": "Port de Sagunt",
      "fi_fi": "Port de Sagunt",
      "fr_ca": "Port de Sagunt",
      "fr_fr": "Port de Sagunt",
      "gl_es": "Porto de Sagunto",
      "hr_hr": "Port de Sagunt",
      "hu_hu": "Port de Sagunt",
      "it_it": "Port de Sagunt",
      "ja_jp": "ポート・デ・サグント",
      "ka_ge": "საგუნტო-პორტი",
      "ko_kr": "포르트 데 사군트",
      "lt_lt": "Sagunto uostas",
      "lv_lv": "Port de Sagunt",
      "mk_mk": "Пристаниште Сагунто",
      "nl_nl": "Port de Sagunt",
      "no_no": "Port de Sagunt",
      "pl_pl": "Port de Sagunt",
      "pl_si": "Port de Sagunt",
      "pt_br": "Port de Sagunt",
      "pt_pt": "Port de Sagunt",
      "ro_ro": "Port de Sagunt",
      "ru_ru": "Сагунто-порт",
      "sk_sk": "Port de Sagunt",
      "sl_sl": "Port de Sagunt",
      "sr_sp": "Luka Sagunto",
      "sr_sr": "Лука Сагунто",
      "sv_se": "Port de Sagunt",
      "tr_tr": "Port de Sagunt",
      "uk_uk": "Сагунто-порт",
      "vi_vn": "Port de Sagunt",
      "zh_cn": "萨贡托",
      "zh_tw": "薩貢托港"
    }
  },
  {
    "Name": "Vila-real",
    "Country": "spain",
    "X": -49979.6055,
    "Y": 53263.81,
    "InGameId": "villarreal",
    "LocalizationToken": "villarreal",
    "CountryId": 42,
    "LocalizedNames": {
      "bg_bg": "Виляреал",
      "ca_es": "Vila-real",
      "cs_cz": "Villarreal",
      "da_dk": "Villarreal",
      "de_de": "Villarreal",
      "el_gr": "Βιγιαρεάλ",
      "en_gb": "Villarreal",
      "en_us": "Villarreal",
      "es_es": "Villarreal",
      "es_la": "Villarreal",
      "et_ee": "Villarreal",
      "eu_es": "Vila-real",
      "fi_fi": "Vila-real",
      "fr_ca": "Villarreal",
      "fr_fr": "Villarreal",
      "gl_es": "Vila-real",
      "hr_hr": "Villarreal",
      "hu_hu": "Villarreal",
      "it_it": "Villarreal",
      "ja_jp": "ビジャレアル",
      "ka_ge": "ვილიარეალი",
      "ko_kr": "비야레알",
      "lt_lt": "Viljarealis",
      "lv_lv": "Villarreal",
      "mk_mk": "Виљареал",
      "nl_nl": "Villarreal",
      "no_no": "Villarreal",
      "pl_pl": "Villarreal",
      "pl_si": "Villarreal",
      "pt_br": "Villarreal",
      "pt_pt": "Villarreal",
      "ro_ro": "Villarreal",
      "ru_ru": "Вильярреаль",
      "sk_sk": "Villarreal",
      "sl_sl": "Villarreal",
      "sr_sp": "Viljareal",
      "sr_sr": "Виљареал",
      "sv_se": "Villarreal",
      "tr_tr": "Villarreal",
      "uk_uk": "Вільярреал",
      "vi_vn": "Villarreal",
      "zh_cn": "比利亚雷阿尔",
      "zh_tw": "比利亞雷阿爾"
    }
  },
  {
    "Name": "València",
    "Country": "spain",
    "X": -52433.26,
    "Y": 56537.1328,
    "InGameId": "valencia",
    "LocalizationToken": "valencia",
    "CountryId": 42,
    "LocalizedNames": {
      "bg_bg": "Валенсия",
      "ca_es": "València",
      "cs_cz": "Valencie",
      "da_dk": "Valencia",
      "de_de": "Valencia",
      "el_gr": "Βαλένθια",
      "en_gb": "Valencia",
      "en_us": "Valencia",
      "es_es": "Valencia",
      "es_la": "Valencia",
      "et_ee": "Valencia",
      "eu_es": "Valentzia",
      "fi_fi": "Valencia",
      "fr_ca": "Valence",
      "fr_fr": "Valence",
      "gl_es": "Valencia",
      "hr_hr": "Valencia",
      "hu_hu": "Valencia",
      "it_it": "Valencia",
      "ja_jp": "バレンシア",
      "ka_ge": "ვალენსია",
      "ko_kr": "발렌시아",
      "lt_lt": "Valensija",
      "lv_lv": "Valencia",
      "mk_mk": "Валенсија",
      "nl_nl": "Valencia",
      "no_no": "Valencia",
      "pl_pl": "Walencja",
      "pl_si": "Walencja",
      "pt_br": "Valência",
      "pt_pt": "Valência",
      "ro_ro": "Valencia",
      "ru_ru": "Валенсия",
      "sk_sk": "Valencia",
      "sl_sl": "Valencija",
      "sr_sp": "Valensija",
      "sr_sr": "Валенсија",
      "sv_se": "Valencia",
      "tr_tr": "Valensiya",
      "uk_uk": "Валенсія",
      "vi_vn": "Valencia",
      "zh_cn": "巴伦西亚",
      "zh_tw": "瓦倫西亞"
    }
  },
  {
    "Name": "Pamplona",
    "Country": "spain",
    "X": -53642.293,
    "Y": 37235.92,
    "InGameId": "pamplona",
    "LocalizationToken": "pamplona",
    "CountryId": 42,
    "LocalizedNames": {
      "bg_bg": "Памплона",
      "ca_es": "Pamplona",
      "cs_cz": "Pamplona",
      "da_dk": "Pamplona",
      "de_de": "Pamplona",
      "el_gr": "Παμπλόνα",
      "en_gb": "Pamplona",
      "en_us": "Pamplona",
      "es_es": "Pamplona",
      "es_la": "Pamplona",
      "et_ee": "Pamplona",
      "eu_es": "Iruñea",
      "fi_fi": "Pamplona",
      "fr_ca": "Pampelune",
      "fr_fr": "Pampelune",
      "gl_es": "Iruña",
      "hr_hr": "Pamplona",
      "hu_hu": "Pamplona",
      "it_it": "Pamplona",
      "ja_jp": "パンプローナ",
      "ka_ge": "პამპლონა",
      "ko_kr": "팜플로나",
      "lt_lt": "Pamplona",
      "lv_lv": "Pamplona",
      "mk_mk": "Памплона",
      "nl_nl": "Pamplona",
      "no_no": "Pamplona",
      "pl_pl": "Pampeluna",
      "pl_si": "Pampeluna",
      "pt_br": "Pamplona",
      "pt_pt": "Pamplona",
      "ro_ro": "Pamplona",
      "ru_ru": "Памплона",
      "sk_sk": "Pamplona",
      "sl_sl": "Pamplona",
      "sr_sp": "Pamplona",
      "sr_sr": "Памплона",
      "sv_se": "Pamplona",
      "tr_tr": "Pamplona",
      "uk_uk": "Памплона",
      "vi_vn": "Pamplona",
      "zh_cn": "潘普洛纳",
      "zh_tw": "潘普洛納"
    }
  },
  {
    "Name": "Teruel",
    "Country": "spain",
    "X": -53817.168,
    "Y": 51245.7656,
    "InGameId": "teruel",
    "LocalizationToken": "teruel",
    "CountryId": 42,
    "LocalizedNames": {
      "bg_bg": "Теруел",
      "ca_es": "Terol",
      "cs_cz": "Teruel",
      "da_dk": "Teruel",
      "de_de": "Teruel",
      "el_gr": "Τερουέλ",
      "en_gb": "Teruel",
      "en_us": "Teruel",
      "es_es": "Teruel",
      "es_la": "Teruel",
      "et_ee": "Teruel",
      "eu_es": "Teruel",
      "fi_fi": "Teruel",
      "fr_ca": "Teruel",
      "fr_fr": "Teruel",
      "gl_es": "Teruel",
      "hr_hr": "Teruel",
      "hu_hu": "Teruel",
      "it_it": "Teruel",
      "ja_jp": "テルエル",
      "ka_ge": "ტერუელი",
      "ko_kr": "테루엘",
      "lt_lt": "Teruelis",
      "lv_lv": "Teruel",
      "mk_mk": "Теруел",
      "nl_nl": "Teruel",
      "no_no": "Teruel",
      "pl_pl": "Teruel",
      "pl_si": "Teruel",
      "pt_br": "Teruel",
      "pt_pt": "Teruel",
      "ro_ro": "Teruel",
      "ru_ru": "Теруэль",
      "sk_sk": "Teruel",
      "sl_sl": "Teruel",
      "sr_sp": "Teruel",
      "sr_sr": "Теруел",
      "sv_se": "Teruel",
      "tr_tr": "Teruel",
      "uk_uk": "Теруель",
      "vi_vn": "Teruel",
      "zh_cn": "特鲁埃尔",
      "zh_tw": "特魯埃爾"
    }
  },
  {
    "Name": "Bilbao",
    "Country": "spain",
    "X": -58855.082,
    "Y": 32583.2656,
    "InGameId": "bilbao",
    "LocalizationToken": "bilbao",
    "CountryId": 42,
    "LocalizedNames": {
      "bg_bg": "Билбао",
      "ca_es": "Bilbao",
      "cs_cz": "Bilbao",
      "da_dk": "Bilbao",
      "de_de": "Bilbao",
      "el_gr": "Μπιλμπάο",
      "en_gb": "Bilbao",
      "en_us": "Bilbao",
      "es_es": "Bilbao",
      "es_la": "Bilbao",
      "et_ee": "Bilbao",
      "eu_es": "Bilbo",
      "fi_fi": "Bilbao",
      "fr_ca": "Bilbao",
      "fr_fr": "Bilbao",
      "gl_es": "Bilbao",
      "hr_hr": "Bilbao",
      "hu_hu": "Bilbao",
      "it_it": "Bilbao",
      "ja_jp": "ビルバオ",
      "ka_ge": "ბილბაო",
      "ko_kr": "빌바오",
      "lt_lt": "Bilbao",
      "lv_lv": "Bilbao",
      "mk_mk": "Билбао",
      "nl_nl": "Bilbao",
      "no_no": "Bilbao",
      "pl_pl": "Bilbao",
      "pl_si": "Bilbao",
      "pt_br": "Bilbao",
      "pt_pt": "Bilbau",
      "ro_ro": "Bilbao",
      "ru_ru": "Бильбао",
      "sk_sk": "Bilbao",
      "sl_sl": "Bilbao",
      "sr_sp": "Bilbao",
      "sr_sr": "Билбао",
      "sv_se": "Bilbao",
      "tr_tr": "Bilbao",
      "uk_uk": "Більбао",
      "vi_vn": "Bilbao",
      "zh_cn": "毕尔巴鄂",
      "zh_tw": "畢爾巴鄂"
    }
  },
  {
    "Name": "Soria",
    "Country": "spain",
    "X": -59305.1367,
    "Y": 41603.0938,
    "InGameId": "soria",
    "LocalizationToken": "soria",
    "CountryId": 42,
    "LocalizedNames": {
      "bg_bg": "Сория",
      "ca_es": "Sòria",
      "cs_cz": "Soria",
      "da_dk": "Soria",
      "de_de": "Soria",
      "el_gr": "Σορία",
      "en_gb": "Soria",
      "en_us": "Soria",
      "es_es": "Soria",
      "es_la": "Soria",
      "et_ee": "Soria",
      "eu_es": "Soria",
      "fi_fi": "Soria",
      "fr_ca": "Soria",
      "fr_fr": "Soria",
      "gl_es": "Soria",
      "hr_hr": "Soria",
      "hu_hu": "Soria",
      "it_it": "Soria",
      "ja_jp": "ソリア",
      "ka_ge": "სორია",
      "ko_kr": "소리아",
      "lt_lt": "Sorija",
      "lv_lv": "Soria",
      "mk_mk": "Сорија",
      "nl_nl": "Soria",
      "no_no": "Soria",
      "pl_pl": "Soria",
      "pl_si": "Soria",
      "pt_br": "Sória",
      "pt_pt": "Soria",
      "ro_ro": "Soria",
      "ru_ru": "Сория",
      "sk_sk": "Soria",
      "sl_sl": "Soria",
      "sr_sp": "Soria",
      "sr_sr": "Сориа",
      "sv_se": "Soria",
      "tr_tr": "Soria",
      "uk_uk": "Сорія",
      "vi_vn": "Soria",
      "zh_cn": "索里亚",
      "zh_tw": "索里亞"
    }
  },
  {
    "Name": "Albacete",
    "Country": "spain",
    "X": -58355.48,
    "Y": 58394.95,
    "InGameId": "albacete",
    "LocalizationToken": "albacete",
    "CountryId": 42,
    "LocalizedNames": {
      "bg_bg": "Албасете",
      "ca_es": "Albacete",
      "cs_cz": "Albacete",
      "da_dk": "Albacete",
      "de_de": "Albacete",
      "el_gr": "Αλμπαθέτε",
      "en_gb": "Albacete",
      "en_us": "Albacete",
      "es_es": "Albacete",
      "es_la": "Albacete",
      "et_ee": "Albacete",
      "eu_es": "Albacete",
      "fi_fi": "Albacete",
      "fr_ca": "Albacete",
      "fr_fr": "Albacete",
      "gl_es": "Albacete",
      "hr_hr": "Albacete",
      "hu_hu": "Albacete",
      "it_it": "Albacete",
      "ja_jp": "アルバセテ",
      "ka_ge": "ალბაცეტე",
      "ko_kr": "알바세테",
      "lt_lt": "Albasetė",
      "lv_lv": "Albacete",
      "mk_mk": "Албасете",
      "nl_nl": "Albacete",
      "no_no": "Albacete",
      "pl_pl": "Albacete",
      "pl_si": "Albacete",
      "pt_br": "Albacete",
      "pt_pt": "Albacete",
      "ro_ro": "Albacete",
      "ru_ru": "Альбасете",
      "sk_sk": "Albacete",
      "sl_sl": "Albacete",
      "sr_sp": "Albacete",
      "sr_sr": "Албацете",
      "sv_se": "Albacete",
      "tr_tr": "Albacete",
      "uk_uk": "Альбасете",
      "vi_vn": "Albacete",
      "zh_cn": "阿尔瓦塞特",
      "zh_tw": "阿爾巴塞特"
    }
  },
  {
    "Name": "Murcia",
    "Country": "spain",
    "X": -56501.8438,
    "Y": 64632.65,
    "InGameId": "murcia",
    "LocalizationToken": "murcia",
    "CountryId": 42,
    "LocalizedNames": {
      "bg_bg": "Мурсия",
      "ca_es": "Múrcia",
      "cs_cz": "Murcia",
      "da_dk": "Murcia",
      "de_de": "Murcia",
      "el_gr": "Μούρθια",
      "en_gb": "Murcia",
      "en_us": "Murcia",
      "es_es": "Murcia",
      "es_la": "Murcia",
      "et_ee": "Murcia",
      "eu_es": "Murtzia",
      "fi_fi": "Murcia",
      "fr_ca": "Murcie",
      "fr_fr": "Murcie",
      "gl_es": "Murcia",
      "hr_hr": "Murcia",
      "hu_hu": "Murcia",
      "it_it": "Murcia",
      "ja_jp": "ムルシア",
      "ka_ge": "მურსია",
      "ko_kr": "무르시아",
      "lt_lt": "Mursija",
      "lv_lv": "Murcia",
      "mk_mk": "Мурсија",
      "nl_nl": "Murcia",
      "no_no": "Murcia",
      "pl_pl": "Murcja",
      "pl_si": "Murcja",
      "pt_br": "Múrcia",
      "pt_pt": "Múrcia",
      "ro_ro": "Murcia",
      "ru_ru": "Мурсия",
      "sk_sk": "Murcia",
      "sl_sl": "Murcia",
      "sr_sp": "Mursija",
      "sr_sr": "Мурсија",
      "sv_se": "Murcia",
      "tr_tr": "Murcia",
      "uk_uk": "Мурсія",
      "vi_vn": "Murcia",
      "zh_cn": "穆尔西亚",
      "zh_tw": "莫夕亞"
    }
  },
  {
    "Name": "Santander",
    "Country": "spain",
    "X": -62560.4766,
    "Y": 31170.168,
    "InGameId": "santander",
    "LocalizationToken": "santander",
    "CountryId": 42,
    "LocalizedNames": {
      "bg_bg": "Сантандер",
      "ca_es": "Santander",
      "cs_cz": "Santander",
      "da_dk": "Santander",
      "de_de": "Santander",
      "el_gr": "Σανταντέρ",
      "en_gb": "Santander",
      "en_us": "Santander",
      "es_es": "Santander",
      "es_la": "Santander",
      "et_ee": "Santander",
      "eu_es": "Santander",
      "fi_fi": "Santander",
      "fr_ca": "Santander",
      "fr_fr": "Santander",
      "gl_es": "Santander",
      "hr_hr": "Santander",
      "hu_hu": "Santander",
      "it_it": "Santander",
      "ja_jp": "サンタンデール",
      "ka_ge": "სანტანდერი",
      "ko_kr": "산탄데르",
      "lt_lt": "Santanderas",
      "lv_lv": "Santander",
      "mk_mk": "Сантандер",
      "nl_nl": "Santander",
      "no_no": "Santander",
      "pl_pl": "Santander",
      "pl_si": "Santander",
      "pt_br": "Santander",
      "pt_pt": "Santander",
      "ro_ro": "Santander",
      "ru_ru": "Сантандер",
      "sk_sk": "Santander",
      "sl_sl": "Santander",
      "sr_sp": "Santander",
      "sr_sr": "Сантандер",
      "sv_se": "Santander",
      "tr_tr": "Santander",
      "uk_uk": "Сантандер",
      "vi_vn": "Santander",
      "zh_cn": "桑坦德",
      "zh_tw": "桑坦德"
    }
  },
  {
    "Name": "Burgos",
    "Country": "spain",
    "X": -62334.11,
    "Y": 37141.07,
    "InGameId": "burgos",
    "LocalizationToken": "burgos",
    "CountryId": 42,
    "LocalizedNames": {
      "bg_bg": "Бургос",
      "ca_es": "Burgos",
      "cs_cz": "Burgos",
      "da_dk": "Burgos",
      "de_de": "Burgos",
      "el_gr": "Μπουργκός",
      "en_gb": "Burgos",
      "en_us": "Burgos",
      "es_es": "Burgos",
      "es_la": "Burgos",
      "et_ee": "Burgos",
      "eu_es": "Burgos",
      "fi_fi": "Burgos",
      "fr_ca": "Burgos",
      "fr_fr": "Burgos",
      "gl_es": "Burgos",
      "hr_hr": "Burgos",
      "hu_hu": "Burgos",
      "it_it": "Burgos",
      "ja_jp": "ブルゴス",
      "ka_ge": "ბურგოსი",
      "ko_kr": "부르고스",
      "lt_lt": "Burgosas",
      "lv_lv": "Burgos",
      "mk_mk": "Бургос",
      "nl_nl": "Burgos",
      "no_no": "Burgos",
      "pl_pl": "Burgos",
      "pl_si": "Burgos",
      "pt_br": "Burgos",
      "pt_pt": "Burgos",
      "ro_ro": "Burgos",
      "ru_ru": "Бургос",
      "sk_sk": "Burgos",
      "sl_sl": "Burgos",
      "sr_sp": "Burgos",
      "sr_sr": "Бургос",
      "sv_se": "Burgos",
      "tr_tr": "Burgos",
      "uk_uk": "Бургос",
      "vi_vn": "Burgos",
      "zh_cn": "布尔戈斯",
      "zh_tw": "布爾戈斯"
    }
  },
  {
    "Name": "Almería",
    "Country": "spain",
    "X": -63728.0039,
    "Y": 69091.0,
    "InGameId": "almeria",
    "LocalizationToken": "almeria",
    "CountryId": 42,
    "LocalizedNames": {
      "bg_bg": "Алмерия",
      "ca_es": "Almeria",
      "cs_cz": "Almería",
      "da_dk": "Almería",
      "de_de": "Almería",
      "el_gr": "Αλμερία",
      "en_gb": "Almería",
      "en_us": "Almería",
      "es_es": "Almería",
      "es_la": "Almería",
      "et_ee": "Almería",
      "eu_es": "Almería",
      "fi_fi": "Almería",
      "fr_ca": "Almería",
      "fr_fr": "Almería",
      "gl_es": "Almería",
      "hr_hr": "Almería",
      "hu_hu": "Almería",
      "it_it": "Almería",
      "ja_jp": "アルメリア",
      "ka_ge": "ალმერია",
      "ko_kr": "알메리아",
      "lt_lt": "Almerija",
      "lv_lv": "Almería",
      "mk_mk": "Алмерија",
      "nl_nl": "Almería",
      "no_no": "Almería",
      "pl_pl": "Almería",
      "pl_si": "Almería",
      "pt_br": "Almeria",
      "pt_pt": "Almeria",
      "ro_ro": "Almería",
      "ru_ru": "Альмерия",
      "sk_sk": "Almería",
      "sl_sl": "Almería",
      "sr_sp": "Almeria",
      "sr_sr": "Алмериа",
      "sv_se": "Almeria",
      "tr_tr": "Almería",
      "uk_uk": "Альмерія",
      "vi_vn": "Almería",
      "zh_cn": "阿尔梅里亚",
      "zh_tw": "阿爾梅里亞"
    }
  },
  {
    "Name": "Valladolid",
    "Country": "spain",
    "X": -68298.24,
    "Y": 40437.5625,
    "InGameId": "valladolid",
    "LocalizationToken": "valladolid",
    "CountryId": 42,
    "LocalizedNames": {
      "bg_bg": "Валядолид",
      "ca_es": "Valladolid",
      "cs_cz": "Valladolid",
      "da_dk": "Valladolid",
      "de_de": "Valladolid",
      "el_gr": "Βαγιαδολίδ",
      "en_gb": "Valladolid",
      "en_us": "Valladolid",
      "es_es": "Valladolid",
      "es_la": "Valladolid",
      "et_ee": "Valladolid",
      "eu_es": "Valladolid",
      "fi_fi": "Valladolid",
      "fr_ca": "Valladolid",
      "fr_fr": "Valladolid",
      "gl_es": "Valladolid",
      "hr_hr": "Valladolid",
      "hu_hu": "Valladolid",
      "it_it": "Valladolid",
      "ja_jp": "バリャドリッド",
      "ka_ge": "ვალიადოლიდი",
      "ko_kr": "바야돌리드",
      "lt_lt": "Valjadolidas",
      "lv_lv": "Valladolid",
      "mk_mk": "Ваљадолид",
      "nl_nl": "Valladolid",
      "no_no": "Valladolid",
      "pl_pl": "Valladolid",
      "pl_si": "Valladolid",
      "pt_br": "Valladolid",
      "pt_pt": "Valladolid",
      "ro_ro": "Valladolid",
      "ru_ru": "Вальядолид",
      "sk_sk": "Valladolid",
      "sl_sl": "Valladolid",
      "sr_sp": "Valjadolid",
      "sr_sr": "Ваљадолид",
      "sv_se": "Valladolid",
      "tr_tr": "Valladolid",
      "uk_uk": "Вальядолід",
      "vi_vn": "Valladolid",
      "zh_cn": "巴利亚多利德",
      "zh_tw": "華拉杜列"
    }
  },
  {
    "Name": "Madrid",
    "Country": "spain",
    "X": -65897.78,
    "Y": 48202.9258,
    "InGameId": "madrid",
    "LocalizationToken": "madrid",
    "CountryId": 42,
    "LocalizedNames": {
      "bg_bg": "Мадрид",
      "ca_es": "Madrid",
      "cs_cz": "Madrid",
      "da_dk": "Madrid",
      "de_de": "Madrid",
      "el_gr": "Μαδρίτη",
      "en_gb": "Madrid",
      "en_us": "Madrid",
      "es_es": "Madrid",
      "es_la": "Madrid",
      "et_ee": "Madrid",
      "eu_es": "Madril",
      "fi_fi": "Madrid",
      "fr_ca": "Madrid",
      "fr_fr": "Madrid",
      "gl_es": "Madrid",
      "hr_hr": "Madrid",
      "hu_hu": "Madrid",
      "it_it": "Madrid",
      "ja_jp": "マドリード",
      "ka_ge": "მადრიდი",
      "ko_kr": "마드리드",
      "lt_lt": "Madridas",
      "lv_lv": "Madrid",
      "mk_mk": "Мадрид",
      "nl_nl": "Madrid",
      "no_no": "Madrid",
      "pl_pl": "Madryt",
      "pl_si": "Madryt",
      "pt_br": "Madri",
      "pt_pt": "Madrid",
      "ro_ro": "Madrid",
      "ru_ru": "Мадрид",
      "sk_sk": "Madrid",
      "sl_sl": "Madrid",
      "sr_sp": "Madrid",
      "sr_sr": "Мадрид",
      "sv_se": "Madrid",
      "tr_tr": "Madrid",
      "uk_uk": "Мадрид",
      "vi_vn": "Madrid",
      "zh_cn": "马德里",
      "zh_tw": "馬德里"
    }
  },
  {
    "Name": "Mengíbar",
    "Country": "spain",
    "X": -68015.0,
    "Y": 62969.6563,
    "InGameId": "mengibar",
    "LocalizationToken": "mengibar",
    "CountryId": 42,
    "LocalizedNames": {
      "bg_bg": "Менгибар",
      "ca_es": "Mengíbar",
      "cs_cz": "Mengíbar",
      "da_dk": "Mengíbar",
      "de_de": "Mengíbar",
      "el_gr": "Μενχιμπάρ",
      "en_gb": "Mengíbar",
      "en_us": "Mengíbar",
      "es_es": "Mengíbar",
      "es_la": "Mengíbar",
      "et_ee": "Mengíbar",
      "eu_es": "Mengíbar",
      "fi_fi": "Mengíbar",
      "fr_ca": "Mengíbar",
      "fr_fr": "Mengíbar",
      "gl_es": "Mengíbar",
      "hr_hr": "Mengíbar",
      "hu_hu": "Mengíbar",
      "it_it": "Mengíbar",
      "ja_jp": "メンヒバル",
      "ka_ge": "მენხიბარი",
      "ko_kr": "멘지바르",
      "lt_lt": "Menchibaras",
      "lv_lv": "Mengíbar",
      "mk_mk": "Менгибар",
      "nl_nl": "Mengíbar",
      "no_no": "Mengíbar",
      "pl_pl": "Mengíbar",
      "pl_si": "Mengíbar",
      "pt_br": "Mengíbar",
      "pt_pt": "Mengíbar",
      "ro_ro": "Mengíbar",
      "ru_ru": "Менхибар",
      "sk_sk": "Mengíbar",
      "sl_sl": "Mengíbar",
      "sr_sp": "Mengibar",
      "sr_sr": "Менгибар",
      "sv_se": "Mengíbar",
      "tr_tr": "Mengíbar",
      "uk_uk": "Менгібар",
      "vi_vn": "Mengíbar",
      "zh_cn": "门希瓦尔",
      "zh_tw": "門希瓦爾"
    }
  },
  {
    "Name": "El Ejido",
    "Country": "spain",
    "X": -67717.41,
    "Y": 68991.34,
    "InGameId": "el_ejido",
    "LocalizationToken": "el_ejido",
    "CountryId": 42,
    "LocalizedNames": {
      "bg_bg": "Ел Ехидо",
      "ca_es": "El Ejido",
      "cs_cz": "El Ejido",
      "da_dk": "El Ejido",
      "de_de": "El Ejido",
      "el_gr": "Ελ Εχίδο",
      "en_gb": "El Ejido",
      "en_us": "El Ejido",
      "es_es": "El Ejido",
      "es_la": "El Ejido",
      "et_ee": "El Ejido",
      "eu_es": "El Ejido",
      "fi_fi": "El Ejido",
      "fr_ca": "El Ejido",
      "fr_fr": "El Ejido",
      "gl_es": "El Ejido",
      "hr_hr": "El Ejido",
      "hu_hu": "El Ejido",
      "it_it": "El Ejido",
      "ja_jp": "エル・エヒド",
      "ka_ge": "ელ-ეხიდო",
      "ko_kr": "엘에히도",
      "lt_lt": "El Echidas",
      "lv_lv": "El Ejido",
      "mk_mk": "Ел Ехидо",
      "nl_nl": "El Ejido",
      "no_no": "El Ejido",
      "pl_pl": "El Ejido",
      "pl_si": "El Ejido",
      "pt_br": "El Ejido",
      "pt_pt": "El Ejido",
      "ro_ro": "El Ejido",
      "ru_ru": "Эль-Эхидо",
      "sk_sk": "El Ejido",
      "sl_sl": "El Ejido",
      "sr_sp": "Ehido",
      "sr_sr": "Ехидо",
      "sv_se": "El Ejido",
      "tr_tr": "El Ejido",
      "uk_uk": "Ель-Ехідо",
      "vi_vn": "El Ejido",
      "zh_cn": "埃尔埃希多",
      "zh_tw": "埃爾埃希多"
    }
  },
  {
    "Name": "Gijón",
    "Country": "spain",
    "X": -70985.84,
    "Y": 28247.2734,
    "InGameId": "gijon",
    "LocalizationToken": "gijon",
    "CountryId": 42,
    "LocalizedNames": {
      "bg_bg": "Хихон",
      "ca_es": "Gijón",
      "cs_cz": "Gijón",
      "da_dk": "Gijón",
      "de_de": "Gijón",
      "el_gr": "Χιχόν",
      "en_gb": "Gijón",
      "en_us": "Gijón",
      "es_es": "Gijón",
      "es_la": "Gijón",
      "et_ee": "Gijón",
      "eu_es": "Gijón",
      "fi_fi": "Gijón",
      "fr_ca": "Gijón",
      "fr_fr": "Gijón",
      "gl_es": "Xixón",
      "hr_hr": "Gijón",
      "hu_hu": "Gijón",
      "it_it": "Gijón",
      "ja_jp": "ヒホン",
      "ka_ge": "ხიხონი",
      "ko_kr": "히혼",
      "lt_lt": "Chichonas",
      "lv_lv": "Gijón",
      "mk_mk": "Хихон",
      "nl_nl": "Gijón",
      "no_no": "Gijón",
      "pl_pl": "Gijón",
      "pl_si": "Gijón",
      "pt_br": "Gijón",
      "pt_pt": "Gijón",
      "ro_ro": "Gijón",
      "ru_ru": "Хихон",
      "sk_sk": "Gijón",
      "sl_sl": "Gijón",
      "sr_sp": "Hihon",
      "sr_sr": "Хихон",
      "sv_se": "Gijón",
      "tr_tr": "Gijón",
      "uk_uk": "Хіхон",
      "vi_vn": "Gijón",
      "zh_cn": "希洪",
      "zh_tw": "希洪"
    }
  },
  {
    "Name": "León",
    "Country": "spain",
    "X": -70940.31,
    "Y": 34227.5039,
    "InGameId": "leon",
    "LocalizationToken": "leon",
    "CountryId": 42,
    "LocalizedNames": {
      "bg_bg": "Леон",
      "ca_es": "Lleó",
      "cs_cz": "León",
      "da_dk": "León",
      "de_de": "León",
      "el_gr": "Λεόν",
      "en_gb": "León",
      "en_us": "León",
      "es_es": "León",
      "es_la": "León",
      "et_ee": "León",
      "eu_es": "León",
      "fi_fi": "León",
      "fr_ca": "León",
      "fr_fr": "León",
      "gl_es": "León",
      "hr_hr": "León",
      "hu_hu": "León",
      "it_it": "León",
      "ja_jp": "レオン",
      "ka_ge": "ლეონი",
      "ko_kr": "레온",
      "lt_lt": "Leonas",
      "lv_lv": "León",
      "mk_mk": "Лион",
      "nl_nl": "León",
      "no_no": "León",
      "pl_pl": "León",
      "pl_si": "León",
      "pt_br": "León",
      "pt_pt": "Leão",
      "ro_ro": "León",
      "ru_ru": "Леон",
      "sk_sk": "León",
      "sl_sl": "León",
      "sr_sp": "Leon",
      "sr_sr": "Леон",
      "sv_se": "León",
      "tr_tr": "León",
      "uk_uk": "Леон",
      "vi_vn": "León",
      "zh_cn": "莱昂",
      "zh_tw": "萊昂"
    }
  },
  {
    "Name": "Ciudad Real",
    "Country": "spain",
    "X": -68946.25,
    "Y": 55954.7578,
    "InGameId": "ciudad_real",
    "LocalizationToken": "ciudad_real",
    "CountryId": 42,
    "LocalizedNames": {
      "bg_bg": "Сиудад Реал",
      "ca_es": "Ciudad Real",
      "cs_cz": "Ciudad Real",
      "da_dk": "Ciudad Real",
      "de_de": "Ciudad Real",
      "el_gr": "Σιουδάδ Ρεάλ",
      "en_gb": "Ciudad Real",
      "en_us": "Ciudad Real",
      "es_es": "Ciudad Real",
      "es_la": "Ciudad Real",
      "et_ee": "Ciudad Real",
      "eu_es": "Ciudad Real",
      "fi_fi": "Ciudad Real",
      "fr_ca": "Ciudad Real",
      "fr_fr": "Ciudad Real",
      "gl_es": "Cidade Real",
      "hr_hr": "Ciudad Real",
      "hu_hu": "Ciudad Real",
      "it_it": "Ciudad Real",
      "ja_jp": "シウダー・レアル",
      "ka_ge": "სიუდად-რეალი",
      "ko_kr": "시우다드레알",
      "lt_lt": "Siudad-Realis",
      "lv_lv": "Ciudad Real",
      "mk_mk": "Сиудад Реал",
      "nl_nl": "Ciudad Real",
      "no_no": "Ciudad Real",
      "pl_pl": "Ciudad Real",
      "pl_si": "Ciudad Real",
      "pt_br": "Ciudad Real",
      "pt_pt": "Cidade Real",
      "ro_ro": "Ciudad Real",
      "ru_ru": "Сьюдад-Реаль",
      "sk_sk": "Ciudad Real",
      "sl_sl": "Ciudad Real",
      "sr_sp": "Sijudad Real",
      "sr_sr": "Сијудад Реал",
      "sv_se": "Ciudad Real",
      "tr_tr": "Ciudad Real",
      "uk_uk": "Сьюдад-Реал",
      "vi_vn": "Ciudad Real",
      "zh_cn": "雷阿尔城",
      "zh_tw": "雷亞爾城"
    }
  },
  {
    "Name": "Puertollano",
    "Country": "spain",
    "X": -70063.05,
    "Y": 58776.8945,
    "InGameId": "puertollano",
    "LocalizationToken": "puertollano",
    "CountryId": 42,
    "LocalizedNames": {
      "bg_bg": "Пуертолано",
      "ca_es": "Puertollano",
      "cs_cz": "Puertollano",
      "da_dk": "Puertollano",
      "de_de": "Puertollano",
      "el_gr": "Πουερτογιάνο",
      "en_gb": "Puertollano",
      "en_us": "Puertollano",
      "es_es": "Puertollano",
      "es_la": "Puertollano",
      "et_ee": "Puertollano",
      "eu_es": "Puertollano",
      "fi_fi": "Puertollano",
      "fr_ca": "Puertollano",
      "fr_fr": "Puertollano",
      "gl_es": "Puertollano",
      "hr_hr": "Puertollano",
      "hu_hu": "Puertollano",
      "it_it": "Puertollano",
      "ja_jp": "プエルトリャノ",
      "ka_ge": "პუერტოლიანო",
      "ko_kr": "푸에르토야노",
      "lt_lt": "Puertoljanas",
      "lv_lv": "Puertollano",
      "mk_mk": "Пуертољано",
      "nl_nl": "Puertollano",
      "no_no": "Puertollano",
      "pl_pl": "Puertollano",
      "pl_si": "Puertollano",
      "pt_br": "Puertollano",
      "pt_pt": "Puertollano",
      "ro_ro": "Puertollano",
      "ru_ru": "Пуэртольяно",
      "sk_sk": "Puertollano",
      "sl_sl": "Puertollano",
      "sr_sp": "Puertoljano",
      "sr_sr": "Пуертољано",
      "sv_se": "Puertollano",
      "tr_tr": "Puertollano",
      "uk_uk": "Пуертояно",
      "vi_vn": "Puertollano",
      "zh_cn": "普埃尔托利亚诺",
      "zh_tw": "普埃爾托利亞諾"
    }
  },
  {
    "Name": "Bailén",
    "Country": "spain",
    "X": -69463.4844,
    "Y": 61585.25,
    "InGameId": "bailen",
    "LocalizationToken": "bailen",
    "CountryId": 42,
    "LocalizedNames": {
      "bg_bg": "Байлен",
      "ca_es": "Bailèn",
      "cs_cz": "Bailén",
      "da_dk": "Bailén",
      "de_de": "Bailén",
      "el_gr": "Μπαϊλέν",
      "en_gb": "Bailén",
      "en_us": "Bailén",
      "es_es": "Bailén",
      "es_la": "Bailén",
      "et_ee": "Bailén",
      "eu_es": "Bailén",
      "fi_fi": "Bailén",
      "fr_ca": "Bailén",
      "fr_fr": "Bailén",
      "gl_es": "Bailén",
      "hr_hr": "Bailén",
      "hu_hu": "Bailén",
      "it_it": "Bailén",
      "ja_jp": "バイレン",
      "ka_ge": "ბაილენი",
      "ko_kr": "바일렌",
      "lt_lt": "Bajlenas",
      "lv_lv": "Bailén",
      "mk_mk": "Баилен",
      "nl_nl": "Bailén",
      "no_no": "Bailén",
      "pl_pl": "Bailén",
      "pl_si": "Bailén",
      "pt_br": "Bailén",
      "pt_pt": "Bailén",
      "ro_ro": "Bailén",
      "ru_ru": "Байлен",
      "sk_sk": "Bailén",
      "sl_sl": "Bailén",
      "sr_sp": "Bailen",
      "sr_sr": "Баилен",
      "sv_se": "Bailén",
      "tr_tr": "Bailén",
      "uk_uk": "Байлен",
      "vi_vn": "Bailén",
      "zh_cn": "拜伦",
      "zh_tw": "拜倫"
    }
  },
  {
    "Name": "Granada",
    "Country": "spain",
    "X": -70482.0859,
    "Y": 65656.875,
    "InGameId": "granada",
    "LocalizationToken": "granada",
    "CountryId": 42,
    "LocalizedNames": {
      "bg_bg": "Гранада",
      "ca_es": "Granada",
      "cs_cz": "Granada",
      "da_dk": "Granada",
      "de_de": "Granada",
      "el_gr": "Γρανάδα",
      "en_gb": "Granada",
      "en_us": "Granada",
      "es_es": "Granada",
      "es_la": "Granada",
      "et_ee": "Granada",
      "eu_es": "Granada",
      "fi_fi": "Granada",
      "fr_ca": "Grenade",
      "fr_fr": "Grenade",
      "gl_es": "Granada",
      "hr_hr": "Granada",
      "hu_hu": "Granada",
      "it_it": "Granada",
      "ja_jp": "グラナダ",
      "ka_ge": "გრანადა",
      "ko_kr": "그라나다",
      "lt_lt": "Granada",
      "lv_lv": "Granada",
      "mk_mk": "Гренада",
      "nl_nl": "Granada",
      "no_no": "Granada",
      "pl_pl": "Grenada",
      "pl_si": "Grenada",
      "pt_br": "Granada",
      "pt_pt": "Granada",
      "ro_ro": "Granada",
      "ru_ru": "Гранада",
      "sk_sk": "Granada",
      "sl_sl": "Granada",
      "sr_sp": "Granada",
      "sr_sr": "Гранада",
      "sv_se": "Granada",
      "tr_tr": "Gırnata",
      "uk_uk": "Гранада",
      "vi_vn": "Granada",
      "zh_cn": "格拉纳达",
      "zh_tw": "格拉納達"
    }
  },
  {
    "Name": "Navia",
    "Country": "spain",
    "X": -75062.08,
    "Y": 27800.5039,
    "InGameId": "navia",
    "LocalizationToken": "navia",
    "CountryId": 42,
    "LocalizedNames": {
      "bg_bg": "Навия",
      "ca_es": "Navia",
      "cs_cz": "Navia",
      "da_dk": "Navia",
      "de_de": "Navia",
      "el_gr": "Νάβια",
      "en_gb": "Navia",
      "en_us": "Navia",
      "es_es": "Navia",
      "es_la": "Navia",
      "et_ee": "Navia",
      "eu_es": "Navia",
      "fi_fi": "Navia",
      "fr_ca": "Navia",
      "fr_fr": "Navia",
      "gl_es": "Navia",
      "hr_hr": "Navia",
      "hu_hu": "Navia",
      "it_it": "Navia",
      "ja_jp": "ナビア",
      "ka_ge": "ნავია",
      "ko_kr": "나비아",
      "lt_lt": "Navija",
      "lv_lv": "Navia",
      "mk_mk": "Навиа",
      "nl_nl": "Navia",
      "no_no": "Navia",
      "pl_pl": "Navia",
      "pl_si": "Navia",
      "pt_br": "Navia",
      "pt_pt": "Navia",
      "ro_ro": "Navia",
      "ru_ru": "Навия",
      "sk_sk": "Navia",
      "sl_sl": "Navia",
      "sr_sp": "Navia",
      "sr_sr": "Навиа",
      "sv_se": "Navia",
      "tr_tr": "Navia",
      "uk_uk": "Навія",
      "vi_vn": "Navia",
      "zh_cn": "纳维亚",
      "zh_tw": "納維亞"
    }
  },
  {
    "Name": "Salamanca",
    "Country": "spain",
    "X": -73525.99,
    "Y": 43417.7031,
    "InGameId": "salamanca",
    "LocalizationToken": "salamanca",
    "CountryId": 42,
    "LocalizedNames": {
      "bg_bg": "Саламанка",
      "ca_es": "Salamanca",
      "cs_cz": "Salamanca",
      "da_dk": "Salamanca",
      "de_de": "Salamanca",
      "el_gr": "Σαλαμάνκα",
      "en_gb": "Salamanca",
      "en_us": "Salamanca",
      "es_es": "Salamanca",
      "es_la": "Salamanca",
      "et_ee": "Salamanca",
      "eu_es": "Salamanca",
      "fi_fi": "Salamanca",
      "fr_ca": "Salamanque",
      "fr_fr": "Salamanque",
      "gl_es": "Salamanca",
      "hr_hr": "Salamanca",
      "hu_hu": "Salamanca",
      "it_it": "Salamanca",
      "ja_jp": "サラマンカ",
      "ka_ge": "სალამანკა",
      "ko_kr": "살라망카",
      "lt_lt": "Salamanka",
      "lv_lv": "Salamanca",
      "mk_mk": "Саламанка",
      "nl_nl": "Salamanca",
      "no_no": "Salamanca",
      "pl_pl": "Salamanka",
      "pl_si": "Salamanka",
      "pt_br": "Salamanca",
      "pt_pt": "Salamanca",
      "ro_ro": "Salamanca",
      "ru_ru": "Саламанка",
      "sk_sk": "Salamanca",
      "sl_sl": "Salamanca",
      "sr_sp": "Salamanka",
      "sr_sr": "Саламанка",
      "sv_se": "Salamanca",
      "tr_tr": "Salamanca",
      "uk_uk": "Саламанка",
      "vi_vn": "Salamanca",
      "zh_cn": "萨拉曼卡",
      "zh_tw": "薩拉曼卡"
    }
  },
  {
    "Name": "Almaraz",
    "Country": "spain",
    "X": -74733.7344,
    "Y": 49374.4258,
    "InGameId": "almaraz",
    "LocalizationToken": "almaraz",
    "CountryId": 42,
    "LocalizedNames": {
      "bg_bg": "Алмараз",
      "ca_es": "Almaraz",
      "cs_cz": "Almaraz",
      "da_dk": "Almaraz",
      "de_de": "Almaraz",
      "el_gr": "Αλμαράθ",
      "en_gb": "Almaraz",
      "en_us": "Almaraz",
      "es_es": "Almaraz",
      "es_la": "Almaraz",
      "et_ee": "Almaraz",
      "eu_es": "Almaraz",
      "fi_fi": "Almaraz",
      "fr_ca": "Almaraz",
      "fr_fr": "Almaraz",
      "gl_es": "Almaraz",
      "hr_hr": "Almaraz",
      "hu_hu": "Almaraz",
      "it_it": "Almaraz",
      "ja_jp": "アルマラス",
      "ka_ge": "ალმარასი",
      "ko_kr": "알마라스",
      "lt_lt": "Almarasas",
      "lv_lv": "Almaraz",
      "mk_mk": "Алмараз",
      "nl_nl": "Almaraz",
      "no_no": "Almaraz",
      "pl_pl": "Almaraz",
      "pl_si": "Almaraz",
      "pt_br": "Almaraz",
      "pt_pt": "Almaraz",
      "ro_ro": "Almaraz",
      "ru_ru": "Альмарас",
      "sk_sk": "Almaraz",
      "sl_sl": "Almaraz",
      "sr_sp": "Almaraz",
      "sr_sr": "Алмараз",
      "sv_se": "Almaraz",
      "tr_tr": "Almaraz",
      "uk_uk": "Альмарас",
      "vi_vn": "Almaraz",
      "zh_cn": "阿尔马拉斯",
      "zh_tw": "阿爾馬拉斯"
    }
  },
  {
    "Name": "Córdoba",
    "Country": "spain",
    "X": -74402.16,
    "Y": 61412.9,
    "InGameId": "cordoba",
    "LocalizationToken": "cordoba",
    "CountryId": 42,
    "LocalizedNames": {
      "bg_bg": "Кордоба",
      "ca_es": "Còrdova",
      "cs_cz": "Córdoba",
      "da_dk": "Córdoba",
      "de_de": "Córdoba",
      "el_gr": "Κόρδοβα",
      "en_gb": "Córdoba",
      "en_us": "Córdoba",
      "es_es": "Córdoba",
      "es_la": "Córdoba",
      "et_ee": "Córdoba",
      "eu_es": "Kordoba",
      "fi_fi": "Córdoba",
      "fr_ca": "Cordoue",
      "fr_fr": "Cordoue",
      "gl_es": "Córdoba",
      "hr_hr": "Córdoba",
      "hu_hu": "Córdoba",
      "it_it": "Córdoba",
      "ja_jp": "コルドバ",
      "ka_ge": "კორდობა",
      "ko_kr": "코르도바",
      "lt_lt": "Kordoba",
      "lv_lv": "Córdoba",
      "mk_mk": "Кордоба",
      "nl_nl": "Córdoba",
      "no_no": "Córdoba",
      "pl_pl": "Kordoba",
      "pl_si": "Kordoba",
      "pt_br": "Córdoba",
      "pt_pt": "Córdoba",
      "ro_ro": "Córdoba",
      "ru_ru": "Кордоба",
      "sk_sk": "Córdoba",
      "sl_sl": "Córdoba",
      "sr_sp": "Kordoba",
      "sr_sr": "Кордоба",
      "sv_se": "Córdoba",
      "tr_tr": "Kurtuba",
      "uk_uk": "Кордова",
      "vi_vn": "Córdoba",
      "zh_cn": "科尔多瓦",
      "zh_tw": "哥多華"
    }
  },
  {
    "Name": "Málaga",
    "Country": "spain",
    "X": -74294.31,
    "Y": 68209.875,
    "InGameId": "malaga",
    "LocalizationToken": "malaga",
    "CountryId": 42,
    "LocalizedNames": {
      "bg_bg": "Малага",
      "ca_es": "Màlaga",
      "cs_cz": "Málaga",
      "da_dk": "Málaga",
      "de_de": "Málaga",
      "el_gr": "Μάλαγα",
      "en_gb": "Málaga",
      "en_us": "Málaga",
      "es_es": "Málaga",
      "es_la": "Málaga",
      "et_ee": "Málaga",
      "eu_es": "Málaga",
      "fi_fi": "Málaga",
      "fr_ca": "Malaga",
      "fr_fr": "Malaga",
      "gl_es": "Málaga",
      "hr_hr": "Málaga",
      "hu_hu": "Málaga",
      "it_it": "Málaga",
      "ja_jp": "マラガ",
      "ka_ge": "მალაგა",
      "ko_kr": "말라가",
      "lt_lt": "Malaga",
      "lv_lv": "Málaga",
      "mk_mk": "Малага",
      "nl_nl": "Málaga",
      "no_no": "Málaga",
      "pl_pl": "Malaga",
      "pl_si": "Malaga",
      "pt_br": "Málaga",
      "pt_pt": "Málaga",
      "ro_ro": "Málaga",
      "ru_ru": "Малага",
      "sk_sk": "Málaga",
      "sl_sl": "Málaga",
      "sr_sp": "Malaga",
      "sr_sr": "Малага",
      "sv_se": "Málaga",
      "tr_tr": "Málaga",
      "uk_uk": "Малага",
      "vi_vn": "Málaga",
      "zh_cn": "马拉加",
      "zh_tw": "馬拉加"
    }
  },
  {
    "Name": "O Barco",
    "Country": "spain",
    "X": -77471.89,
    "Y": 33979.75,
    "InGameId": "o_barco",
    "LocalizationToken": "o_barco",
    "CountryId": 42,
    "LocalizedNames": {
      "bg_bg": "O-Барко-де-Валдеоррас",
      "ca_es": "O Barco",
      "cs_cz": "O Barco",
      "da_dk": "O Barco",
      "de_de": "O Barco",
      "el_gr": "Ο Μπάρκο",
      "en_gb": "O Barco",
      "en_us": "O Barco",
      "es_es": "O Barco",
      "es_la": "O Barco",
      "et_ee": "O Barco",
      "eu_es": "O Barco",
      "fi_fi": "O Barco",
      "fr_ca": "O Barco",
      "fr_fr": "O Barco",
      "gl_es": "O Barco de Valdeorras",
      "hr_hr": "O Barco",
      "hu_hu": "O Barco",
      "it_it": "O Barco",
      "ja_jp": "オ・バルコ",
      "ka_ge": "ო-ბარკო",
      "ko_kr": "오 바르코",
      "lt_lt": "O-Barko",
      "lv_lv": "O Barco",
      "mk_mk": "О Барко",
      "nl_nl": "O Barco",
      "no_no": "O Barco",
      "pl_pl": "O Barco",
      "pl_si": "O Barco",
      "pt_br": "O Barco",
      "pt_pt": "O Barco",
      "ro_ro": "O Barco",
      "ru_ru": "O-Барко-де-Вальдеоррас",
      "sk_sk": "O Barco",
      "sl_sl": "O Barco",
      "sr_sp": "O Barko",
      "sr_sr": "О Барко",
      "sv_se": "O Barco",
      "tr_tr": "O Barco",
      "uk_uk": "О-Барко-де-Вальдеоррас",
      "vi_vn": "O Barco",
      "zh_cn": "埃尔巴尔科",
      "zh_tw": "奧瓦爾科"
    }
  },
  {
    "Name": "Sevilla",
    "Country": "spain",
    "X": -80651.09,
    "Y": 62085.91,
    "InGameId": "sevilla",
    "LocalizationToken": "sevilla",
    "CountryId": 42,
    "LocalizedNames": {
      "bg_bg": "Севиля",
      "ca_es": "Sevilla",
      "cs_cz": "Sevilla",
      "da_dk": "Seville",
      "de_de": "Sevilla",
      "el_gr": "Σεβίλλη",
      "en_gb": "Seville",
      "en_us": "Seville",
      "es_es": "Sevilla",
      "es_la": "Sevilla",
      "et_ee": "Seville",
      "eu_es": "Sevilla",
      "fi_fi": "Sevilla",
      "fr_ca": "Séville",
      "fr_fr": "Séville",
      "gl_es": "Sevilla",
      "hr_hr": "Sevilla",
      "hu_hu": "Sevilla",
      "it_it": "Siviglia",
      "ja_jp": "セビリア",
      "ka_ge": "სევილია",
      "ko_kr": "세비야",
      "lt_lt": "Sevilija",
      "lv_lv": "Seville",
      "mk_mk": "Севиља",
      "nl_nl": "Sevilla",
      "no_no": "Sevilla",
      "pl_pl": "Sewilla",
      "pl_si": "Sewilla",
      "pt_br": "Sevilha",
      "pt_pt": "Sevilha",
      "ro_ro": "Sevilia",
      "ru_ru": "Севилья",
      "sk_sk": "Sevilla",
      "sl_sl": "Sevilla",
      "sr_sp": "Sevilja",
      "sr_sr": "Севиља",
      "sv_se": "Seville",
      "tr_tr": "Sevilla",
      "uk_uk": "Севілья",
      "vi_vn": "Seville",
      "zh_cn": "塞维利亚",
      "zh_tw": "塞維利亞"
    }
  },
  {
    "Name": "Algeciras",
    "Country": "spain",
    "X": -80942.375,
    "Y": 69036.42,
    "InGameId": "algeciras",
    "LocalizationToken": "algeciras",
    "CountryId": 42,
    "LocalizedNames": {
      "bg_bg": "Алхесирас",
      "ca_es": "Algesires",
      "cs_cz": "Algeciras",
      "da_dk": "Algeciras",
      "de_de": "Algeciras",
      "el_gr": "Αλχεθίρας",
      "en_gb": "Algeciras",
      "en_us": "Algeciras",
      "es_es": "Algeciras",
      "es_la": "Algeciras",
      "et_ee": "Algeciras",
      "eu_es": "Algeciras",
      "fi_fi": "Algeciras",
      "fr_ca": "Algésiras",
      "fr_fr": "Algésiras",
      "gl_es": "Alxeciras",
      "hr_hr": "Algeciras",
      "hu_hu": "Algeciras",
      "it_it": "Algeciras",
      "ja_jp": "アルヘシラス",
      "ka_ge": "ალხესირასი",
      "ko_kr": "알헤시라스",
      "lt_lt": "Alchesirasas",
      "lv_lv": "Algeciras",
      "mk_mk": "Алгесирас",
      "nl_nl": "Algeciras",
      "no_no": "Algeciras",
      "pl_pl": "Algeciras",
      "pl_si": "Algeciras",
      "pt_br": "Algeciras",
      "pt_pt": "Algeciras",
      "ro_ro": "Algeciras",
      "ru_ru": "Альхесирас",
      "sk_sk": "Algeciras",
      "sl_sl": "Algeciras",
      "sr_sp": "Alhesiras",
      "sr_sr": "Алхесирас",
      "sv_se": "Algeciras",
      "tr_tr": "Algeciras",
      "uk_uk": "Альхесірас",
      "vi_vn": "Algeciras",
      "zh_cn": "阿尔赫西拉斯",
      "zh_tw": "阿爾赫西拉斯"
    }
  },
  {
    "Name": "A Coruña",
    "Country": "spain",
    "X": -83140.91,
    "Y": 25857.3438,
    "InGameId": "a_coruna",
    "LocalizationToken": "a_coruna",
    "CountryId": 42,
    "LocalizedNames": {
      "bg_bg": "Коруня",
      "ca_es": "La Corunya",
      "cs_cz": "A Coruña",
      "da_dk": "A Coruña",
      "de_de": "A Coruña",
      "el_gr": "Λα Κορούνια",
      "en_gb": "A Coruña",
      "en_us": "A Coruña",
      "es_es": "A Coruña",
      "es_la": "A Coruña",
      "et_ee": "A Coruña",
      "eu_es": "Coruña",
      "fi_fi": "A Coruña",
      "fr_ca": "La Corogne",
      "fr_fr": "La Corogne",
      "gl_es": "A Coruña",
      "hr_hr": "A Coruña",
      "hu_hu": "A Coruña",
      "it_it": "A Coruña",
      "ja_jp": "ア・コルーニャ",
      "ka_ge": "ლა-კორუნია",
      "ko_kr": "라코루냐",
      "lt_lt": "La Korunja",
      "lv_lv": "A Coruña",
      "mk_mk": "Коруња",
      "nl_nl": "A Coruña",
      "no_no": "La Coruña",
      "pl_pl": "A Coruña",
      "pl_si": "A Coruña",
      "pt_br": "A Coruña",
      "pt_pt": "Corunha",
      "ro_ro": "La Coruña",
      "ru_ru": "А-Корунья",
      "sk_sk": "A Coruña",
      "sl_sl": "A Coruña",
      "sr_sp": "Korunja",
      "sr_sr": "Коруња",
      "sv_se": "A Coruña",
      "tr_tr": "A Coruña",
      "uk_uk": "Ла-Корунья",
      "vi_vn": "A Coruña",
      "zh_cn": "拉科鲁尼亚",
      "zh_tw": "拉科魯尼亞"
    }
  },
  {
    "Name": "Guarda",
    "Country": "portugal",
    "X": -82192.2656,
    "Y": 43708.63,
    "InGameId": "guarda",
    "LocalizationToken": "guarda",
    "CountryId": 36,
    "LocalizedNames": {
      "bg_bg": "Гуарда",
      "ca_es": "Guarda",
      "cs_cz": "Guarda",
      "da_dk": "Guarda",
      "de_de": "Guarda",
      "el_gr": "Γκουάρδα",
      "en_gb": "Guarda",
      "en_us": "Guarda",
      "es_es": "Guarda",
      "es_la": "Guarda",
      "et_ee": "Guarda",
      "eu_es": "Guarda",
      "fi_fi": "Guarda",
      "fr_ca": "Guarda",
      "fr_fr": "Guarda",
      "gl_es": "Guarda",
      "hr_hr": "Guarda",
      "hu_hu": "Guarda",
      "it_it": "Guarda",
      "ja_jp": "グアルダ",
      "ka_ge": "გუარდა",
      "ko_kr": "구아르다",
      "lt_lt": "Gvarda",
      "lv_lv": "Guarda",
      "mk_mk": "Гварда",
      "nl_nl": "Guarda",
      "no_no": "Guarda",
      "pl_pl": "Guarda",
      "pl_si": "Guarda",
      "pt_br": "Guarda",
      "pt_pt": "Guarda",
      "ro_ro": "Guarda",
      "ru_ru": "Гуарда",
      "sk_sk": "Guarda",
      "sl_sl": "Guarda",
      "sr_sp": "Guarda",
      "sr_sr": "Гуарда",
      "sv_se": "Guarda",
      "tr_tr": "Guarda",
      "uk_uk": "Гуарда",
      "vi_vn": "Guarda",
      "zh_cn": "瓜尔达",
      "zh_tw": "瓜達"
    }
  },
  {
    "Name": "Badajoz",
    "Country": "spain",
    "X": -83250.01,
    "Y": 52860.4141,
    "InGameId": "badajoz",
    "LocalizationToken": "badajoz",
    "CountryId": 42,
    "LocalizedNames": {
      "bg_bg": "Бадахос",
      "ca_es": "Badajoz",
      "cs_cz": "Badajoz",
      "da_dk": "Badajoz",
      "de_de": "Badajoz",
      "el_gr": "Μπανταχόθ",
      "en_gb": "Badajoz",
      "en_us": "Badajoz",
      "es_es": "Badajoz",
      "es_la": "Badajoz",
      "et_ee": "Badajoz",
      "eu_es": "Badajoz",
      "fi_fi": "Badajoz",
      "fr_ca": "Badajoz",
      "fr_fr": "Badajoz",
      "gl_es": "Badaxoz",
      "hr_hr": "Badajoz",
      "hu_hu": "Badajoz",
      "it_it": "Badajoz",
      "ja_jp": "バダホス",
      "ka_ge": "ბადახოსი",
      "ko_kr": "바다호스",
      "lt_lt": "Badachosas",
      "lv_lv": "Badajoz",
      "mk_mk": "Бадахоз",
      "nl_nl": "Badajoz",
      "no_no": "Badajoz",
      "pl_pl": "Badajoz",
      "pl_si": "Badajoz",
      "pt_br": "Badajoz",
      "pt_pt": "Badajoz",
      "ro_ro": "Badajoz",
      "ru_ru": "Бадахос",
      "sk_sk": "Badajoz",
      "sl_sl": "Badajoz",
      "sr_sp": "Badahoz",
      "sr_sr": "Бадахоз",
      "sv_se": "Badajoz",
      "tr_tr": "Badajoz",
      "uk_uk": "Бадахоз",
      "vi_vn": "Badajoz",
      "zh_cn": "巴达霍斯",
      "zh_tw": "巴達霍斯"
    }
  },
  {
    "Name": "Vigo",
    "Country": "spain",
    "X": -85371.51,
    "Y": 31880.5586,
    "InGameId": "vigo",
    "LocalizationToken": "vigo",
    "CountryId": 42,
    "LocalizedNames": {
      "bg_bg": "Виго",
      "ca_es": "Vigo",
      "cs_cz": "Vigo",
      "da_dk": "Vigo",
      "de_de": "Vigo",
      "el_gr": "Βίγκο",
      "en_gb": "Vigo",
      "en_us": "Vigo",
      "es_es": "Vigo",
      "es_la": "Vigo",
      "et_ee": "Vigo",
      "eu_es": "Vigo",
      "fi_fi": "Vigo",
      "fr_ca": "Vigo",
      "fr_fr": "Vigo",
      "gl_es": "Vigo",
      "hr_hr": "Vigo",
      "hu_hu": "Vigo",
      "it_it": "Vigo",
      "ja_jp": "ビーゴ",
      "ka_ge": "ვიგო",
      "ko_kr": "비고",
      "lt_lt": "Vigas",
      "lv_lv": "Vigo",
      "mk_mk": "Виго",
      "nl_nl": "Vigo",
      "no_no": "Vigo",
      "pl_pl": "Vigo",
      "pl_si": "Vigo",
      "pt_br": "Vigo",
      "pt_pt": "Vigo",
      "ro_ro": "Vigo",
      "ru_ru": "Виго",
      "sk_sk": "Vigo",
      "sl_sl": "Vigo",
      "sr_sp": "Vigo",
      "sr_sr": "Виго",
      "sv_se": "Vigo",
      "tr_tr": "Vigo",
      "uk_uk": "Віґо",
      "vi_vn": "Vigo",
      "zh_cn": "维戈",
      "zh_tw": "比戈"
    }
  },
  {
    "Name": "Porto",
    "Country": "portugal",
    "X": -86461.77,
    "Y": 38180.2,
    "InGameId": "porto",
    "LocalizationToken": "porto",
    "CountryId": 36,
    "LocalizedNames": {
      "bg_bg": "Порто",
      "ca_es": "Porto",
      "cs_cz": "Porto",
      "da_dk": "Porto",
      "de_de": "Porto",
      "el_gr": "Πόρτο",
      "en_gb": "Porto",
      "en_us": "Porto",
      "es_es": "Oporto",
      "es_la": "Oporto",
      "et_ee": "Porto",
      "eu_es": "Porto",
      "fi_fi": "Porto",
      "fr_ca": "Porto",
      "fr_fr": "Porto",
      "gl_es": "Porto",
      "hr_hr": "Porto",
      "hu_hu": "Porto",
      "it_it": "Porto",
      "ja_jp": "ポルト",
      "ka_ge": "პორტო",
      "ko_kr": "포르투",
      "lt_lt": "Portas",
      "lv_lv": "Porto",
      "mk_mk": "Порто",
      "nl_nl": "Porto",
      "no_no": "Porto",
      "pl_pl": "Porto",
      "pl_si": "Porto",
      "pt_br": "Porto",
      "pt_pt": "Porto",
      "ro_ro": "Porto",
      "ru_ru": "Порту",
      "sk_sk": "Porto",
      "sl_sl": "Porto",
      "sr_sp": "Porto",
      "sr_sr": "Порто",
      "sv_se": "Porto",
      "tr_tr": "Porto",
      "uk_uk": "Порту",
      "vi_vn": "Porto",
      "zh_cn": "波尔图",
      "zh_tw": "波多"
    }
  },
  {
    "Name": "Coimbra",
    "Country": "portugal",
    "X": -87901.56,
    "Y": 43976.832,
    "InGameId": "coimbra",
    "LocalizationToken": "coimbra",
    "CountryId": 36,
    "LocalizedNames": {
      "bg_bg": "Коимбра",
      "ca_es": "Coïmbra",
      "cs_cz": "Coimbra",
      "da_dk": "Coimbra",
      "de_de": "Coimbra",
      "el_gr": "Κοΐμπρα",
      "en_gb": "Coimbra",
      "en_us": "Coimbra",
      "es_es": "Coímbra",
      "es_la": "Coímbra",
      "et_ee": "Coimbra",
      "eu_es": "Coimbra",
      "fi_fi": "Coimbra",
      "fr_ca": "Coimbra",
      "fr_fr": "Coimbra",
      "gl_es": "Coimbra",
      "hr_hr": "Coimbra",
      "hu_hu": "Coimbra",
      "it_it": "Coimbra",
      "ja_jp": "コインブラ",
      "ka_ge": "კოიმბრა",
      "ko_kr": "코임브라",
      "lt_lt": "Koimbra",
      "lv_lv": "Coimbra",
      "mk_mk": "Коимбра",
      "nl_nl": "Coimbra",
      "no_no": "Coimbra",
      "pl_pl": "Coimbra",
      "pl_si": "Coimbra",
      "pt_br": "Coimbra",
      "pt_pt": "Coimbra",
      "ro_ro": "Coimbra",
      "ru_ru": "Коимбра",
      "sk_sk": "Coimbra",
      "sl_sl": "Coimbra",
      "sr_sp": "Koimbra",
      "sr_sr": "Коимбра",
      "sv_se": "Coimbra",
      "tr_tr": "Coimbra",
      "uk_uk": "Коїмбра",
      "vi_vn": "Coimbra",
      "zh_cn": "科英布拉",
      "zh_tw": "科英布拉"
    }
  },
  {
    "Name": "Ponte de Sor",
    "Country": "portugal",
    "X": -87609.93,
    "Y": 49702.11,
    "InGameId": "ponte_de_sor",
    "LocalizationToken": "ponte_de_sor",
    "CountryId": 36,
    "LocalizedNames": {
      "bg_bg": "Понте де Сор",
      "ca_es": "Ponte de Sor",
      "cs_cz": "Ponte de Sor",
      "da_dk": "Ponte de Sor",
      "de_de": "Ponte de Sor",
      "el_gr": "Πόντε ντε Σορ",
      "en_gb": "Ponte de Sor",
      "en_us": "Ponte de Sor",
      "es_es": "Ponte de Sor",
      "es_la": "Ponte de Sor",
      "et_ee": "Ponte de Sor",
      "eu_es": "Ponte de Sor",
      "fi_fi": "Ponte de Sor",
      "fr_ca": "Ponte de Sor",
      "fr_fr": "Ponte de Sor",
      "gl_es": "Ponte de Sor",
      "hr_hr": "Ponte de Sor",
      "hu_hu": "Ponte de Sor",
      "it_it": "Ponte de Sor",
      "ja_jp": "ポンテ・デ・ソル",
      "ka_ge": "პონტე-დი-სორი",
      "ko_kr": "폰트드소르",
      "lt_lt": "Ponte de Soras",
      "lv_lv": "Ponte de Sor",
      "mk_mk": "Понте де Сор",
      "nl_nl": "Ponte de Sor",
      "no_no": "Ponte de Sor",
      "pl_pl": "Ponte de Sor",
      "pl_si": "Ponte de Sor",
      "pt_br": "Ponte de Sor",
      "pt_pt": "Ponte de Sor",
      "ro_ro": "Ponte de Sor",
      "ru_ru": "Понти-ди-Сор",
      "sk_sk": "Ponte de Sor",
      "sl_sl": "Ponte de Sor",
      "sr_sp": "Ponte de Sor",
      "sr_sr": "Понте де Сор",
      "sv_se": "Ponte de Sor",
      "tr_tr": "Ponte de Sor",
      "uk_uk": "Понте-де-Сор",
      "vi_vn": "Ponte de Sor",
      "zh_cn": "蓬蒂-迪索尔",
      "zh_tw": "蓬蒂-迪索爾"
    }
  },
  {
    "Name": "Évora",
    "Country": "portugal",
    "X": -87442.32,
    "Y": 54044.29,
    "InGameId": "evora",
    "LocalizationToken": "evora",
    "CountryId": 36,
    "LocalizedNames": {
      "bg_bg": "Евора",
      "ca_es": "Èvora",
      "cs_cz": "Evora",
      "da_dk": "Evora",
      "de_de": "Evora",
      "el_gr": "Εβόρα",
      "en_gb": "Evora",
      "en_us": "Evora",
      "es_es": "Évora",
      "es_la": "Évora",
      "et_ee": "Evora",
      "eu_es": "Évora",
      "fi_fi": "Évora",
      "fr_ca": "Évora",
      "fr_fr": "Évora",
      "gl_es": "Évora",
      "hr_hr": "Evora",
      "hu_hu": "Évora",
      "it_it": "Evora",
      "ja_jp": "エヴォラ",
      "ka_ge": "ევორა",
      "ko_kr": "에보라",
      "lt_lt": "Evora",
      "lv_lv": "Evora",
      "mk_mk": "Евора",
      "nl_nl": "Evora",
      "no_no": "Évora",
      "pl_pl": "Evora",
      "pl_si": "Evora",
      "pt_br": "Évora",
      "pt_pt": "Évora",
      "ro_ro": "Evora",
      "ru_ru": "Эвора",
      "sk_sk": "Evora",
      "sl_sl": "Evora",
      "sr_sp": "Evora",
      "sr_sr": "Евора",
      "sv_se": "Évora",
      "tr_tr": "Evora",
      "uk_uk": "Евора",
      "vi_vn": "Evora",
      "zh_cn": "埃武拉",
      "zh_tw": "埃武拉"
    }
  },
  {
    "Name": "Beja",
    "Country": "portugal",
    "X": -88339.31,
    "Y": 56611.06,
    "InGameId": "beja",
    "LocalizationToken": "beja",
    "CountryId": 36,
    "LocalizedNames": {
      "bg_bg": "Бежа",
      "ca_es": "Beja",
      "cs_cz": "Beja",
      "da_dk": "Beja",
      "de_de": "Beja",
      "el_gr": "Μπέχα",
      "en_gb": "Beja",
      "en_us": "Beja",
      "es_es": "Beja",
      "es_la": "Beja",
      "et_ee": "Beja",
      "eu_es": "Beja",
      "fi_fi": "Beja",
      "fr_ca": "Beja",
      "fr_fr": "Beja",
      "gl_es": "Beja",
      "hr_hr": "Beja",
      "hu_hu": "Beja",
      "it_it": "Beja",
      "ja_jp": "ベージャ",
      "ka_ge": "ბეჟა",
      "ko_kr": "베자",
      "lt_lt": "Beža",
      "lv_lv": "Beja",
      "mk_mk": "Бежа",
      "nl_nl": "Beja",
      "no_no": "Beja",
      "pl_pl": "Beja",
      "pl_si": "Beja",
      "pt_br": "Beja",
      "pt_pt": "Beja",
      "ro_ro": "Beja",
      "ru_ru": "Бежа",
      "sk_sk": "Beja",
      "sl_sl": "Beja",
      "sr_sp": "Beža",
      "sr_sr": "Бежа",
      "sv_se": "Beja",
      "tr_tr": "Beja",
      "uk_uk": "Бежа",
      "vi_vn": "Beja",
      "zh_cn": "贝雅",
      "zh_tw": "貝雅"
    }
  },
  {
    "Name": "Huelva",
    "Country": "spain",
    "X": -84837.58,
    "Y": 62064.168,
    "InGameId": "huelva",
    "LocalizationToken": "huelva",
    "CountryId": 42,
    "LocalizedNames": {
      "bg_bg": "Уелва",
      "ca_es": "Huelva",
      "cs_cz": "Huelva",
      "da_dk": "Huelva",
      "de_de": "Huelva",
      "el_gr": "Ουέλβα",
      "en_gb": "Huelva",
      "en_us": "Huelva",
      "es_es": "Huelva",
      "es_la": "Huelva",
      "et_ee": "Huelva",
      "eu_es": "Huelva",
      "fi_fi": "Huelva",
      "fr_ca": "Huelva",
      "fr_fr": "Huelva",
      "gl_es": "Huelva",
      "hr_hr": "Huelva",
      "hu_hu": "Huelva",
      "it_it": "Huelva",
      "ja_jp": "ウエルバ",
      "ka_ge": "უელვა",
      "ko_kr": "우엘바",
      "lt_lt": "Huelva",
      "lv_lv": "Huelva",
      "mk_mk": "Хуелва",
      "nl_nl": "Huelva",
      "no_no": "Huelva",
      "pl_pl": "Huelva",
      "pl_si": "Huelva",
      "pt_br": "Huelva",
      "pt_pt": "Huelva",
      "ro_ro": "Huelva",
      "ru_ru": "Уэльва",
      "sk_sk": "Huelva",
      "sl_sl": "Huelva",
      "sr_sp": "Huelva",
      "sr_sr": "Хуелва",
      "sv_se": "Huelva",
      "tr_tr": "Huelva",
      "uk_uk": "Уельва",
      "vi_vn": "Huelva",
      "zh_cn": "韦尔瓦",
      "zh_tw": "韋爾瓦"
    }
  },
  {
    "Name": "Setúbal",
    "Country": "portugal",
    "X": -92016.95,
    "Y": 53957.3672,
    "InGameId": "setubal",
    "LocalizationToken": "setubal",
    "CountryId": 36,
    "LocalizedNames": {
      "bg_bg": "Сетубал",
      "ca_es": "Setúbal",
      "cs_cz": "Setúbal",
      "da_dk": "Setúbal",
      "de_de": "Setúbal",
      "el_gr": "Σετούμπαλ",
      "en_gb": "Setúbal",
      "en_us": "Setúbal",
      "es_es": "Setúbal",
      "es_la": "Setúbal",
      "et_ee": "Setúbal",
      "eu_es": "Setúbal",
      "fi_fi": "Setúbal",
      "fr_ca": "Setúbal",
      "fr_fr": "Setúbal",
      "gl_es": "Setúbal",
      "hr_hr": "Setúbal",
      "hu_hu": "Setúbal",
      "it_it": "Setúbal",
      "ja_jp": "セトゥーバル",
      "ka_ge": "სეტუბალი",
      "ko_kr": "세투발",
      "lt_lt": "Setubalis",
      "lv_lv": "Setúbal",
      "mk_mk": "Сетубал",
      "nl_nl": "Setúbal",
      "no_no": "Setúbal",
      "pl_pl": "Setúbal",
      "pl_si": "Setúbal",
      "pt_br": "Setúbal",
      "pt_pt": "Setúbal",
      "ro_ro": "Setúbal",
      "ru_ru": "Сетубал",
      "sk_sk": "Setúbal",
      "sl_sl": "Setúbal",
      "sr_sp": "Setubal",
      "sr_sr": "Сетубал",
      "sv_se": "Setúbal",
      "tr_tr": "Setúbal",
      "uk_uk": "Сетубал",
      "vi_vn": "Setúbal",
      "zh_cn": "塞图巴尔",
      "zh_tw": "塞圖巴"
    }
  },
  {
    "Name": "Cortiçadas de Lavre",
    "Country": "portugal",
    "X": -88668.92,
    "Y": 52508.4375,
    "InGameId": "corticadas",
    "LocalizationToken": "corticadas_de_lavre",
    "CountryId": 36,
    "LocalizedNames": {
      "bg_bg": "Кортисадаш",
      "ca_es": "Cortiçadas de Lavre",
      "cs_cz": "Cortiçadas de Lavre",
      "da_dk": "Cortiçadas de Lavre",
      "de_de": "Cortiçadas de Lavre",
      "el_gr": "Κορτισάδας ντε Λάβρε",
      "en_gb": "Cortiçadas de Lavre",
      "en_us": "Cortiçadas de Lavre",
      "es_es": "Cortiçadas de Lavre",
      "es_la": "Cortiçadas de Lavre",
      "et_ee": "Cortiçadas de Lavre",
      "eu_es": "Cortiçadas de Lavre",
      "fi_fi": "Cortiçadas de Lavre",
      "fr_ca": "Cortiçadas de Lavre",
      "fr_fr": "Cortiçadas de Lavre",
      "gl_es": "Cortiçadas de Lavre",
      "hr_hr": "Cortiçadas de Lavre",
      "hu_hu": "Cortiçadas de Lavre",
      "it_it": "Cortiçadas de Lavre",
      "ja_jp": "コルティサダス・デ・ラブレ",
      "ka_ge": "კორტისადაში",
      "ko_kr": "쿠르티사다스 드라브르",
      "lt_lt": "Kortikadas De Lavre",
      "lv_lv": "Cortiçadas de Lavre",
      "mk_mk": "Кортисадаш де Лавре",
      "nl_nl": "Cortiçadas de Lavre",
      "no_no": "Cortiçadas de Lavre",
      "pl_pl": "Cortiçadas de Lavre",
      "pl_si": "Cortiçadas de Lavre",
      "pt_br": "Cortiçadas de Lavre",
      "pt_pt": "Cortiçadas de Lavre",
      "ro_ro": "Cortiçadas de Lavre",
      "ru_ru": "Кортисадаш",
      "sk_sk": "Cortiçadas de Lavre",
      "sl_sl": "Cortiçadas de Lavre",
      "sr_sp": "Kortisadas de Lavre",
      "sr_sr": "Кортисадас де Лавре",
      "sv_se": "Cortiçadas de Lavre",
      "tr_tr": "Cortiçadas de Lavre",
      "uk_uk": "Кортісада-де-Лавр",
      "vi_vn": "Cortiçadas de Lavre",
      "zh_cn": "库尔蒂萨达什-迪拉夫勒",
      "zh_tw": "拉夫科爾蒂薩斯"
    }
  },
  {
    "Name": "Sines",
    "Country": "portugal",
    "X": -92331.4844,
    "Y": 55848.4922,
    "InGameId": "sines",
    "LocalizationToken": "sines",
    "CountryId": 36,
    "LocalizedNames": {
      "bg_bg": "Синеш",
      "ca_es": "Sines",
      "cs_cz": "Sines",
      "da_dk": "Sines",
      "de_de": "Sines",
      "el_gr": "Σίνες",
      "en_gb": "Sines",
      "en_us": "Sines",
      "es_es": "Sines",
      "es_la": "Sines",
      "et_ee": "Sines",
      "eu_es": "Sines",
      "fi_fi": "Sines",
      "fr_ca": "Sines",
      "fr_fr": "Sines",
      "gl_es": "Sines",
      "hr_hr": "Sines",
      "hu_hu": "Sines",
      "it_it": "Sines",
      "ja_jp": "シネス",
      "ka_ge": "სინესი",
      "ko_kr": "시네스",
      "lt_lt": "Sinesas",
      "lv_lv": "Sines",
      "mk_mk": "Синес",
      "nl_nl": "Sines",
      "no_no": "Sines",
      "pl_pl": "Sines",
      "pl_si": "Sines",
      "pt_br": "Sines",
      "pt_pt": "Sines",
      "ro_ro": "Sines",
      "ru_ru": "Синиш",
      "sk_sk": "Sines",
      "sl_sl": "Sines",
      "sr_sp": "Sineš",
      "sr_sr": "Синеш",
      "sv_se": "Sines",
      "tr_tr": "Sines",
      "uk_uk": "Сінес",
      "vi_vn": "Sines",
      "zh_cn": "锡尼什",
      "zh_tw": "錫尼什"
    }
  },
  {
    "Name": "Olhão",
    "Country": "portugal",
    "X": -89220.61,
    "Y": 61890.7422,
    "InGameId": "olhao",
    "LocalizationToken": "olhao",
    "CountryId": 36,
    "LocalizedNames": {
      "bg_bg": "Олхао",
      "ca_es": "Olhão",
      "cs_cz": "Olhão",
      "da_dk": "Olhão",
      "de_de": "Olhão",
      "el_gr": "Ολιάο",
      "en_gb": "Olhão",
      "en_us": "Olhão",
      "es_es": "Olhão",
      "es_la": "Olhão",
      "et_ee": "Olhão",
      "eu_es": "Olhão",
      "fi_fi": "Olhão",
      "fr_ca": "Olhão",
      "fr_fr": "Olhão",
      "gl_es": "Olhão",
      "hr_hr": "Olhão",
      "hu_hu": "Olhão",
      "it_it": "Olhão",
      "ja_jp": "オリョン",
      "ka_ge": "ოლანი",
      "ko_kr": "올량",
      "lt_lt": "Oljaunas",
      "lv_lv": "Olhão",
      "mk_mk": "Олијан",
      "nl_nl": "Olhão",
      "no_no": "Olhão",
      "pl_pl": "Olhão",
      "pl_si": "Olhão",
      "pt_br": "Olhão",
      "pt_pt": "Olhão",
      "ro_ro": "Olhăo",
      "ru_ru": "Ольян",
      "sk_sk": "Olhão",
      "sl_sl": "Olhão",
      "sr_sp": "Olhao",
      "sr_sr": "Олхао",
      "sv_se": "Olhão",
      "tr_tr": "Olhão",
      "uk_uk": "Ольяу",
      "vi_vn": "Olhão",
      "zh_cn": "奥良",
      "zh_tw": "奧良"
    }
  },
  {
    "Name": "Faro",
    "Country": "portugal",
    "X": -90530.3359,
    "Y": 62415.5859,
    "InGameId": "faro",
    "LocalizationToken": "faro",
    "CountryId": 36,
    "LocalizedNames": {
      "bg_bg": "Фаро",
      "ca_es": "Faro",
      "cs_cz": "Faro",
      "da_dk": "Faro",
      "de_de": "Faro",
      "el_gr": "Φάρο",
      "en_gb": "Faro",
      "en_us": "Faro",
      "es_es": "Faro",
      "es_la": "Faro",
      "et_ee": "Faro",
      "eu_es": "Faro",
      "fi_fi": "Faro",
      "fr_ca": "Faro",
      "fr_fr": "Faro",
      "gl_es": "Faro",
      "hr_hr": "Faro",
      "hu_hu": "Faro",
      "it_it": "Faro",
      "ja_jp": "ファロ",
      "ka_ge": "ფარუ",
      "ko_kr": "파루",
      "lt_lt": "Faras",
      "lv_lv": "Faro",
      "mk_mk": "Фаро",
      "nl_nl": "Faro",
      "no_no": "Faro",
      "pl_pl": "Faro",
      "pl_si": "Faro",
      "pt_br": "Faro",
      "pt_pt": "Faro",
      "ro_ro": "Faro",
      "ru_ru": "Фару",
      "sk_sk": "Faro",
      "sl_sl": "Faro",
      "sr_sp": "Faru",
      "sr_sr": "Фару",
      "sv_se": "Faro",
      "tr_tr": "Faro",
      "uk_uk": "Фару",
      "vi_vn": "Faro",
      "zh_cn": "法鲁",
      "zh_tw": "法羅"
    }
  },
  {
    "Name": "Lisboa",
    "Country": "portugal",
    "X": -93310.5156,
    "Y": 49853.3438,
    "InGameId": "lisboa",
    "LocalizationToken": "lisboa",
    "CountryId": 36,
    "LocalizedNames": {
      "bg_bg": "Лисабон",
      "ca_es": "Lisboa",
      "cs_cz": "Lisabon",
      "da_dk": "Lissabon",
      "de_de": "Lissabon",
      "el_gr": "Λισαβόνα",
      "en_gb": "Lisbon",
      "en_us": "Lisbon",
      "es_es": "Lisboa",
      "es_la": "Lisboa",
      "et_ee": "Lissabon",
      "eu_es": "Lisboa",
      "fi_fi": "Lissabon",
      "fr_ca": "Lisbonne",
      "fr_fr": "Lisbonne",
      "gl_es": "Lisboa",
      "hr_hr": "Lisabon",
      "hu_hu": "Lisszabon",
      "it_it": "Lisbona",
      "ja_jp": "リスボン",
      "ka_ge": "ლისაბონი",
      "ko_kr": "리스본",
      "lt_lt": "Lisabona",
      "lv_lv": "Lisbon",
      "mk_mk": "Лисабон",
      "nl_nl": "Lissabon",
      "no_no": "Lisboa",
      "pl_pl": "Lizbona",
      "pl_si": "Lizbona",
      "pt_br": "Lisboa",
      "pt_pt": "Lisboa",
      "ro_ro": "Lisabona",
      "ru_ru": "Лиссабон",
      "sk_sk": "Lisabon",
      "sl_sl": "Lizbona",
      "sr_sp": "Lisabon",
      "sr_sr": "Лисабон",
      "sv_se": "Lisabon",
      "tr_tr": "Lizbon",
      "uk_uk": "Лісабон",
      "vi_vn": "Lisbon",
      "zh_cn": "里斯本",
      "zh_tw": "里斯本"
    }
  },
  {
    "Name": "Bologna",
    "Country": "italy",
    "X": 219.988281,
    "Y": 33482.82,
    "InGameId": "bologna",
    "LocalizationToken": "bologna",
    "CountryId": 6,
    "LocalizedNames": {
      "bg_bg": "Болония",
      "ca_es": "Bolonya",
      "cs_cz": "Boloňa",
      "da_dk": "Bologna",
      "de_de": "Bologna",
      "el_gr": "Μπολόνια",
      "en_gb": "Bologna",
      "en_us": "Bologna",
      "es_es": "Bolonia",
      "es_la": "Bolonia",
      "et_ee": "Bologna",
      "eu_es": "Bolonia",
      "fi_fi": "Bologna",
      "fr_ca": "Bologne",
      "fr_fr": "Bologne",
      "gl_es": "Boloña",
      "hr_hr": "Bologna",
      "hu_hu": "Bologna",
      "it_it": "Bologna",
      "ja_jp": "ボローニャ",
      "ka_ge": "ბოლონია",
      "ko_kr": "볼로냐",
      "lt_lt": "Bolonija",
      "lv_lv": "Boloņa",
      "mk_mk": "Болоња",
      "nl_nl": "Bologna",
      "no_no": "Bologna",
      "pl_pl": "Bolonia",
      "pl_si": "Bolonia",
      "pt_br": "Bolonha",
      "pt_pt": "Bolonha",
      "ro_ro": "Bologna",
      "ru_ru": "Болонья",
      "sk_sk": "Boloňa",
      "sl_sl": "Bologna",
      "sr_sp": "Bolonja",
      "sr_sr": "Болоња",
      "sv_se": "Bologna",
      "tr_tr": "Bolonya",
      "uk_uk": "Болонья",
      "vi_vn": "Bologna",
      "zh_cn": "博洛尼亚",
      "zh_tw": "波隆那"
    }
  },
  {
    "Name": "Firenze",
    "Country": "italy",
    "X": 581.625,
    "Y": 38367.7734,
    "InGameId": "firenze",
    "LocalizationToken": "firenze",
    "CountryId": 6,
    "LocalizedNames": {
      "bg_bg": "Флоренция",
      "ca_es": "Florència",
      "cs_cz": "Florencie",
      "da_dk": "Firenze",
      "de_de": "Florenz",
      "el_gr": "Φλωρεντία",
      "en_gb": "Florence",
      "en_us": "Florence",
      "es_es": "Florencia",
      "es_la": "Florencia",
      "et_ee": "Firenze",
      "eu_es": "Florentzia",
      "fi_fi": "Firenze",
      "fr_ca": "Florence",
      "fr_fr": "Florence",
      "gl_es": "Florencia",
      "hr_hr": "Firenca",
      "hu_hu": "Firenze",
      "it_it": "Firenze",
      "ja_jp": "フィレンツェ",
      "ka_ge": "ფლორენცია",
      "ko_kr": "피렌체",
      "lt_lt": "Florencija",
      "lv_lv": "Florence",
      "mk_mk": "Фиренца",
      "nl_nl": "Florence",
      "no_no": "Firenze",
      "pl_pl": "Florencja",
      "pl_si": "Florencja",
      "pt_br": "Florença",
      "pt_pt": "Florença",
      "ro_ro": "Florenţa",
      "ru_ru": "Флоренция",
      "sk_sk": "Florencia",
      "sl_sl": "Florence",
      "sr_sp": "Firenca",
      "sr_sr": "Фиренца",
      "sv_se": "Florens",
      "tr_tr": "Floransa",
      "uk_uk": "Флоренція",
      "vi_vn": "Florence",
      "zh_cn": "佛罗伦萨",
      "zh_tw": "佛羅倫斯"
    }
  },
  {
    "Name": "Terni",
    "Country": "italy",
    "X": 6216.492,
    "Y": 45691.66,
    "InGameId": "terni",
    "LocalizationToken": "terni",
    "CountryId": 6,
    "LocalizedNames": {
      "bg_bg": "Терни",
      "ca_es": "Terni",
      "cs_cz": "Terni",
      "da_dk": "Terni",
      "de_de": "Terni",
      "el_gr": "Τέρνι",
      "en_gb": "Terni",
      "en_us": "Terni",
      "es_es": "Terni",
      "es_la": "Terni",
      "et_ee": "Terni",
      "eu_es": "Terni",
      "fi_fi": "Terni",
      "fr_ca": "Terni",
      "fr_fr": "Terni",
      "gl_es": "Terni",
      "hr_hr": "Terni",
      "hu_hu": "Terni",
      "it_it": "Terni",
      "ja_jp": "テルニ",
      "ka_ge": "ტერნი",
      "ko_kr": "테르니",
      "lt_lt": "Ternis",
      "lv_lv": "Terni",
      "mk_mk": "Терни",
      "nl_nl": "Terni",
      "no_no": "Terni",
      "pl_pl": "Terni",
      "pl_si": "Terni",
      "pt_br": "Terni",
      "pt_pt": "Terni",
      "ro_ro": "Terni",
      "ru_ru": "Терни",
      "sk_sk": "Terni",
      "sl_sl": "Terni",
      "sr_sp": "Terni",
      "sr_sr": "Терни",
      "sv_se": "Terni",
      "tr_tr": "Terni",
      "uk_uk": "Терні",
      "vi_vn": "Terni",
      "zh_cn": "特尔尼",
      "zh_tw": "特爾尼"
    }
  },
  {
    "Name": "Roma",
    "Country": "italy",
    "X": 5511.125,
    "Y": 48579.21,
    "InGameId": "roma",
    "LocalizationToken": "roma",
    "CountryId": 6,
    "LocalizedNames": {
      "bg_bg": "Рим",
      "ca_es": "Roma",
      "cs_cz": "Řím",
      "da_dk": "Rom",
      "de_de": "Rom",
      "el_gr": "Ρώμη",
      "en_gb": "Rome",
      "en_us": "Rome",
      "es_es": "Roma",
      "es_la": "Roma",
      "et_ee": "Rooma",
      "eu_es": "Erroma",
      "fi_fi": "Rooma",
      "fr_ca": "Rome",
      "fr_fr": "Rome",
      "gl_es": "Roma",
      "hr_hr": "Rim",
      "hu_hu": "Róma",
      "it_it": "Roma",
      "ja_jp": "ローマ",
      "ka_ge": "რომი",
      "ko_kr": "로마",
      "lt_lt": "Roma",
      "lv_lv": "Rome",
      "mk_mk": "Рим",
      "nl_nl": "Rome",
      "no_no": "Roma",
      "pl_pl": "Rzym",
      "pl_si": "Rzym",
      "pt_br": "Roma",
      "pt_pt": "Roma",
      "ro_ro": "Roma",
      "ru_ru": "Рим",
      "sk_sk": "Rím",
      "sl_sl": "Rim",
      "sr_sp": "Rim",
      "sr_sr": "Рим",
      "sv_se": "Rom",
      "tr_tr": "Roma",
      "uk_uk": "Рим",
      "vi_vn": "Rome",
      "zh_cn": "罗马",
      "zh_tw": "羅馬"
    }
  },
  {
    "Name": "Ancona",
    "Country": "italy",
    "X": 9424.281,
    "Y": 39138.16,
    "InGameId": "ancona",
    "LocalizationToken": "ancona",
    "CountryId": 6,
    "LocalizedNames": {
      "bg_bg": "Анкона",
      "ca_es": "Ancona",
      "cs_cz": "Ancona",
      "da_dk": "Ancona",
      "de_de": "Ancona",
      "el_gr": "Ανκόνα",
      "en_gb": "Ancona",
      "en_us": "Ancona",
      "es_es": "Ancona",
      "es_la": "Ancona",
      "et_ee": "Ancona",
      "eu_es": "Ancona",
      "fi_fi": "Ancona",
      "fr_ca": "Ancône",
      "fr_fr": "Ancône",
      "gl_es": "Ancona",
      "hr_hr": "Ancona",
      "hu_hu": "Ancona",
      "it_it": "Ancona",
      "ja_jp": "アンコーナ",
      "ka_ge": "ანკონა",
      "ko_kr": "안코나",
      "lt_lt": "Ankona",
      "lv_lv": "Ankona",
      "mk_mk": "Анкона",
      "nl_nl": "Ancona",
      "no_no": "Ancona",
      "pl_pl": "Ankona",
      "pl_si": "Ankona",
      "pt_br": "Ancona",
      "pt_pt": "Ancona",
      "ro_ro": "Ancona",
      "ru_ru": "Анкона",
      "sk_sk": "Ancona",
      "sl_sl": "Ancona",
      "sr_sp": "Ankona",
      "sr_sr": "Анкона",
      "sv_se": "Ancona",
      "tr_tr": "Ankona",
      "uk_uk": "Анкона",
      "vi_vn": "Ancona",
      "zh_cn": "安科纳",
      "zh_tw": "安科納"
    }
  },
  {
    "Name": "Palermo",
    "Country": "italy",
    "X": 8350.891,
    "Y": 70487.19,
    "InGameId": "palermo",
    "LocalizationToken": "palermo",
    "CountryId": 6,
    "LocalizedNames": {
      "bg_bg": "Палермо",
      "ca_es": "Palerm",
      "cs_cz": "Palermo",
      "da_dk": "Palermo",
      "de_de": "Palermo",
      "el_gr": "Παλέρμο",
      "en_gb": "Palermo",
      "en_us": "Palermo",
      "es_es": "Palermo",
      "es_la": "Palermo",
      "et_ee": "Palermo",
      "eu_es": "Palermo",
      "fi_fi": "Palermo",
      "fr_ca": "Palerme",
      "fr_fr": "Palerme",
      "gl_es": "Palermo",
      "hr_hr": "Palermo",
      "hu_hu": "Palermo",
      "it_it": "Palermo",
      "ja_jp": "パレルモ",
      "ka_ge": "პალერმო",
      "ko_kr": "팔레르모",
      "lt_lt": "Palermas",
      "lv_lv": "Palermo",
      "mk_mk": "Палермо",
      "nl_nl": "Palermo",
      "no_no": "Palermo",
      "pl_pl": "Palermo",
      "pl_si": "Palermo",
      "pt_br": "Palermo",
      "pt_pt": "Palermo",
      "ro_ro": "Palermo",
      "ru_ru": "Палермо",
      "sk_sk": "Palermo",
      "sl_sl": "Palermo",
      "sr_sp": "Palermo",
      "sr_sr": "Палермо",
      "sv_se": "Palermo",
      "tr_tr": "Palermo",
      "uk_uk": "Палермо",
      "vi_vn": "Palermo",
      "zh_cn": "巴勒莫",
      "zh_tw": "巴勒摩"
    }
  },
  {
    "Name": "Pescara",
    "Country": "italy",
    "X": 12397.9961,
    "Y": 46327.97,
    "InGameId": "pescara",
    "LocalizationToken": "pescara",
    "CountryId": 6,
    "LocalizedNames": {
      "bg_bg": "Пескара",
      "ca_es": "Pescara",
      "cs_cz": "Pescara",
      "da_dk": "Pescara",
      "de_de": "Pescara",
      "el_gr": "Πεσκάρα",
      "en_gb": "Pescara",
      "en_us": "Pescara",
      "es_es": "Pescara",
      "es_la": "Pescara",
      "et_ee": "Pescara",
      "eu_es": "Pescara",
      "fi_fi": "Pescara",
      "fr_ca": "Pescara",
      "fr_fr": "Pescara",
      "gl_es": "Pescara",
      "hr_hr": "Pescara",
      "hu_hu": "Pescara",
      "it_it": "Pescara",
      "ja_jp": "ペスカーラ",
      "ka_ge": "პესკარა",
      "ko_kr": "페스카라",
      "lt_lt": "Peskara",
      "lv_lv": "Peskāra",
      "mk_mk": "Пескара",
      "nl_nl": "Pescara",
      "no_no": "Pescara",
      "pl_pl": "Pescara",
      "pl_si": "Pescara",
      "pt_br": "Pescara",
      "pt_pt": "Pescara",
      "ro_ro": "Pescara",
      "ru_ru": "Пескара",
      "sk_sk": "Pescara",
      "sl_sl": "Pescara",
      "sr_sp": "Peskara",
      "sr_sr": "Пескара",
      "sv_se": "Pescara",
      "tr_tr": "Peskere",
      "uk_uk": "Пескара",
      "vi_vn": "Pescara",
      "zh_cn": "佩斯卡拉",
      "zh_tw": "佩斯卡拉"
    }
  },
  {
    "Name": "Cassino",
    "Country": "italy",
    "X": 12043.8906,
    "Y": 52064.8945,
    "InGameId": "cassino",
    "LocalizationToken": "cassino",
    "CountryId": 6,
    "LocalizedNames": {
      "bg_bg": "Касино",
      "ca_es": "Cassino",
      "cs_cz": "Cassino",
      "da_dk": "Cassino",
      "de_de": "Cassino",
      "el_gr": "Κασσίνο",
      "en_gb": "Cassino",
      "en_us": "Cassino",
      "es_es": "Cassino",
      "es_la": "Cassino",
      "et_ee": "Cassino",
      "eu_es": "Cassino",
      "fi_fi": "Cassino",
      "fr_ca": "Cassino",
      "fr_fr": "Cassino",
      "gl_es": "Cassino",
      "hr_hr": "Cassino",
      "hu_hu": "Cassino",
      "it_it": "Cassino",
      "ja_jp": "カッシーノ",
      "ka_ge": "კასინო",
      "ko_kr": "카시노",
      "lt_lt": "Kasinas",
      "lv_lv": "Kassino",
      "mk_mk": "Касино",
      "nl_nl": "Cassino",
      "no_no": "Cassino",
      "pl_pl": "Cassino",
      "pl_si": "Cassino",
      "pt_br": "Cassino",
      "pt_pt": "Cassino",
      "ro_ro": "Cassino",
      "ru_ru": "Кассино",
      "sk_sk": "Cassino",
      "sl_sl": "Cassino",
      "sr_sp": "Kasino",
      "sr_sr": "Касино",
      "sv_se": "Cassino",
      "tr_tr": "Cassino",
      "uk_uk": "Кассіно",
      "vi_vn": "Cassino",
      "zh_cn": "卡西诺",
      "zh_tw": "卡西諾"
    }
  },
  {
    "Name": "Napoli",
    "Country": "italy",
    "X": 12418.1016,
    "Y": 55071.93,
    "InGameId": "napoli",
    "LocalizationToken": "napoli",
    "CountryId": 6,
    "LocalizedNames": {
      "bg_bg": "Неапол",
      "ca_es": "Nàpols",
      "cs_cz": "Neapol",
      "da_dk": "Napoli",
      "de_de": "Neapel",
      "el_gr": "Νάπολη",
      "en_gb": "Naples",
      "en_us": "Naples",
      "es_es": "Nápoles",
      "es_la": "Nápoles",
      "et_ee": "Napoli",
      "eu_es": "Napoles",
      "fi_fi": "Napoli",
      "fr_ca": "Naples",
      "fr_fr": "Naples",
      "gl_es": "Nápoles",
      "hr_hr": "Napulj",
      "hu_hu": "Nápoly",
      "it_it": "Napoli",
      "ja_jp": "ナポリ",
      "ka_ge": "ნეაპოლი",
      "ko_kr": "나폴리",
      "lt_lt": "Neapolis",
      "lv_lv": "Neapole",
      "mk_mk": "Наполи",
      "nl_nl": "Napels",
      "no_no": "Napoli",
      "pl_pl": "Neapol",
      "pl_si": "Neapol",
      "pt_br": "Nápoles",
      "pt_pt": "Nápoles",
      "ro_ro": "Napoli",
      "ru_ru": "Неаполь",
      "sk_sk": "Neapol",
      "sl_sl": "Neapelj",
      "sr_sp": "Napulj",
      "sr_sr": "Напуљ",
      "sv_se": "Neapel",
      "tr_tr": "Napoli",
      "uk_uk": "Неаполь",
      "vi_vn": "Naples",
      "zh_cn": "那不勒斯",
      "zh_tw": "那不勒斯"
    }
  },
  {
    "Name": "Messina",
    "Country": "italy",
    "X": 17975.7422,
    "Y": 70539.16,
    "InGameId": "messina",
    "LocalizationToken": "messina",
    "CountryId": 6,
    "LocalizedNames": {
      "bg_bg": "Месина",
      "ca_es": "Messina",
      "cs_cz": "Messina",
      "da_dk": "Messina",
      "de_de": "Messina",
      "el_gr": "Μεσσήνη",
      "en_gb": "Messina",
      "en_us": "Messina",
      "es_es": "Mesina",
      "es_la": "Mesina",
      "et_ee": "Messina",
      "eu_es": "Messina",
      "fi_fi": "Messina",
      "fr_ca": "Messine",
      "fr_fr": "Messine",
      "gl_es": "Messina",
      "hr_hr": "Messina",
      "hu_hu": "Messina",
      "it_it": "Messina",
      "ja_jp": "メッシーナ",
      "ka_ge": "მესინა",
      "ko_kr": "메시나",
      "lt_lt": "Mesina",
      "lv_lv": "Mesīna",
      "mk_mk": "Месина",
      "nl_nl": "Messina",
      "no_no": "Messina",
      "pl_pl": "Mesyna",
      "pl_si": "Mesyna",
      "pt_br": "Messina",
      "pt_pt": "Messina",
      "ro_ro": "Messina",
      "ru_ru": "Мессина",
      "sk_sk": "Messina",
      "sl_sl": "Messina",
      "sr_sp": "Mesina",
      "sr_sr": "Месина",
      "sv_se": "Messina",
      "tr_tr": "Messina",
      "uk_uk": "Мессіна",
      "vi_vn": "Messina",
      "zh_cn": "墨西拿",
      "zh_tw": "美西納"
    }
  },
  {
    "Name": "Villa San Giovanni",
    "Country": "italy",
    "X": 19020.7031,
    "Y": 70314.04,
    "InGameId": "sangiovanni",
    "LocalizationToken": "villa_san_giovanni",
    "CountryId": 6,
    "LocalizedNames": {
      "bg_bg": "Вила Сан Джовани",
      "ca_es": "Villa San Giovanni",
      "cs_cz": "Villa San Giovanni",
      "da_dk": "Villa San Giovanni",
      "de_de": "Villa San Giovanni",
      "el_gr": "Βίλλα Σαν Τζοβάννι",
      "en_gb": "Villa San Giovanni",
      "en_us": "Villa San Giovanni",
      "es_es": "Villa San Giovanni",
      "es_la": "Villa San Giovanni",
      "et_ee": "Villa San Giovanni",
      "eu_es": "Villa San Giovanni",
      "fi_fi": "Villa San Giovanni",
      "fr_ca": "Villa San Giovanni",
      "fr_fr": "Villa San Giovanni",
      "gl_es": "Villa San Giovanni",
      "hr_hr": "Villa San Giovanni",
      "hu_hu": "Villa San Giovanni",
      "it_it": "Villa San Giovanni",
      "ja_jp": "ヴィッラ・サン・ジョヴァンニ",
      "ka_ge": "ვილა-სან-ჯოვანი",
      "ko_kr": "빌라 산 조반니",
      "lt_lt": "Villa San Giovanni",
      "lv_lv": "Villa San Džovani",
      "mk_mk": "Вила Сан Џовани",
      "nl_nl": "Villa San Giovanni",
      "no_no": "Villa San Giovanni",
      "pl_pl": "Villa San Giovanni",
      "pl_si": "Villa San Giovanni",
      "pt_br": "Villa San Giovanni",
      "pt_pt": "Villa San Giovanni",
      "ro_ro": "Villa San Giovanni",
      "ru_ru": "Вилла-Сан-Джованни",
      "sk_sk": "Villa San Giovanni",
      "sl_sl": "Villa San Giovanni",
      "sr_sp": "Vila San Đovani",
      "sr_sr": "Вила Сан Ђовани",
      "sv_se": "Villa San Giovanni",
      "tr_tr": "Villa San Giovanni",
      "uk_uk": "Вілла-Сан-Джованні",
      "vi_vn": "Villa San Giovanni",
      "zh_cn": "圣乔瓦尼镇",
      "zh_tw": "聖喬瓦尼"
    }
  },
  {
    "Name": "Catania",
    "Country": "italy",
    "X": 16420.7148,
    "Y": 74982.42,
    "InGameId": "catania",
    "LocalizationToken": "catania",
    "CountryId": 6,
    "LocalizedNames": {
      "bg_bg": "Катания",
      "ca_es": "Catània",
      "cs_cz": "Catania",
      "da_dk": "Catania",
      "de_de": "Catania",
      "el_gr": "Κατάνια",
      "en_gb": "Catania",
      "en_us": "Catania",
      "es_es": "Catania",
      "es_la": "Catania",
      "et_ee": "Catania",
      "eu_es": "Catania",
      "fi_fi": "Catania",
      "fr_ca": "Catane",
      "fr_fr": "Catane",
      "gl_es": "Catania",
      "hr_hr": "Catania",
      "hu_hu": "Catania",
      "it_it": "Catania",
      "ja_jp": "カターニア",
      "ka_ge": "კატანია",
      "ko_kr": "카타니아",
      "lt_lt": "Katanija",
      "lv_lv": "Katānija",
      "mk_mk": "Катанија",
      "nl_nl": "Catania",
      "no_no": "Catania",
      "pl_pl": "Katania",
      "pl_si": "Katania",
      "pt_br": "Catânia",
      "pt_pt": "Catania",
      "ro_ro": "Catania",
      "ru_ru": "Катания",
      "sk_sk": "Catania",
      "sl_sl": "Catania",
      "sr_sp": "Katanija",
      "sr_sr": "Катанија",
      "sv_se": "Catania",
      "tr_tr": "Katanya",
      "uk_uk": "Катанія",
      "vi_vn": "Catania",
      "zh_cn": "卡塔尼亚",
      "zh_tw": "卡塔尼亞"
    }
  },
  {
    "Name": "Catanzaro",
    "Country": "italy",
    "X": 23291.6563,
    "Y": 66425.76,
    "InGameId": "catanzaro",
    "LocalizationToken": "catanzaro",
    "CountryId": 6,
    "LocalizedNames": {
      "bg_bg": "Катандзаро",
      "ca_es": "Catanzaro",
      "cs_cz": "Catanzaro",
      "da_dk": "Catanzaro",
      "de_de": "Catanzaro",
      "el_gr": "Καταντζάρο",
      "en_gb": "Catanzaro",
      "en_us": "Catanzaro",
      "es_es": "Catanzaro",
      "es_la": "Catanzaro",
      "et_ee": "Catanzaro",
      "eu_es": "Catanzaro",
      "fi_fi": "Catanzaro",
      "fr_ca": "Catanzaro",
      "fr_fr": "Catanzaro",
      "gl_es": "Catanzaro",
      "hr_hr": "Catanzaro",
      "hu_hu": "Catanzaro",
      "it_it": "Catanzaro",
      "ja_jp": "カタンザーロ",
      "ka_ge": "კატანძარო",
      "ko_kr": "카탄차로",
      "lt_lt": "Katancaras",
      "lv_lv": "Katandzāro",
      "mk_mk": "Катанзаро",
      "nl_nl": "Catanzaro",
      "no_no": "Catanzaro",
      "pl_pl": "Catanzaro",
      "pl_si": "Catanzaro",
      "pt_br": "Catanzaro",
      "pt_pt": "Catanzaro",
      "ro_ro": "Catanzaro",
      "ru_ru": "Катандзаро",
      "sk_sk": "Catanzaro",
      "sl_sl": "Catanzaro",
      "sr_sp": "Katancaro",
      "sr_sr": "Катанцаро",
      "sv_se": "Catanzaro",
      "tr_tr": "Katansar",
      "uk_uk": "Катандзаро",
      "vi_vn": "Catanzaro",
      "zh_cn": "卡坦扎罗",
      "zh_tw": "卡坦扎羅"
    }
  },
  {
    "Name": "Bari",
    "Country": "italy",
    "X": 23031.04,
    "Y": 53831.2734,
    "InGameId": "bari",
    "LocalizationToken": "bari",
    "CountryId": 6,
    "LocalizedNames": {
      "bg_bg": "Бари",
      "ca_es": "Bari",
      "cs_cz": "Bari",
      "da_dk": "Bari",
      "de_de": "Bari",
      "el_gr": "Μπάρι",
      "en_gb": "Bari",
      "en_us": "Bari",
      "es_es": "Bari",
      "es_la": "Bari",
      "et_ee": "Bari",
      "eu_es": "Bari",
      "fi_fi": "Bari",
      "fr_ca": "Bari",
      "fr_fr": "Bari",
      "gl_es": "Bari",
      "hr_hr": "Bari",
      "hu_hu": "Bari",
      "it_it": "Bari",
      "ja_jp": "バーリ",
      "ka_ge": "ბარი",
      "ko_kr": "바리",
      "lt_lt": "Baris",
      "lv_lv": "Bari",
      "mk_mk": "Бари",
      "nl_nl": "Bari",
      "no_no": "Bari",
      "pl_pl": "Bari",
      "pl_si": "Bari",
      "pt_br": "Bari",
      "pt_pt": "Bari",
      "ro_ro": "Bari",
      "ru_ru": "Бари",
      "sk_sk": "Bari",
      "sl_sl": "Bari",
      "sr_sp": "Bari",
      "sr_sr": "Бари",
      "sv_se": "Bari",
      "tr_tr": "Bari",
      "uk_uk": "Барі",
      "vi_vn": "Bari",
      "zh_cn": "巴里",
      "zh_tw": "巴里"
    }
  },
  {
    "Name": "Taranto",
    "Country": "italy",
    "X": 25490.4258,
    "Y": 57821.28,
    "InGameId": "taranto",
    "LocalizationToken": "taranto",
    "CountryId": 6,
    "LocalizedNames": {
      "bg_bg": "Таранто",
      "ca_es": "Tàrent",
      "cs_cz": "Taranto",
      "da_dk": "Taranto",
      "de_de": "Tarent",
      "el_gr": "Τάραντας",
      "en_gb": "Taranto",
      "en_us": "Taranto",
      "es_es": "Tarento",
      "es_la": "Tarento",
      "et_ee": "Taranto",
      "eu_es": "Tarento",
      "fi_fi": "Taranto",
      "fr_ca": "Tarente",
      "fr_fr": "Tarente",
      "gl_es": "Tarento",
      "hr_hr": "Taranto",
      "hu_hu": "Taranto",
      "it_it": "Taranto",
      "ja_jp": "ターラント",
      "ka_ge": "ტარანტო",
      "ko_kr": "타란토",
      "lt_lt": "Tarantas",
      "lv_lv": "Taranto",
      "mk_mk": "Таранто",
      "nl_nl": "Taranto",
      "no_no": "Taranto",
      "pl_pl": "Tarent",
      "pl_si": "Tarent",
      "pt_br": "Taranto",
      "pt_pt": "Taranto",
      "ro_ro": "Taranto",
      "ru_ru": "Таранто",
      "sk_sk": "Taranto",
      "sl_sl": "Taranto",
      "sr_sp": "Taranto",
      "sr_sr": "Таранто",
      "sv_se": "Taranto",
      "tr_tr": "Taranto",
      "uk_uk": "Таранто",
      "vi_vn": "Taranto",
      "zh_cn": "塔兰托",
      "zh_tw": "塔蘭托"
    }
  },
  {
    "Name": "Suzzara",
    "Country": "italy",
    "X": -1130.94922,
    "Y": 31926.668,
    "InGameId": "suzzara",
    "LocalizationToken": "suzzara",
    "CountryId": 6,
    "LocalizedNames": {
      "bg_bg": "Судзара",
      "ca_es": "Suzzara",
      "cs_cz": "Suzzara",
      "da_dk": "Suzzara",
      "de_de": "Suzzara",
      "el_gr": "Σουτζάρα",
      "en_gb": "Suzzara",
      "en_us": "Suzzara",
      "es_es": "Suzzara",
      "es_la": "Suzzara",
      "et_ee": "Suzzara",
      "eu_es": "Suzzara",
      "fi_fi": "Suzzara",
      "fr_ca": "Suzzara",
      "fr_fr": "Suzzara",
      "gl_es": "Suzzara",
      "hr_hr": "Suzzara",
      "hu_hu": "Suzzara",
      "it_it": "Suzzara",
      "ja_jp": "スッザーラ",
      "ka_ge": "სუძარა",
      "ko_kr": "수자라",
      "lt_lt": "Suzzara",
      "lv_lv": "Sucara",
      "mk_mk": "Суцара",
      "nl_nl": "Suzzara",
      "no_no": "Suzzara",
      "pl_pl": "Suzzara",
      "pl_si": "Suzzara",
      "pt_br": "Suzzara",
      "pt_pt": "Suzzara",
      "ro_ro": "Suzzara",
      "ru_ru": "Судзара",
      "sk_sk": "Suzzara",
      "sl_sl": "Suzzara",
      "sr_sp": "Sucara",
      "sr_sr": "Суцара",
      "sv_se": "Suzzara",
      "tr_tr": "Suzzara",
      "uk_uk": "Суццара",
      "vi_vn": "Suzzara",
      "zh_cn": "苏扎拉",
      "zh_tw": "蘇扎拉"
    }
  },
  {
    "Name": "Parma",
    "Country": "italy",
    "X": -3406.56641,
    "Y": 33434.168,
    "InGameId": "parma",
    "LocalizationToken": "parma",
    "CountryId": 6,
    "LocalizedNames": {
      "bg_bg": "Парма",
      "ca_es": "Parma",
      "cs_cz": "Parma",
      "da_dk": "Parma",
      "de_de": "Parma",
      "el_gr": "Πάρμα",
      "en_gb": "Parma",
      "en_us": "Parma",
      "es_es": "Parma",
      "es_la": "Parma",
      "et_ee": "Parma",
      "eu_es": "Parma",
      "fi_fi": "Parma",
      "fr_ca": "Parme",
      "fr_fr": "Parme",
      "gl_es": "Parma",
      "hr_hr": "Parma",
      "hu_hu": "Párma",
      "it_it": "Parma",
      "ja_jp": "パルマ",
      "ka_ge": "პარმა",
      "ko_kr": "파르마",
      "lt_lt": "Parma",
      "lv_lv": "Parma",
      "mk_mk": "Парма",
      "nl_nl": "Parma",
      "no_no": "Parma",
      "pl_pl": "Parma",
      "pl_si": "Parma",
      "pt_br": "Parma",
      "pt_pt": "Parma",
      "ro_ro": "Parma",
      "ru_ru": "Парма",
      "sk_sk": "Parma",
      "sl_sl": "Parma",
      "sr_sp": "Parma",
      "sr_sr": "Парма",
      "sv_se": "Parma",
      "tr_tr": "Parma",
      "uk_uk": "Парма",
      "vi_vn": "Parma",
      "zh_cn": "帕尔马",
      "zh_tw": "帕爾馬"
    }
  },
  {
    "Name": "Livorno",
    "Country": "italy",
    "X": -2968.42188,
    "Y": 39681.6836,
    "InGameId": "livorno",
    "LocalizationToken": "livorno",
    "CountryId": 6,
    "LocalizedNames": {
      "bg_bg": "Ливорно",
      "ca_es": "Liorna",
      "cs_cz": "Livorno",
      "da_dk": "Livorno",
      "de_de": "Livorno",
      "el_gr": "Λιβόρνο",
      "en_gb": "Livorno",
      "en_us": "Livorno",
      "es_es": "Livorno",
      "es_la": "Livorno",
      "et_ee": "Livorno",
      "eu_es": "Livorno",
      "fi_fi": "Livorno",
      "fr_ca": "Livourne",
      "fr_fr": "Livourne",
      "gl_es": "Livorno",
      "hr_hr": "Livorno",
      "hu_hu": "Livorno",
      "it_it": "Livorno",
      "ja_jp": "リヴォルノ",
      "ka_ge": "ლივორნო",
      "ko_kr": "리보르노",
      "lt_lt": "Livornas",
      "lv_lv": "Livorno",
      "mk_mk": "Ливорно",
      "nl_nl": "Livorno",
      "no_no": "Livorno",
      "pl_pl": "Livorno",
      "pl_si": "Livorno",
      "pt_br": "Livorno",
      "pt_pt": "Livorno",
      "ro_ro": "Livorno",
      "ru_ru": "Ливорно",
      "sk_sk": "Livorno",
      "sl_sl": "Livorno",
      "sr_sp": "Livorno",
      "sr_sr": "Ливорно",
      "sv_se": "Livorno",
      "tr_tr": "Ligurna",
      "uk_uk": "Ліворно",
      "vi_vn": "Livorno",
      "zh_cn": "里窝那",
      "zh_tw": "利弗諾"
    }
  },
  {
    "Name": "Cagliari",
    "Country": "italy",
    "X": -10160.8555,
    "Y": 64051.8164,
    "InGameId": "cagliari",
    "LocalizationToken": "cagliari",
    "CountryId": 6,
    "LocalizedNames": {
      "bg_bg": "Каляри",
      "ca_es": "Càller",
      "cs_cz": "Cagliari",
      "da_dk": "Cagliari",
      "de_de": "Cagliari",
      "el_gr": "Κάλιαρι",
      "en_gb": "Cagliari",
      "en_us": "Cagliari",
      "es_es": "Cagliari",
      "es_la": "Cagliari",
      "et_ee": "Cagliari",
      "eu_es": "Cagliari",
      "fi_fi": "Cagliari",
      "fr_ca": "Cagliari",
      "fr_fr": "Cagliari",
      "gl_es": "Cagliari",
      "hr_hr": "Cagliari",
      "hu_hu": "Cagliari",
      "it_it": "Cagliari",
      "ja_jp": "カリャリ",
      "ka_ge": "კალიარი",
      "ko_kr": "칼리아리",
      "lt_lt": "Kaljaris",
      "lv_lv": "Cagliari",
      "mk_mk": "Каљари",
      "nl_nl": "Cagliari",
      "no_no": "Cagliari",
      "pl_pl": "Cagliari",
      "pl_si": "Cagliari",
      "pt_br": "Cagliari",
      "pt_pt": "Cagliari",
      "ro_ro": "Cagliari",
      "ru_ru": "Кальяри",
      "sk_sk": "Cagliari",
      "sl_sl": "Cagliari",
      "sr_sp": "Kaljari",
      "sr_sr": "Каљари",
      "sv_se": "Cagliari",
      "tr_tr": "Cagliari",
      "uk_uk": "Кальярі",
      "vi_vn": "Cagliari",
      "zh_cn": "卡利亚里",
      "zh_tw": "卡利亞里"
    }
  },
  {
    "Name": "Odense",
    "Country": "denmark",
    "X": 1017.5625,
    "Y": -27270.543,
    "InGameId": "odense",
    "LocalizationToken": "odense",
    "CountryId": 21,
    "LocalizedNames": {
      "bg_bg": "Оденсе",
      "ca_es": "Odense",
      "cs_cz": "Odense",
      "da_dk": "Odense",
      "de_de": "Odense",
      "el_gr": "Όντενσε",
      "en_gb": "Odense",
      "en_us": "Odense",
      "es_es": "Odense",
      "es_la": "Odense",
      "et_ee": "Odense",
      "eu_es": "Odense",
      "fi_fi": "Odense",
      "fr_ca": "Odense",
      "fr_fr": "Odense",
      "gl_es": "Odense",
      "hr_hr": "Odense",
      "hu_hu": "Odense",
      "it_it": "Odense",
      "ja_jp": "オーデンセ",
      "ka_ge": "ოდენსე",
      "ko_kr": "오덴세",
      "lt_lt": "Odensė",
      "lv_lv": "Odense",
      "mk_mk": "Оденсе",
      "nl_nl": "Odense",
      "no_no": "Odense",
      "pl_pl": "Odense",
      "pl_si": "Odense",
      "pt_br": "Odense",
      "pt_pt": "Odense",
      "ro_ro": "Odense",
      "ru_ru": "Оденсе",
      "sk_sk": "Odense",
      "sl_sl": "Odense",
      "sr_sp": "Odense",
      "sr_sr": "Оденсе",
      "sv_se": "Odense",
      "tr_tr": "Odense",
      "uk_uk": "Оденсе",
      "vi_vn": "Odense",
      "zh_cn": "欧登塞",
      "zh_tw": "奧登色"
    }
  },
  {
    "Name": "Aalborg",
    "Country": "denmark",
    "X": 480.1328,
    "Y": -36290.2227,
    "InGameId": "aalborg",
    "LocalizationToken": "aalborg",
    "CountryId": 21,
    "LocalizedNames": {
      "bg_bg": "Олбор",
      "ca_es": "Aalborg",
      "cs_cz": "Aalborg",
      "da_dk": "Aalborg",
      "de_de": "Aalborg",
      "el_gr": "Άλμποργκ",
      "en_gb": "Aalborg",
      "en_us": "Aalborg",
      "es_es": "Aalborg",
      "es_la": "Aalborg",
      "et_ee": "Aalborg",
      "eu_es": "Aalborg",
      "fi_fi": "Aalborg",
      "fr_ca": "Aalborg",
      "fr_fr": "Aalborg",
      "gl_es": "Aalborg",
      "hr_hr": "Aalborg",
      "hu_hu": "Aalborg",
      "it_it": "Aalborg",
      "ja_jp": "オールボー",
      "ka_ge": "ოლბორგი",
      "ko_kr": "올보르",
      "lt_lt": "Alborgas",
      "lv_lv": "Olborga",
      "mk_mk": "Олборг",
      "nl_nl": "Aalborg",
      "no_no": "Ålborg",
      "pl_pl": "Aalborg",
      "pl_si": "Aalborg",
      "pt_br": "Aalborg",
      "pt_pt": "Aalborg",
      "ro_ro": "Aalborg",
      "ru_ru": "Ольборг",
      "sk_sk": "Aalborg",
      "sl_sl": "Aalborg",
      "sr_sp": "Olborg",
      "sr_sr": "Олборг",
      "sv_se": "Ålborg",
      "tr_tr": "Aalborg",
      "uk_uk": "Ольборг",
      "vi_vn": "Aalborg",
      "zh_cn": "奥尔堡",
      "zh_tw": "奧爾堡"
    }
  },
  {
    "Name": "Hirtshals",
    "Country": "denmark",
    "X": 775.34375,
    "Y": -39243.4141,
    "InGameId": "hirtshals",
    "LocalizationToken": "hirtshals",
    "CountryId": 21,
    "LocalizedNames": {
      "bg_bg": "Хирцхалс",
      "ca_es": "Hirtshals",
      "cs_cz": "Hirtshals",
      "da_dk": "Hirtshals",
      "de_de": "Hirtshals",
      "el_gr": "Χίρτσχαλς",
      "en_gb": "Hirtshals",
      "en_us": "Hirtshals",
      "es_es": "Hirtshals",
      "es_la": "Hirtshals",
      "et_ee": "Hirtshals",
      "eu_es": "Hirtshals",
      "fi_fi": "Hirtshals",
      "fr_ca": "Hirtshals",
      "fr_fr": "Hirtshals",
      "gl_es": "Hirtshals",
      "hr_hr": "Hirtshals",
      "hu_hu": "Hirtshals",
      "it_it": "Hirtshals",
      "ja_jp": "ヒァツハルス",
      "ka_ge": "ჰირტსჰალსი",
      "ko_kr": "히르트스할스",
      "lt_lt": "Hirtshalsas",
      "lv_lv": "Hirtshalsa",
      "mk_mk": "Хиртсхалс",
      "nl_nl": "Hirtshals",
      "no_no": "Hirtshals",
      "pl_pl": "Hirtshals",
      "pl_si": "Hirtshals",
      "pt_br": "Hirtshals",
      "pt_pt": "Hirtshals",
      "ro_ro": "Hirtshals",
      "ru_ru": "Хиртсхальс",
      "sk_sk": "Hirtshals",
      "sl_sl": "Hirtshals",
      "sr_sp": "Hirtshals",
      "sr_sr": "Хиртсхалс",
      "sv_se": "Hirtshals",
      "tr_tr": "Hirtshals",
      "uk_uk": "Хіртсхальс",
      "vi_vn": "Hirtshals",
      "zh_cn": "希茨海尔斯",
      "zh_tw": "希茨海爾斯"
    }
  },
  {
    "Name": "Frederikshavn",
    "Country": "denmark",
    "X": 2361.57031,
    "Y": -38511.5859,
    "InGameId": "frederikshv",
    "LocalizationToken": "frederikshavn",
    "CountryId": 21,
    "LocalizedNames": {
      "bg_bg": "Фредерикшавн",
      "ca_es": "Frederikshavn",
      "cs_cz": "Frederikshavn",
      "da_dk": "Frederikshavn",
      "de_de": "Frederikshavn",
      "el_gr": "Φρεντεριξχάβν",
      "en_gb": "Frederikshavn",
      "en_us": "Frederikshavn",
      "es_es": "Frederikshavn",
      "es_la": "Frederikshavn",
      "et_ee": "Frederikshavn",
      "eu_es": "Frederikshavn",
      "fi_fi": "Frederikshavn",
      "fr_ca": "Frederikshavn",
      "fr_fr": "Frederikshavn",
      "gl_es": "Frederikshavn",
      "hr_hr": "Frederikshavn",
      "hu_hu": "Frederikshavn",
      "it_it": "Frederikshavn",
      "ja_jp": "フレゼリクスハウン",
      "ka_ge": "ფრედერიქსჰავნი",
      "ko_kr": "프레데릭스하운",
      "lt_lt": "Frederikshaunas",
      "lv_lv": "Frederikshavna",
      "mk_mk": "Фредериксхавн",
      "nl_nl": "Frederikshavn",
      "no_no": "Frederikshavn",
      "pl_pl": "Frederikshavn",
      "pl_si": "Frederikshavn",
      "pt_br": "Frederikshavn",
      "pt_pt": "Frederikshavn",
      "ro_ro": "Frederikshavn",
      "ru_ru": "Фредериксхавн",
      "sk_sk": "Frederikshavn",
      "sl_sl": "Frederikshavn",
      "sr_sp": "Frederikshavn",
      "sr_sr": "Фредериксхавн",
      "sv_se": "Fredrikshamn",
      "tr_tr": "Frederikshavn",
      "uk_uk": "Фредеріксгавн",
      "vi_vn": "Frederikshavn",
      "zh_cn": "腓特烈港",
      "zh_tw": "腓特烈港"
    }
  },
  {
    "Name": "Göteborg",
    "Country": "sweden",
    "X": 6835.76172,
    "Y": -40547.6172,
    "InGameId": "goteborg",
    "LocalizationToken": "goteborg",
    "CountryId": 43,
    "LocalizedNames": {
      "bg_bg": "Гьотеборг",
      "ca_es": "Göteborg",
      "cs_cz": "Göteborg",
      "da_dk": "Göteborg",
      "de_de": "Göteborg",
      "el_gr": "Γκέτεμποργκ",
      "en_gb": "Gothenburg",
      "en_us": "Gothenburg",
      "es_es": "Gotemburgo",
      "es_la": "Gotemburgo",
      "et_ee": "Göteborg",
      "eu_es": "Göteborg",
      "fi_fi": "Göteborg",
      "fr_ca": "Göteborg",
      "fr_fr": "Göteborg",
      "gl_es": "Gotemburgo",
      "hr_hr": "Göteborg",
      "hu_hu": "Göteborg",
      "it_it": "Göteborg",
      "ja_jp": "ヨーテボリ",
      "ka_ge": "გეტებორგი",
      "ko_kr": "예테보리",
      "lt_lt": "Geteborgas",
      "lv_lv": "Gēteborga",
      "mk_mk": "Гетеборг",
      "nl_nl": "Göteborg",
      "no_no": "Göteborg",
      "pl_pl": "Göteborg",
      "pl_si": "Göteborg",
      "pt_br": "Gotemburgo",
      "pt_pt": "Gotemburgo",
      "ro_ro": "Göteborg",
      "ru_ru": "Гётеборг",
      "sk_sk": "Göteborg",
      "sl_sl": "Göteborg",
      "sr_sp": "Geteborg",
      "sr_sr": "Гетеборг",
      "sv_se": "Göteborg",
      "tr_tr": "Göthenburg",
      "uk_uk": "Гетеборг",
      "vi_vn": "Gothenburg",
      "zh_cn": "哥特堡",
      "zh_tw": "哥特堡"
    }
  },
  {
    "Name": "Oslo",
    "Country": "norway",
    "X": 4140.789,
    "Y": -53680.668,
    "InGameId": "oslo",
    "LocalizationToken": "oslo",
    "CountryId": 35,
    "LocalizedNames": {
      "bg_bg": "Осло",
      "ca_es": "Oslo",
      "cs_cz": "Oslo",
      "da_dk": "Oslo",
      "de_de": "Oslo",
      "el_gr": "Όσλο",
      "en_gb": "Oslo",
      "en_us": "Oslo",
      "es_es": "Oslo",
      "es_la": "Oslo",
      "et_ee": "Oslo",
      "eu_es": "Oslo",
      "fi_fi": "Oslo",
      "fr_ca": "Oslo",
      "fr_fr": "Oslo",
      "gl_es": "Oslo",
      "hr_hr": "Oslo",
      "hu_hu": "Oslo",
      "it_it": "Oslo",
      "ja_jp": "オスロ",
      "ka_ge": "ოსლო",
      "ko_kr": "오슬로",
      "lt_lt": "Oslas",
      "lv_lv": "Oslo",
      "mk_mk": "Осло",
      "nl_nl": "Oslo",
      "no_no": "Oslo",
      "pl_pl": "Oslo",
      "pl_si": "Oslo",
      "pt_br": "Oslo",
      "pt_pt": "Oslo",
      "ro_ro": "Oslo",
      "ru_ru": "Осло",
      "sk_sk": "Oslo",
      "sl_sl": "Oslo",
      "sr_sp": "Oslo",
      "sr_sr": "Осло",
      "sv_se": "Oslo",
      "tr_tr": "Oslo",
      "uk_uk": "Осло",
      "vi_vn": "Oslo",
      "zh_cn": "奥斯陆",
      "zh_tw": "奧斯陸"
    }
  },
  {
    "Name": "Malmö",
    "Country": "sweden",
    "X": 10234.7344,
    "Y": -28001.293,
    "InGameId": "malmo",
    "LocalizationToken": "malmo",
    "CountryId": 43,
    "LocalizedNames": {
      "bg_bg": "Малмьо",
      "ca_es": "Malmö",
      "cs_cz": "Malmö",
      "da_dk": "Malmö",
      "de_de": "Malmö",
      "el_gr": "Μάλμο",
      "en_gb": "Malmö",
      "en_us": "Malmö",
      "es_es": "Malmö",
      "es_la": "Malmö",
      "et_ee": "Malmö",
      "eu_es": "Malmö",
      "fi_fi": "Malmö",
      "fr_ca": "Malmö",
      "fr_fr": "Malmö",
      "gl_es": "Malmö",
      "hr_hr": "Malmö",
      "hu_hu": "Malmö",
      "it_it": "Malmö",
      "ja_jp": "マルメー",
      "ka_ge": "მალმე",
      "ko_kr": "말뫼",
      "lt_lt": "Malmė",
      "lv_lv": "Malme",
      "mk_mk": "Малме",
      "nl_nl": "Malmö",
      "no_no": "Malmö",
      "pl_pl": "Malmö",
      "pl_si": "Malmö",
      "pt_br": "Malmo",
      "pt_pt": "Malmo",
      "ro_ro": "Malmö",
      "ru_ru": "Мальмё",
      "sk_sk": "Malmö",
      "sl_sl": "Malmö",
      "sr_sp": "Malme",
      "sr_sr": "Малме",
      "sv_se": "Malmö",
      "tr_tr": "Malmö",
      "uk_uk": "Мальме",
      "vi_vn": "Malmö",
      "zh_cn": "马尔默",
      "zh_tw": "馬爾摩"
    }
  },
  {
    "Name": "Trelleborg",
    "Country": "sweden",
    "X": 10508.1875,
    "Y": -26107.1563,
    "InGameId": "trelleborg",
    "LocalizationToken": "trelleborg",
    "CountryId": 43,
    "LocalizedNames": {
      "bg_bg": "Трелебори",
      "ca_es": "Trelleborg",
      "cs_cz": "Trelleborg",
      "da_dk": "Trelleborg",
      "de_de": "Trelleborg",
      "el_gr": "Τρέλεμπργκ",
      "en_gb": "Trelleborg",
      "en_us": "Trelleborg",
      "es_es": "Trelleborg",
      "es_la": "Trelleborg",
      "et_ee": "Trelleborg",
      "eu_es": "Trelleborg",
      "fi_fi": "Trelleborg",
      "fr_ca": "Trelleborg",
      "fr_fr": "Trelleborg",
      "gl_es": "Trelleborg",
      "hr_hr": "Trelleborg",
      "hu_hu": "Trelleborg",
      "it_it": "Trelleborg",
      "ja_jp": "トレレボリ",
      "ka_ge": "ტრელებორგი",
      "ko_kr": "트렐레보리",
      "lt_lt": "Treleborgas",
      "lv_lv": "Trelleborja",
      "mk_mk": "Трелеборг",
      "nl_nl": "Trelleborg",
      "no_no": "Trelleborg",
      "pl_pl": "Trelleborg",
      "pl_si": "Trelleborg",
      "pt_br": "Trelleborg",
      "pt_pt": "Trelleborg",
      "ro_ro": "Trelleborg",
      "ru_ru": "Треллеборг",
      "sk_sk": "Trelleborg",
      "sl_sl": "Trelleborg",
      "sr_sp": "Treleborg",
      "sr_sr": "Трелеборг",
      "sv_se": "Trelleborg",
      "tr_tr": "Trelleborg",
      "uk_uk": "Треллеборг",
      "vi_vn": "Trelleborg",
      "zh_cn": "特雷勒堡",
      "zh_tw": "特雷勒堡"
    }
  },
  {
    "Name": "København",
    "Country": "denmark",
    "X": 6800.617,
    "Y": -28717.9063,
    "InGameId": "kobenhavn",
    "LocalizationToken": "kobenhavn",
    "CountryId": 21,
    "LocalizedNames": {
      "bg_bg": "Копенхаген",
      "ca_es": "Copenhaguen",
      "cs_cz": "Kodaň",
      "da_dk": "København",
      "de_de": "Kopenhagen",
      "el_gr": "Κοπεγχάγη",
      "en_gb": "Copenhagen",
      "en_us": "Copenhagen",
      "es_es": "Copenhague",
      "es_la": "Copenhague",
      "et_ee": "Kopenhaagen",
      "eu_es": "Kopenhage",
      "fi_fi": "Kööpenhamina",
      "fr_ca": "Copenhague",
      "fr_fr": "Copenhague",
      "gl_es": "Copenhague",
      "hr_hr": "Kopenhagen",
      "hu_hu": "Koppenhága",
      "it_it": "Copenhagen",
      "ja_jp": "コペンハーゲン",
      "ka_ge": "კოპენჰაგენი",
      "ko_kr": "코펜하겐",
      "lt_lt": "Kopenhaga",
      "lv_lv": "Kopenhāgena",
      "mk_mk": "Копенхаген",
      "nl_nl": "Kopenhagen",
      "no_no": "København",
      "pl_pl": "Kopenhaga",
      "pl_si": "Kopenhaga",
      "pt_br": "Copenhague",
      "pt_pt": "Copenhaga",
      "ro_ro": "Copenhaga",
      "ru_ru": "Копенгаген",
      "sk_sk": "Kodaň",
      "sl_sl": "København",
      "sr_sp": "Kopenhagen",
      "sr_sr": "Копенхаген",
      "sv_se": "Köpenhamn",
      "tr_tr": "Kopenhag",
      "uk_uk": "Копенгаген",
      "vi_vn": "Copenhagen",
      "zh_cn": "哥本哈根",
      "zh_tw": "哥本哈根"
    }
  },
  {
    "Name": "Helsingborg",
    "Country": "sweden",
    "X": 9005.078,
    "Y": -30502.55,
    "InGameId": "helsingborg",
    "LocalizationToken": "helsingborg",
    "CountryId": 43,
    "LocalizedNames": {
      "bg_bg": "Хелсингбори",
      "ca_es": "Helsingborg",
      "cs_cz": "Helsingborg",
      "da_dk": "Helsingborg",
      "de_de": "Helsingborg",
      "el_gr": "Χέλσινγκμποργκ",
      "en_gb": "Helsingborg",
      "en_us": "Helsingborg",
      "es_es": "Helsingborg",
      "es_la": "Helsingborg",
      "et_ee": "Helsingborg",
      "eu_es": "Helsingborg",
      "fi_fi": "Helsingborg",
      "fr_ca": "Helsingborg",
      "fr_fr": "Helsingborg",
      "gl_es": "Helsingborg",
      "hr_hr": "Helsingborg",
      "hu_hu": "Helsingborg",
      "it_it": "Helsingborg",
      "ja_jp": "ヘルシンボリ",
      "ka_ge": "ჰელსინგბორგი",
      "ko_kr": "헬싱보리",
      "lt_lt": "Helsingborgas",
      "lv_lv": "Helsingborja",
      "mk_mk": "Хелсингборг",
      "nl_nl": "Helsingborg",
      "no_no": "Helsingborg",
      "pl_pl": "Helsingborg",
      "pl_si": "Helsingborg",
      "pt_br": "Helsingburgo",
      "pt_pt": "Helsingborg",
      "ro_ro": "Helsingborg",
      "ru_ru": "Хельсингборг",
      "sk_sk": "Helsingborg",
      "sl_sl": "Helsingborg",
      "sr_sp": "Helsingborg",
      "sr_sr": "Хелсингборг",
      "sv_se": "Helsingborg",
      "tr_tr": "Helsingborg",
      "uk_uk": "Гельсінборг",
      "vi_vn": "Helsingborg",
      "zh_cn": "赫尔辛堡",
      "zh_tw": "赫爾辛堡"
    }
  },
  {
    "Name": "Jönköping",
    "Country": "sweden",
    "X": 13877.1367,
    "Y": -40045.72,
    "InGameId": "jonkoping",
    "LocalizationToken": "jonkoping",
    "CountryId": 43,
    "LocalizedNames": {
      "bg_bg": "Йоншьопинг",
      "ca_es": "Jönköping",
      "cs_cz": "Jönköping",
      "da_dk": "Jönköping",
      "de_de": "Jönköping",
      "el_gr": "Γιένσεπινγκ",
      "en_gb": "Jönköping",
      "en_us": "Jönköping",
      "es_es": "Jönköping",
      "es_la": "Jönköping",
      "et_ee": "Jönköping",
      "eu_es": "Jönköping",
      "fi_fi": "Jönköping",
      "fr_ca": "Jönköping",
      "fr_fr": "Jönköping",
      "gl_es": "Jönköping",
      "hr_hr": "Jönköping",
      "hu_hu": "Jönköping",
      "it_it": "Jönköping",
      "ja_jp": "ヨンショーピング",
      "ka_ge": "იენჩეპინგი",
      "ko_kr": "옌셰핑",
      "lt_lt": "Jonšiopingas",
      "lv_lv": "Jenšēpinga",
      "mk_mk": "Јеншепинг",
      "nl_nl": "Jönköping",
      "no_no": "Jönköping",
      "pl_pl": "Jönköping",
      "pl_si": "Jönköping",
      "pt_br": "Jönköping",
      "pt_pt": "Jönköping",
      "ro_ro": "Jönköping",
      "ru_ru": "Йёнчёпинг",
      "sk_sk": "Jönköping",
      "sl_sl": "Jönköping",
      "sr_sp": "Jenčeping",
      "sr_sr": "Јенћепинг",
      "sv_se": "Jönköping",
      "tr_tr": "Jönköping",
      "uk_uk": "Єнчепінг",
      "vi_vn": "Jönköping",
      "zh_cn": "延雪平",
      "zh_tw": "延雪平"
    }
  },
  {
    "Name": "Karlskrona",
    "Country": "sweden",
    "X": 16745.4336,
    "Y": -31232.5234,
    "InGameId": "karlskrona",
    "LocalizationToken": "karlskrona",
    "CountryId": 43,
    "LocalizedNames": {
      "bg_bg": "Карлскруна",
      "ca_es": "Karlskrona",
      "cs_cz": "Karlskrona",
      "da_dk": "Karlskrona",
      "de_de": "Karlskrona",
      "el_gr": "Κάρλσκρονα",
      "en_gb": "Karlskrona",
      "en_us": "Karlskrona",
      "es_es": "Karlskrona",
      "es_la": "Karlskrona",
      "et_ee": "Karlskrona",
      "eu_es": "Karlskrona",
      "fi_fi": "Karlskrona",
      "fr_ca": "Karlskrona",
      "fr_fr": "Karlskrona",
      "gl_es": "Karlskrona",
      "hr_hr": "Karlskrona",
      "hu_hu": "Karlskrona",
      "it_it": "Karlskrona",
      "ja_jp": "カールスクルーナ",
      "ka_ge": "კარლსკრონა",
      "ko_kr": "칼스크로나",
      "lt_lt": "Karlskruna",
      "lv_lv": "Karlskrūna",
      "mk_mk": "Карлскрона",
      "nl_nl": "Karlskrona",
      "no_no": "Karlskrona",
      "pl_pl": "Karlskrona",
      "pl_si": "Karlskrona",
      "pt_br": "Karlskrona",
      "pt_pt": "Karlskrona",
      "ro_ro": "Karlskrona",
      "ru_ru": "Карлскруна",
      "sk_sk": "Karlskrona",
      "sl_sl": "Karlskrona",
      "sr_sp": "Karlskrona",
      "sr_sr": "Карлскрона",
      "sv_se": "Karlskrona",
      "tr_tr": "Karlskrona",
      "uk_uk": "Карлскруна",
      "vi_vn": "Karlskrona",
      "zh_cn": "卡尔斯克鲁纳",
      "zh_tw": "卡爾斯克魯納"
    }
  },
  {
    "Name": "Växjö",
    "Country": "sweden",
    "X": 15519.2773,
    "Y": -35205.69,
    "InGameId": "vaxjo",
    "LocalizationToken": "vaxjo",
    "CountryId": 43,
    "LocalizedNames": {
      "bg_bg": "Ваксьо",
      "ca_es": "Växjö",
      "cs_cz": "Växjö",
      "da_dk": "Växjö",
      "de_de": "Växjö",
      "el_gr": "Βέξιε",
      "en_gb": "Växjö",
      "en_us": "Växjö",
      "es_es": "Växjö",
      "es_la": "Växjö",
      "et_ee": "Växjö",
      "eu_es": "Växjö",
      "fi_fi": "Växjö",
      "fr_ca": "Växjö",
      "fr_fr": "Växjö",
      "gl_es": "Växjö",
      "hr_hr": "Växjö",
      "hu_hu": "Växjö",
      "it_it": "Växjö",
      "ja_jp": "ベクショー",
      "ka_ge": "ვექშე",
      "ko_kr": "벡셰",
      "lt_lt": "Vekšė",
      "lv_lv": "Vēkše",
      "mk_mk": "Векше",
      "nl_nl": "Växjö",
      "no_no": "Växjö",
      "pl_pl": "Växjö",
      "pl_si": "Växjö",
      "pt_br": "Växjö",
      "pt_pt": "Växjö",
      "ro_ro": "Växjö",
      "ru_ru": "Векшё",
      "sk_sk": "Växjö",
      "sl_sl": "Växjö",
      "sr_sp": "Vekše",
      "sr_sr": "Векше",
      "sv_se": "Växjö",
      "tr_tr": "Vaxjö",
      "uk_uk": "Векше",
      "vi_vn": "Växjö",
      "zh_cn": "韦克舍",
      "zh_tw": "韋克舍"
    }
  },
  {
    "Name": "Linköping",
    "Country": "sweden",
    "X": 17511.5469,
    "Y": -43713.0273,
    "InGameId": "linkoping",
    "LocalizationToken": "linkoping",
    "CountryId": 43,
    "LocalizedNames": {
      "bg_bg": "Линшьопинг",
      "ca_es": "Linköping",
      "cs_cz": "Linköping",
      "da_dk": "Linköping",
      "de_de": "Linköping",
      "el_gr": "Λινσέπινγκ",
      "en_gb": "Linköping",
      "en_us": "Linköping",
      "es_es": "Linköping",
      "es_la": "Linköping",
      "et_ee": "Linköping",
      "eu_es": "Linköping",
      "fi_fi": "Linköping",
      "fr_ca": "Linköping",
      "fr_fr": "Linköping",
      "gl_es": "Linköping",
      "hr_hr": "Linköping",
      "hu_hu": "Linköping",
      "it_it": "Linköping",
      "ja_jp": "リンシェーピング",
      "ka_ge": "ლინჩეპინგი",
      "ko_kr": "린셰핑",
      "lt_lt": "Linšiopingas",
      "lv_lv": "Linšēpinga",
      "mk_mk": "Линшепинг",
      "nl_nl": "Linköping",
      "no_no": "Linköping",
      "pl_pl": "Linköping",
      "pl_si": "Linköping",
      "pt_br": "Linköping",
      "pt_pt": "Linköping",
      "ro_ro": "Linköping",
      "ru_ru": "Линчёпинг",
      "sk_sk": "Linköping",
      "sl_sl": "Linköping",
      "sr_sp": "Linćeping",
      "sr_sr": "Линћепинг",
      "sv_se": "Linköping",
      "tr_tr": "Linköping",
      "uk_uk": "Лінчепінг",
      "vi_vn": "Linköping",
      "zh_cn": "林雪平",
      "zh_tw": "林雪坪"
    }
  },
  {
    "Name": "Örebro",
    "Country": "sweden",
    "X": 16587.707,
    "Y": -48894.8438,
    "InGameId": "orebro",
    "LocalizationToken": "orebro",
    "CountryId": 43,
    "LocalizedNames": {
      "bg_bg": "Йоребру",
      "ca_es": "Örebro",
      "cs_cz": "Örebro",
      "da_dk": "Örebro",
      "de_de": "Örebro",
      "el_gr": "Όρεμπρο",
      "en_gb": "Örebro",
      "en_us": "Örebro",
      "es_es": "Örebro",
      "es_la": "Örebro",
      "et_ee": "Örebro",
      "eu_es": "Örebro",
      "fi_fi": "Örebro",
      "fr_ca": "Örebro",
      "fr_fr": "Örebro",
      "gl_es": "Örebro",
      "hr_hr": "Örebro",
      "hu_hu": "Örebro",
      "it_it": "Örebro",
      "ja_jp": "エレブルー",
      "ka_ge": "ერებრუ",
      "ko_kr": "외레브로",
      "lt_lt": "Erebru",
      "lv_lv": "Erebrū",
      "mk_mk": "Еребру",
      "nl_nl": "Örebro",
      "no_no": "Örebro",
      "pl_pl": "Örebro",
      "pl_si": "Örebro",
      "pt_br": "Örebro",
      "pt_pt": "Örebro",
      "ro_ro": "Örebro",
      "ru_ru": "Эребру",
      "sk_sk": "Örebro",
      "sl_sl": "Örebro",
      "sr_sp": "Erebro",
      "sr_sr": "Еребро",
      "sv_se": "Örebro",
      "tr_tr": "Örebro",
      "uk_uk": "Еребру",
      "vi_vn": "Örebro",
      "zh_cn": "厄勒布鲁",
      "zh_tw": "厄勒布魯"
    }
  },
  {
    "Name": "Kalmar",
    "Country": "sweden",
    "X": 19959.36,
    "Y": -34554.6,
    "InGameId": "kalmar",
    "LocalizationToken": "kalmar",
    "CountryId": 43,
    "LocalizedNames": {
      "bg_bg": "Калмар",
      "ca_es": "Kalmar",
      "cs_cz": "Kalmar",
      "da_dk": "Kalmar",
      "de_de": "Kalmar",
      "el_gr": "Κάλμαρ",
      "en_gb": "Kalmar",
      "en_us": "Kalmar",
      "es_es": "Kalmar",
      "es_la": "Kalmar",
      "et_ee": "Kalmar",
      "eu_es": "Kalmar",
      "fi_fi": "Kalmar",
      "fr_ca": "Kalmar",
      "fr_fr": "Kalmar",
      "gl_es": "Kalmar",
      "hr_hr": "Kalmar",
      "hu_hu": "Kalmar",
      "it_it": "Kalmar",
      "ja_jp": "カルマル",
      "ka_ge": "კალმარი",
      "ko_kr": "칼마르",
      "lt_lt": "Kalmaras",
      "lv_lv": "Kalmara",
      "mk_mk": "Калмар",
      "nl_nl": "Kalmar",
      "no_no": "Kalmar",
      "pl_pl": "Kalmar",
      "pl_si": "Kalmar",
      "pt_br": "Kalmar",
      "pt_pt": "Kalmar",
      "ro_ro": "Kalmar",
      "ru_ru": "Кальмар",
      "sk_sk": "Kalmar",
      "sl_sl": "Kalmar",
      "sr_sp": "Kalmar",
      "sr_sr": "Калмар",
      "sv_se": "Kalmar",
      "tr_tr": "Kalmar",
      "uk_uk": "Кальмар",
      "vi_vn": "Kalmar",
      "zh_cn": "卡尔马",
      "zh_tw": "卡爾馬"
    }
  },
  {
    "Name": "Södertälje",
    "Country": "sweden",
    "X": 23325.07,
    "Y": -46540.0,
    "InGameId": "sodertalje",
    "LocalizationToken": "sodertalje",
    "CountryId": 43,
    "LocalizedNames": {
      "bg_bg": "Сьодертеле",
      "ca_es": "Södertälje",
      "cs_cz": "Södertälje",
      "da_dk": "Södertälje",
      "de_de": "Södertälje",
      "el_gr": "Σέντερτελγε",
      "en_gb": "Södertälje",
      "en_us": "Södertälje",
      "es_es": "Södertälje",
      "es_la": "Södertälje",
      "et_ee": "Södertälje",
      "eu_es": "Södertälje",
      "fi_fi": "Södertälje",
      "fr_ca": "Södertälje",
      "fr_fr": "Södertälje",
      "gl_es": "Södertälje",
      "hr_hr": "Södertälje",
      "hu_hu": "Södertälje",
      "it_it": "Södertälje",
      "ja_jp": "セーデルテリエ",
      "ka_ge": "სედერტელიე",
      "ko_kr": "쇠데르텔리에",
      "lt_lt": "Sioderteljė",
      "lv_lv": "Sēdetēlje",
      "mk_mk": "Седертелје",
      "nl_nl": "Södertälje",
      "no_no": "Södertälje",
      "pl_pl": "Södertälje",
      "pl_si": "Södertälje",
      "pt_br": "Södertälje",
      "pt_pt": "Södertälje",
      "ro_ro": "Södertälje",
      "ru_ru": "Сёдертелье",
      "sk_sk": "Södertälje",
      "sl_sl": "Södertälje",
      "sr_sp": "Sedertelje",
      "sr_sr": "Седертеље",
      "sv_se": "Södertälje",
      "tr_tr": "Södertälje",
      "uk_uk": "Седертельє",
      "vi_vn": "Södertälje",
      "zh_cn": "南泰利耶",
      "zh_tw": "南泰利耶"
    }
  },
  {
    "Name": "Västerås",
    "Country": "sweden",
    "X": 20562.6016,
    "Y": -50738.4336,
    "InGameId": "vasteraas",
    "LocalizationToken": "vasteras",
    "CountryId": 43,
    "LocalizedNames": {
      "bg_bg": "Вестерос",
      "ca_es": "Västerås",
      "cs_cz": "Västerås",
      "da_dk": "Västerås",
      "de_de": "Västerås",
      "el_gr": "Βεστερός",
      "en_gb": "Västerås",
      "en_us": "Västerås",
      "es_es": "Västerås",
      "es_la": "Västerås",
      "et_ee": "Västerås",
      "eu_es": "Västerås",
      "fi_fi": "Västerås",
      "fr_ca": "Västerås",
      "fr_fr": "Västerås",
      "gl_es": "Västerås",
      "hr_hr": "Västerås",
      "hu_hu": "Västerås",
      "it_it": "Västerås",
      "ja_jp": "ヴェステロース",
      "ka_ge": "ვესტეროსი",
      "ko_kr": "베스테로스",
      "lt_lt": "Vesterosas",
      "lv_lv": "Vesterosa",
      "mk_mk": "Вестерос",
      "nl_nl": "Västerås",
      "no_no": "Västerås",
      "pl_pl": "Västerås",
      "pl_si": "Västerås",
      "pt_br": "Västerås",
      "pt_pt": "Västerås",
      "ro_ro": "Västerås",
      "ru_ru": "Вестерос",
      "sk_sk": "Västerås",
      "sl_sl": "Västerås",
      "sr_sp": "Vesteros",
      "sr_sr": "Вестерос",
      "sv_se": "Västerås",
      "tr_tr": "Vasteras",
      "uk_uk": "Вестерос",
      "vi_vn": "Västerås",
      "zh_cn": "韦斯特罗斯",
      "zh_tw": "韋斯特羅斯"
    }
  },
  {
    "Name": "Stockholm",
    "Country": "sweden",
    "X": 24368.4766,
    "Y": -48316.2227,
    "InGameId": "stockholm",
    "LocalizationToken": "stockholm",
    "CountryId": 43,
    "LocalizedNames": {
      "bg_bg": "Стокхолм",
      "ca_es": "Estocolm",
      "cs_cz": "Stockholm",
      "da_dk": "Stockholm",
      "de_de": "Stockholm",
      "el_gr": "Στοκχόλμη",
      "en_gb": "Stockholm",
      "en_us": "Stockholm",
      "es_es": "Estocolmo",
      "es_la": "Estocolmo",
      "et_ee": "Stockholm",
      "eu_es": "Stockholm",
      "fi_fi": "Tukholma",
      "fr_ca": "Stockholm",
      "fr_fr": "Stockholm",
      "gl_es": "Estocolmo",
      "hr_hr": "Stockholm",
      "hu_hu": "Stockholm",
      "it_it": "Stoccolma",
      "ja_jp": "ストックホルム",
      "ka_ge": "სტოკჰოლმი",
      "ko_kr": "스톡홀름",
      "lt_lt": "Stokholmas",
      "lv_lv": "Stokholma",
      "mk_mk": "Стокхолм",
      "nl_nl": "Stockholm",
      "no_no": "Stockholm",
      "pl_pl": "Sztokholm",
      "pl_si": "Sztokholm",
      "pt_br": "Estocolmo",
      "pt_pt": "Estocolmo",
      "ro_ro": "Stockholm",
      "ru_ru": "Стокгольм",
      "sk_sk": "Štokholm",
      "sl_sl": "Stockholm",
      "sr_sp": "Stokholm",
      "sr_sr": "Стокхолм",
      "sv_se": "Stockholm",
      "tr_tr": "Stokholm",
      "uk_uk": "Стокгольм",
      "vi_vn": "Stockholm",
      "zh_cn": "斯德哥尔摩",
      "zh_tw": "斯德哥爾摩"
    }
  },
  {
    "Name": "Nynäshamn",
    "Country": "sweden",
    "X": 24731.8555,
    "Y": -46499.9375,
    "InGameId": "nynashamn",
    "LocalizationToken": "nynashamn",
    "CountryId": 43,
    "LocalizedNames": {
      "bg_bg": "Нюнесхамн",
      "ca_es": "Nynäshamn",
      "cs_cz": "Nynäshamn",
      "da_dk": "Nynäshamn",
      "de_de": "Nynäshamn",
      "el_gr": "Νινεσχάμν",
      "en_gb": "Nynäshamn",
      "en_us": "Nynäshamn",
      "es_es": "Nynäshamn",
      "es_la": "Nynäshamn",
      "et_ee": "Nynäshamn",
      "eu_es": "Nynäshamn",
      "fi_fi": "Nynäshamn",
      "fr_ca": "Nynäshamn",
      "fr_fr": "Nynäshamn",
      "gl_es": "Nynäshamn",
      "hr_hr": "Nynäshamn",
      "hu_hu": "Nynäshamn",
      "it_it": "Nynäshamn",
      "ja_jp": "ニュネスハムン",
      "ka_ge": "ნიუნესჰამნი",
      "ko_kr": "뉘네스함",
      "lt_lt": "Nineshamnas",
      "lv_lv": "Nīneshamna",
      "mk_mk": "Нинешам",
      "nl_nl": "Nynäshamn",
      "no_no": "Nynäshamn",
      "pl_pl": "Nynäshamn",
      "pl_si": "Nynäshamn",
      "pt_br": "Nynäshamn",
      "pt_pt": "Nynäshamn",
      "ro_ro": "Nynäshamn",
      "ru_ru": "Нюнесхамн",
      "sk_sk": "Nynäshamn",
      "sl_sl": "Nynäshamn",
      "sr_sp": "Nineshamn",
      "sr_sr": "Нинесхамн",
      "sv_se": "Nynäshamn",
      "tr_tr": "Nynäshamn",
      "uk_uk": "Нинесгамн",
      "vi_vn": "Nynäshamn",
      "zh_cn": "尼奈斯港",
      "zh_tw": "尼奈斯港"
    }
  },
  {
    "Name": "Uppsala",
    "Country": "sweden",
    "X": 23521.3281,
    "Y": -52104.625,
    "InGameId": "uppsala",
    "LocalizationToken": "uppsala",
    "CountryId": 43,
    "LocalizedNames": {
      "bg_bg": "Упсала",
      "ca_es": "Uppsala",
      "cs_cz": "Uppsala",
      "da_dk": "Uppsala",
      "de_de": "Uppsala",
      "el_gr": "Ουψάλα",
      "en_gb": "Uppsala",
      "en_us": "Uppsala",
      "es_es": "Upsala",
      "es_la": "Upsala",
      "et_ee": "Uppsala",
      "eu_es": "Uppsala",
      "fi_fi": "Uppsala",
      "fr_ca": "Uppsala",
      "fr_fr": "Uppsala",
      "gl_es": "Uppsala",
      "hr_hr": "Uppsala",
      "hu_hu": "Uppsala",
      "it_it": "Uppsala",
      "ja_jp": "ウプサラ",
      "ka_ge": "უფსალა",
      "ko_kr": "웁살라",
      "lt_lt": "Upsala",
      "lv_lv": "Upsāla",
      "mk_mk": "Упсала",
      "nl_nl": "Uppsala",
      "no_no": "Uppsala",
      "pl_pl": "Uppsala",
      "pl_si": "Uppsala",
      "pt_br": "Uppsala",
      "pt_pt": "Uppsala",
      "ro_ro": "Uppsala",
      "ru_ru": "Уппсала",
      "sk_sk": "Uppsala",
      "sl_sl": "Uppsala",
      "sr_sp": "Upsala",
      "sr_sr": "Упсала",
      "sv_se": "Uppsala",
      "tr_tr": "Uppsala",
      "uk_uk": "Уппсала",
      "vi_vn": "Uppsala",
      "zh_cn": "乌普萨拉",
      "zh_tw": "烏普薩拉"
    }
  },
  {
    "Name": "Kapellskär",
    "Country": "sweden",
    "X": 27787.4141,
    "Y": -51382.78,
    "InGameId": "kapellskar",
    "LocalizationToken": "kapellskar",
    "CountryId": 43,
    "LocalizedNames": {
      "bg_bg": "Капелскар",
      "ca_es": "Kapellskär",
      "cs_cz": "Kapellskär",
      "da_dk": "Kapellskär",
      "de_de": "Kapellskär",
      "el_gr": "Κάπελχερ",
      "en_gb": "Kapellskär",
      "en_us": "Kapellskär",
      "es_es": "Kapellskär",
      "es_la": "Kapellskär",
      "et_ee": "Kapellskär",
      "eu_es": "Kapellskär",
      "fi_fi": "Kapellskär",
      "fr_ca": "Kapellskär",
      "fr_fr": "Kapellskär",
      "gl_es": "Kapellskär",
      "hr_hr": "Kapellskär",
      "hu_hu": "Kapellskär",
      "it_it": "Kapellskär",
      "ja_jp": "カペルシャー",
      "ka_ge": "კაპერლშერი",
      "ko_kr": "카펠스카",
      "lt_lt": "Kapellskär",
      "lv_lv": "Kapelšēra",
      "mk_mk": "Капелшер",
      "nl_nl": "Kapellskär",
      "no_no": "Kapellskär",
      "pl_pl": "Kapellskär",
      "pl_si": "Kapellskär",
      "pt_br": "Kapellskär",
      "pt_pt": "Kapellskär",
      "ro_ro": "Kapellskär",
      "ru_ru": "Капельшер",
      "sk_sk": "Kapellskär",
      "sl_sl": "Kapellskär",
      "sr_sp": "Kapelsker",
      "sr_sr": "Капелскер",
      "sv_se": "Kapellskär",
      "tr_tr": "Kapellskär",
      "uk_uk": "Капельшир",
      "vi_vn": "Kapellskär",
      "zh_cn": "卡珀尔谢尔",
      "zh_tw": "卡珀爾謝爾"
    }
  },
  {
    "Name": "Esbjerg",
    "Country": "denmark",
    "X": -5274.55469,
    "Y": -27976.0156,
    "InGameId": "esbjerg",
    "LocalizationToken": "esbjerg",
    "CountryId": 21,
    "LocalizedNames": {
      "bg_bg": "Есбер",
      "ca_es": "Esbjerg",
      "cs_cz": "Esbjerg",
      "da_dk": "Esbjerg",
      "de_de": "Esbjerg",
      "el_gr": "Έσμπιεργκ",
      "en_gb": "Esbjerg",
      "en_us": "Esbjerg",
      "es_es": "Esbjerg",
      "es_la": "Esbjerg",
      "et_ee": "Esbjerg",
      "eu_es": "Esbjerg",
      "fi_fi": "Esbjerg",
      "fr_ca": "Esbjerg",
      "fr_fr": "Esbjerg",
      "gl_es": "Esbjerg",
      "hr_hr": "Esbjerg",
      "hu_hu": "Esbjerg",
      "it_it": "Esbjerg",
      "ja_jp": "エスビャウ",
      "ka_ge": "ესბერგი",
      "ko_kr": "에스비에르",
      "lt_lt": "Esbjergas",
      "lv_lv": "Esbjerga",
      "mk_mk": "Есбјерг",
      "nl_nl": "Esbjerg",
      "no_no": "Esbjerg",
      "pl_pl": "Esbjerg",
      "pl_si": "Esbjerg",
      "pt_br": "Esbjerg",
      "pt_pt": "Esbjerg",
      "ro_ro": "Esbjerg",
      "ru_ru": "Эсбьерг",
      "sk_sk": "Esbjerg",
      "sl_sl": "Esbjerg",
      "sr_sp": "Esbjerg",
      "sr_sr": "Есбјерг",
      "sv_se": "Esbjerg",
      "tr_tr": "Esbjerg",
      "uk_uk": "Есб'єрг",
      "vi_vn": "Esbjerg",
      "zh_cn": "埃斯比约",
      "zh_tw": "埃斯比約"
    }
  },
  {
    "Name": "Kristiansand",
    "Country": "norway",
    "X": -4788.40234,
    "Y": -43061.625,
    "InGameId": "kristiansand",
    "LocalizationToken": "kristiansand",
    "CountryId": 35,
    "LocalizedNames": {
      "bg_bg": "Кристиансан",
      "ca_es": "Kristiansand",
      "cs_cz": "Kristiansand",
      "da_dk": "Kristiansand",
      "de_de": "Kristiansand",
      "el_gr": "Κρίστιανσαντ",
      "en_gb": "Kristiansand",
      "en_us": "Kristiansand",
      "es_es": "Kristiansand",
      "es_la": "Kristiansand",
      "et_ee": "Kristiansand",
      "eu_es": "Kristiansand",
      "fi_fi": "Kristiansand",
      "fr_ca": "Kristiansand",
      "fr_fr": "Kristiansand",
      "gl_es": "Kristiansand",
      "hr_hr": "Kristiansand",
      "hu_hu": "Kristiansand",
      "it_it": "Kristiansand",
      "ja_jp": "クリスチャンサン",
      "ka_ge": "კრისტიანსანი",
      "ko_kr": "크리스티안산",
      "lt_lt": "Kristiansandas",
      "lv_lv": "Kristiansanna",
      "mk_mk": "Кристијансанд",
      "nl_nl": "Kristiansand",
      "no_no": "Kristiansand",
      "pl_pl": "Kristiansand",
      "pl_si": "Kristiansand",
      "pt_br": "Kristiansand",
      "pt_pt": "Kristiansand",
      "ro_ro": "Kristiansand",
      "ru_ru": "Кристиансанн",
      "sk_sk": "Kristiansand",
      "sl_sl": "Kristiansand",
      "sr_sp": "Kristijansand",
      "sr_sr": "Кристијансанд",
      "sv_se": "Kristiansand",
      "tr_tr": "Kristiansand",
      "uk_uk": "Крістіансанн",
      "vi_vn": "Kristiansand",
      "zh_cn": "克里斯蒂安桑",
      "zh_tw": "克里斯蒂安桑"
    }
  },
  {
    "Name": "Stavanger",
    "Country": "norway",
    "X": -10808.8164,
    "Y": -48421.8945,
    "InGameId": "stavanger",
    "LocalizationToken": "stavanger",
    "CountryId": 35,
    "LocalizedNames": {
      "bg_bg": "Ставангер",
      "ca_es": "Stavanger",
      "cs_cz": "Stavanger",
      "da_dk": "Stavanger",
      "de_de": "Stavanger",
      "el_gr": "Σταβάνγκερ",
      "en_gb": "Stavanger",
      "en_us": "Stavanger",
      "es_es": "Stavanger",
      "es_la": "Stavanger",
      "et_ee": "Stavanger",
      "eu_es": "Stavanger",
      "fi_fi": "Stavanger",
      "fr_ca": "Stavanger",
      "fr_fr": "Stavanger",
      "gl_es": "Stavanger",
      "hr_hr": "Stavanger",
      "hu_hu": "Stavanger",
      "it_it": "Stavanger",
      "ja_jp": "スタヴァンゲル",
      "ka_ge": "სტავანგერი",
      "ko_kr": "스타방에르",
      "lt_lt": "Stavangeris",
      "lv_lv": "Stavangera",
      "mk_mk": "Ставангер",
      "nl_nl": "Stavanger",
      "no_no": "Stavanger",
      "pl_pl": "Stavanger",
      "pl_si": "Stavanger",
      "pt_br": "Stavanger",
      "pt_pt": "Stavanger",
      "ro_ro": "Stavanger",
      "ru_ru": "Ставангер",
      "sk_sk": "Stavanger",
      "sl_sl": "Stavanger",
      "sr_sp": "Stavanger",
      "sr_sr": "Ставангер",
      "sv_se": "Stavanger",
      "tr_tr": "Stavanger",
      "uk_uk": "Ставангер",
      "vi_vn": "Stavanger",
      "zh_cn": "斯塔万格",
      "zh_tw": "斯塔萬格"
    }
  },
  {
    "Name": "Bergen",
    "Country": "norway",
    "X": -10571.707,
    "Y": -56397.7656,
    "InGameId": "bergen",
    "LocalizationToken": "bergen",
    "CountryId": 35,
    "LocalizedNames": {
      "bg_bg": "Берген",
      "ca_es": "Bergen",
      "cs_cz": "Bergen",
      "da_dk": "Bergen",
      "de_de": "Bergen",
      "el_gr": "Μπέργκεν",
      "en_gb": "Bergen",
      "en_us": "Bergen",
      "es_es": "Bergen",
      "es_la": "Bergen",
      "et_ee": "Bergen",
      "eu_es": "Bergen",
      "fi_fi": "Bergen",
      "fr_ca": "Bergen",
      "fr_fr": "Bergen",
      "gl_es": "Bergen",
      "hr_hr": "Bergen",
      "hu_hu": "Bergen",
      "it_it": "Bergen",
      "ja_jp": "ベルゲン",
      "ka_ge": "ბერგენი",
      "ko_kr": "베르겐",
      "lt_lt": "Bergenas",
      "lv_lv": "Bergena",
      "mk_mk": "Берген",
      "nl_nl": "Bergen",
      "no_no": "Bergen",
      "pl_pl": "Bergen",
      "pl_si": "Bergen",
      "pt_br": "Bergen",
      "pt_pt": "Bergen",
      "ro_ro": "Bergen",
      "ru_ru": "Берген",
      "sk_sk": "Bergen",
      "sl_sl": "Bergen",
      "sr_sp": "Bergen",
      "sr_sr": "Берген",
      "sv_se": "Bergen",
      "tr_tr": "Bergen",
      "uk_uk": "Берген",
      "vi_vn": "Bergen",
      "zh_cn": "卑尔根",
      "zh_tw": "卑爾根"
    }
  }
];
export default g_cities_json;