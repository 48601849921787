import Nawigacja from "../Komponenty/Nawigacja"
import Axios from "axios";
import g_cities_json from "../Komponenty/cities";
import g_countries_json from "../Komponenty/countries";
import MapaETS from "./MapaETS";

export default function Mapa(props){
    return(
        <>
			<Nawigacja />
            <MapaETS />
        </>
    )
};